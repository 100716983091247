import { useState, useEffect } from 'react';
import { SelectChangeEvent } from '@mui/material';

import api from 'services/api';
import { useCycle } from 'hooks/useCycles';

import { BreadcrumbBar } from 'components/BreadcrumbBar';
import { ContentContainer } from 'components/ContentContainer';

import { ITeamData } from 'domains/Teams/interfaces/ITeamData';
import { SearchTeamsComponent } from 'components/SearchTeams';
import { Main } from '../components/Main';
import { Nav } from '../components/Nav';
import { Search } from '../components/Search';
import { TeamContent } from '../components/TeamContent';
import { ObjectiveList } from '../components/TeamContent/ObjectiveList';

import { IObjectiveData } from '../interfaces/IObjectiveData';
import { FormNewObjectives } from '../components/FormNewObjectives';
import { FormNewTasks } from '../components/FormNewTasks';
import { DragAndDrop } from '../components/DragAndDrop';

export const ObjectivesTeamPage: React.FC = () => {
	const { cycle } = useCycle();

	const [isSwitchActived, setIsSwitchActived] = useState(false);
	const [teamFilter, setTeamFilter] = useState('');
	const [teamList, setTeamList] = useState<ITeamData[]>([]);
	const [objectiveList, setObjectiveList] = useState<IObjectiveData[]>();
	const [reloadTasks, setReloadTasks] = useState(false);
	const [selectedTeamId, setSelectedTeamId] = useState('');

	const handleChangeFilterPerson = (event: SelectChangeEvent) => {
		setTeamFilter(event.target.value as string);
	};

	const handleSwitchActived = () => {
		setIsSwitchActived(!isSwitchActived);
	};

	const handleSelectedTeamId = (newId: string) => {
		setSelectedTeamId(newId);
	};

	const handleObjectivesTeamData = () => {
		api.get(`cycles/${cycle.id}/objectives/teams/${selectedTeamId}`).then(response => {
			setObjectiveList(response.data.data);
		});
	};

	useEffect(() => {
		if (cycle.id) {
			api.get(`cycles/${cycle.id}/teams`).then(response => {
				setTeamList(response.data.data);
			});

			if (teamList[0]?.id) setSelectedTeamId(`${teamList[0].id}`);

			api.get(`cycles/${cycle.id}/objectives/teams/${selectedTeamId}`).then(response => {
				setObjectiveList(response.data.data);
			});
		}
	}, [cycle]);

	useEffect(() => {
		if (cycle.id) handleObjectivesTeamData();
	}, [cycle, selectedTeamId]);

	const switchProps = {
		leftTitle: 'OKRs',
		rightTitle: 'Tarefas',
		handleSwitchActived,
		switchState: isSwitchActived,
	};

	return (
		<>
			<ContentContainer>
				<Main>
					<Nav pageType="team" />

					<div>
						<BreadcrumbBar
							title={isSwitchActived ? 'Tarefas Cadastradas' : 'Objetivos Cadastrados'}
							buttonContent={isSwitchActived ? 'Nova Tarefa' : 'Novo Objetivo'}
							hasButton
							modalTitle={isSwitchActived ? 'Nova Tarefa' : 'Novo Objetivo'}
							hasSwitch
							switchProps={switchProps}
						>
							{isSwitchActived && (
								<FormNewTasks
									isEditForm={false}
									isDetailsForm={false}
									reloadTasks={() => setReloadTasks(!reloadTasks)}
								/>
							)}
							{!isSwitchActived && <FormNewObjectives isEditForm={false} />}
						</BreadcrumbBar>

						{!isSwitchActived && teamList.length && (
							<>
								<Search onChange={handleChangeFilterPerson} />
								<TeamContent>
									<SearchTeamsComponent
										teamsList={teamList}
										selectedTeamId={selectedTeamId}
										handleSelectedTeamId={handleSelectedTeamId}
									/>

									{objectiveList && objectiveList.length > 0 && (
										<ObjectiveList objectiveList={objectiveList} objectiveFilter={teamFilter} />
									)}
								</TeamContent>
							</>
						)}
					</div>
					{isSwitchActived && (
						<>
							<div className="dnd-selectId-container">
								<SearchTeamsComponent
									teamsList={teamList || []}
									selectedTeamId={selectedTeamId}
									handleSelectedTeamId={handleSelectedTeamId}
								/>
								<DragAndDrop reloadTasks={() => setReloadTasks(!reloadTasks)} teamId={selectedTeamId} />
							</div>
						</>
					)}
				</Main>
			</ContentContainer>
		</>
	);
};
