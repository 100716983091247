import { useState, useEffect } from 'react';

import api from 'services/api';
import { deleteKeyResults } from 'domains/Objectives/services/ObjectivesServices';

import { Modal } from 'components/Modal';
import { ModalProvider } from 'hooks/useModal';
import { IPersonData } from 'domains/Persons/interfaces/IPersonData';
import { Container as ContainerSmallModal } from 'domains/Objectives/components/InfoModal/style';
import { FormEditKeyResultsProvider } from 'domains/Objectives/contexts/FormEditKeyResults';
import { FiEdit, FiInfo, FiTrash2, FiRefreshCcw, FiBarChart } from 'react-icons/fi';
import { Button } from 'components/Button';
import {
	Container,
	Head,
	Result,
	Weight,
	Description,
	Responsible,
	Progress,
	Numbers,
	ModalButtonSettings,
	Overlay,
} from './style';

import doubleArrowIcon from '../../assets/double-arrow-up.svg';

import { ICardKRsProps } from '../../interfaces/IObjectiveData';
import { ContentModalDetailsKeyResults } from '../DetailsModalKRs';
import { FormNewKeyResults } from '../FormNewKeyResults';
import { ContentModalKeyResultsIndicators } from '../ContentModalKeyResultsIndicators';
import { FormUpdateIndicators } from '../ContentModalKeyResultsIndicators/FormUpdateIndicators';

interface CardProps {
	kr: ICardKRsProps;
	objectiveId: string;
}

const modalItems = [
	{
		hasModal: true,
		title: 'Detalhes do Key Result',
		name: 'Ver detalhes',
		icon: 'FiInfo',
	},
	{
		hasModal: true,
		title: 'Detalhes do Indicador',
		name: 'Visualizar Indicadores',
		icon: 'FiBarChart',
	},
	{
		hasModal: true,
		title: 'Atualizar Key Result',
		name: 'Atualizar Key Result',
		icon: 'FiRefresh',
	},
	{
		hasModal: true,
		title: 'Excluir Key Result',
		name: 'Excluir Key Result',
		icon: 'FiTrash2',
	},
];

export const CardKRs: React.FC<CardProps> = ({ kr, objectiveId }) => {
	const [responsible, setResponsible] = useState<IPersonData>({} as IPersonData);

	const [openModalActions, setOpenModalActions] = useState(false);
	const [openModalDetailsKeyResult, setOpenModalDetailsKeyResult] = useState(false);
	const [openModalEditKeyResult, setOpenModalEditKeyResult] = useState(false);
	const [openModalKeyResultIndicators, setOpenModalKeyResultIndicators] = useState(false);

	const [isEditKeyResultForm, setIsEditKeyResultForm] = useState(false);
	const [isEditKeyResultIndicator, setIsEditKeyResultIndicator] = useState(false);

	useEffect(() => {
		api.get(`users/${kr.responsableId}`).then(response => {
			setResponsible(response.data.data);
		});
	}, []);

	const handleOpenModalSettings = async (type: string) => {
		if (openModalActions) {
			if (type === 'Ver detalhes') {
				setOpenModalDetailsKeyResult(true);
				setOpenModalEditKeyResult(false);
			}
			if (type === 'Atualizar Key Result') {
				setOpenModalEditKeyResult(true);
				setOpenModalDetailsKeyResult(false);
			}
			if (type === 'Excluir Key Result') {
				await deleteKeyResults(objectiveId, kr.id);
			}
			if (type === 'Visualizar Indicadores') {
				setOpenModalKeyResultIndicators(true);
			}
		}
		setOpenModalActions(!openModalActions);
	};

	return (
		<Container>
			<Head>
				<img src={doubleArrowIcon} alt="Double Arrow" />

				<div>
					<Result>
						Finalidade: <span>{kr.result}</span>
					</Result>

					<>
						<Overlay openModalActions={openModalActions} onClick={() => setOpenModalActions(false)} />
						<ModalButtonSettings onClick={() => setOpenModalActions(true)} className="btn-smallModal">
							<span />
							<span />
							<span />
						</ModalButtonSettings>

						<ContainerSmallModal openModalSettings={openModalActions} openModal={openModalActions}>
							{modalItems !== undefined &&
								openModalActions &&
								modalItems.map(item => (
									<button
										onClick={() => handleOpenModalSettings(item.name)}
										key={item.name}
										className={`item-${item.icon}`}
									>
										{item.icon === 'FiInfo' && <FiInfo />}
										{item.icon === 'FiEdit' && <FiEdit />}
										{item.icon === 'FiTrash2' && <FiTrash2 />}
										{item.icon === 'FiRefresh' && <FiRefreshCcw />}
										{item.icon === 'FiBarChart' && <FiBarChart />}
										{item.name}
									</button>
								))}
						</ContainerSmallModal>

						<ModalProvider>
							<Modal
								title={isEditKeyResultForm ? 'Atualizar Key Result' : 'Detalhes do Key Result'}
								openModalValue={openModalDetailsKeyResult}
								handleOpenModalValue={() => setOpenModalDetailsKeyResult(!openModalDetailsKeyResult)}
							>
								{!isEditKeyResultForm && (
									<>
										<ContentModalDetailsKeyResults keyResult={kr} responsable={responsible} />
										<Button
											bgColor="primary"
											className="btn-edit-kr"
											onClick={() => setIsEditKeyResultForm(true)}
										>
											Editar KeyResult
										</Button>
									</>
								)}
								{isEditKeyResultForm && (
									<FormEditKeyResultsProvider
										keyResult={{ ...kr, deliveryDate: `${new Date()}`, finalDate: `${new Date()}` }}
										keyResultId={kr.id}
										objectiveId={objectiveId}
									>
										<FormNewKeyResults
											isEditForm
											handleSubmitKeyResult={() => setIsEditKeyResultForm(false)}
										/>
									</FormEditKeyResultsProvider>
								)}
							</Modal>
						</ModalProvider>

						<ModalProvider>
							<Modal
								title="Atualizar Key Result"
								openModalValue={openModalEditKeyResult}
								handleOpenModalValue={() => setOpenModalEditKeyResult(!openModalEditKeyResult)}
							>
								<FormEditKeyResultsProvider
									keyResult={{ ...kr, deliveryDate: `${new Date()}`, finalDate: `${new Date()}` }}
									keyResultId={kr.id}
									objectiveId={objectiveId}
								>
									<FormNewKeyResults
										isEditForm
										handleSubmitKeyResult={() => setOpenModalEditKeyResult(!openModalEditKeyResult)}
									/>
								</FormEditKeyResultsProvider>
							</Modal>
						</ModalProvider>

						<ModalProvider>
							<Modal
								title={isEditKeyResultIndicator ? 'Atualizar Indicador' : 'Detalhes do Indicador'}
								openModalValue={openModalKeyResultIndicators}
								handleOpenModalValue={() => {
									setOpenModalKeyResultIndicators(!openModalKeyResultIndicators);
									setIsEditKeyResultIndicator(false);
								}}
								size={isEditKeyResultIndicator ? 'normal' : 'large'}
							>
								{!isEditKeyResultIndicator && (
									<ContentModalKeyResultsIndicators
										keyResult={kr}
										handleModalContent={() => setIsEditKeyResultIndicator(true)}
									/>
								)}
								{isEditKeyResultIndicator && (
									<FormUpdateIndicators
										keyResult={kr}
										handleModalContent={() => setIsEditKeyResultIndicator(false)}
									/>
								)}
							</Modal>
						</ModalProvider>
					</>

					<Weight>
						Peso: <span>{kr.weight} | Atualizado há 10 dias</span>
					</Weight>
				</div>
			</Head>

			<Description>{kr.description}</Description>

			{responsible.person !== undefined && (
				<Responsible>
					<img src={responsible.image} />
					<p>{responsible.person.name}</p>
				</Responsible>
			)}

			<Progress progress={kr.progress}>
				<div>
					<span />
				</div>

				{`${kr.progress}%`}
			</Progress>

			<Numbers>631.000 / 1.750.000</Numbers>
		</Container>
	);
};
