interface formatsProps {
	[key: string]: { mask: string; prefix: string };
}

export const formats: formatsProps = {
	cep: {
		mask: '#####-###',
		prefix: '',
	},
	cnpj: {
		mask: '##.###.###/####-##',
		prefix: '',
	},
	cpf: {
		mask: '###.###.###-##',
		prefix: '',
	},
	telephone: {
		mask: '(##) ##### ####',
		prefix: '',
	},
};
