import styled, { css } from 'styled-components';

interface ContainerProps {
	bgColor: string;
	color: string;
	disabled: boolean;
}

export const Container = styled.button<ContainerProps>`
	display: flex;
	align-items: center;
	justify-content: center;

	max-width: 360px;
	width: 100%;
	height: 3rem;

	background-color: ${props => props.bgColor};

	border: none;
	border-radius: 0.25rem;
	box-shadow: var(--elevation-200);

	font: var(--body-md-500);
	color: ${props => props.color};

	transition: filter 0.2s ease-in-out;

	&:hover {
		filter: brightness(88%);
	}

	${props =>
		props.disabled &&
		css`
			& {
				background-color: var(--gray-300);
				color: var(--gray-400);
				cursor: default;
			}
		`}
`;
