import styled, { css } from 'styled-components';

interface TableRowProps {
	selected?: boolean;
}

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 50px;

	.modal-btn {
		margin-left: 0;
		width: 100%;

		button {
			max-width: 500px;
		}
	}

	.submit-btn {
		margin-top: 2.5rem;
		width: 12rem;
		margin-left: 70%;
	}

	.dates-inputs {
		display: flex;
	}

	@media (max-width: 425px) {
		.submit-btn {
			margin-left: auto;
		}

		.dates-inputs {
			flex-direction: column;
			gap: 1rem;
			width: 100%;
		}
	}
`;

export const TableContainer = styled.div`
	& span {
		margin-top: 15px;
		margin-bottom: 15px;
		width: 100%;
	}

	width: 100%;
	height: 269px;
	max-height: 313px;
	min-height: 313px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: var(--gray-200);
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--gray-400);
		border-radius: 4px;
	}
`;

export const TableRow = styled.div<TableRowProps>`
	width: 100%;

	& b {
		font: var(--body-lg-500);
		color: var(--gray-700);
	}

	& p {
		font: var(--body-xs-500);
		color: var(--gray-500);
		text-align: left;
		width: 100%;
	}

	${props =>
		props.selected &&
		css`
			position: relative;
			width: 100%;

			& span {
				display: block;
				position: absolute;
				left: -24px;
				top: -15px;
				height: 100%;
				width: 1rem;
				background: var(--terciary-400);
			}
		`}
`;
