import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;

	display: flex;
	flex-direction: column;
`;

export const Header = styled.div`
	width: 100%;
	padding: 2rem 2.8rem;

	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 1rem;

	& h1 {
		font-family: 'Fira Sans';
		font-style: normal;
		font-weight: 500;
		font-size: 22px;
		line-height: 125%;
		color: var(--gray-700);
	}

	& button {
		width: 247px;
		height: 48px;
		text-align: center;

		background: none;
		background-color: var(--terciary-400);
		border-radius: 4px;
		border: none;
		box-shadow: var(--elevation-200);

		font-family: 'Fira Sans';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 135%;
		color: white;
	}
`;
