import { CardObjectives } from '../CardObjectives';

import { Container } from './style';

import { IObjectiveList } from '../../interfaces/IObjectiveData';

export const ObjectiveList: React.FC<IObjectiveList> = ({ objectiveList, objectiveFilter }) => (
	<Container>
		{objectiveList
			.filter(
				objective =>
					objective.name !== undefined &&
					objective.name.toLowerCase().includes(objectiveFilter.toLowerCase()),
			)
			.map(item => (
				<CardObjectives key={item.id} objective={item} />
			))}
	</Container>
);
