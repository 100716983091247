import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;

	& > div {
		padding: 0 3rem 2.5rem;
	}

	& > div > div {
		display: flex;
		gap: 1rem;
	}

	& > div > form {
		display: flex;
		gap: 1.5rem;

		margin-bottom: 1rem;

		& fieldset {
			height: 3.5rem;
		}

		& fieldset:last-child {
			max-width: 15.5rem;
			width: 100%;
		}
	}

	@media (max-width: 48rem) {
		& > div {
			display: flex;
			flex-direction: column;

			max-width: 32rem;

			margin: 0 auto;
			padding: 0 1.25rem;
		}

		& form fieldset {
			margin: 1rem 0;
		}
	}

	.inputs-container {
		display: flex;
		gap: 2rem;
		width: 100%;

		@media (max-width: 426px) {
			flex-direction: column;
			gap: 0rem;

			fieldset {
				max-width: 25rem !important;
			}
		}

		fieldset {
			width: 100%;
		}
	}
`;
