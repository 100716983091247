import api from '../../../services/api';
import { IFormNewCycle } from '../Interfaces/ICompaniesCyclesDataRequest';
import { INewCompanieFormData } from '../Interfaces/INewCompanieFormData';

export const storeNewCompanieRequest = async (data: INewCompanieFormData) => {
	await api
		.post('/companies', data)
		.then(response => {
			console.log(response.data);
		})
		.catch(error => console.log(error.message));
};

export const getCompaniesRequest = async () => {
	const companiesData = await api.get('/companies').then(response => response.data);

	return companiesData.data;
};

export const getCompanieByIdRequest = async (id: string) => {
	const companiesData = await api.get(`/companies/${id}`).then(response => response.data);

	return companiesData.data;
};

export const getFilteredCompaniesRequest = async (socialReason: string) => {
	const companiesData = await api.get(`/companies/?filter=${socialReason}`).then(response => response.data);

	return companiesData.data;
};

export const putSelectedCompanieRequest = async (companyData: INewCompanieFormData) => {
	const data = await api
		.put(`/companies/${companyData.id}`, companyData)
		.then(response => response.data)
		.catch(error => error.message);

	return data;
};

export const getDownloadModel = async () => {
	const data = await api
		.get('/users/download-model', {
			responseType: 'blob',
		})
		.then(response => response.data);

	return data;
};

export const storeUploadModel = async (newModel: FormData) => {
	const headers = {
		'Content-Type': 'multipart/form-data',
	};

	await api.post('/users/upload-model', newModel, { headers }).catch(error => console.log(error.message));
};

export const getUsersRequest = async () => {
	const data = await api.get('/users').then(response => response.data);

	return data.data;
};

export const getCompanieCyclesRequest = async (id: number) => {
	const data = await api.get('/cycles', { params: { companyId: id } }).then(response => response.data);

	return data.data;
};

export const storeNewCycleRequest = async (data: IFormNewCycle) => {
	await api.post('/cycles', data).catch(error => console.log(error.message));
};

export const putCycleRequest = async (data: IFormNewCycle, id: number) => {
	await api.put(`/cycles/${id}`, data).catch(error => console.log(error.message));
};

export const getCompanieModulesRequest = async (id: number) => {
	const cycle = JSON.parse(localStorage.getItem('@Advy:cycle')!);

	const data = await api
		.get(`/cycles/${cycle.id}/modules`, { params: { companyId: id } })
		.then(response => response.data);

	return data.data;
};

export const storeNewModuleRequest = async (data: IFormNewCycle) => {
	await api.post('/modules', data).catch(error => console.log(error.message));
};

export const putModuleRequest = async (data: IFormNewCycle, id: number) => {
	await api.put(`/modules/${id}`, data).catch(error => console.log(error.message));
};
