import { KeyResultMonitoring } from '../KeyResultMonitoring';
import { KeyResultsEvolution } from '../KeyResultsEvolution';
import { OkrsConclusion } from '../OkrsConclusion';
import { OKRsProgress } from '../OKRsProgress';
import { PieCharts } from '../PieCharts';
import { Performance } from '../Performance';
import { Section } from './style';

export const CompanyPageView = () => (
	<Section>
		<OKRsProgress titles={['OKRs Criadas', 'Progresso das OKRs']} pageView="company" />
		<PieCharts pageView="company" />
		<KeyResultsEvolution pageView="company" />
		<KeyResultMonitoring pageView="company" />
		<OkrsConclusion />
		<Performance pageView="company" />
	</Section>
);
