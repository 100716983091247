import styled from 'styled-components';

export const Container = styled.div`
	padding-top: 2rem;

	& form {
		margin-top: 2rem;
		fieldset {
			height: 3.5rem;

			margin-bottom: 1rem;

			border: none;
		}

		fieldset:last-child {
			margin-bottom: 1.5rem;
		}

		.MuiAutocomplete-root {
			height: 3rem;

			margin-bottom: 2rem;
		}

		button {
			margin-left: auto;
		}
	}
`;

export const ProfileContainer = styled.div`
	padding-top: 2rem;
	display: flex;
	flex-direction: column;

	& .add-profile-btn {
		background: none;
		border: none;
		display: flex;
		align-items: center;
		gap: 0.4rem;
		color: var(--blue-400);

		font-family: 'Fira Sans';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;

		& svg {
			height: 1.5rem;
			width: 1.5rem;
		}
	}

	.buttons-container-end {
		margin-top: 3rem;
		display: flex !important;
		flex-wrap: wrap;
		flex-direction: row;
		width: 100%;
		justify-content: flex-end;
		gap: 1rem;
		justify-self: flex-end;

		@media (min-width: 49rem) {
			button {
				max-width: 13rem !important;
			}
		}

		& .back-btn {
			background-color: transparent;
			border: 1px solid var(--primary-400);
			box-sizing: border-box;
			color: var(--primary-400);
			border-radius: 4px;
			height: 3rem;
			box-shadow: var(--elevation-200);
			width: 100%;
		}
	}
`;

export const ProfilesList = styled.div`
	width: 100%;
	height: auto;

	div {
		display: flex;
		align-items: center;
		justify-content: space-between;

		p {
			font-family: 'Fira Sans';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
		}

		button {
			background: none;
			border: none;
			color: var(--red-400);

			& svg {
				height: 1.2rem;
				width: 1.2rem;
			}
		}
	}
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;

	width: 17rem;

	margin: 1.5rem auto;

	& > div {
		position: relative;

		display: flex;
		flex-direction: column;
		align-items: center;

		width: 100%;

		& > span {
			display: flex;
			align-items: center;
			justify-content: center;

			height: 1.5rem;
			width: 1.5rem;

			margin-bottom: 0.5rem;

			background-color: var(--gray-200);

			border-radius: 50%;

			font: var(--body-sm-400);
			color: var(--gray-400);
		}

		& p {
			font: var(--body-xs-400);
			color: var(--gray-400);
		}

		&:first-child > span {
			background-color: var(--secondary-400);

			font: var(--body-sm-500);
			color: var(--white);
		}

		&:first-child > p {
			color: var(--secondary-400);
		}
	}

	& > div:first-child::after {
		content: '';
		position: absolute;
		top: 24%;
		left: 56%;
		z-index: -1;

		height: 1px;
		width: 8rem;

		background-color: var(--gray-300);
	}
`;

export const ThreeColumns = styled.div`
	display: flex;
	justify-content: space-between;

	height: auto;

	& fieldset {
		width: 11.5rem;

		& input,
		& label {
			width: 100%;
		}
	}

	@media (max-width: 48rem) {
		flex-direction: column;
		width: 100%;

		& fieldset {
			width: 100%;
		}

		.telephone-input {
			display: flex;
		}
	}
`;
