import api from 'services/api';
import { IObjectivesFormData } from '../interfaces/FormNewObjectivesData';
import { IKeyResultData } from '../interfaces/IFormNewKeyResultsData';
import { IFormPutIndicator } from '../interfaces/IIndicators';

const cycle = JSON.parse(localStorage.getItem('@Advy:cycle')!);

export const getObjectivesList = async () => {
	const data = await api
		.get(`cycles/${cycle.id}/objectives`)
		.then(response => response.data)
		.catch(error => error.message);

	return data.data;
};

export const getCompanyObjectives = async () => {
	const data = await api.get(`/cycles/${cycle.id}/objectives/company`).then(response => response.data);

	return data;
};

export const getManagersRequest = async () => {
	const data = await api.get('/users').then(response => response.data);

	return data;
};

export const getTeamsRequest = async () => {
	const data = await api.get(`/cycles/${cycle.id}/teams`).then(response => response.data);

	return data;
};

export const getTeamsObjectivesById = async (id: string) => {
	const data = await api.get(`/cycles/${cycle.id}/objectives/teams/${id}`).then(response => response.data);

	return data.data;
};

export const storeNewObjectives = async (data: IObjectivesFormData) => {
	const requestData = await api.post(`/cycles/${cycle.id}/objectives`, data);

	return requestData;
};

export const putEditObjectives = async (data: IObjectivesFormData, id?: string) => {
	const requestData = await api.put(`/cycles/${cycle.id}/objectives/${id}`, data);

	return requestData;
};

export const deleteObjective = async (id: string) => {
	const requestData = await api.delete(`/cycles/${cycle.id}/objectives/${id}`);

	return requestData;
};

export const getAreasRequest = async () => {
	const data = await api.get('/areas').then(response => response.data);

	return data.data;
};

export const getUserByIdRequest = async (id: string) => {
	const data = await api.get(`/users/${id}`).then(response => response.data);

	return data.data;
};

export const putEditObjectivesRequest = async (id: string, objectiveData: IObjectivesFormData) => {
	const data = await api
		.put(`/cycle/${cycle.id}/objectives/${id}`, objectiveData)
		.then(response => response.data)
		.catch(error => error.message);

	return data;
};

export const getKeyResultsList = async (objectiveId: string) => {
	const data = await api
		.get(`/objectives/${objectiveId}/key-results`)
		.then(response => response.data)
		.catch(error => error.message);

	return data.data;
};

export const storeNewKeyResults = async (formData: IKeyResultData, objectiveId: string) => {
	const data = await api
		.post(`/objectives/${objectiveId}/key-results`, formData)
		.then(response => response.data)
		.catch(error => error.message);

	return data;
};

export const putEditKeyResults = async (formData: IKeyResultData, objectiveId: string, id?: string) => {
	const data = await api
		.put(`/objectives/${objectiveId}/key-results/${id}`, formData)
		.then(response => response.data)
		.catch(error => error.message);

	return data;
};

export const deleteKeyResults = async (id: string, objectiveId: string) => {
	const data = await api
		.delete(`/objectives/${objectiveId}/key-results/${id}`)
		.then(response => response.data)
		.catch(error => error.message);

	return data;
};

export const getIndicatorsUpdates = async (id: string) => {
	const data = await api
		.get(`/key-results/${id}/indicator`)
		.then(response => response.data.data)
		.catch(error => error.message);

	return data;
};

export const getIndicatorsStatusOptions = async () => {
	const data = await api
		.get('/indicator/status')
		.then(response => response.data)
		.catch(error => error.message);

	return data.data;
};

export const getMeasuresOptions = async () => {
	const data = await api
		.get('/measure')
		.then(response => response.data.data)
		.catch(error => error.message);

	return data;
};

export const getIndicatorsComments = async (id: string) => {
	const data = await api
		.get(`/indicator/${id}/comments`)
		.then(response => response.data)
		.catch(error => error.message);

	return data.data;
};

export const createComment = async (id: string, comment: string) => {
	const data = await api
		.post(`/indicator/${id}/comments`, {
			description: comment,
		})
		.then(response => response.data)
		.catch(error => error.message);

	return data.data;
};

export const putUpdateIndicator = async (id: string, formData: IFormPutIndicator) => {
	const data = await api
		.put(`/key-results/${id}/indicator`, formData)
		.then(response => response.data)
		.catch(error => error.message);

	return data;
};

export const getManagerObjectivesList = async (managerId: string) => {
	const data = await api
		.get(`/cycles/${cycle.id}/objectives/managers/users/${managerId}`)
		.then(response => response.data)
		.catch(error => error.message);

	return data.data;
};
