import { useContext, useEffect, useState } from 'react';
import { IUser } from 'domains/Authentication/interfaces/IUser';

import { Input } from 'components/Input';
import { CardComponent, ProfileContainer, SelectableButton } from 'domains/Home/pages/style';
import { AuthContext } from 'domains/Authentication/hooks/useAuth';
import { getUserByIdRequest } from 'domains/Objectives/services/ObjectivesServices';

interface ISearchSubordinatesComponentProps {
	selectedSubordinateId: string;
	handleSelectedSubordinateId(id: string): void;
}

export const SearchSubordinatesComponent = ({
	selectedSubordinateId,
	handleSelectedSubordinateId,
}: ISearchSubordinatesComponentProps) => {
	const { user } = useContext(AuthContext);

	const [subordinatesData, setSubordinatesData] = useState<IUser[]>([]);
	const [subordinateFilter, setSubordinateFilter] = useState('');
	const [filteredSubordinateList, setFilteredSubordinateList] = useState<IUser[]>([]);

	const getAllSubordinatesData = async () => {
		user.subordinates.forEach(async (id: string) => {
			const data = await getUserByIdRequest(id);

			setSubordinatesData(() => {
				const index = subordinatesData.indexOf(data);
				if (index === -1) {
					return [...subordinatesData, data];
				}
				return [...subordinatesData];
			});

			setFilteredSubordinateList(() => {
				const index = subordinatesData.indexOf(data);
				if (index === -1) {
					return [...subordinatesData, data];
				}
				return [...subordinatesData];
			});
		});

		handleSelectedSubordinateId(user.subordinates[0]);
	};

	useEffect(() => {
		getAllSubordinatesData();
	}, []);

	useEffect(() => {
		const charReg = /^\s*[a-zA-Z0-9-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ,\s]+\s*$/;

		if (subordinateFilter !== '' && charReg.test(subordinateFilter)) {
			const exp = new RegExp(`${subordinateFilter.trim()}`, 'i');

			const filteredSubordinates = subordinatesData?.filter(user => exp.test(user.person.name));

			setFilteredSubordinateList(filteredSubordinates);
		} else {
			setFilteredSubordinateList(subordinatesData);
		}
	}, [subordinateFilter]);

	return (
		<CardComponent className="search-subordinates-container">
			<Input
				value={subordinateFilter}
				search
				name="team-filter"
				placeholder="Buscar Colaborador"
				label="Buscar Colaborador"
				onChange={e => setSubordinateFilter(e.target.value)}
			/>
			{filteredSubordinateList.map((user, index) => (
				<SelectableButton
					isSelected={`${user.id}` === selectedSubordinateId}
					onClick={() => handleSelectedSubordinateId(`${user.id}`)}
					key={user.id + index}
					style={{
						height: '5rem',
					}}
				>
					<ProfileContainer key={user.id}>
						<img src={user.image} alt="Foto do Líder" />
						<p className="user-description">{user.person.name}</p>
					</ProfileContainer>
				</SelectableButton>
			))}
		</CardComponent>
	);
};
