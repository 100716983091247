import { useEffect, useState, useRef } from 'react';
import { Container, Text, TextContainer } from './style';

interface IProgressBarProps {
	size: number;
	progress: number;
	strokeWidth: number;
	circleOneStroke: string;
	circleTwoStroke: string;
}

export const CircularProgressBar = ({
	size,
	progress,
	strokeWidth,
	circleOneStroke,
	circleTwoStroke,
}: IProgressBarProps) => {
	const [offset, setOffset] = useState(0);

	const circleRef = useRef(null);

	const center = size / 2;
	const radius = size / 2 - strokeWidth / 2;
	const circumference = 2 * Math.PI * radius;

	useEffect(() => {
		const progressOffset = ((100 - progress) / 100) * circumference;
		setOffset(progressOffset);
	}, [setOffset, circumference, progress, offset]);

	return (
		<Container>
			<svg className="svg" width={size} height={size}>
				<circle
					className="svg-circle-bg"
					stroke={circleOneStroke}
					cx={center}
					cy={center}
					r={radius}
					strokeWidth={strokeWidth}
				/>
				<circle
					className="svg-circle"
					ref={circleRef}
					stroke={circleTwoStroke}
					cx={center}
					cy={center}
					r={radius}
					strokeWidth={strokeWidth}
					strokeDasharray={circumference}
					strokeDashoffset={offset || 0}
					strokeLinecap="round"
				/>
			</svg>
			<TextContainer className="text-container-progress">
				<Text>{progress.toFixed(0)}%</Text>
			</TextContainer>
		</Container>
	);
};
