import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Autocomplete, TextField } from '@mui/material';
import { AuthContext } from 'domains/Authentication/hooks/useAuth';
import { getManagersRequest } from 'domains/Objectives/services/ObjectivesServices';
import { IUser } from 'domains/Authentication/interfaces/IUser';
import { getUsersRequest } from 'domains/Companies/services/CompaniesServices';

import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { InfoTeamsProps, ITeamData } from '../../../interfaces/ITeamData';
import { IFormNewTeamData } from '../../../interfaces/IFormNewTeamData';

import { Container } from './style';
import { AutocompleteMembers } from '../../AutocompleteMembers';
import { storeEditTeamRequest } from '../../../services/TeamsServices';

interface IEntityWithIdAndLabel {
	id: string;
	label: string;
}

export const FormEditTeams: React.FC<InfoTeamsProps> = ({ team, handleModalState }) => {
	const { user } = useContext(AuthContext);

	const [selectedTeam] = useState<ITeamData>(() => ({
		id: team.id,
		description: team.description,
		leader: {
			name: team.leader.name,
			id: team.leader.id,
			image: team.leader.image,
		},
		members: team.members.map(member => ({
			name: member.name,
			id: member.id,
			image: member.image,
		})),
	}));

	const [formData] = useState<IFormNewTeamData>({
		id: selectedTeam.id,
		description: selectedTeam.description,
		leader: {
			id: selectedTeam.leader.id,
			name: selectedTeam.leader.name,
			image: selectedTeam.leader.image,
		},
		members: selectedTeam.members.map(member => ({
			name: member.name,
			id: member.id,
		})),
	});

	const [responsable, setResponsable] = useState<IEntityWithIdAndLabel | null>({
		id: team.leader.id,
		label: team.leader.name,
	});
	const [responsableOptions, setResponsableOptions] = useState<IEntityWithIdAndLabel[]>([
		{ id: team.leader.id, label: team.leader.name },
	]);
	const [membersOptions, setMembersOptions] = useState<{ id: string; name: string }[]>([]);

	const handleSubmit = async (values: IFormNewTeamData) => {
		const formatedValues = {
			description: values.description,
			leader: responsable?.id,
			members: values.members.map(member => member.id),
		};

		await storeEditTeamRequest({ id: team.id, ...formatedValues }).then(() => {
			if (handleModalState) handleModalState();
		});
	};

	useEffect(() => {
		const getManagersOptions = async () => {
			const data = await getManagersRequest();
			const managersArray = data.data;
			const formatedArray = managersArray.map((item: IUser) => ({
				id: item.person.id,
				label: item.person.name,
			}));

			setResponsableOptions([...responsableOptions, ...formatedArray]);
		};

		const getUsers = async () => {
			const data = await getUsersRequest();
			const formatedUsersArray = data.map((user: IUser) => ({ id: user.id, name: user.person.name }));
			const filteredUsersArray = formatedUsersArray.filter((user: IUser) =>
				formData.members.includes({ id: user.id, name: user.person?.name }),
			);

			setMembersOptions(filteredUsersArray);
			if (formData.members.length > 0) setMembersOptions([...selectedTeam.members, ...membersOptions]);
		};

		Promise.all([getManagersOptions(), getUsers()]);
	}, []);

	return (
		<Container>
			<Formik
				initialValues={formData}
				onSubmit={async (values: IFormNewTeamData) => {
					handleSubmit(values);
				}}
			>
				{props => (
					<form
						onSubmit={e => {
							e.preventDefault();
							props.handleSubmit(e);
						}}
					>
						<Input
							name="description"
							placeholder=""
							label="Nome do Time"
							value={props.values.description}
							onChange={props.handleChange}
							required
						/>

						<div>
							<Autocomplete
								options={responsableOptions}
								value={responsable}
								onChange={(_, value) => {
									props.setFieldValue('leader.id', value?.id, true);
									setResponsable(value);
								}}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								renderInput={params => (
									<TextField
										{...params}
										label="Gestor"
										variant="standard"
										name="responsibleId"
										required
									/>
								)}
								style={{ marginBottom: '1rem' }}
							/>

							<button
								className="self-assign-btn"
								onClick={() => setResponsable({ id: user.person.id, label: user.person.name })}
								type="button"
							>
								Atribuir a mim
							</button>
						</div>

						<AutocompleteMembers
							name="members"
							label="Profissionais"
							value={props.values.members}
							options={membersOptions}
							onChange={(_, value) => {
								props.setFieldValue('members', value);
							}}
						/>

						<Button type="submit" bgColor="primary">
							Salvar Alterações
						</Button>
					</form>
				)}
			</Formik>
		</Container>
	);
};
