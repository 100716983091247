import api from 'services/api';

const cycle = JSON.parse(localStorage.getItem('@Advy:cycle')!);

export const getOkrsUserReports = async () => {
	const data = await api
		.get(`/cycles/${cycle.id}/dashboards/user/okr`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getOkrsManagerReports = async () => {
	const data = await api
		.get(`/cycles/${cycle.id}/dashboards/manager/okr`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getOkrsTeamsReports = async () => {
	const data = await api
		.get(`/cycles/${cycle.id}/dashboards/manager/okr`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getOkrsCompanyReports = async (cycleId: string) => {
	const data = await api
		.get(`/cycles/${cycleId || cycle.id}/dashboards/company/okr`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getAllTeamsOptions = async () => {
	const data = await api
		.get(`/cycles/${cycle.id}/teams`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getAllResponsablesOptions = async () => {
	const data = await api
		.get(`/users/managers`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getOkrsRepresentativenessTeamReports = async (teamId: string, pageView: string) => {
	const data = await api
		.get(`/cycles/${cycle.id}/dashboards/${pageView}/okr/representativeness/teams/${teamId}`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getOkrsRepresentativenessUserReports = async (userId: string, pageView: string) => {
	const data = await api
		.get(`/cycles/${cycle.id}/dashboards/${pageView}/okr/representativeness/responsible/${userId}`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getKrsRepresentativenessTeamReports = async (teamId: string, pageView: string) => {
	const data = await api
		.get(`/cycles/${cycle.id}/dashboards/${pageView}/okr/key-results/representativeness/teams/${teamId}`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getKrsRepresentativenessUserReports = async (userId: string, pageView: string) => {
	const data = await api
		.get(`/cycles/${cycle.id}/dashboards/${pageView}/okr/key-results/representativeness/responsible/${userId}`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getUserObjectives = async () => {
	const data = await api
		.get(`/cycles/${cycle.id}/objectives`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getManagerObjectives = async (userId: string) => {
	const data = await api
		.get(`/cycles/${cycle.id}/objectives/managers/users/${userId}`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getTeamsObjectives = async (teamId: string) => {
	const data = await api
		.get(`/cycles/${cycle.id}/objectives/teams/${teamId}`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getCompanyObjectives = async () => {
	const data = await api
		.get(`/cycles/${cycle.id}/objectives/company`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getObjectivesListByUserId = async (userId: string) => {
	const data = await api
		.get(`/cycles/${cycle.id}/objectives/managers/users/${userId}`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getObjectivesListByTeamId = async (teamId: string) => {
	const data = await api
		.get(`/cycles/${cycle.id}/objectives/managers/users/${teamId}`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getMonitoringUserKeyResultsByObjectiveId = async (okrId: string, pageView: string) => {
	const data = await api
		.get(`/cycles/${cycle.id}/dashboards/${pageView}/okr/key-results/objectives/${okrId}`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const postKeyResultsFromPeriod = async (pageView: string, period: { startDate: string; endDate: string }) => {
	const data = await api
		.post(`/cycles/${cycle.id}/dashboards/${pageView}/okr/key-results/period`, period)
		.then(response => response.data)
		.catch(error => console.log(error.message));

	return data.data;
};

export const getReportsUsersPerformances = async (pageView: string) => {
	const data = await api
		.get(`/cycles/${cycle.id}/dashboards/${pageView}/okr/pereformance/users`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getReportsTeamsPerformances = async (pageView: string) => {
	const data = await api
		.get(`/cycles/${cycle.id}/dashboards/${pageView}/okr/pereformance/teams`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getConclusionsReports = async () => {
	const data = await api
		.get(`/cycles/${cycle.id}/dashboards/user/okr/conclusions`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};

export const getEngagementList = async () => {
	const data = await api
		.get(`/cycles/${cycle.id}/dashboards/manager/okr/engagement`)
		.then(response => response.data)
		.catch(error => {
			console.log(error);
		});

	return data.data;
};
