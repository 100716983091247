import { useContext, useEffect, useState } from 'react';
import { useToast } from 'hooks/useToast';
import { useModal } from 'hooks/useModal';
import { AuthContext } from 'domains/Authentication/hooks/useAuth';

import {
	deleteTask,
	getAllTaskComments,
	putEditTask,
	storeNewTask,
	storeNewTaskComment,
} from 'domains/Objectives/services/TasksServices';
import {
	getKeyResultsList,
	getManagersRequest,
	getObjectivesList,
	getUserByIdRequest,
} from 'domains/Objectives/services/ObjectivesServices';
import {
	formatDateComments,
	isDateBeforeFromAnotherDate,
	isDateBeforeFromToday,
} from 'domains/Objectives/utils/formatDates';

import * as Yup from 'yup';
import { ICardKRsProps, IObjectiveData } from 'domains/Objectives/interfaces/IObjectiveData';
import { EntityWithIdAndDescription, EntityWithIdAndLabel } from 'domains/Objectives/interfaces/IFormNewKeyResultsData';
import { IUser } from 'domains/Authentication/interfaces/IUser';
import { ITask, ITaskComment, ITaskFormData } from 'domains/Objectives/interfaces/ITaskFormData';
import { taskStatusObject } from 'domains/Objectives/utils/taskStatusObject';

import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ptBrLocale from 'date-fns/locale/pt-BR';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { Input } from 'components/Input';
import { MdOutlineMessage } from 'react-icons/md';
import { FiTrash2 } from 'react-icons/fi';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider, SectionTitle } from 'styles/globalStyledComponents';
import { Button } from 'components/Button';
import { Autocomplete, TextareaAutosize, TextField } from '@mui/material';

import Profile from '../../assets/profile-01.png';
import { Container, TaskStatus, TaskStatusContainer, DeleteButton, MenuButton, CommentsArea } from './style';
import {
	CommentContainer,
	CommentsContainer,
	MessageContainer,
	ResponsableNameAndDate,
	Title,
} from '../ContentModalKeyResultsIndicators/style';

interface IFormNewTasksProps {
	isEditForm: boolean;
	taskData?: ITask;
	isDetailsForm: boolean;
	reloadTasks: () => void;
}

interface ITaskStatusProps {
	status: 'todo' | 'doing' | 'done';
	value: number;
	label: string;
}

export const FormNewTasks = ({ isEditForm, isDetailsForm, taskData, reloadTasks }: IFormNewTasksProps) => {
	const { user } = useContext(AuthContext);
	const { addToast } = useToast();
	const { handleModalState } = useModal();

	const taskDataKeyResultObject = taskData
		? { id: taskData.keyResult.id, description: taskData.keyResult.description }
		: { id: '', description: '' };
	const taskDataResponsableObject = taskData
		? { id: taskData.user.id, label: taskData.user.person.name }
		: { id: '', label: '' };

	const [taskStatus, setTaskStatus] = useState<ITaskStatusProps>(
		taskData ? taskStatusObject[taskData.status] : taskStatusObject[1],
	);
	const [isFormValid, setIsFormValid] = useState(false);
	const [isInvalidDate, setIsInvalidDate] = useState(false);
	const [isEditInvalidDate, setIsEditInvalidDate] = useState(false);

	const [objectiveSelected, setObjectiveSelected] = useState<EntityWithIdAndDescription | null>(
		taskData ? taskData.keyResult.objective : null,
	);
	const [objectivesOptions, setObjectivesOptions] = useState<EntityWithIdAndDescription[]>([]);

	const [keyResultSelected, setKeyResultSelected] = useState<EntityWithIdAndDescription | null>(
		taskData ? taskDataKeyResultObject : null,
	);
	const [keyResultsOptions, setKeyResultsOptions] = useState<EntityWithIdAndDescription[]>([]);

	const [taskDescription, setTaskDescription] = useState(taskData ? taskData.description : '');

	const [deliveryDate, setDeliveryDate] = useState<Date | null>(taskData ? new Date(taskData.predictedDate) : null);

	const [responsable, setResponsable] = useState<EntityWithIdAndLabel | null>(
		taskData ? taskDataResponsableObject : null,
	);
	const [responsableOptions, setResponsableOptions] = useState<EntityWithIdAndLabel[]>([]);
	const [responsablesData, setResponsablesData] = useState<IUser[]>([]);
	const [responsablesIdsComments, setResponsablesIdsComments] = useState(['']);

	const [comments, setComments] = useState<ITaskComment[]>([]);
	const [newComment, setNewComment] = useState('');
	const [reloadData, setReloadData] = useState(false);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleDeleteTask = async () => {
		if (taskData) await deleteTask(taskData.keyResult.id, taskData.id);
		handleModalState();
		reloadTasks();
	};

	const handleClickTaskStatusMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseTaskStatusMenu = () => {
		setAnchorEl(null);
	};

	const handleTaskStatus = (status: ITaskStatusProps) => {
		setTaskStatus(status);
	};

	const getResponsableNameById = (id: string) => {
		const responsableFilteredArray = responsablesData.filter(data => data.id === id);

		if (responsableFilteredArray.length) {
			return responsableFilteredArray[0]?.person.name;
		}
		return 'User name';
	};

	const getResponsableImageById = (id: string) => {
		const responsableFilteredArray = responsablesData.filter(data => data.id === id);

		if (responsableFilteredArray.length) {
			return responsableFilteredArray[0]?.image;
		}
		return Profile;
	};

	const minDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

	const schema = Yup.object().shape({
		deliveryDate: isEditForm ? Yup.date().required() : Yup.date().min(minDate).required(),
		responsable: Yup.object().required(),
		objectiveSelected: Yup.object().required(),
		keyResultSelected: Yup.object().required(),
		taskDescription: Yup.string().required(),
	});

	const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		if (!isEditForm) {
			if (keyResultSelected && responsable) {
				const formatedFormData: ITaskFormData = {
					userId: responsable.id,
					status: taskStatus.value,
					description: taskDescription,
					predicatedDate: `${deliveryDate}`,
				};

				await storeNewTask(formatedFormData, keyResultSelected.id);
				handleModalState();
				reloadTasks();
			}
			return;
		}

		if (taskData) {
			const keyResultToForm = keyResultSelected || { id: '', description: '' };
			const objectiveToForm = objectiveSelected || { id: '', description: '' };

			const formData: ITask = {
				...taskData,
				status: taskStatus.value,
				description: taskDescription,
				keyResult: {
					...keyResultToForm,
					objective: { ...objectiveToForm },
				},
				predictedDate: `${deliveryDate}`,
				user: taskData.user,
				comments: comments.length,
			};

			await putEditTask(formData, taskData.keyResult.id, taskData.id).then(() => {
				handleModalState();
				addToast({
					type: 'success',
					description: 'Tarefa atualizada com Sucesso',
					close: true,
				});
				reloadTasks();
			});
		}
	};

	const submitComment = () => {
		if (taskData)
			storeNewTaskComment(taskData.id, newComment).then(() => {
				setNewComment('');
				setReloadData(!reloadData);
			});
	};

	const handleFormValidate = async () => {
		await schema
			.validate(
				{ deliveryDate, responsable, objectiveSelected, keyResultSelected, taskDescription },
				{ abortEarly: false },
			)
			.then(response => {
				if (response) {
					setIsFormValid(true);
					setIsInvalidDate(false);
				}
			})
			.catch(error => {
				const errorPath = error.inner[0].path;
				if (errorPath === 'deliveryDate') setIsInvalidDate(true);
				setIsFormValid(false);
			});
	};

	useEffect(() => {
		const handleObjectivesOptions = async () => {
			const data = await getObjectivesList();
			const objectivesFormatedArray = data.map((objective: IObjectiveData) => ({
				id: objective.id,
				description: objective.description,
			}));

			setObjectivesOptions(objectivesFormatedArray);
		};

		const getManagersOptions = async () => {
			const data = await getManagersRequest();
			const managersArray = data.data;
			const formatedArray = managersArray.map((item: IUser) => ({
				id: item.person.id,
				label: item.person.name,
			}));

			setResponsableOptions(formatedArray);
		};

		Promise.all([handleObjectivesOptions(), getManagersOptions()]);
	}, []);

	useEffect(() => {
		const handleKeyResultsOptions = async () => {
			if (objectiveSelected) {
				const data = await getKeyResultsList(objectiveSelected.id);
				const keyResultsFormatedArray = data.map((key: ICardKRsProps) => ({
					id: key.id,
					description: key.description,
				}));

				setKeyResultsOptions(keyResultsFormatedArray);
			} else {
				setKeyResultSelected(null);
			}
		};

		handleKeyResultsOptions();
	}, [objectiveSelected]);

	useEffect(() => {
		if (taskData) {
			const getComments = async () => {
				const comments: ITaskComment[] = await getAllTaskComments(taskData.id);
				const responsableIdsArray = comments.map(comment => comment.userId);

				setResponsablesIdsComments(
					responsableIdsArray.filter(
						(id: string, index: number) => responsableIdsArray.indexOf(id) === index,
					),
				);
				setComments(comments);
			};

			const getAllResponsablesData = () => {
				responsablesIdsComments.forEach(async (id: string) => {
					const data = await getUserByIdRequest(id);

					setResponsablesData(() => {
						const index = responsablesData.indexOf(data);
						if (index === -1) {
							return [...responsablesData, data];
						}
						return [...responsablesData];
					});
				});
			};

			getComments().then(() => getAllResponsablesData());
		}
	}, [reloadData]);

	useEffect(() => {
		if (!isDetailsForm) {
			if (!isEditForm) {
				if (
					objectiveSelected &&
					taskStatus &&
					taskDescription &&
					deliveryDate &&
					responsable &&
					keyResultSelected
				) {
					handleFormValidate();
				} else setIsFormValid(false);
			} else if (taskData) {
				if (
					objectiveSelected?.id !== taskData.keyResult.objective.id ||
					taskStatus.value !== taskData.status ||
					taskDescription !== taskData.description ||
					deliveryDate?.getTime() !== new Date(taskData.predictedDate).getTime() ||
					responsable?.label !== taskData.user.person.name ||
					keyResultSelected?.id !== taskData.keyResult.id
				) {
					handleFormValidate();
				} else setIsFormValid(false);
			}
		}
	}, [objectiveSelected, taskStatus, taskDescription, deliveryDate, responsable, keyResultSelected]);

	useEffect(() => {
		if (!isDetailsForm) {
			if (!isEditForm) {
				const isFinalDateValid =
					`${deliveryDate}` !== 'Invalid Date' &&
					deliveryDate !== null &&
					!isDateBeforeFromToday(deliveryDate);

				if (!isFinalDateValid && deliveryDate !== null) {
					setIsInvalidDate(true);
				} else setIsInvalidDate(false);
			}

			if (isEditForm && taskData) {
				const isFinalDateValid =
					`${deliveryDate}` !== 'Invalid Date' &&
					deliveryDate !== null &&
					isDateBeforeFromAnotherDate(taskData.predictedDate, deliveryDate) === false;

				if (!isFinalDateValid && deliveryDate !== null) {
					setIsEditInvalidDate(true);
				} else setIsEditInvalidDate(false);
			}
		}
	}, [deliveryDate]);

	return (
		<Container>
			<TaskStatusContainer>
				<div className="flex-container">
					<TaskStatus status={taskStatus.status}>{taskStatus.label}</TaskStatus>

					{isEditForm && isEditForm && (
						<>
							<MenuButton onClick={handleClickTaskStatusMenu}>Mover Tarefa</MenuButton>
							<Menu
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'center',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}
								open={open}
								onClose={handleCloseTaskStatusMenu}
							>
								<MenuItem
									key={'todo'}
									selected={taskStatus.status === 'todo'}
									onClick={() => handleTaskStatus({ status: 'todo', label: 'A fazer', value: 1 })}
								>
									<TaskStatus status="todo">A Fazer</TaskStatus>
								</MenuItem>
								<MenuItem
									key={'doing'}
									selected={taskStatus.status === 'doing'}
									onClick={() =>
										handleTaskStatus({
											status: 'doing',
											label: 'Em Progresso',
											value: 2,
										})
									}
								>
									<TaskStatus status="doing">Em Progresso</TaskStatus>
								</MenuItem>
								<MenuItem
									key={'done'}
									selected={taskStatus.status === 'done'}
									onClick={() => handleTaskStatus({ status: 'done', label: 'Concluído', value: 3 })}
								>
									<TaskStatus status="done">Concluído</TaskStatus>
								</MenuItem>
							</Menu>
						</>
					)}
				</div>

				{isEditForm && (
					<DeleteButton onClick={handleDeleteTask}>
						<FiTrash2 />
						Excluir Tarefa
					</DeleteButton>
				)}
			</TaskStatusContainer>

			<Divider marginTop={'1.5rem'} marginBottom={'1.5rem'} />

			<SectionTitle>OKR</SectionTitle>

			<Autocomplete
				options={objectivesOptions}
				value={objectiveSelected}
				onChange={(_, value) => {
					setObjectiveSelected(value);
				}}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				disabled={isDetailsForm}
				renderInput={params => (
					<TextField {...params} label="Objetivo" variant="standard" name="objective" required />
				)}
				getOptionLabel={option => option.description}
			/>

			<Autocomplete
				options={keyResultsOptions}
				value={keyResultSelected}
				onChange={(_, value) => {
					setKeyResultSelected(value);
				}}
				disabled={!objectiveSelected || isDetailsForm}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				getOptionLabel={option => option.description}
				renderInput={params => (
					<TextField
						{...params}
						label="Key Result"
						variant="standard"
						name="objective"
						required
						style={{ marginTop: '1.5rem' }}
					/>
				)}
			/>

			<Divider marginTop={'2rem'} marginBottom={'2rem'} />

			<SectionTitle>Informações da Tarefa</SectionTitle>

			<Input
				placeholder="Descrição da Tarefa"
				required
				value={taskDescription}
				name="taskDescription"
				label="Descrição da Tarefa"
				onChange={e => setTaskDescription(e.target.value)}
				disabled={isDetailsForm}
			/>

			<div className={`flex-container ${!isDetailsForm ? 'editable' : ''}`} style={{ marginTop: '2rem' }}>
				<LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBrLocale}>
					<DatePicker
						label="Data de Entrega"
						value={deliveryDate}
						onChange={value => {
							setDeliveryDate(value);
						}}
						minDate={isEditForm && taskData ? new Date(taskData.predictedDate) : new Date()}
						renderInput={params => (
							<TextField
								{...params}
								variant="standard"
								placeholder="Data de Entrega"
								style={{ width: '100%' }}
								required
								error={!isDetailsForm && (isEditForm ? isEditInvalidDate : isInvalidDate)}
								helperText={isInvalidDate || isEditInvalidDate ? 'Data Inválida' : ''}
								disabled={true}
							/>
						)}
						disabled={isDetailsForm}
					/>
				</LocalizationProvider>

				<div style={{ width: '100%' }}>
					<Autocomplete
						options={responsableOptions}
						value={responsable}
						onChange={(_, value) => {
							setResponsable(value);
						}}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						renderInput={params => (
							<TextField {...params} label="Atribuir para" variant="standard" name="objective" required />
						)}
						disabled={isDetailsForm}
					/>
					<button
						className="self-assign-btn"
						onClick={() => setResponsable({ id: user.person.id, label: user.person.name })}
						type="button"
						disabled={isDetailsForm}
					>
						Atribuir a mim
					</button>
				</div>
			</div>

			{(isEditForm || isDetailsForm) && (
				<CommentsArea>
					<Title>
						<MdOutlineMessage />
						<h1>Comentários</h1>
					</Title>

					<CommentsContainer>
						{comments.map(comment => (
							<CommentContainer selfComment={user.id === comment.userId}>
								<img src={getResponsableImageById(comment.userId)} alt="Profile" />
								<div>
									<ResponsableNameAndDate>
										{getResponsableNameById(comment.userId)} <span />
										{formatDateComments(comment.createdAt)}
									</ResponsableNameAndDate>
									<MessageContainer>
										<p>{comment.description}</p>
									</MessageContainer>
								</div>
							</CommentContainer>
						))}
					</CommentsContainer>

					<TextareaAutosize
						value={newComment}
						minRows={3}
						maxRows={20}
						onChange={event => setNewComment(event.target.value)}
					/>
					<Button
						bgColor="primary"
						className="btn-send-comment"
						disabled={!newComment}
						onClick={submitComment}
					>
						Enviar
					</Button>
				</CommentsArea>
			)}

			{!isDetailsForm && (
				<Button
					bgColor="primary"
					className="submit-btn"
					disabled={!isFormValid || isInvalidDate || isEditInvalidDate}
					type="submit"
					onClick={event => handleSubmit(event)}
				>
					{isEditForm ? 'Salvar Alterações' : 'Criar'}
				</Button>
			)}
		</Container>
	);
};
