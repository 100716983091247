import React, { useContext, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { AuthContext } from 'domains/Authentication/hooks/useAuth';
import { ILoginFormData } from 'domains/Authentication/interfaces/ILoginFormData';
import { Checkbox } from '../Checkbox';

export const FormLogin: React.FC = () => {
	const [formData] = useState<ILoginFormData>({
		email: '',
		password: '',
	});
	const [isLoadingLogin, setIsLoadingLogin] = useState(false);

	const { login } = useContext(AuthContext);

	const validationSchema = Yup.object().shape({
		email: Yup.string().email('Insira um e-mail válido').required('E-mail é um campo obrigatório'),
		password: Yup.string().min(6, 'Insira uma senha válida').required('Insira uma senha'),
	});

	const handleSubmit = async (values: ILoginFormData) => {
		setIsLoadingLogin(true);
		await login({
			email: values.email,
			password: values.password,
		}).then(() => {
			setIsLoadingLogin(false);
		});
	};

	return (
		<Formik
			initialValues={formData}
			validationSchema={validationSchema}
			onSubmit={async (values: ILoginFormData) => handleSubmit(values)}
		>
			{props => (
				<form
					onSubmit={e => {
						e.preventDefault();
						props.handleSubmit(e);
					}}
				>
					<Input
						name="email"
						label="E-mail"
						placeholder="E-mail"
						onChange={props.handleChange}
						required
						value={props.values.email}
						error={props.touched.email && Boolean(props.errors.email)}
						helperText={props.touched.email && props.errors.email ? props.errors.email : undefined}
					/>

					<Input
						name="password"
						type="password"
						label="Senha"
						placeholder="Senha"
						required
						value={props.values.password}
						onChange={props.handleChange}
						error={props.touched.password && Boolean(props.errors.password)}
						helperText={props.touched.password && props.errors.password ? props.errors.password : undefined}
					/>

					<Checkbox>
						<input type="checkbox" name="remember" />
						<p>Me mantenha conectado</p>
					</Checkbox>

					<Button type="submit" bgColor="primary" isLoading={isLoadingLogin}>
						Entrar
					</Button>
				</form>
			)}
		</Formik>
	);
};
