import { useState, useEffect } from 'react';

import api from 'services/api';

import { Modal } from 'components/Modal';
import { ReactComponent as AstronautOpsIcon } from 'assets/astronaut-ops.svg';

import { ModalContent } from '../ModalContent';

import { Container } from './style';

import { IAreaData } from '../../interfaces/IAreaData';
import { ILeaderData } from '../../interfaces/ILeaderData';

export const OpsContent: React.FC = () => {
	const [listAreas, setListAreas] = useState<IAreaData[]>([]);
	const [leadersList, setLeadersList] = useState<ILeaderData[]>([]);

	useEffect(() => {
		api.get('areas').then(response => {
			setListAreas(response.data.data);
		});

		api.get('users/manager').then(response => {
			setLeadersList(response.data.data);
		});
	}, []);

	return (
		<Container>
			<AstronautOpsIcon />

			<h3>Ops!</h3>

			<p>
				Você não possui nenhum colaborador cadastrado. Adicione um colaborador agora mesmo para aproveitar o
				Advy.
			</p>

			<Modal title="Novo Profissional" buttonContent="Novo Profissional">
				<ModalContent listAreas={listAreas} leadersList={leadersList} />
			</Modal>
		</Container>
	);
};
