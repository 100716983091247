import { Card } from 'components/Card';
import { getEngagementList } from 'domains/Reports/services/reportsServices';
import { useEffect, useState } from 'react';
import { Divider } from 'styles/globalStyledComponents';
import { ItemTitle } from '../style';
import { EngagementTable } from './EngagementTable';
import { Container } from './style';

export const Engagement = () => {
	const [data, setData] = useState([]);

	const handleDataEngagement = async () => {
		await getEngagementList().then(response => setData(response));
	};

	useEffect(() => {
		handleDataEngagement();
	}, []);

	return (
		<>
			<ItemTitle>Engajamento</ItemTitle>
			<Container>
				<Card title="Engajamento por Responsável" className="card">
					<Divider marginTop="0.5rem" marginBottom="0.5rem" />
					<EngagementTable engagements={data} />
				</Card>
			</Container>
		</>
	);
};
