import styled from 'styled-components';

export const Container = styled.div`
	margin-top: 1.5rem;

	& > h5 {
		padding-top: 0.5rem;
		padding-bottom: 1rem;

		font: var(--body-sm-500);
		color: var(--secondary-400);
	}
`;

export const Items = styled.div`
	& > h6 {
		padding-bottom: 0.5rem;

		font: var(--body-sm-500);
		color: var(--gray-700);
	}

	& p {
		padding-bottom: 1rem;

		font: var(--body-sm-400);
		color: var(--gray-500);
	}

	& div > strong {
		display: block;
		padding: 0.5rem 0;

		font: var(--body-sm-500);
		color: var(--primary-400);
	}

	.contributors-container {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		margin-bottom: 3rem;

		.professional-container {
			display: flex;
			align-items: center;
			justify-content: center;

			font-family: Fira Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			color: var(--gray-500);

			p {
				padding-bottom: 0px;
			}

			img {
				height: 1.5rem;
				width: 1.5rem;
				border-radius: 50%;
				box-shadow: var(--elevation-200);
				margin-right: 8px;
			}
		}
	}
`;
