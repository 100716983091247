import { useState } from 'react';
import { Button } from 'components/Button';
import { useFormEditObjectives } from 'domains/Objectives/contexts/FormEditObjectives';
import { useFormNewObjectives } from 'domains/Objectives/contexts/FormNewObjectives';
import { putEditObjectives, storeNewObjectives } from 'domains/Objectives/services/ObjectivesServices';
import { objectiveLevelsOptions } from 'domains/Objectives/utils/objectiveLevel';
import { useModal } from 'hooks/useModal';

interface RevisionProps {
	handleBackStep: () => void;
	isEditForm: boolean;
	objectiveId?: string;
}

export const Revision = ({ handleBackStep, isEditForm, objectiveId }: RevisionProps) => {
	const { formData, selectedOptionsGeralForm } = isEditForm ? useFormEditObjectives() : useFormNewObjectives();
	const { handleModalState } = useModal();

	const [isLoadingRequest, setIsLoadingRequest] = useState(false);

	const hookSubstitute = () => {
		const handleIsEditForm = () => {
			console.log(isEditForm);
		};
		return { handleIsEditForm };
	};
	const { handleIsEditForm } = isEditForm ? useFormEditObjectives() : hookSubstitute();

	const handleSubmitValues = async () => {
		setIsLoadingRequest(true);
		if (!isEditForm) {
			await storeNewObjectives(formData).then(() => {
				setIsLoadingRequest(false);
				handleModalState();
			});
		} else {
			await putEditObjectives(formData, objectiveId).then(() => {
				handleIsEditForm();
				setIsLoadingRequest(false);
			});
		}
	};

	const getKeyByValue = (object: { [key: string]: string }, value: string) =>
		Object.keys(object).find(key => object[key] === value);

	const formatDate = (date: string): string => {
		const dateObject = new Date(date);
		const day = dateObject.getDate();
		const month = dateObject.getMonth() + 1;
		const year = dateObject.getFullYear();

		return `${day}/${month}/${year}`;
	};

	return (
		<>
			<h2 className="revision-item-title">Revisão</h2>

			<h3 className="revision-item-title">Informações Gerais:</h3>

			<h3 className="revision-secondary-title">Nível do objetivo:</h3>
			<p className="description-text first-letter-capitalize">
				{getKeyByValue(objectiveLevelsOptions, formData.levelId)}
			</p>

			<h3 className="revision-secondary-title">Objetivo:</h3>
			<p className="description-text">{formData.name}</p>

			{formData.levelId === 'bb0b22d1-cf13-4eb9-ab54-86fda22067872' && (
				<>
					<h3 className="revision-secondary-title">Empresa:</h3>
					<p className="description-text">{selectedOptionsGeralForm.company.name}</p>
				</>
			)}

			<h3 className="revision-secondary-title">Gestor:</h3>
			<p className="description-text">{selectedOptionsGeralForm.responsable?.label}</p>

			<h3 className="revision-secondary-title">Data Final:</h3>
			<p className="description-text">{formatDate(formData.finalDate)}</p>

			{formData.levelId === 'bb0b22d1-cf13-4eb9-ab54-86fda22067871' && (
				<>
					<h2 className="revision-secondary-title">Contribuintes:</h2>
					<h3
						style={{
							color: 'var(--primary-400)',
							marginBottom: '10px',
							fontWeight: '500',
							fontSize: '14px',
						}}
					>
						{selectedOptionsGeralForm.team.label}
					</h3>
					<div className="contributors-container">
						{selectedOptionsGeralForm.selectedProfessionals.map(member => (
							<div className="professional-container" key={member.id}>
								<img src={member.image} alt={member.name} />
								<p>{member.name}</p>
							</div>
						))}
					</div>
				</>
			)}

			<h2 className="revision-item-title">Fundamentação:</h2>

			<h3 className="revision-secondary-title">Descrição Geral:</h3>
			<p className="description-text">{formData.description}</p>

			<h3 className="revision-secondary-title">Visão de Sucesso:</h3>
			<p className="description-text">{formData.successVision}</p>

			<h3 className="revision-secondary-title">Visão de Falha:</h3>
			<p className="description-text">{formData.failVision}</p>

			<h3 className="revision-secondary-title">Alinhamento de estratégia:</h3>
			<p className="description-text">{formData.strategyAlignment}</p>

			<h3 className="revision-secondary-title">Correspondências:</h3>
			<h3
				style={{
					color: 'var(--primary-400)',
					marginTop: '20px',
					marginBottom: '10px',
					fontWeight: '500',
					fontSize: '14px',
				}}
			>
				Áreas
			</h3>
			{selectedOptionsGeralForm.correspondences.areas.map(area => (
				<p className="description-text" key={area.id}>
					{area.description}
				</p>
			))}

			{formData.levelId !== 'bb0b22d1-cf13-4eb9-ab54-86fda22067871' && (
				<>
					<h3
						style={{
							color: 'var(--primary-400)',
							marginBottom: '10px',
							fontWeight: '500',
							fontSize: '14px',
							marginTop: '10px',
						}}
					>
						Times
					</h3>
					{selectedOptionsGeralForm.correspondences.teams.map(team => (
						<p className="description-text" key={team.id}>
							{team.label}
						</p>
					))}
				</>
			)}

			<h2 className="revision-item-title">Detalhes:</h2>

			<h3 className="revision-secondary-title">Modo privado:</h3>
			<p className="description-text">{formData.isPrivate ? 'Ativado' : 'Desativado'}</p>

			<h3 className="revision-secondary-title">Peso:</h3>
			<p className="description-text">{formData.weight}</p>

			<div className="buttons-container">
				<button type="button" className="back-btn" onClick={() => handleBackStep()}>
					Voltar
				</button>
				<Button
					type="button"
					bgColor="primary"
					className="continue-btn"
					onClick={() => handleSubmitValues()}
					isLoading={isLoadingRequest}
					disabled={isLoadingRequest}
				>
					{isEditForm ? 'Salvar Alterações' : 'Criar Objetivo'}
				</Button>
			</div>
		</>
	);
};
