interface ITaskStatusObject {
	[key: number]: {
		status: 'todo' | 'doing' | 'done';
		label: string;
		value: number;
	};
}

export const taskStatusObject: ITaskStatusObject = {
	1: {
		status: 'todo',
		label: 'A Fazer',
		value: 1,
	},
	2: {
		status: 'doing',
		label: 'Em Progresso',
		value: 2,
	},
	3: {
		status: 'done',
		label: 'Concluído',
		value: 3,
	},
};
