import api from 'services/api';

const cycle = JSON.parse(localStorage.getItem('@Advy:cycle')!);

export const getUserInfos = async () => {
	const data = await api
		.get(`cycles/${cycle.id}/home/user/info`)
		.then(response => response.data)
		.catch(error => error);

	return data.data;
};

export const getUserOKRs = async () => {
	const data = await api
		.get(`cycles/${cycle.id}/home/user/okrs`)
		.then(response => response.data)
		.catch(error => error);

	return data.data;
};

export const getUserTasks = async () => {
	const data = await api
		.get(`cycles/${cycle.id}/home/user/tasks`)
		.then(response => response.data)
		.catch(error => error);

	return data.data;
};

export const getManagerInfos = async (managerId: string) => {
	const data = await api
		.get(`cycles/${cycle.id}/home/manager/user/${managerId}/info`)
		.then(response => response.data)
		.catch(error => error);

	return data.data;
};

export const getManagerOKRs = async (managerId: string) => {
	const data = await api
		.get(`cycles/${cycle.id}/home/manager/user/${managerId}/okrs`)
		.then(response => response.data)
		.catch(error => error);

	return data.data;
};

export const getManagerTasks = async (managerId: string) => {
	const data = await api
		.get(`cycles/${cycle.id}/home/manager/user/${managerId}/tasks`)
		.then(response => response.data)
		.catch(error => error);

	return data.data;
};

export const getTeamsInfos = async (teamId: string) => {
	const data = await api
		.get(`cycles/${cycle.id}/home/teams/team/${teamId}/info`)
		.then(response => response.data)
		.catch(error => error);

	return data.data;
};

export const getTeamsOKRs = async (teamId: string) => {
	const data = await api
		.get(`cycles/${cycle.id}/home/teams/team/${teamId}/okrs`)
		.then(response => response.data)
		.catch(error => error);

	return data.data;
};

export const getCompanyInfos = async () => {
	const data = await api
		.get(`cycles/${cycle.id}/home/company/info`)
		.then(response => response.data)
		.catch(error => error);

	return data.data;
};

export const getCompanyOKRs = async () => {
	const data = await api
		.get(`cycles/${cycle.id}/home/company/okrs`)
		.then(response => response.data)
		.catch(error => error);

	return data.data;
};

export const getCompanyTotalities = async () => {
	const data = await api
		.get(`cycles/${cycle.id}/home/company/totalities`)
		.then(response => response.data)
		.catch(error => error);

	return data.data;
};
