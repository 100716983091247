import { IPerformance } from 'domains/Reports/Interfaces/IPerformances';
import { Percentual, TableContainer } from './styles';

interface PerformancesTableProps {
	performances: IPerformance[];
	isTeamComponent: boolean;
}

export const PerformanceTable = ({ performances, isTeamComponent }: PerformancesTableProps) => (
	<TableContainer>
		<table>
			<tbody>
				<tr className="table-header">
					<th>{isTeamComponent ? 'Time' : 'Responsável'}</th>
					<th>Criado</th>
					<th>Concluído</th>
					<th>Percentual</th>
				</tr>

				{performances.map(performance => (
					<tr key={`${performance}`}>
						<th>{performance.responsible}</th>
						<th>{performance.created}</th>
						<th>{performance.completed}</th>
						<Percentual value={performance.percentual}>{performance.percentual}%</Percentual>
					</tr>
				))}
			</tbody>
		</table>
	</TableContainer>
);
