import styled, { css } from 'styled-components';

interface IButtonNavProps {
	selected: boolean;
}

interface IProgressProps {
	progress: number;
}

interface ICommentContainerProps {
	selfComment: boolean;
}

export const ComponentArea = styled.div`
	display: flex;
	flex-direction: column;
	overflow-x: hidden;

	.btn-edit-indicator {
		align-self: flex-end;
		margin-top: 2rem;
		max-width: 13rem;
	}
`;

export const SectionTitle = styled.h2`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 135%;
	color: var(--secondary-400);
	margin-bottom: 0.5rem;
	margin-top: 1.5rem;
`;

export const ItemDescription = styled.p`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 135%;
	color: var(--gray-500);
`;

export const ContentContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;

	@media (max-width: 1024px) {
		flex-wrap: wrap;
	}
`;

export const ChartContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;

	box-shadow: var(--elevation-200);
	max-width: 50rem;
	min-width: 17rem;
	max-height: 37.7rem;
	padding: 0.5rem 2rem;
	gap: 2rem;
	margin-top: 1rem;

	.chart-container-indicators {
		overflow-x: auto;
		width: 100%;
		overflow-y: auto;

		@media (min-width: 1025px) {
			width: auto;
			overflow-x: hidden;
			overflow-y: hidden;
		}
	}
`;

export const Progress = styled.div<IProgressProps>`
	display: flex;
	align-items: center;
	gap: 1rem;

	height: 1rem;
	width: 100%;

	margin-bottom: 0.5rem;
	margin-top: -1rem;

	font: var(--body-md-500);
	color: var(--secondary-400);

	div {
		height: 0.6rem;
		width: 100%;

		background-color: var(--gray-200);

		border-radius: 200px;

		span {
			display: block;
			border: solid;
			background-color: var(--secondary-400);
			height: 100%;
			width: ${props => props.progress}%;

			border-radius: 200px;
		}
	}
`;

export const Marking = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	margin-top: -2rem;
	padding-bottom: 1rem;

	p {
		font-family: 'Fira Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 135%;

		color: var(--gray-500);
	}
`;

export const RightContainer = styled.div`
	display: flex;
	flex-direction: column;

	box-shadow: var(--elevation-200);

	max-width: 24rem;
	min-width: 10rem;
	max-height: 37.7rem;
	margin-top: 1rem;
	padding-bottom: 1rem;

	textarea {
		padding: 10px;
		width: 90%;
		align-self: center;
		border-color: var(--gray-300);
		margin-top: 1rem;

		color: var(--gray-700);

		font-family: 'Fira Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 135%;

		&:focus {
			border: 1.5px solid var(--secondary-400);
		}
	}

	.btn-send-comment {
		width: 8.125rem;
		height: 3rem;
		align-self: flex-end;
		margin-top: 1rem;
		margin-right: 1.5rem;
	}
`;

export const NavContainer = styled.nav`
	display: flex;
	align-items: center;
	gap: 4rem;

	width: 100%;
	height: 4.5rem;

	padding: 0 3rem;

	box-shadow: var(--elevation-400);

	@media (max-width: 48rem) {
		padding: 0 1.25rem;
		gap: 10%;
	}
`;

export const ButtonNav = styled.button<IButtonNavProps>`
	height: 100%;

	background-color: var(--white);

	border: none;

	font: var(--body-lg-400);
	color: var(--gray-400);

	cursor: pointer;

	${props =>
		props.selected &&
		css`
			font: var(--body-lg-500);
			color: var(--terciary-400);

			border-bottom: 4px solid var(--terciary-400);

			transition: all 0.2s;
		`}
`;

export const Title = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 1rem;
	gap: 0.5rem;

	color: var(--secondary-400);

	svg {
		height: 1.5rem;
		width: 1.5rem;
	}

	h1 {
		font-family: 'Fira Sans';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 135%;
	}
`;

export const ResponsableNameAndDate = styled.p`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 0.5rem;

	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 135%;

	color: var(--gray-500);

	span {
		display: flex;
		border-radius: 50%;
		height: 4px;
		width: 4px;
		border: solid;
		color: var(--gray-500);
	}
`;

// Activities:

export const ActivitiesContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-height: 29.5rem;
	height: 29.5rem;
	overflow-y: scroll;
`;

export const ActivityContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem;
	padding-top: 0.5rem;
	gap: 0.5rem;
`;

export const ActivitiesTitle = styled.h2`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 135%;

	color: var(--secondary-400);
`;

export const ActivitiesDescription = styled.p`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;

	color: var(--gray-700);
`;

export const Divider = styled.span`
	display: block;
	width: 100%;
	height: 1px;

	background: var(--gray-200);

	margin-top: 0.2rem;
	margin-bottom: 0.2rem;
`;

// Comments:

export const CommentsContainer = styled.div`
	display: flex;
	flex-direction: column-reverse;
	max-height: 20rem;
	height: 20rem;
	overflow-y: scroll;
	padding: 0.5rem 1.5rem;
	gap: 1.5rem;
	width: 100%;
`;

export const MessageContainer = styled.div`
	box-shadow: var(--elevation-200);
	padding: 0.5rem 1rem;
	border-radius: 4px;
	margin-top: 4px;
	max-width: 19rem;
	overflow-wrap: break-word;

	p {
		font-family: 'Fira Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 140%;

		color: var(--gray-700);
	}
`;

export const CommentContainer = styled.div<ICommentContainerProps>`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;

	img {
		box-shadow: var(--elevation-100);
		border-radius: 50%;

		width: 40px;
		height: 40px;
		margin: ${props => (props.selfComment ? '0 0 0 0.5rem' : '0 0.5rem 0 0')};
	}

	span {
		border: solid 0.5px;
	}

	${props =>
		props.selfComment &&
		css`
			& {
				justify-content: flex-end;
				padding-right: 3rem;

				img {
					position: absolute;
					right: 0;
				}

				${MessageContainer} {
					max-width: 15.5rem;
				}
			}
		`}

	${MessageContainer} {
		background-color: ${props => (props.selfComment ? '#DDEEEE' : '#FFF')};
	}
`;
