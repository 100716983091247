import styled, { css } from 'styled-components';

interface ProgressProps {
	progress: number;
}
interface ContainerProps {
	openModalActions: boolean;
}

export const Container = styled.div`
	padding: 1rem 1rem 1.5rem;

	max-width: 22rem;
	width: 100%;

	border: 1px solid #ebebeb;
	border-radius: 4px;
`;

export const Head = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	gap: 0.5rem;

	margin-bottom: 0.75rem;
	.btn-smallModal {
		background: none;
		box-shadow: none;
		width: 20px;
		position: absolute;
		top: -15px;
		right: 0;
	}

	.btn-edit-kr {
		margin-left: auto;
		width: 15.5rem;
	}

	.item-FiTrash2 {
		color: var(--red-400);

		svg {
			color: var(--red-400);
		}
	}
`;

export const Result = styled.div`
	font: var(--body-xs-400);
	color: var(--gray-500);
`;

export const Weight = styled.p`
	font: var(--body-xs-400);
	color: var(--gray-500);
`;

export const Description = styled.p`
	margin-bottom: 0.75rem;

	font: var(--body-sm-400);
	color: var(--gray-700);
`;

export const Responsible = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;

	p {
		font: var(--body-sm-400);
		color: var(--gray-700);
	}

	img {
		height: 1.5rem;
		width: 1.5rem;
	}

	span {
		font: var(--body-xs-400);
		color: var(--gray-500);
	}
`;

export const Progress = styled.div<ProgressProps>`
	display: flex;
	align-items: center;
	gap: 1rem;

	height: 2rem;
	width: 100%;

	margin-bottom: 0.25rem;

	font: var(--body-md-500);

	div {
		height: 4px;
		width: 100%;

		background-color: var(--gray-200);

		border-radius: 200px;

		span {
			display: block;

			height: 100%;
			width: ${props => props.progress}%;

			border-radius: 200px;
		}
	}
	${props =>
		props.progress >= 0 &&
		css`
			color: var(--red-400);

			div span {
				background-color: var(--red-400);
			}
		`}

	${props =>
		props.progress >= 50 &&
		css`
			color: var(--yellow-400);

			div span {
				background-color: var(--yellow-400);
			}
		`}

	${props =>
		props.progress >= 80 &&
		css`
			color: var(--green-400);

			div span {
				background-color: var(--green-400);
			}
		`}
`;

export const Numbers = styled.div`
	text-align: right;
	font: var(--body-xs-400);
	color: var(--gray-500);
`;

export const ModalButtonSettings = styled.button`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2px;

	width: 2rem;
	height: 2rem;

	background-color: transparent;
	border: none;

	cursor: pointer;

	span {
		display: block;

		width: 3px;
		height: 3px;

		background-color: var(--gray-700);
		border: none;
		border-radius: 50%;
	}
`;

export const Overlay = styled.div<ContainerProps>`
	display: none;
	align-items: center;
	justify-content: center;

	position: fixed;
	top: 50%;
	left: 50%;
	z-index: -10;

	transform: translate(-50%, -50%);

	height: 100vh;
	width: 100%;

	${props =>
		props.openModalActions &&
		css`
			z-index: 10;

			background-color: var(--black);
			opacity: 0.05;
			display: flex;
		`}

	transition: opacity 0.2s ease-out;
`;
