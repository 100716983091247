import React, { createContext, useContext, useEffect, useState } from 'react';
import { ICycle } from 'domains/Authentication/interfaces/IUser';

interface EntityWithIdAndName {
	id: string;
	name: string;
}

interface ICyclesContextData {
	cycle: EntityWithIdAndName;
	cycleDates: { startDate: string; endDate: string };
	cyclesOptions: EntityWithIdAndName[];
	handleCycle(option: EntityWithIdAndName): void;
}

const CyclesContext = createContext<ICyclesContextData>({} as ICyclesContextData);

const CyclesProvider: React.FC = ({ children }) => {
	const [cycle, setCycle] = useState<EntityWithIdAndName>({ id: '', name: '' });
	const [cycleDates, setCycleDates] = useState({
		startDate: '',
		endDate: '',
	});
	const [cyclesOptions, setCyclesOptions] = useState<EntityWithIdAndName[]>([{ id: '', name: '' }]);
	const [cyclesObjectFullArray, setCyclesObjectFullArray] = useState<ICycle[]>();

	useEffect(() => {
		const cycleObject = localStorage.getItem('@Advy:cycle');
		const cyclesArray = localStorage.getItem('@Advy:cyclesOptions');

		if (cycleObject && cyclesArray) {
			const currentCycle = JSON.parse(cycleObject);
			const cyclesOptionsArray = JSON.parse(cyclesArray).map((cycle: ICycle) => ({
				id: cycle.id,
				name: cycle.description,
			}));
			console.log(currentCycle);
			setCycle({ id: currentCycle.id, name: currentCycle.description });
			setCycleDates({
				startDate: currentCycle.startTime,
				endDate: currentCycle.endTime,
			});
			setCyclesOptions(cyclesOptionsArray);
			setCyclesObjectFullArray(JSON.parse(cyclesArray));
		}
	}, []);

	const handleCycle = (option: EntityWithIdAndName) => {
		if (cyclesObjectFullArray) {
			const selectedCycleObject = cyclesObjectFullArray?.filter((cycle: ICycle) => cycle.id === option.id);

			localStorage.setItem('@Advy:cycle', JSON.stringify(selectedCycleObject[0]));
		}

		setCycle(option);
	};

	return (
		<CyclesContext.Provider value={{ cycle, cyclesOptions, handleCycle, cycleDates }}>
			{children}
		</CyclesContext.Provider>
	);
};

const useCycle = (): ICyclesContextData => {
	const context = useContext(CyclesContext);

	if (!context) {
		throw new Error('useCycle must be used within a CyclesProvider');
	}

	return context;
};

export { CyclesProvider, useCycle };
