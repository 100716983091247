import { Input } from 'components/Input';
import { CardComponent, CircleTeamProfile, ProfileContainer, SelectableButton } from 'domains/Home/pages/style';
import { ITeamData } from 'domains/Teams/interfaces/ITeamData';
import { useEffect, useState } from 'react';
import { Progress } from 'domains/Home/components/CardOKRs/components/styles';

interface ISearchTeamsComponentProps {
	teamsList: ITeamData[];
	selectedTeamId: string;
	handleSelectedTeamId(newId: string): void;
}

export const SearchTeamsComponent = ({
	teamsList,
	selectedTeamId,
	handleSelectedTeamId,
}: ISearchTeamsComponentProps) => {
	const [teamFilter, setTeamFilter] = useState('');
	const [filteredTeamsList, setFilteredTeamsList] = useState([...teamsList]);

	useEffect(() => {
		const charReg = /^\s*[a-zA-Z0-9-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ,\s]+\s*$/;

		if (teamFilter !== '' && charReg.test(teamFilter)) {
			const exp = new RegExp(`${teamFilter.trim()}`, 'i');

			const filteredTeams = teamsList?.filter(team => exp.test(team.description));

			setFilteredTeamsList(filteredTeams);
		} else {
			setFilteredTeamsList(teamsList);
		}
	}, [teamFilter]);

	useEffect(() => {
		handleSelectedTeamId(`${teamsList[0].id}`);
	}, []);

	return (
		<CardComponent className="search-teams-container">
			<Input
				value={teamFilter}
				search
				name="team-filter"
				placeholder="Buscar time"
				label="Buscar time"
				onChange={e => setTeamFilter(e.target.value)}
			/>
			{filteredTeamsList?.map(team => (
				<SelectableButton
					isSelected={`${team.id}` === selectedTeamId}
					onClick={() => handleSelectedTeamId(`${team.id}`)}
					key={team.id}
				>
					<ProfileContainer key={team.id}>
						<CircleTeamProfile>
							{team.description.split(' ').map(index => (
								<span key={index}>{index.slice(0, 1)}</span>
							))}
						</CircleTeamProfile>
						<p className="team-description">{team.description}</p>
					</ProfileContainer>
					{team.completionIndex && (
						<Progress progress={team.completionIndex * 100}>
							<div>
								<span />
							</div>

							{`${team.completionIndex * 100}%`}
						</Progress>
					)}
				</SelectableButton>
			))}
		</CardComponent>
	);
};
