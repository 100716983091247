import styled from 'styled-components';
import { Divider } from 'styles/globalStyledComponents';

interface IColumnContainer {
	borderColor: string;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
`;

export const ColumnContainer = styled.div<IColumnContainer>`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	padding: 1rem;

	background: #f9f9f9;

	border-top: 4px solid ${props => props.borderColor};
	border-radius: 4px;

	@media (max-width: 375px) {
		${Divider} {
			min-width: 2rem;
		}
	}
`;

export const ColumnTitle = styled.h2`
	font-family: 'Fira Sans';
	text-align: left;
	width: 100%;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 135%;
	color: var(--gray-700);
`;

export const TaskContainerCard = styled.div`
	min-height: 10rem;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;

	box-shadow: var(--elevation-200);
	background-color: #ffffff;
	padding: 1rem;

	${Divider} {
		min-width: 5rem;
		width: 100%;
	}
`;

export const DescriptionText = styled.p`
	display: flex;

	color: var(--gray-500);
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 135%;

	svg {
		height: 1rem;
		width: 1rem;
		margin-right: 5px;
	}
`;

export const TaskTitle = styled.h3`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;

	color: var(--gray-700);
`;

export const ProfileContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	gap: 0.5rem;

	img {
		height: 1.5rem;
		width: 1.5rem;
		border-radius: 50%;

		box-shadow: var(--elevation-200);
	}

	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 135%;
	color: var(--gray-700);
`;

export const BottomDescription = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	gap: 1rem;

	@media (max-width: 375px) {
		flex-wrap: wrap;
	}
`;
