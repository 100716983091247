import styled from 'styled-components';

export const ColumnsContainer = styled.div`
	display: flex;
	flex-direction: row !important;
	flex-wrap: wrap;

	gap: 3rem;
`;

export const WarningContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 1rem;

	h2 {
		font-family: 'Fira Sans';
		font-style: normal;
		font-weight: 500;
		font-size: 22px;
		line-height: 125%;
		color: var(--secondary-400);
	}

	p {
		font-family: 'Fira Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 135%;
		color: var(--gray-700);

		max-width: 305px;
	}
`;
