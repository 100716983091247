import { measuresOptions } from 'domains/Objectives/utils/measures';
import { IPersonData } from 'domains/Persons/interfaces/IPersonData';
import { ICardKRsProps } from '../../interfaces/IObjectiveData';

import { Container, Items } from './style';

interface IContentModalProps {
	keyResult: ICardKRsProps;
	responsable: IPersonData;
}

export const ContentModalDetailsKeyResults = ({ keyResult, responsable }: IContentModalProps) => {
	const measureName = keyResult.measure.name;

	return (
		<Container>
			<h5>Sobre</h5>

			<Items>
				<h6>Título do Key Result:</h6>
				<p>{keyResult.result}</p>
			</Items>

			<Items>
				<h6>Descrição:</h6>
				<p>{keyResult.description}</p>
			</Items>

			<Items>
				<h6>Data Final:</h6>
				<p>{keyResult.finalDate || '15/07/2022'}</p>
			</Items>

			<h5>Medição</h5>

			<Items>
				<h6>Finalidade:</h6>
				<p>{keyResult.result}</p>
			</Items>

			<Items>
				<h6>Valor Inicial:</h6>
				<p>{keyResult.polarity.initialValue}</p>
			</Items>

			<Items>
				<h6>Valor Final:</h6>
				<p>{keyResult.polarity.completedValue}</p>
			</Items>

			<Items>
				<h6>Medida:</h6>
				<p>{measuresOptions[measureName].name}</p>
			</Items>

			<Items>
				<h6>Peso:</h6>
				<p>{keyResult.weight}</p>
			</Items>

			<h5>Participantes</h5>
			<Items>
				<h6>Gestor:</h6>
				<p>{responsable.person.name}</p>
			</Items>

			<Items>
				<h6>Contribuintes Diretos:</h6>
				<div>
					<strong>{keyResult.teams[0].id}</strong>

					{keyResult.teams[0].usersId.map(userId => (
						<p key={userId}>{userId}</p>
					))}
				</div>
			</Items>
		</Container>
	);
};
