import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;

	margin-bottom: 2rem;

	p {
		font: var(--body-sm-400);
		line-height: 100%;
		color: var(--gray-700);
	}

	@media (max-width: 512px) {
		margin-bottom: 1.5rem;
	}
`;
