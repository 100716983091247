import { formatDateNormal } from 'domains/Objectives/utils/formatDates';
import { Container, Items } from './style';

import { IModalProps } from '../IModelProps';

export const ContentInfoModal: React.FC<IModalProps> = ({ objective, responsableName }) => (
	<Container>
		<h5>Geral</h5>

		<Items>
			<h6>Objetivo:</h6>
			<p>{objective?.name}</p>
		</Items>

		<Items>
			<h6>Nível do Objetivo:</h6>
			<p>{objective?.level?.name}</p>
		</Items>

		<Items>
			<h6>Indivíduo Responsável:</h6>
			<p>{responsableName}</p>
		</Items>

		<Items>
			<h6>Data Final:</h6>
			<p>{formatDateNormal(objective.date)}</p>
		</Items>

		<h5>Fundamentação</h5>

		<Items>
			<h6>Descrição Geral:</h6>
			<p>{objective?.description}</p>
		</Items>

		<Items>
			<h6>Visão de Sucesso:</h6>
			<p>{objective?.successVision}</p>
		</Items>

		<Items>
			<h6>Visão de Falha:</h6>
			<p>{objective?.failVision}</p>
		</Items>

		<Items>
			<h6>Alinhamento de Estratégia:</h6>
			<p>{objective?.strategyAlignment}</p>
		</Items>

		<Items>
			<h6>Correspondências:</h6>

			<div>
				<strong>Áreas</strong>

				{objective?.correspondences?.areas.map((area, index) => (
					<p key={area.id || index + 1}>{area.description || 'Área sem nome'}</p>
				))}
			</div>

			<div>
				<strong>Times</strong>

				{objective?.correspondences?.teams.map((team, index) => (
					<p key={team.id || index}>{team.description || 'Time sem nome'}</p>
				))}
			</div>
		</Items>

		<h5>Detalhes</h5>

		<Items>
			<h6>Modo Privado:</h6>
			<p>{objective?.isPrivate ? 'Ativado' : 'Desativado'}</p>
		</Items>

		<Items>
			<h6>Peso:</h6>
			<p>{objective?.weight}</p>
		</Items>
	</Container>
);
