import React from 'react';

import { AuthProvider } from '../domains/Authentication/hooks/useAuth';
import { CyclesProvider } from './useCycles';
import { ModalProvider } from './useModal';
import { ToastProvider } from './useToast';

export const AppProvider: React.FC = ({ children }) => (
	<AuthProvider>
		<CyclesProvider>
			<ToastProvider>
				<ModalProvider>{children}</ModalProvider>
			</ToastProvider>
		</CyclesProvider>
	</AuthProvider>
);
