import styled from 'styled-components';

export const Container = styled.div`
	& form {
		margin-top: 1.5rem;

		fieldset {
			height: 3.5rem;

			margin-bottom: 1rem;

			border: none;
		}

		fieldset:last-child {
			margin-bottom: 1.5rem;
		}

		.MuiAutocomplete-root {
			height: 3rem;

			margin-bottom: 2rem;
		}

		button {
			margin-left: auto;
		}

		.self-assign-btn {
			border: none;
			background-color: transparent;
			color: var(--primary-400);
			font-size: 14px;
			margin-bottom: 1rem;
		}
	}
`;
