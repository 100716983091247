import { useState, useEffect, useContext } from 'react';
import { useFormNewKeyResults } from 'domains/Objectives/contexts/FormNewKeyResults';
import { useFormEditKeyResults } from 'domains/Objectives/contexts/FormEditKeyResults';
import { AuthContext } from 'domains/Authentication/hooks/useAuth';

import { removeItem } from 'domains/Objectives/utils/removeItem';
import { getManagersRequest, getTeamsRequest } from 'domains/Objectives/services/ObjectivesServices';

import { IMember, TeamsOptions } from 'domains/Objectives/interfaces/FormNewObjectivesData';
import { ITeamData } from 'domains/Teams/interfaces/ITeamData';
import { IUser } from 'domains/Authentication/interfaces/IUser';
import { EntityWithIdAndLabel } from 'domains/Objectives/interfaces/IFormNewKeyResultsData';

import { Autocomplete, Checkbox, TextField } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ItemTitle, SectionTitle } from 'styles/globalStyledComponents';
import { ButtonsContainer, TeamButton } from '../../style';

interface IParticipantsFormProps {
	handleNextStep: () => void;
	handleBackStep: () => void;
	isEditForm: boolean;
}

export const ParticipantsForm = ({ handleNextStep, handleBackStep, isEditForm }: IParticipantsFormProps) => {
	const { user } = useContext(AuthContext);
	const { formData, handleFormData, selectedOptionsForm, handleSelectedOptionsForm } = isEditForm
		? useFormEditKeyResults()
		: useFormNewKeyResults();

	const [team, setTeam] = useState<TeamsOptions>(selectedOptionsForm.team);
	const [teamsOptions, setTeamsOptions] = useState<TeamsOptions[]>([]);
	const [selectedProfessionals, setSelectedProfessionals] = useState<IMember[]>(
		selectedOptionsForm.selectedProfessionals,
	);
	const [allProfessionalsIsSelected, setAllProfessionalsIsSelected] = useState(false);

	const [responsable, setResponsable] = useState<EntityWithIdAndLabel | null>(selectedOptionsForm.responsable);
	const [responsableOptions, setResponsableOptions] = useState<EntityWithIdAndLabel[]>([]);

	const handleProfessionalSelect = (event: React.ChangeEvent<HTMLInputElement>, member: IMember) => {
		if (event.target.checked) {
			setSelectedProfessionals([...selectedProfessionals, member]);
		} else if (selectedProfessionals.length > 1) {
			const professionalRemovedArray = removeItem({ array: selectedProfessionals, value: member });
			setSelectedProfessionals(professionalRemovedArray);
		} else {
			setSelectedProfessionals([]);
		}
	};

	const handleSubmitValues = () => {
		const selectedProfessionalsIds = selectedProfessionals.map(professional => professional.id);
		const teamsObject = {
			id: team.id || 'none',
			usersId: selectedProfessionalsIds[0] !== '' ? selectedProfessionalsIds : ['none'],
		};

		handleFormData({
			...formData,
			teams: [teamsObject],
			responsableId: responsable?.id || '',
		});

		handleSelectedOptionsForm({
			...selectedOptionsForm,
			responsable,
			team,
			selectedProfessionals,
		});
		handleNextStep();
	};

	useEffect(() => {
		const getManagersOptions = async () => {
			const data = await getManagersRequest();
			const managersArray = data.data;
			const formatedArray = managersArray.map((item: IUser) => ({
				id: item.person.id,
				label: item.person.name,
			}));

			setResponsableOptions(formatedArray);
		};

		const getTeamsOptions = async () => {
			const data = await getTeamsRequest();
			const teamsArray = data.data;
			const formatedArray = teamsArray.map((team: ITeamData) => ({
				id: team.id,
				label: team.description,
				members: team.members,
			}));

			setTeamsOptions(formatedArray);
		};

		Promise.all([getManagersOptions(), getTeamsOptions()]);
	}, []);

	return (
		<>
			<SectionTitle>Participantes</SectionTitle>

			<Autocomplete
				options={responsableOptions}
				value={responsable}
				onChange={(_, value) => {
					setResponsable(value);
				}}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				renderInput={params => (
					<TextField {...params} label="Gestor" variant="standard" name="responsibleId" required />
				)}
			/>

			<button
				className="self-assign-btn"
				onClick={() => setResponsable({ id: user.person.id, label: user.person.name })}
				type="button"
			>
				Atribuir a mim
			</button>

			<ItemTitle>Contribuintes Diretos</ItemTitle>

			<div className="container-contributors">
				<div className="option-selector">
					<p className="option-selector-title">Times</p>
					{teamsOptions.map(teamOption => (
						<TeamButton
							type="button"
							selected={teamOption.label === team.label}
							onClick={() => {
								setTeam({ ...teamOption });
								setSelectedProfessionals([]);
								setAllProfessionalsIsSelected(false);
							}}
							key={teamOption.id}
						>
							{teamOption.label}
							<ArrowForwardIosIcon style={{ width: '15px', height: '15px' }} />
						</TeamButton>
					))}
				</div>

				<div className="option-selector">
					<p className="option-selector-title">Profissionais</p>
					{teamsOptions && team.id && (
						<>
							<div className="professional-container">
								<Checkbox
									color="primary"
									onChange={event => {
										if (event.target.checked) {
											setSelectedProfessionals(team.members);
											setAllProfessionalsIsSelected(true);
										} else {
											setSelectedProfessionals([]);
											setAllProfessionalsIsSelected(false);
										}
									}}
									checked={allProfessionalsIsSelected}
								/>
								<h2>Selecionar todos</h2>
							</div>
							{team.members.map(member => (
								<div className="professional-container" key={member.id}>
									<Checkbox
										color="primary"
										checked={selectedProfessionals.indexOf(member) !== -1}
										onChange={event => {
											handleProfessionalSelect(event, member);
										}}
									/>
									<img src={member.image} alt={member.name} />
									<p>{member.name}</p>
								</div>
							))}
						</>
					)}
				</div>
			</div>

			<ButtonsContainer>
				<button type="button" className="back-btn" onClick={() => handleBackStep()}>
					Voltar
				</button>
				<button
					disabled={!(team && selectedProfessionals.length && responsable)}
					type="button"
					className="continue-btn"
					onClick={() => handleSubmitValues()}
				>
					Continuar
				</button>
			</ButtonsContainer>
		</>
	);
};
