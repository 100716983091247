import api from 'services/api';
import { IFormNewTeam, IFormEditTeam } from '../interfaces/IFormNewTeamData';

const cycle = JSON.parse(localStorage.getItem('@Advy:cycle')!);

export const storeNewTeamRequest = async (data: IFormNewTeam) => {
	await api
		.post(`/cycles/${cycle.id}/teams`, data)
		.then(response => {
			console.log(response.data);
		})
		.catch(error => console.log(error.message));
};

export const storeEditTeamRequest = async (data: IFormEditTeam) => {
	await api
		.put(`/cycles/${cycle.id}/teams/${data.id}`, { user: data })
		.then(response => {
			console.log(response.data);
		})
		.catch(error => console.log(error.message));
};

export const deleteTeamRequest = async (teamId: string) => {
	await api
		.delete(`/cycles/${cycle.id}/teams/${teamId}`)
		.then(response => {
			console.log(response.data.data);
		})
		.catch(error => console.log(error));
};
