import { useCallback, useEffect, useState } from 'react';
import { ModalProvider } from 'hooks/useModal';

import { MdOutlineClose } from 'react-icons/md';
import { FiInfo, FiEdit, FiTrash2, FiPlusCircle } from 'react-icons/fi';
import { HiOutlineDuplicate } from 'react-icons/hi';
import { FormNewKeyResultsProvider } from 'domains/Objectives/contexts/FormNewKeyResults';
import { FormEditObjectivesProvider } from 'domains/Objectives/contexts/FormEditObjectives';
import { deleteObjective, getUserByIdRequest } from 'domains/Objectives/services/ObjectivesServices';

import { Button } from 'components/Button';
import { ContentInfoModal } from './ContentInfoModal';

import { ModalButton, ModalButtonSettings, Overlay, Container, Head } from './style';

import { IModalProps } from './IModelProps';
import { FormNewObjectives } from '../FormNewObjectives';
import { FormNewKeyResults } from '../FormNewKeyResults';

export const InfoModal: React.FC<IModalProps> = ({
	modalItems,
	objective,
	isOpenDetailsModal,
	handleIsOpenDetailsModal,
}) => {
	const [openModal, setOpenModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);
	const [openModalSettings, setOpenModalSettings] = useState(false);
	const [openStoreNewKeyResultModal, setOpenStoreNewKeyResultModal] = useState(false);
	const [responsableName, setResponsableName] = useState('');

	const handleOpenModalSettings = useCallback(
		(icon?: string) => {
			setOpenModalSettings(true);

			if (icon === 'FiEdit') {
				handleIsEditForm();
				setOpenStoreNewKeyResultModal(true);
			}

			if (icon === 'FiPlus') {
				setIsEditForm(false);
				setOpenStoreNewKeyResultModal(true);
			}

			if (icon === 'FiInfo') {
				setIsEditForm(false);
				setOpenStoreNewKeyResultModal(false);
			}

			if (icon === 'FiTrash2') {
				setOpenModalSettings(false);
				deleteObjective(objective.id);
			}

			if (icon === 'HiDuplicate') {
				setOpenModalSettings(false);
				deleteObjective(objective.id);
			}

			if (openModalSettings && icon !== 'FiTrash2' && icon !== 'HiDuplicate') {
				setOpenModalSettings(false);
				setOpenModal(!openModal);
			}
		},
		[openModalSettings],
	);

	const handleIsEditForm = () => {
		setIsEditForm(!isEditForm);
	};

	const handleStoreNewKeyResultModal = () => {
		setOpenModal(false);
		setOpenStoreNewKeyResultModal(!openStoreNewKeyResultModal);
	};

	const handleObjectiveResponsableData = async () => {
		const data = await getUserByIdRequest(objective.responsableId);

		setResponsableName(data.person.name);
	};

	useEffect(() => {
		setIsEditForm(false);
		setOpenStoreNewKeyResultModal(false);
		if (isOpenDetailsModal) setOpenModal(!openModal);
	}, [isOpenDetailsModal]);

	useEffect(() => {
		if (!openModal && handleIsOpenDetailsModal) handleIsOpenDetailsModal();
	}, [openModal]);

	useEffect(() => {
		handleObjectiveResponsableData();
	}, []);

	return (
		<>
			<Overlay
				openModal={openModal}
				openModalSettings={openModalSettings}
				onClick={() => {
					setOpenModal(false);
					setOpenModalSettings(false);
					setOpenStoreNewKeyResultModal(false);
					setIsEditForm(false);
				}}
			/>

			<ModalButtonSettings onClick={() => handleOpenModalSettings()}>
				<span />
				<span />
				<span />
			</ModalButtonSettings>

			<Container openModalSettings={openModalSettings} openModal={openModal}>
				{modalItems !== undefined &&
					openModalSettings &&
					modalItems.map(item => (
						<button
							onClick={() => handleOpenModalSettings(item.icon)}
							key={item.name}
							className={`item-${item.icon}`}
						>
							{item.icon === 'FiInfo' && <FiInfo />}
							{item.icon === 'FiPlus' && <FiPlusCircle />}
							{item.icon === 'FiEdit' && <FiEdit />}
							{item.icon === 'FiTrash2' && <FiTrash2 />}
							{item.icon === 'HiDuplicate' && <HiOutlineDuplicate />}
							{item.name}
						</button>
					))}
			</Container>

			{openModal && (
				<Container openModal={openModal}>
					{!isEditForm && !openStoreNewKeyResultModal && (
						<>
							<Head openModal={openModal} openModalSettings={openModalSettings}>
								<h4>Detalhes do Objetivo</h4>

								<MdOutlineClose
									onClick={() => setOpenModal(!openModal)}
									size={24}
									color="var(--gray-700)"
								/>
							</Head>

							{objective !== undefined && (
								<ContentInfoModal objective={objective} responsableName={responsableName} />
							)}

							<ModalButton onClick={() => setIsEditForm(true)}>
								<Button bgColor="primary">Editar Objetivo</Button>
							</ModalButton>
						</>
					)}
					{isEditForm && objective && (
						<ModalProvider>
							<FormEditObjectivesProvider objective={objective} handleIsEditForm={handleIsEditForm}>
								<Head openModal={openModal} openModalSettings={openModalSettings}>
									<h4>Editar Objetivo</h4>

									<MdOutlineClose
										onClick={() => setOpenModal(!openModal)}
										size={24}
										color="var(--gray-700)"
									/>
								</Head>
								<FormNewObjectives isEditForm objectiveId={objective.id} />
							</FormEditObjectivesProvider>
						</ModalProvider>
					)}
					{!isEditForm && openStoreNewKeyResultModal && (
						<>
							<Head openModal={openModal} openModalSettings={openModalSettings}>
								<h4>Novo Key Result</h4>

								<MdOutlineClose
									onClick={() => setOpenModal(!openModal)}
									size={24}
									color="var(--gray-700)"
								/>
							</Head>
							<FormNewKeyResultsProvider objectiveId={objective.id}>
								<FormNewKeyResults
									isEditForm={false}
									handleSubmitKeyResult={handleStoreNewKeyResultModal}
								/>
							</FormNewKeyResultsProvider>
						</>
					)}
				</Container>
			)}
		</>
	);
};
