import { useState, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { SelectChangeEvent } from '@mui/material';

import api from 'services/api';

import { Input } from 'components/Input';
import { ContentContainer } from 'components/ContentContainer';
import { BreadcrumbBar } from 'components/BreadcrumbBar';

import { Main } from '../components/Main';
import { Nav } from '../components/Nav';
import { ModalContent } from '../components/ModalContent';
import { Table } from '../components/Table';
import { Profile } from '../components/Profile';
import { OpsContent } from '../components/OpsContent';

import { IPersonDataV3 } from '../interfaces/IPersonData';
import { IProfileData } from '../interfaces/IProfileData';
import { IAreaData } from '../interfaces/IAreaData';
import { ILeaderData } from '../interfaces/ILeaderData';

export const PersonsPage: React.FC = () => {
	const formRef = useRef<FormHandles>(null);

	const [filterPerson, setFilterPerson] = useState('');
	const [filterTeam, setFilterTeam] = useState('');

	const [listPersons, setListPersons] = useState<IPersonDataV3[]>([]);
	const [personSelected, setPersonSelected] = useState<IPersonDataV3[]>([]);
	const [listAreas, setListAreas] = useState<IAreaData[]>([]);
	const [leadersList, setLeadersList] = useState<ILeaderData[]>([]);
	const [personProfile, setPersonProfile] = useState<IProfileData>();

	const handlePersonSelected = (personId: string) => {
		const filtered = listPersons.filter((item: { id: string }) => item.id === personId);

		setPersonSelected(filtered);
	};

	const handleChangeFilterPerson = (event: SelectChangeEvent) => {
		setFilterPerson(event.target.value as string);
	};

	const handleChangeFilterTeam = (event: SelectChangeEvent) => {
		setFilterTeam(event.target.value as string);
	};

	useEffect(() => {
		api.get('users').then(response => {
			setListPersons(response.data.data);
			setPersonSelected([response.data.data[0]]);
		});

		api.get('areas').then(response => {
			setListAreas(response.data.data);
		});

		api.get('users/managers').then(response => {
			setLeadersList(response.data.data);
		});
	}, []);

	useEffect(() => {
		api.get(`users/${personSelected}`).then(response => {
			setPersonProfile(response.data.data);
		});
	}, [personSelected]);

	return (
		<>
			<ContentContainer>
				<Main>
					<Nav />

					<div>
						<BreadcrumbBar
							title="Profissionais Cadastrados"
							buttonContent="Novo Profissional"
							hasButton
							modalTitle="Novo Colaborador"
						>
							<ModalContent listAreas={listAreas} leadersList={leadersList} />
						</BreadcrumbBar>

						{listPersons[0] && (
							<Form ref={formRef} onSubmit={() => console.log('teste')}>
								<div className="inputs-container">
									<Input
										onChange={handleChangeFilterPerson}
										name="filterPerson"
										label="Buscar Profissional"
										placeholder="Buscar profissional"
										search
									/>

									<Input
										onChange={handleChangeFilterTeam}
										className="team-form"
										name="filterTeam"
										label="Times"
										placeholder="Times"
										search
									/>
								</div>
							</Form>
						)}

						{listPersons[0] ? (
							<div>
								<Table
									handlePersonSelected={handlePersonSelected}
									listPersons={listPersons}
									filterPerson={filterPerson}
									filterTeam={filterTeam}
								/>

								{personProfile !== undefined && (
									<Profile
										personProfile={personProfile}
										listPerson={personSelected}
										listAreas={listAreas}
									/>
								)}
							</div>
						) : (
							<OpsContent />
						)}
					</div>
				</Main>
			</ContentContainer>
		</>
	);
};
