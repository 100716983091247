import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Card } from 'components/Card';
import { IOkrsReportData } from 'domains/Reports/Interfaces/IOkrsReportData';
import {
	getOkrsCompanyReports,
	getOkrsManagerReports,
	getOkrsTeamsReports,
	getOkrsUserReports,
} from 'domains/Reports/services/reportsServices';
import { useCycle } from 'hooks/useCycles';
import { useEffect, useState } from 'react';
import { Divider } from 'styles/globalStyledComponents';
import { ItemTitle, Text } from '../style';
import { Container, OkrText, Progress, ProgressContainer, OkrProgressContainer } from './style';

interface IOKRsProgressProps {
	titles: string[];
	pageView: string;
}

const colors = ['#FF7043', '#8E24AA', '#4527A0', '#039BE5', '#EF5350'];

export const OKRsProgress = ({ titles, pageView }: IOKRsProgressProps) => {
	const { cycle, cyclesOptions } = useCycle();

	const [okrsReport, setOkrsReport] = useState<IOkrsReportData>({
		total: 0,
		orks: [],
	});

	const [cycleOkrsReport, setCycleOkrsReport] = useState<IOkrsReportData>({
		total: 0,
		orks: [],
	});

	const [cycleSelected, setCycleSelected] = useState(cycle);

	const handleUserOkrsReports = async () => {
		if (pageView === 'user') await getOkrsUserReports().then(response => setOkrsReport(response));
		if (pageView === 'manager') await getOkrsManagerReports().then(response => setOkrsReport(response));
		if (pageView === 'teams') await getOkrsTeamsReports().then(response => setOkrsReport(response));
		if (pageView === 'company') {
			await getOkrsCompanyReports(cycle.id).then((response: IOkrsReportData) => {
				setOkrsReport(response);
				setCycleOkrsReport(response);
			});
		}
	};

	const handleOkrsCompanyReportsByCycle = async () => {
		await getOkrsCompanyReports(cycleSelected.id).then(response => {
			setCycleOkrsReport(response);
		});
	};

	const handleProgressColor = (index: string): string => {
		const stringArray = index.split('');
		const indexNumber = stringArray[stringArray.length - 1];

		if (indexNumber === '0' || indexNumber === '1') return colors[0];
		if (indexNumber === '2' || indexNumber === '3') return colors[1];
		if (indexNumber === '4' || indexNumber === '5') return colors[2];
		if (indexNumber === '6' || indexNumber === '7') return colors[3];
		if (indexNumber === '8' || indexNumber === '9') return colors[4];

		return colors[0];
	};

	useEffect(() => {
		if (pageView === 'company') handleOkrsCompanyReportsByCycle();
	}, [cycleSelected]);

	useEffect(() => {
		const handleReportsData = async () => {
			await handleUserOkrsReports();
		};

		Promise.all([handleReportsData()]);
	}, []);

	return (
		<Container>
			<ItemTitle>OKRs</ItemTitle>

			{pageView === 'company' && (
				<Card title="OKRs criadas por Ciclos" className="card">
					<Divider marginTop="0.5rem" marginBottom="3rem" />
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							paddingRight: '1rem',
						}}
						className="autocomplete-and-description-container"
					>
						<Autocomplete
							options={cyclesOptions}
							value={cycleSelected}
							onChange={(_, value) => {
								if (value) setCycleSelected(value);
							}}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							renderInput={params => (
								<TextField
									{...params}
									label="Selecionar Ciclo"
									placeholder="Selecionar Ciclo"
									variant="standard"
								/>
							)}
							style={{ width: '55%', marginBottom: '20px' }}
							getOptionLabel={option => option.name}
						/>
						<Text>Quantidade de OKRs: {okrsReport.total}</Text>
					</div>

					<ProgressContainer>
						{cycleOkrsReport.orks.map((okr, index) => (
							<OkrProgressContainer>
								<OkrText>{okr.name}</OkrText>
								<Progress
									progress={Number(okr.progress)}
									color={handleProgressColor(`${index}`)}
									key={okr.id}
								>
									<div>
										<span />
									</div>
									<OkrText>{okr.keyResults}</OkrText>
								</Progress>
							</OkrProgressContainer>
						))}
					</ProgressContainer>
				</Card>
			)}

			<Card title={titles[0]} className="card">
				<Divider marginTop="0.5rem" marginBottom="3rem" />
				<Text>Quantidade de OKRs: {okrsReport.total}</Text>

				<ProgressContainer>
					{okrsReport.orks.map((okr, index) => (
						<OkrProgressContainer>
							<OkrText>{okr.name}</OkrText>
							<Progress
								progress={Number(okr.progress)}
								color={handleProgressColor(`${index}`)}
								key={okr.id}
							>
								<div>
									<span />
								</div>
								<OkrText>{okr.keyResults}</OkrText>
							</Progress>
						</OkrProgressContainer>
					))}
				</ProgressContainer>
			</Card>

			<Card title={titles[1]} className="card">
				<Divider marginTop="0.5rem" marginBottom="3rem" />
				<Text>Quantidade de OKRs: {okrsReport.total}</Text>

				<ProgressContainer>
					{okrsReport.orks.map((okr, index) => (
						<OkrProgressContainer>
							<OkrText>{okr.name}</OkrText>
							<Progress
								progress={Number(okr.progress)}
								color={handleProgressColor(`${index}`)}
								key={okr.id}
							>
								<div>
									<span />
								</div>
								<OkrText>{okr.progress}%</OkrText>
							</Progress>
						</OkrProgressContainer>
					))}
				</ProgressContainer>
			</Card>
		</Container>
	);
};
