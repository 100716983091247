import React, { useRef, useCallback, useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'components/Button';

import { AuthContext } from 'domains/Authentication/hooks/useAuth';
import { getCompanieByIdRequest } from 'domains/Companies/services/CompaniesServices';
import sidebarRoutes from 'config/router/sidebar/sidebar';

import { FiMenu, FiArrowLeft } from 'react-icons/fi';
import logoAdvy from '../assets/advy-small-white.svg';
import { MenuItem } from './components/MenuItem';

import { Container, Header, Nav, Company, HamburguerMenu, Overlay } from './style';

export const MenuMobile: React.FC = () => {
	const { user } = useContext(AuthContext);

	const [collapseState, setCollapseState] = useState(false);
	const [companyHeaderData, setCompanyHeaderData] = useState({
		socialReason: '',
		image: '',
	});

	const getCompanyData = async () => {
		const data = await getCompanieByIdRequest(user.companyId);

		setCompanyHeaderData({
			socialReason: data.socialReason,
			image: data.image,
		});
	};

	useEffect(() => {
		getCompanyData();
	}, []);

	const content = useRef<HTMLDivElement>(null);

	const collapseMenu = useCallback(() => {
		setCollapseState(!collapseState);
	}, [collapseState]);

	return (
		<>
			<HamburguerMenu collapseState={collapseState} onClick={collapseMenu}>
				<FiMenu />
			</HamburguerMenu>

			<Overlay collapseState={collapseState} onClick={collapseMenu} />

			<Container ref={content} collapseState={collapseState}>
				<Header collapseState={collapseState} onClick={collapseMenu}>
					<FiArrowLeft />

					<img src={logoAdvy} alt="Advy" />
				</Header>

				<Company collapseState={collapseState}>
					<img src={companyHeaderData.image} alt="Avatar image" />

					<h4>{companyHeaderData.socialReason}</h4>
				</Company>

				<Nav collapseState={collapseState}>
					<ul>
						{sidebarRoutes.map(sidebarRoute => (
							<MenuItem sidebarRoutes={sidebarRoute} key={sidebarRoute.header} />
						))}
					</ul>
				</Nav>

				<Link
					to="/"
					onClick={() => {
						localStorage.clear();
					}}
				>
					<Button bgColor="primary">Sair</Button>
				</Link>
			</Container>
		</>
	);
};
