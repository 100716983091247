import { useEffect, useState } from 'react';
import { ICompaniesCyclesDataRequest, IModule } from 'domains/Companies/Interfaces/ICompaniesCyclesDataRequest';
import { ICompaniesModulesDataRequest, ICycle } from 'domains/Companies/Interfaces/ICompaniesModulesDataRequest';
import { Divider } from 'domains/Companies/styles/styles';
import Checkbox from '@mui/material/Checkbox';
import { Modal } from 'components/Modal';
import { Container } from './style';
import { FormEditCyclesOrModules } from '../FormEditCyclesOrModules';

interface CyclesOrModulesDetailsProps {
	isCycleComponent: boolean;
	itemId: number;
	cycles: ICompaniesCyclesDataRequest[] | undefined;
	modules: ICompaniesModulesDataRequest[] | undefined;
	companyId: number;
}

export const CyclesOrModulesDetails = ({
	isCycleComponent,
	itemId,
	cycles,
	modules,
	companyId,
}: CyclesOrModulesDetailsProps) => {
	const [formData, setFormData] = useState({
		id: itemId,
		description: '',
		currentYear: '',
		startTime: new Date(),
		endTime: new Date(),
		companyId: 0,
	});
	const [itemCycles, setItemCycles] = useState<ICycle[]>();
	const [itemModules, setItemModules] = useState<IModule[]>();
	const [itemDetails, setItemDetails] = useState<ICompaniesCyclesDataRequest | ICompaniesModulesDataRequest>();
	const [itemPeriodo, setItemPeriodo] = useState('');

	const formatDate = (date: string): string => {
		const dateObject = new Date(date);
		const day = dateObject.getDate();
		const month = dateObject.getMonth() + 1;
		const year = dateObject.getFullYear();

		return `${day}/${month}/${year}`;
	};

	const handleStates = () => {
		const selectedCycle = cycles?.filter(item => item.id === itemId)[0];
		const selectedModules = modules?.filter(item => item.id === itemId)[0];

		if (isCycleComponent && selectedCycle) {
			setItemDetails(selectedCycle);
			setItemModules(selectedCycle.modules);

			const startDate = formatDate(selectedCycle.startTime);
			const endDate = formatDate(selectedCycle.endTime);
			setItemPeriodo(`De ${startDate} a ${endDate}`);

			setFormData({
				...formData,
				description: selectedCycle.description,
				currentYear: `${Number(selectedCycle.currentYear) + 1}`,
				startTime: new Date(selectedCycle.startTime),
				endTime: new Date(selectedCycle.endTime),
				companyId: selectedCycle.companyId,
			});
		} else if (!isCycleComponent && selectedModules) {
			setItemDetails(selectedModules);
			setItemCycles(selectedModules.cycles);

			const startDate = formatDate(selectedModules.startTime);
			const endDate = formatDate(selectedModules.endTime);
			setItemPeriodo(`De ${startDate} a ${endDate}`);

			setFormData({
				...formData,
				description: selectedModules.description,
				currentYear: `${Number(selectedModules.currentYear) + 1}`,
				startTime: new Date(selectedModules.startTime),
				endTime: new Date(selectedModules.endTime),
				companyId: selectedModules.companyId,
			});
		}
	};

	useEffect(() => {
		handleStates();
	}, []);

	useEffect(() => {
		handleStates();
	}, [itemId, isCycleComponent]);

	return (
		<Container>
			<p>
				<b>Nome do {isCycleComponent ? 'Ciclo' : 'Módulo'}</b>: {itemDetails?.description}
			</p>
			<div className="time-content">
				<p className="date">
					<b>Período: </b> {itemPeriodo}
				</p>
				<p>
					<b>Ano Vigente: </b>
					{itemDetails?.currentYear}
				</p>
			</div>

			<Divider />

			<b style={{ fontSize: '19px' }}>{!isCycleComponent ? 'Ciclos' : 'Módulos'}</b>

			{isCycleComponent && (
				<div className="items-container">
					{itemModules?.map(module => (
						<div key={module.description}>
							<div className="checkbox-container">
								<Checkbox checked={module.enabled} color="primary" />
								<b>{module.description}</b>
							</div>
							<p>
								De {formatDate(module.startTime)} a {formatDate(module.endTime)}
							</p>
						</div>
					))}
				</div>
			)}

			{!isCycleComponent && (
				<div className="items-container">
					{itemCycles?.map(cycle => (
						<div key={cycle.id}>
							<div className="checkbox-container">
								<Checkbox checked={cycle.enabled} color="primary" />
								<b>{cycle.description}</b>
							</div>
							<p>
								De {formatDate(cycle.startTime)} a {formatDate(cycle.endTime)}
							</p>
						</div>
					))}
				</div>
			)}

			<Modal
				title={isCycleComponent ? 'Editar Ciclo' : 'Editar Módulo'}
				buttonContent={isCycleComponent ? 'Editar Ciclo' : 'Editar Módulo'}
				hasButton
			>
				<FormEditCyclesOrModules
					isCycleForm={isCycleComponent}
					companyId={companyId}
					id={itemId}
					data={formData}
				/>
			</Modal>
		</Container>
	);
};
