import { useState, useRef, useCallback } from 'react';
import { MdOutlineClose } from 'react-icons/md';

import { TableContainer } from 'components/Table';
import { Button } from 'components/Button';

import { InfoPerson } from '../InfoPerson';
import { EditPerson } from '../EditPerson';

import {
	ContainerDesktop,
	TableRow,
	ContainerMobile,
	Item,
	Avatar,
	Overlay,
	Container,
	Head,
	ModalButton,
} from './style';

import { IPersonDataV3 } from '../../interfaces/IPersonData';
import { IListPersonsTable } from '../../interfaces/IListPersonsTable';

export const Table: React.FC<IListPersonsTable> = ({ listPersons, handlePersonSelected, filterPerson, filterTeam }) => {
	const trRef = useRef<HTMLTableRowElement>(null);

	const [openModal, setOpenModal] = useState(false);
	const [openModalType, setOpenModalType] = useState('');
	const [person, setPerson] = useState<IPersonDataV3>({} as IPersonDataV3);
	const [selected, setSelected] = useState(true);
	const [index, setIndex] = useState(listPersons[0].id);

	const handleOpenModal = useCallback(async (p: IPersonDataV3) => {
		setOpenModal(!openModal);
		setOpenModalType('Detalhes do Profissional');
		setPerson(p);
	}, []);

	const handleClick = (personId: string) => {
		handlePersonSelected(personId);

		setIndex(personId);

		if (selected) {
			setSelected(true);
		}

		if (!selected) {
			setSelected(!selected);
		}
	};

	return (
		<>
			<ContainerDesktop>
				<TableContainer tableItemsNumber={3}>
					<thead>
						<tr>
							<th>Nome</th>
							<th>Cargo</th>
							<th>Setor/Time</th>
						</tr>
					</thead>

					<tbody>
						{filterPerson === '' && filterTeam === ''
							? listPersons.map(item => (
									<TableRow
										key={item.id}
										ref={trRef}
										onClick={() => handleClick(item.id)}
										selected={selected && index === item.id}
									>
										<td>{item.person.name}</td>
										<td>Desenvolvedor</td>
										<td>Mobile</td>
									</TableRow>
							  ))
							: listPersons
									.filter(person =>
										person.person.name.toLowerCase().includes(filterPerson.toLowerCase()),
									)
									.map(item => (
										<TableRow
											key={item.id}
											ref={trRef}
											onClick={() => handleClick(item.id)}
											selected={selected && index === item.id}
										>
											<td>{item.person.name}</td>
											<td>Desenvolvedor</td>
											<td>Mobile</td>
										</TableRow>
									))}
					</tbody>
				</TableContainer>
			</ContainerDesktop>

			<Overlay
				openModal={openModal}
				onClick={() => {
					setOpenModal(false);
				}}
			/>

			<ContainerMobile>
				<h4>Profissionais</h4>

				{listPersons
					.filter(person => person.person.name.toLowerCase().includes(filterPerson.toLowerCase()))
					.map(item => (
						<Item onClick={() => handleOpenModal(item)}>
							<Avatar src={item.image} alt={item.person.name} />

							<p>{item.person.name}</p>
						</Item>
					))}
			</ContainerMobile>

			{openModal && (
				<Container openModal={openModal}>
					<Head openModal={openModal}>
						<h4>{openModalType}</h4>

						<MdOutlineClose onClick={() => setOpenModal(!openModal)} size={24} color="var(--gray-700)" />
					</Head>

					{person !== undefined && openModalType !== 'Editar Profissional' && (
						<>
							<InfoPerson person={person} />

							<ModalButton onClick={() => setOpenModalType('Editar Profissional')}>
								<Button bgColor="primary">Editar Informações</Button>
							</ModalButton>
						</>
					)}

					{person !== undefined && openModalType === 'Editar Profissional' && <EditPerson person={person} />}
				</Container>
			)}
		</>
	);
};
