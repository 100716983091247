import React from 'react';
import { useTransition } from 'react-spring';

import { ToastContent } from './ToastContent';

import { Container } from './style';
import { IToastProps } from './IToastProps';

export const Toast: React.FC<IToastProps> = ({ messages }) => {
	const messagesWithTransitions = useTransition(messages, {
		from: { right: '-200%' },
		enter: { right: '0%' },
		leave: { right: '-200%' },
	});

	return (
		<Container>
			{messagesWithTransitions((styles, message) => (
				<ToastContent key={message.id} style={styles} message={message}></ToastContent>
			))}
		</Container>
	);
};
