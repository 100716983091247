import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { IErrors } from 'domains/Authentication/interfaces/IErrors';

export const FormChangePassword: React.FC = ({ children }) => {
	const formRef = useRef<FormHandles>(null);

	const handleSubmit = useCallback(async (data: object) => {
		try {
			formRef.current?.setErrors({});

			const schema = Yup.object().shape({
				password: Yup.string().min(6),
			});

			await schema.validate(data, {
				abortEarly: false,
			});

			// successToast(successMessageChangePassword);
		} catch (err) {
			const validationErrors: IErrors = {};

			if (err instanceof Yup.ValidationError) {
				err.inner.forEach(error => {
					validationErrors[error.path!] = error.message;
				});

				formRef.current?.setErrors(validationErrors);
			}
		}
	}, []);

	return (
		<Form ref={formRef} onSubmit={handleSubmit}>
			{children}
		</Form>
	);
};
