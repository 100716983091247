import styled from 'styled-components';
import { Divider } from 'styles/globalStyledComponents';

export const DatesContainer = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 2rem;
	margin-bottom: 2rem;
	margin-top: 1rem;
`;

export const Container = styled.div`
	.card {
		height: auto;
		max-height: 70rem;

		@media (max-width: 375px) {
			${Divider} {
				min-width: 11rem;
			}
		}
	}
`;
