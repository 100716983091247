import styled, { css } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem;
	height: auto;

	@media (min-width: 769px) {
		gap: 2%;
	}

	.card {
		max-height: 600px;
		height: 600px;

		@media (min-width: 769px) {
			width: 48%;
		}

		h3 {
			font-family: 'Fira Sans';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 135%;

			color: var(--gray-700);
		}
	}
`;

export const TableContainer = styled.div`
	min-height: 200px;
	max-height: 500px;
	min-height: 495px;
	width: 100%;

	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: var(--gray-200);
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--gray-400);
		border-radius: 4px;
	}

	table {
		width: 100%;

		tbody {
			width: 100%;

			.table-header {
				th:nth-child(1) {
					text-align: left;
				}

				th {
					text-align: center;
					font-family: 'Fira Sans';
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 135%;

					color: var(--gray-500);
					border-bottom: 1px solid var(--gray-200);
				}
			}
		}

		th:nth-child(1) {
			text-align: left;
		}

		th {
			padding: 0.5rem;
			text-align: center;

			font-family: 'Fira Sans';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 140%;
			color: var(--gray-700);
			border-bottom: 1px solid var(--gray-200);
		}
	}
`;

interface PercentualProps {
	value: number;
}

export const Percentual = styled.th<PercentualProps>`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 135%;

	${props =>
		props.value >= 0 &&
		css`
			color: var(--red-400) !important;
		`}

	${props =>
		props.value >= 50 &&
		css`
			color: var(--yellow-400) !important;
		`}

	${props =>
		props.value >= 80 &&
		css`
			color: var(--green-400) !important;
		`}
`;
