import { Input } from 'components/Input';
import { useContext, useEffect, useState } from 'react';

import { ReactComponent as IndividualIcon } from 'domains/Objectives/assets/individual.svg';
import { ReactComponent as CompanyIcon } from 'domains/Objectives/assets/company.svg';
import { ReactComponent as TeamIcon } from 'domains/Objectives/assets/team.svg';
import { ReactComponent as IndividualDisabledIcon } from 'domains/Objectives/assets/individualDisabled.svg';
import { ReactComponent as CompanyDisabledIcon } from 'domains/Objectives/assets/companyDisabled.svg';
import { ReactComponent as TeamDisabledIcon } from 'domains/Objectives/assets/teamDisabled.svg';

import { IUser } from 'domains/Authentication/interfaces/IUser';
import { getManagersRequest, getTeamsRequest } from 'domains/Objectives/services/ObjectivesServices';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Button } from 'components/Button';
import { Autocomplete, Checkbox, TextField, CircularProgress } from '@mui/material';
import { AuthContext } from 'domains/Authentication/hooks/useAuth';
import { Formik } from 'formik';

import { objectiveLevelsOptions } from 'domains/Objectives/utils/objectiveLevel';
import { ITeamData } from 'domains/Teams/interfaces/ITeamData';
import { getCompanieByIdRequest } from 'domains/Companies/services/CompaniesServices';
import { useFormNewObjectives } from 'domains/Objectives/contexts/FormNewObjectives';
import { defaultTeam, useFormEditObjectives } from 'domains/Objectives/contexts/FormEditObjectives';
import {
	EntityWithIdAndLabel,
	IMember,
	TeamsOptions,
	IObjectivesFormData,
} from 'domains/Objectives/interfaces/FormNewObjectivesData';

import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ptBrLocale from 'date-fns/locale/pt-BR';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { removeItem } from 'domains/Objectives/utils/removeItem';
import { isDateBeforeFromAnotherDate, isDateBeforeFromToday } from 'domains/Objectives/utils/formatDates';
import { TeamButton } from '../../style';

interface GeralFormProps {
	handleNextStep: () => void;
	isEditForm: boolean;
	pageView?: string;
}

export const GeralForm = ({ handleNextStep, isEditForm, pageView }: GeralFormProps) => {
	const { user } = useContext(AuthContext);
	const { formData, handleFormData, selectedOptionsGeralForm, handleSelectedOptionsGeralForm } = isEditForm
		? useFormEditObjectives()
		: useFormNewObjectives();

	const [geralFormData] = useState(formData);
	const [objectiveLevel, setObjectiveLevel] = useState(() => {
		if (pageView && pageView === 'teams') return objectiveLevelsOptions.team;
		if (pageView && pageView === 'company') return objectiveLevelsOptions.company;

		return formData.levelId || objectiveLevelsOptions.individual;
	});

	const [responsable, setResponsable] = useState<EntityWithIdAndLabel | null>(selectedOptionsGeralForm.responsable);
	const [team, setTeam] = useState<TeamsOptions>(selectedOptionsGeralForm.team);
	const [selectedProfessionals, setSelectedProfessionals] = useState<IMember[]>(
		selectedOptionsGeralForm.selectedProfessionals,
	);
	const [allProfessionalsIsSelected, setAllProfessionalsIsSelected] = useState(false);
	const [companyId] = useState(selectedOptionsGeralForm.company.id);
	const [companyName, setCompanyName] = useState('');

	const [finalDate, setFinalDate] = useState<Date | null>(formData.finalDate ? new Date(formData.finalDate) : null);
	const [isInvalidDate, setIsInvalidDate] = useState(false);
	const [isEditInvalidDate, setIsEditInvalidDate] = useState(false);

	const [responsableOptions, setResponsableOptions] = useState<EntityWithIdAndLabel[]>([]);
	const [teamsOptions, setTeamsOptions] = useState<TeamsOptions[]>([]);
	const [loadingData, setLoadingData] = useState(true);
	const [loadingResponsable, setLoadingResponsable] = useState(isEditForm);

	const handleChange = (_: React.MouseEvent<HTMLElement>, nextLevel: string) => {
		setObjectiveLevel(nextLevel);
	};

	useEffect(() => {
		const getManagersOptions = async () => {
			const data = await getManagersRequest();
			const managersArray = data.data;
			const formatedArray = managersArray.map((item: IUser) => ({
				id: item.person.id,
				label: item.person.name,
			}));

			setResponsableOptions(formatedArray);
		};

		const getTeamsOptions = async () => {
			const data = await getTeamsRequest();
			const teamsArray = data.data;
			const formatedArray = teamsArray.map((team: ITeamData) => ({
				id: team.id,
				label: team.description,
				members: team.members,
			}));

			setTeamsOptions(formatedArray);
		};

		const getCompanieName = async () => {
			const data = await getCompanieByIdRequest(companyId);

			setCompanyName(data.socialReason);
		};

		Promise.all([getManagersOptions(), getTeamsOptions(), getCompanieName()]);
	}, []);

	useEffect(() => {
		setResponsable(selectedOptionsGeralForm.responsable);
		setLoadingData(false);
	}, [selectedOptionsGeralForm.responsable]);

	useEffect(() => {
		if (!loadingData) setLoadingResponsable(false);
	}, [responsable]);

	useEffect(() => {
		if (objectiveLevel !== objectiveLevelsOptions.team) {
			setTeam(defaultTeam);
		}
	}, [objectiveLevel]);

	const handleProfessionalSelect = (event: React.ChangeEvent<HTMLInputElement>, member: IMember) => {
		if (event.target.checked) {
			setSelectedProfessionals([...selectedProfessionals, member]);
		} else if (selectedProfessionals.length > 1) {
			const professionalRemovedArray = removeItem({ array: selectedProfessionals, value: member });
			setSelectedProfessionals(professionalRemovedArray);
		} else {
			setSelectedProfessionals([]);
		}
	};

	const handleSubmit = (values: IObjectivesFormData) => {
		const selectedProfessionalsIds = selectedProfessionals.map(professional => professional.id);
		const teamsObject = {
			id: team.id || 'none',
			members:
				selectedProfessionalsIds[0] !== ''
					? selectedProfessionalsIds.map(id => ({ id, name: '', image: '' }))
					: [],
		};

		const formatedvalues = {
			levelId: objectiveLevel,
			teams: [teamsObject],
			companyId,
			responsableId: responsable?.id || 'none',
			finalDate: `${finalDate}`,
		};

		handleFormData({ ...values, ...formatedvalues });
		handleSelectedOptionsGeralForm({
			...selectedOptionsGeralForm,
			responsable,
			team,
			company: { id: companyId, name: companyName },
			selectedProfessionals,
		});
		handleNextStep();
	};

	useEffect(() => {
		if (!isEditForm) {
			const isFinalDateValid =
				`${finalDate}` !== 'Invalid Date' && finalDate !== null && !isDateBeforeFromToday(finalDate);

			if (!isFinalDateValid && finalDate !== null) {
				setIsInvalidDate(true);
			} else setIsInvalidDate(false);
		}
		if (isEditForm && formData) {
			const isFinalDateValid =
				`${finalDate}` !== 'Invalid Date' &&
				finalDate !== null &&
				!isDateBeforeFromAnotherDate(formData.finalDate, finalDate);

			if (!isFinalDateValid && finalDate !== null) {
				setIsEditInvalidDate(true);
			} else setIsEditInvalidDate(false);
		}
	}, [finalDate]);

	return (
		<Formik
			initialValues={geralFormData}
			enableReinitialize
			onSubmit={async (values: IObjectivesFormData) => {
				handleSubmit(values);
			}}
		>
			{props => (
				<form
					onSubmit={e => {
						e.preventDefault();
						props.handleSubmit(e);
					}}
				>
					<h3 className="form-item-title">Nível do objetivo</h3>
					<ToggleButtonGroup
						orientation="horizontal"
						value={objectiveLevel}
						exclusive
						onChange={handleChange}
						color="secondary"
						className="button-group"
						size="large"
						sx={{
							'@media (max-width: 48rem)': {
								width: '100%',
								justifyContent: 'space-between !important',
								gap: '1rem !important',
							},
						}}
					>
						<ToggleButton value={objectiveLevelsOptions.individual} aria-label="Individuo">
							<div>
								{objectiveLevel === objectiveLevelsOptions.individual && <IndividualIcon />}
								{objectiveLevel !== objectiveLevelsOptions.individual && <IndividualDisabledIcon />}
								<p>Indivíduo</p>
							</div>
						</ToggleButton>
						<ToggleButton value={objectiveLevelsOptions.team} aria-label="Time">
							<div>
								{objectiveLevel === objectiveLevelsOptions.team && <TeamIcon />}
								{objectiveLevel !== objectiveLevelsOptions.team && <TeamDisabledIcon />}
								<p>Time</p>
							</div>
						</ToggleButton>
						<ToggleButton value={objectiveLevelsOptions.company} aria-label="Empresa">
							<div>
								{objectiveLevel === objectiveLevelsOptions.company && <CompanyIcon />}
								{objectiveLevel !== objectiveLevelsOptions.company && <CompanyDisabledIcon />}
								<p>Empresa</p>
							</div>
						</ToggleButton>
					</ToggleButtonGroup>
					<h3 className="form-item-title">Informações Gerais</h3>

					<Input
						placeholder="Descrição do objetivo"
						required
						value={props.values.name}
						name="name"
						label="Descrição do objetivo"
						className="input-geral-form"
						onChange={props.handleChange}
					/>

					{objectiveLevel === objectiveLevelsOptions.team && (
						<>
							<p className="selector-title">Contribuintes</p>
							<div className="container-contributors">
								<div className="option-selector">
									<p className="option-selector-title">Times</p>
									{teamsOptions.map(teamOption => (
										<TeamButton
											type="button"
											selected={teamOption.label === team.label}
											onClick={() => {
												setTeam({ ...teamOption });
												setSelectedProfessionals([]);
												setAllProfessionalsIsSelected(false);
											}}
											key={teamOption.id}
										>
											{teamOption.label}
											<ArrowForwardIosIcon style={{ width: '15px', height: '15px' }} />
										</TeamButton>
									))}
								</div>

								<div className="option-selector">
									<p className="option-selector-title">Profissionais</p>
									{teamsOptions && team.id && (
										<>
											<div className="professional-container">
												<Checkbox
													color="primary"
													onChange={event => {
														if (event.target.checked) {
															setSelectedProfessionals(team.members);
															setAllProfessionalsIsSelected(true);
														} else {
															setSelectedProfessionals([]);
															setAllProfessionalsIsSelected(false);
														}
													}}
													checked={allProfessionalsIsSelected}
												/>
												<h2>Selecionar todos</h2>
											</div>
											{team.members.map(member => (
												<div className="professional-container" key={member.id}>
													<Checkbox
														color="primary"
														checked={selectedProfessionals.indexOf(member) !== -1}
														onChange={event => {
															handleProfessionalSelect(event, member);
														}}
													/>
													<img src={member.image} alt={member.name} />
													<p>{member.name}</p>
												</div>
											))}
										</>
									)}
								</div>
							</div>
						</>
					)}

					{objectiveLevel === objectiveLevelsOptions.company && (
						<div style={{ marginTop: '1.5rem' }}>
							<Input
								placeholder="Descrição do objetivo"
								required
								value={companyName}
								name="name"
								label="Empresa"
								className="input-geral-form"
								disabled
							/>
						</div>
					)}

					<Autocomplete
						options={responsableOptions}
						value={responsable}
						onChange={(_, value) => {
							setResponsable(value);
						}}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						renderInput={params => (
							<TextField
								{...params}
								label="Gestor"
								variant="standard"
								name="responsibleId"
								required
								style={{ marginTop: '1.5rem' }}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<>
											{loadingResponsable ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</>
									),
								}}
							/>
						)}
					/>

					<button
						id="self-assign-btn"
						onClick={() => setResponsable({ id: user.person.id, label: user.person.name })}
						type="button"
					>
						Atribuir a mim
					</button>

					<LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBrLocale}>
						<DatePicker
							label="Data Final"
							value={finalDate}
							onChange={value => {
								setFinalDate(value);
							}}
							minDate={isEditForm && formData ? new Date(formData.finalDate) : new Date()}
							renderInput={params => (
								<TextField
									{...params}
									variant="standard"
									placeholder="Data Final"
									error={isEditForm ? isEditInvalidDate : isInvalidDate}
									helperText={isInvalidDate || isEditInvalidDate ? 'Data Inválida' : ''}
									style={{ width: '100%', marginTop: '2rem' }}
									required
								/>
							)}
						/>
					</LocalizationProvider>

					<Button
						bgColor="primary"
						className="submit-btn"
						type="submit"
						disabled={!(team && selectedProfessionals.length) || isEditInvalidDate || isInvalidDate}
					>
						Continuar
					</Button>
				</form>
			)}
		</Formik>
	);
};
