import styled from 'styled-components';

export const Container = styled.div`
	box-shadow: var(--elevation-200);
	border-radius: 0.25rem;

	overflow: hidden;
	transition: all 0.2s ease-in;

	padding: 1rem 1.25rem 1.5rem 1.25rem;

	width: 18.5rem;
	min-height: 26rem;
`;

export const Header = styled.div`
	display: flex;
	align-items: center;

	gap: 0.5rem;

	img {
		width: 4.791rem;
		height: 4.5rem;
		border-radius: 50%;

		filter: drop-shadow(var(--elevation-400));
	}

	h2 {
		color: var(--gray-700);

		font-weight: 500;
		font-size: 18px;
	}
`;

export const Divider = styled.span`
	display: block;
	width: 264px;
	height: 1px;

	background: var(--gray-200);

	margin-top: 0.959rem;
	margin-bottom: 1.219rem;
`;

export const DataContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 1.429rem;

	font-size: 16px;
	color: var(--gray-700);

	& img {
		width: 2.129rem;
		height: 2rem;
	}

	& p {
		font-weight: 400;
	}

	& b {
		font-weight: 500;
	}
`;

export const DataContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	margin-bottom: 1.5rem;
`;

export const Button = styled.button`
	width: 100%;
	max-width: 16rem;
	height: 3rem;

	color: var(--white);
	font-size: 16px;
	font-weight: 500;

	background: var(--terciary-400);
	box-shadow: var(--elevation-200);

	border: none;
	border-radius: 4px;
	cursor: pointer;
`;
