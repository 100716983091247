import styled from 'styled-components';

interface ContainerProps {
	collapseState: boolean;
}

interface UserProps {
	collapseState: boolean;
}

interface NavProps {
	collapseState: boolean;
}

interface ButtonCollapseProps {
	collapseState: boolean;
}

export const Overlay = styled.div<ButtonCollapseProps>`
	align-items: center;
	justify-content: center;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;

	transform: ${props => (props.collapseState ? 'translateX(0)' : 'translateX(-100%)')};

	height: 100vh;
	width: 100%;

	background-color: var(--black);
	opacity: 0.5;

	transition: all 0.2s ease-out;
`;

export const Container = styled.div<ContainerProps>`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;

	height: 100vh;
	max-width: 17rem;
	width: 100%;

	background-color: var(--white);
	box-shadow: var(--elevation-800);

	transform: ${props => (props.collapseState ? 'translateX(0)' : 'translateX(-100%)')};

	overflow-y: scroll;

	transition: all 0.2s ease-in;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: var(--gray-200);
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--gray-400);
		border-radius: 4px;
	}

	& > a {
		display: block;

		padding: 0 1.5rem 1.5rem;
	}
`;

export const Header = styled.button<ContainerProps>`
	display: flex;
	align-items: center;

	height: 5rem;
	width: 100%;
	background-color: var(--primary-400);

	margin: 0 auto;
	padding: 0 1.5rem 0 1rem;

	svg {
		width: 1.5rem;
		height: 1.5rem;

		color: var(--white);
	}

	img {
		height: 2rem;
	}

	border: none;
`;

export const Company = styled.div<UserProps>`
	display: flex;
	align-items: center;
	gap: 1rem;

	background-color: var(--gray-50);

	padding: 1.5rem 1rem 1.5rem 1.5rem;

	border-bottom: 1px solid #ebebeb;

	img {
		max-width: 3rem;
		width: 100%;
	}

	h4 {
		font: var(--body-md-500);
		color: var(--gray-700);
	}
`;

export const Nav = styled.nav<NavProps>`
	width: 100%;

	padding: 1.5rem 1rem 1.5rem 1.5rem;

	li {
		list-style: none;
		transition: all 0.2s ease-in;

		a {
			display: flex;
			align-items: center;
			justify-content: ${props => (props.collapseState ? 'center' : 'space-between')};
			gap: 0.5rem;

			padding: 1rem 0;

			p {
				margin-right: auto;

				font: var(--body-md-400);
				color: var(--gray-700);
			}
		}
	}
`;

export const HamburguerMenu = styled.button<ButtonCollapseProps>`
	display: none;
	align-items: center;
	justify-content: center;

	width: 1.5rem;
	height: 1.5rem;
	background-color: transparent;

	border: none;

	& > svg {
		width: 100%;
		height: 100%;

		color: var(--white);
	}

	@media (max-width: 48rem) {
		display: flex;
	}
`;
