import { useEffect, useState } from 'react';
import { useFormNewObjectives } from 'domains/Objectives/contexts/FormNewObjectives';

import { Checkbox, TextareaAutosize } from '@mui/material';

import { objectiveLevelsOptions } from 'domains/Objectives/utils/objectiveLevel';
import { getAreasRequest, getTeamsRequest } from 'domains/Objectives/services/ObjectivesServices';
import { ITeamData } from 'domains/Teams/interfaces/ITeamData';
import { IArea, TeamsOptions } from 'domains/Objectives/interfaces/FormNewObjectivesData';
import { useFormEditObjectives } from 'domains/Objectives/contexts/FormEditObjectives';
import { removeItem } from 'domains/Objectives/utils/removeItem';

interface GeralFormProps {
	handleNextStep: () => void;
	handleBackStep: () => void;
	isEditForm: boolean;
}

export const FundamentationForm = ({ handleNextStep, handleBackStep, isEditForm }: GeralFormProps) => {
	const { formData, handleFormData, selectedOptionsGeralForm, handleSelectedOptionsGeralForm } = isEditForm
		? useFormEditObjectives()
		: useFormNewObjectives();

	const [editorGeneralDescriptionState, setEditorGeneralDescriptionState] = useState(formData.description);
	const [editorSuccessVisionState, setEditorSuccessVisionState] = useState(formData.successVision);
	const [editorFailVisionState, setEditorFailVisionState] = useState(formData.failVision);
	const [editorStrategyAlignState, setEditorStrategyAlignState] = useState(formData.strategyAlignment);
	const [areasOptions, setAreasOptions] = useState<IArea[]>([]);
	const [selectedAreas, setSelectedAreas] = useState<IArea[]>(selectedOptionsGeralForm.correspondences.areas);
	const [teamsOptions, setTeamsOptions] = useState<TeamsOptions[]>([]);
	const [selectedTeams, setSelectedTeams] = useState<TeamsOptions[]>(selectedOptionsGeralForm.correspondences.teams);

	const [isDisabled, setIsDisabled] = useState(true);

	const handleSubmitValues = () => {
		const areasIds = selectedAreas.map(areas => areas.id);
		const teamsIds = selectedTeams.map(teams => teams.id);

		handleSelectedOptionsGeralForm({
			...selectedOptionsGeralForm,
			correspondences: {
				areas: selectedAreas,
				teams: selectedTeams,
			},
		});

		handleFormData({
			...formData,
			description: editorGeneralDescriptionState,
			successVision: editorSuccessVisionState,
			failVision: editorFailVisionState,
			strategyAlignment: editorStrategyAlignState,
			correspondences: {
				areas: areasIds,
				teams: teamsIds,
			},
		});
		handleNextStep();
	};

	const handleAreaSelection = (event: React.ChangeEvent<HTMLInputElement>, area: IArea) => {
		if (event.target.checked) {
			setSelectedAreas([...selectedAreas, area]);
		} else {
			const areaRemovedArray = removeItem({ array: selectedAreas, value: area });
			setSelectedAreas(areaRemovedArray);
		}
	};

	const handleTeamSelection = (event: React.ChangeEvent<HTMLInputElement>, team: TeamsOptions) => {
		if (event.target.checked) {
			setSelectedTeams([...selectedTeams, team]);
		} else {
			const teamRemovedArray = removeItem({ array: selectedTeams, value: team });
			setSelectedTeams(teamRemovedArray);
		}
	};

	useEffect(() => {
		const isLevelTeam = formData.levelId === objectiveLevelsOptions.team;
		if (
			editorGeneralDescriptionState &&
			editorSuccessVisionState &&
			editorFailVisionState &&
			editorStrategyAlignState &&
			(isLevelTeam ? selectedAreas.length > 0 : selectedTeams.length > 0 && selectedAreas.length > 0)
		)
			setIsDisabled(false);
		else {
			setIsDisabled(true);
		}
	}, [
		editorGeneralDescriptionState,
		editorSuccessVisionState,
		editorFailVisionState,
		editorStrategyAlignState,
		selectedAreas,
		selectedTeams,
	]);

	useEffect(() => {
		const getAreasOptions = async () => {
			const data = await getAreasRequest();
			setAreasOptions(data);
		};

		const getTeamsOptions = async () => {
			const data = await getTeamsRequest();
			const teamsArray = data.data;
			const formatedArray = teamsArray.map((team: ITeamData) => ({
				id: team.id,
				label: team.description,
				members: team.members,
			}));

			setTeamsOptions(formatedArray);
		};

		Promise.all([getAreasOptions(), getTeamsOptions()]);
	}, []);

	return (
		<>
			<div>
				<h2 className="form-item-title">Fundamentação</h2>
				<h3 className="text-area-title">Descrição Geral*</h3>
				<p className="description-text">
					Uma boa descrição de objetivo, deixa claro para todos envolvidos o que está sendo buscado.
				</p>
				<TextareaAutosize
					value={editorGeneralDescriptionState}
					style={{ width: '100%', maxWidth: '100%' }}
					minRows={3}
					maxRows={20}
					onChange={event => setEditorGeneralDescriptionState(event.target.value)}
				/>
			</div>

			<div>
				<h3 className="text-area-title">Visão de sucesso*</h3>
				<p className="description-text">Esse objetivo será um sucesso se</p>
				<TextareaAutosize
					value={editorSuccessVisionState}
					style={{ width: '100%', maxWidth: '100%' }}
					minRows={3}
					maxRows={20}
					onChange={event => setEditorSuccessVisionState(event.target.value)}
				/>
			</div>

			<div>
				<h3 className="text-area-title">Visão de falha*</h3>
				<p className="description-text">Consideramos um fracasso...</p>
				<TextareaAutosize
					value={editorFailVisionState}
					style={{ width: '100%', maxWidth: '100%' }}
					minRows={3}
					maxRows={20}
					onChange={event => setEditorFailVisionState(event.target.value)}
				/>
			</div>

			<div>
				<h3 className="text-area-title">Alinhamento de estratégia*</h3>
				<p className="description-text">Como esse objetivo se alinha com o planejamento estratégico</p>
				<TextareaAutosize
					value={editorStrategyAlignState}
					style={{ width: '100%', maxWidth: '100%' }}
					minRows={3}
					maxRows={20}
					onChange={event => setEditorStrategyAlignState(event.target.value)}
				/>
			</div>

			<div>
				<h3 className="text-area-title">Correspondências*</h3>
				<p className="description-text">Quais outros times e áreas estão envolvidas com esse objetivo</p>
				<div className="correspondences-selector">
					<p className="option-selector-title">Áreas</p>

					{areasOptions.map((area: IArea) => (
						<div className="option-container" key={area.id}>
							<Checkbox
								color="primary"
								onChange={event => handleAreaSelection(event, area)}
								checked={selectedAreas.findIndex(i => i.id === area.id) !== -1}
							/>
							<p>{area.description}</p>
						</div>
					))}
				</div>
				{formData.levelId !== objectiveLevelsOptions.team && (
					<div className="correspondences-selector">
						<p className="option-selector-title">Times</p>

						{teamsOptions.map(team => (
							<div className="option-container" key={team.id}>
								<Checkbox
									color="primary"
									onChange={event => handleTeamSelection(event, team)}
									checked={selectedTeams.findIndex(i => i.id === team.id) !== -1}
								/>
								<p>{team.label}</p>
							</div>
						))}
					</div>
				)}
			</div>

			<div className="buttons-container">
				<button type="button" className="back-btn" onClick={() => handleBackStep()}>
					Voltar
				</button>
				<button
					disabled={isDisabled}
					type="button"
					className="continue-btn"
					onClick={() => handleSubmitValues()}
				>
					Continuar
				</button>
			</div>
		</>
	);
};
