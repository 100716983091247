import { Autocomplete, TextField } from '@mui/material';
import { Card } from 'components/Card';
import { getTeamsRequest } from 'domains/Objectives/services/ObjectivesServices';
import { IOKR } from 'domains/Reports/Interfaces/IOkrDataRequest';
import { IKeyResult, IMonitoringData, IOkrData } from 'domains/Reports/Interfaces/IOkrMonitoringData';
import { EntityWithIdAndLabel, IResponsableData } from 'domains/Reports/Interfaces/IOkrsReportData';
import {
	getAllResponsablesOptions,
	getCompanyObjectives,
	getManagerObjectives,
	getMonitoringUserKeyResultsByObjectiveId,
	getTeamsObjectives,
	getUserObjectives,
} from 'domains/Reports/services/reportsServices';
import { ITeamData } from 'domains/Teams/interfaces/ITeamData';
import { useEffect, useState } from 'react';
import { Divider } from 'styles/globalStyledComponents';
import { ItemTitle, Subtitle } from '../style';
import { KrTable } from './KrTable';
import { Container, Header, Progress } from './style';

interface IKeyResultMonitoringProps {
	pageView: string;
}

interface IOkrsOptions extends EntityWithIdAndLabel {
	progress: number;
}

export const KeyResultMonitoring = ({ pageView }: IKeyResultMonitoringProps) => {
	const [okrSelected, setOkrSelected] = useState<IOkrsOptions | null>(null);
	const [okrsOptions, setOkrsOptions] = useState<IOkrsOptions[]>([]);
	const [okrData, setOkrData] = useState<IOkrData>();

	const [responsable, setResponsable] = useState<EntityWithIdAndLabel | null>(null);
	const [responsablesOptions, setResponsableOptions] = useState<EntityWithIdAndLabel[]>([]);

	const [team, setTeam] = useState<EntityWithIdAndLabel | null>(null);
	const [teamsOptions, setTeamsOptions] = useState<EntityWithIdAndLabel[]>([]);

	const handleResponsableOptions = async () => {
		await getAllResponsablesOptions().then((response: IResponsableData[]) => {
			const formatedArray = response.map(responsable => ({
				id: responsable.id,
				label: responsable.person.name,
			}));

			setResponsableOptions(formatedArray);
		});
	};

	const handleTeamsOptions = async () => {
		await getTeamsRequest().then(response => {
			const teamsArray = response.data;
			const formatedArray = teamsArray.map((team: ITeamData) => ({
				id: team.id,
				label: team.description,
				members: team.members,
			}));

			setTeamsOptions(formatedArray);
		});
	};

	const handleOkrsOptions = async () => {
		if (pageView === 'user') {
			await getUserObjectives().then((response: IOKR[]) => {
				const formatedArray = response.map(okr => ({ id: okr.id, label: okr.name, progress: okr.progress }));

				setOkrsOptions(formatedArray);
			});
		}
		if (pageView === 'manager' && responsable !== null) {
			await getManagerObjectives(responsable.id).then((response: IOKR[]) => {
				const formatedArray = response.map(okr => ({ id: okr.id, label: okr.name, progress: okr.progress }));

				setOkrsOptions(formatedArray);
			});
		}

		if (pageView === 'teams' && team !== null) {
			await getTeamsObjectives(team.id).then((response: IOKR[]) => {
				const formatedArray = response.map(okr => ({ id: okr.id, label: okr.name, progress: okr.progress }));

				setOkrsOptions(formatedArray);
			});
		}

		if (pageView === 'company') {
			await getCompanyObjectives().then((response: IOKR) => {
				setOkrsOptions([{ id: response.id, label: response.name, progress: response.progress }]);
			});
		}
	};

	const handleOkrData = async () => {
		if (okrSelected) {
			if (pageView === 'user')
				await getMonitoringUserKeyResultsByObjectiveId(okrSelected.id, pageView).then(
					(response: IMonitoringData) => setOkrData({ ...okrData, keyResults: [...response.keyResults] }),
				);
			else {
				await getMonitoringUserKeyResultsByObjectiveId(okrSelected.id, pageView).then(
					(response: IKeyResult[]) => setOkrData({ ...okrData, keyResults: [...response] }),
				);
			}
		}
	};

	useEffect(() => {
		handleOkrData();
	}, [okrSelected]);

	useEffect(() => {
		handleOkrsOptions();
	}, [responsable, team]);

	useEffect(() => {
		if (pageView === 'user' || pageView === 'company') handleOkrsOptions();
		if (pageView === 'manager') handleResponsableOptions();
		if (pageView === 'teams') handleTeamsOptions();
	}, []);

	return (
		<>
			<ItemTitle>Monitoramento dos Key Results</ItemTitle>
			<Container>
				<Card title="Key Results por Objetivo" className="card">
					<Divider marginTop="0.5rem" marginBottom="1.5rem" />
					<Subtitle>Objetivos</Subtitle>
					<Header>
						<div className="inputs-container">
							{pageView === 'manager' && (
								<Autocomplete
									options={responsablesOptions}
									value={responsable}
									onChange={(_, value) => {
										setResponsable(value);
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={params => (
										<TextField
											{...params}
											label="Selecionar Responsável"
											placeholder="Selecionar Responsável"
											variant="standard"
										/>
									)}
									className="autocomplete"
								/>
							)}

							{pageView === 'teams' && (
								<Autocomplete
									options={teamsOptions}
									value={team}
									onChange={(_, value) => {
										setTeam(value);
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={params => (
										<TextField
											{...params}
											label="Selecionar Time"
											placeholder="Selecionar Time"
											variant="standard"
										/>
									)}
									className="autocomplete"
								/>
							)}

							<Autocomplete
								options={okrsOptions}
								value={okrSelected}
								onChange={(_, value) => {
									setOkrSelected(value);
								}}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								renderInput={params => (
									<TextField
										{...params}
										label="Selecionar Objetivo"
										placeholder="Selecionar Objetivo"
										variant="standard"
									/>
								)}
								disabled={pageView === 'manager' ? responsable === null : false}
								className="autocomplete"
							/>
						</div>

						{okrSelected && okrData && (
							<Progress progress={okrSelected.progress}>
								<div>
									<span />
								</div>
								{okrSelected.progress}%
							</Progress>
						)}
					</Header>

					{okrData && <KrTable keyResults={okrData.keyResults} />}
				</Card>
			</Container>
		</>
	);
};
