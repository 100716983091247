import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import uuid from 'uuidv4';
import { useModal } from 'hooks/useModal';

import { storeNewCompanieRequest } from '../../services/CompaniesServices';
import { INewCompanieFormData } from '../../Interfaces/INewCompanieFormData';

import { FormSubTitle, CompanieDataContainer, ContainerForm } from './style';
import { Input } from '../../../../components/Input';
import { AutocompleteComponent } from '../../../../components/Autocomplete';
import { ImageInput } from '../ImageInput';
import { Button } from '../../../../components/Button';
import { cities, ufs } from '../../utils/autocompleteMocks';

export const FormNewCompanies: React.FC = () => {
	const { handleModalState } = useModal();

	const [isLoadingRequest, setIsLoadingRequest] = useState(false);
	const [formData, setFormData] = useState<INewCompanieFormData>({
		id: Number(uuid()),
		socialReason: '',
		fantasyName: '',
		cep: '',
		address: '',
		number: '',
		complement: '',
		district: '',
		city: '',
		uf: '',
		telephone: '',
		email: '',
		cnpj: '',
		office: '',
		responsible: '',
		image: '',
	});

	const validationSchema = Yup.object().shape({
		cep: Yup.string().min(8, 'Insira um CEP válido').required(),
		cnpj: Yup.string().min(14, 'Insira um CNPJ válido').required(),
		telephone: Yup.string().min(11, 'Insira um telefone válido').required(),
		email: Yup.string().email('Insira um e-mail válido').required(),
	});

	const handleSubmit = async (values: INewCompanieFormData) => {
		setIsLoadingRequest(true);
		await storeNewCompanieRequest({ ...values, image: formData.image }).then(() => {
			handleModalState();
			setIsLoadingRequest(false);
		});
	};

	const handleImageData = (value: string) => {
		setFormData({ ...formData, image: value });
	};

	return (
		<Formik
			initialValues={formData}
			validationSchema={validationSchema}
			onSubmit={async (values: INewCompanieFormData) => {
				handleSubmit(values);
			}}
		>
			{props => (
				<form
					onSubmit={e => {
						e.preventDefault();
						props.handleSubmit(e);
					}}
				>
					<ContainerForm>
						<FormSubTitle>Dados da Empresa</FormSubTitle>
						<CompanieDataContainer>
							<ImageInput onChange={handleImageData} />
							<Input
								name="fantasyName"
								placeholder="Nome Fantasia"
								label="Nome Fantasia"
								value={props.values.fantasyName}
								onChange={props.handleChange}
								required
							/>
							<Input
								name="socialReason"
								placeholder="Razão Social"
								label="Razão Social"
								value={props.values.socialReason}
								onChange={props.handleChange}
								required
							/>
							<div className="container-multi-inputs">
								<div className="medium-input-container">
									<Input
										name="cep"
										placeholder="CEP"
										label="CEP"
										mask="cep"
										className="small-input"
										value={props.values.cep}
										error={props.touched.cep && Boolean(props.errors.cep)}
										helperText={
											props.touched.cep && props.errors.cep ? props.errors.cep : undefined
										}
										onChange={props.handleChange}
										required
									/>
								</div>
								<Input
									name="address"
									placeholder="Endereço"
									label="Endereço"
									value={props.values.address}
									required
									onChange={props.handleChange}
								/>
							</div>
							<div className="container-multi-inputs">
								<div className="small-input-container">
									<Input
										name="number"
										placeholder="Número"
										label="Número"
										value={props.values.number}
										required
										onChange={props.handleChange}
									/>
								</div>
								<Input
									name="complement"
									placeholder="Complemento"
									label="Complemento"
									value={props.values.complement}
									onChange={props.handleChange}
								/>
							</div>
							<div className="container-multi-inputs">
								<Input
									name="district"
									placeholder="Bairro"
									label="Bairro"
									required
									onChange={props.handleChange}
									value={props.values.district}
								/>
								<AutocompleteComponent
									options={cities}
									label="Cidade"
									name="city"
									value={props.values.city}
									required
									onChange={(_, value) => {
										props.setFieldValue('city', value, true);
									}}
								/>
								<AutocompleteComponent
									options={ufs}
									label="UF"
									value={props.values.uf}
									name="uf"
									required
									onChange={(_, value) => {
										props.setFieldValue('uf', value, true);
									}}
								/>
							</div>
							<div className="container-multi-inputs">
								<Input
									name="telephone"
									placeholder="Telefone"
									label="Telefone"
									mask="telephone"
									required
									error={props.touched.telephone && Boolean(props.errors.telephone)}
									helperText={
										props.touched.telephone && props.errors.telephone
											? props.errors.telephone
											: undefined
									}
									onChange={props.handleChange}
									value={props.values.telephone}
								/>
								<Input
									name="cnpj"
									placeholder="CNPJ"
									label="CNPJ"
									mask="cnpj"
									error={props.touched.cnpj && Boolean(props.errors.cnpj)}
									helperText={props.touched.cnpj && props.errors.cnpj ? props.errors.cnpj : undefined}
									required
									onChange={props.handleChange}
									value={props.values.cnpj}
								/>
							</div>
						</CompanieDataContainer>
						<FormSubTitle>Responsável</FormSubTitle>
						<CompanieDataContainer>
							<Input
								name="responsible"
								placeholder="Nome Completo"
								label="Nome Completo"
								required
								onChange={props.handleChange}
								value={props.values.responsible}
							/>
							<Input
								name="office"
								placeholder="Cargo"
								label="Cargo"
								required
								onChange={props.handleChange}
								value={props.values.office}
							/>
							<Input
								name="email"
								placeholder="E-mail"
								label="E-mail"
								required
								error={props.touched.email && Boolean(props.errors.email)}
								onChange={props.handleChange}
								value={props.values.email}
								helperText={props.touched.email && props.errors.email ? props.errors.email : undefined}
							/>
						</CompanieDataContainer>
						<Button
							type="submit"
							bgColor="primary"
							disabled={!props.dirty || isLoadingRequest}
							isLoading={isLoadingRequest}
						>
							Cadastrar
						</Button>
					</ContainerForm>
				</form>
			)}
		</Formik>
	);
};
