import { useEffect, useRef, useState } from 'react';
import { IPersonData } from 'domains/Persons/interfaces/IPersonData';
import { getDownloadModel, storeUploadModel, getUsersRequest } from 'domains/Companies/services/CompaniesServices';

import { TableContainer } from 'components/Table';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ContainerButtons, InputContainer, TableRow } from './style';

import DownloadIcon from '../../assets/DownloadIcon.svg';
import UploadIcon from '../../assets/UploadIcon.svg';

export const Table = () => {
	const trRef = useRef<HTMLTableRowElement>(null);
	const [listCollaborators, setListCollaborators] = useState<IPersonData[]>([]);

	useEffect(() => {
		const getUsers = async () => {
			const data = await getUsersRequest();
			setListCollaborators(data);
		};

		getUsers();
	}, []);

	const handleDownloadModel = async () => {
		const data = await getDownloadModel();
		const url = window.URL.createObjectURL(new Blob([data]));
		const link = document.createElement('a');

		link.href = url;
		link.setAttribute('download', `contributors.xlsx`);

		document.body.appendChild(link);
		link.click();
		link.parentNode?.removeChild(link);
	};

	const handleUploadModel = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			const formData = new FormData();
			formData.append('file', event.target.files[0]);
			await storeUploadModel(formData);
		}
	};

	return (
		<TableContainer title="Colaboradores" tableItemsNumber={9}>
			<ContainerButtons>
				<button onClick={handleDownloadModel} type="submit">
					<img src={DownloadIcon} alt="Baixar" />
					<p>Download Template</p>
				</button>
				<InputContainer>
					<label htmlFor="input-table">
						<img src={UploadIcon} alt="Enviar" />
						<p>Upload de Tabela</p>
					</label>
					<input type="file" name="input-table" id="input-table" onChange={handleUploadModel} />
				</InputContainer>
			</ContainerButtons>
			<thead>
				<tr>
					<th style={{ width: '30%' }}>Nome</th>
					<th style={{ width: '25%' }}>Cargo</th>
					<th style={{ width: '25%' }}>Time</th>
					<th style={{ width: '20%' }}>Ações</th>
				</tr>
			</thead>

			<tbody>
				{listCollaborators.map(collaborator => (
					<TableRow key={collaborator.id} ref={trRef}>
						<td>{collaborator.person.name}</td>
						<td>Desenvolvedor</td>
						<td>Mobile</td>

						<td>
							<>
								<EditOutlinedIcon color={'primary'} />
								<DeleteOutlineOutlinedIcon color={'error'} />
							</>
						</td>
					</TableRow>
				))}
			</tbody>
		</TableContainer>
	);
};
