import styled from 'styled-components';
import { ModalProvider } from 'hooks/useModal';

import { Divider } from 'styles/globalStyledComponents';
import { Modal } from 'components/Modal';
import { FormNewObjectives } from 'domains/Objectives/components/FormNewObjectives';
import { ModalContent } from 'domains/Persons/components/ModalContent';
import { ILeaderData } from 'domains/Persons/interfaces/ILeaderData';
import { IAreaData } from 'domains/Persons/interfaces/IAreaData';
import { FormNewTeams } from 'domains/Teams/components/FormNewTeams';
import { IUser } from 'domains/Authentication/interfaces/IUser';
import { Description, ItemValue } from '../ResultsInfos/style';

import ContributorsIcon from '../../assets/contributors-icon.svg';
import TeamIcon from '../../assets/teams-icon.svg';
import OKRsTeamsIcon from '../../assets/okrs-teams-icon.svg';
import TargetIcon from '../../assets/target-icon.svg';

interface ICompanyModaisButtons {
	contributors: number;
	teams: number;
	contributorsWithOkrs: number;
	teamsWithOkrs: number;
	leadersList: ILeaderData[];
	listAreas: IAreaData[];
	colaboratorsList: IUser[];
}

export const CompanyModaisButtons = ({
	contributors,
	teams,
	contributorsWithOkrs,
	teamsWithOkrs,
	leadersList,
	listAreas,
	colaboratorsList,
}: ICompanyModaisButtons) => (
	<Container>
		<CardContainer>
			<img src={ContributorsIcon} alt="Ícone de Colaboradores" />
			<Divider marginTop={'0.5rem'} marginBottom={'1rem'} />
			<Description>Colaboradores</Description>
			<ItemValue>{contributors}</ItemValue>
			<ModalProvider>
				<Modal title={'Novo Colaborador'} buttonContent={'Novo Colaborador'} hasButton>
					<ModalContent listAreas={listAreas} leadersList={leadersList} />
				</Modal>
			</ModalProvider>
		</CardContainer>

		<CardContainer>
			<img src={TeamIcon} alt="Íconde de times" />
			<Divider marginTop={'0.5rem'} marginBottom={'1rem'} />
			<Description>Times</Description>
			<ItemValue>{teams}</ItemValue>
			<ModalProvider>
				<Modal title={'Novo Time'} buttonContent={'Novo Time'} hasButton>
					<FormNewTeams
						membersList={colaboratorsList.map(colaborator => ({
							id: colaborator.id,
							person: {
								name: colaborator.person.name,
							},
						}))}
					/>
				</Modal>
			</ModalProvider>
		</CardContainer>

		<CardContainer>
			<img src={TargetIcon} alt="Ícone de alvo" />
			<Divider marginTop={'1rem'} marginBottom={'1rem'} />
			<Description>Colaboradores com OKRs</Description>
			<ItemValue>{contributorsWithOkrs}</ItemValue>
			<ModalProvider>
				<Modal title={'Novo Objetivo'} buttonContent={'Nova OKR'} hasButton>
					<FormNewObjectives isEditForm={false} />
				</Modal>
			</ModalProvider>
		</CardContainer>

		<CardContainer>
			<img src={OKRsTeamsIcon} alt="Ícone de gráfico" />
			<Divider marginTop={'0.5rem'} marginBottom={'1rem'} />
			<Description>Times com OKRs</Description>
			<ItemValue>{teamsWithOkrs}</ItemValue>
			<ModalProvider>
				<Modal title={'Novo Objetivo'} buttonContent={'Nova OKR'} hasButton>
					<FormNewObjectives isEditForm={false} pageView={'teams'} />
				</Modal>
			</ModalProvider>
		</CardContainer>
	</Container>
);

const Container = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 32px;
`;

const CardContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	width: 180px;
	height: 230px;
	border-radius: 4px;

	box-shadow: var(--elevation-200);

	${Description} {
		font-size: 14px;
	}

	${ItemValue} {
		font-size: 32px;
	}

	${Divider} {
		min-width: 15px;
		width: 9rem;
	}

	.modal-btn {
		width: 9.5rem;
		margin-right: 0;
		margin-left: 0;
	}
`;
