import api from 'services/api';
import { IFormUpdatePersonData } from '../interfaces/IFormUpdatePersonData';

export const storeUpdatePersonDataRequest = async (data: IFormUpdatePersonData) => {
	await api
		.put(`/users/f3eab81d-0532-402a-9438-2a52561daf19`, { user: data })
		.then(response => {
			console.log(response.data);
		})
		.catch(error => console.log(error.message));
};

export const getShowUserData = async (userId: string) => {
	const data = await api.get(`users/${userId}`).then(response => response.data);

	return data.data;
};
