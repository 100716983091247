import { useRef } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { IAutocompleteProps } from '../../domains/Persons/interfaces/IAutocompleteProps';

type IAutocompletePropsOmited = Omit<IAutocompleteProps, 'image' | 'value'>;

interface IAutocompleteComponentProps extends IAutocompletePropsOmited {
	value: string;
}

export const AutocompleteComponent = ({
	label,
	defaultValue,
	disabled,
	required,
	options,
	size,
	onChange,
	value,
}: IAutocompleteComponentProps) => {
	const inputRef = useRef<HTMLInputElement>(null);

	return (
		<Autocomplete
			fullWidth
			disableClearable
			size={size}
			defaultValue={defaultValue}
			value={value}
			options={options}
			disabled={disabled}
			ref={inputRef}
			onChange={onChange}
			renderInput={params => <TextField {...params} label={label} variant="standard" required={required} />}
		/>
	);
};
