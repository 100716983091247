import { createContext, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { IAuthData, IAuthDataRequest } from '../interfaces/IAuthData';
import { IAuthState } from '../interfaces/IAuthState';

const AuthContext = createContext<IAuthData>({} as IAuthData);

const AuthProvider: React.FC = ({ children }) => {
	const [hasError, setHasError] = useState('');
	const navigate = useNavigate();

	const [data, setData] = useState<IAuthState>(() => {
		const token = localStorage.getItem('@Advy:token');
		const user = localStorage.getItem('@Advy:user');

		if (token && user) {
			return { token, user: JSON.parse(user) };
		}

		return {} as IAuthState;
	});

	const login = useCallback(async ({ email, password }) => {
		localStorage.removeItem('@Advy:token');
		localStorage.removeItem('@Advy:user');
		localStorage.removeItem('@Advy:cycle');

		setHasError('');

		api.post('auth/login', {
			email,
			password,
		})
			.then(response => {
				const { user }: IAuthDataRequest = response.data.data;

				const token = response.data.data.access_token;
				const refreshToken = response.data.data.refresh_token;
				const cycle = user.cycles.find(u => u.active === true);

				if (token !== undefined) {
					localStorage.setItem('@Advy:token', `Bearer ${token}`);
					localStorage.setItem('@Advy:refresh_token', `Bearer	${refreshToken}`);

					localStorage.setItem('@Advy:user', JSON.stringify(user));
					localStorage.setItem('@Advy:cycle', JSON.stringify(cycle));
					localStorage.setItem('@Advy:cyclesOptions', JSON.stringify(user.cycles));

					setData({ token, user });
				}

				setHasError('off');
				navigate('/home');
			})
			.catch(error => {
				if (error.response) {
					setHasError('on');
				}
			});
	}, []);

	const recoveryPassword = useCallback(async ({ email }) => {
		setHasError('');

		api.post('auth/recovery-password', {
			email,
		})
			.then(response => {
				setHasError('off');
				navigate('/');
				return response.data.message;
			})
			.catch(error => {
				if (error.response) {
					setHasError('on');
				}
			});
	}, []);

	return (
		<AuthContext.Provider value={{ user: data.user, login, recoveryPassword, hasError }}>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };
