import { Card } from 'components/Card';
import { IEngagement } from 'domains/Reports/Interfaces/IEngagementDataRequest';
import { KrTable } from '../KeyResultMonitoring/KrTable';
import { Progress } from '../KeyResultMonitoring/style';
import { CardHeaderContainer, TableContainer } from './style';

interface EngagementTableProps {
	engagements: IEngagement[];
}

export const EngagementTable = ({ engagements }: EngagementTableProps) => (
	<TableContainer>
		<table style={{ minWidth: '1000px' }}>
			<tbody>
				<tr className="table-header">
					<th>Colaborador </th>
					<th>Time</th>
					<th>OKRs (Individuais)</th>
					<th>KRs (Responsável)</th>
					<th>KRs (Concluídas)</th>
					<th>Score</th>
				</tr>
			</tbody>
		</table>
		{engagements.map(engagement => (
			<Card
				title="Table card"
				isCollapsed
				className="table-card"
				optionHeader={
					<CardHeader
						image={engagement.user.image}
						name={engagement.user.person.name}
						team={engagement.user.team.description}
						okrs={engagement.user.score.objectives}
						krs={engagement.user.score.responsabled}
						completedKrs={engagement.user.score.completed}
						score={engagement.user.score.progress}
					/>
				}
			>
				<KrTable keyResults={engagement.keyResults} />
			</Card>
		))}
	</TableContainer>
);

interface CardHeaderProps {
	image: string;
	name: string;
	team: string;
	okrs: number;
	krs: number;
	completedKrs: number;
	score: number;
}

const CardHeader = (props: CardHeaderProps) => (
	<CardHeaderContainer>
		<table>
			<tbody>
				<tr className="table-header-2">
					<th>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<img src={props.image} alt={props.name} />
							<p>{props.name}</p>
						</div>
					</th>
					<th>
						<p>{props.team}</p>
					</th>
					<th>
						<p>{props.okrs}</p>
					</th>
					<th>
						<p>{props.krs}</p>
					</th>
					<th>
						<p>{props.completedKrs}</p>
					</th>
					<th>
						<Progress progress={props.score}>
							<div>
								<span />
							</div>
							{props.score}%
						</Progress>
					</th>
				</tr>
			</tbody>
		</table>
	</CardHeaderContainer>
);
