import { SettingsModal } from '../SettingsModal';

import { Container, Content, Head, Title, LeaderAvatar, Professionals, DataItems } from './style';

import { ICardProps } from './ICardProps';

import checkRoundIcon from '../../assets/check-round.svg';
import pieChartIcon from '../../assets/pie-chart.svg';
import targetOkrIcon from '../../assets/target-okr.svg';

export const CardTeams: React.FC<ICardProps> = ({ team }) => (
	<Container>
		<Head>
			<Title>{team.description}</Title>

			<SettingsModal team={team} />
		</Head>

		<Content>
			<LeaderAvatar>
				<img src={team.leader.image} />

				<p>
					Líder:
					<span>{team.leader.name}</span>
				</p>
			</LeaderAvatar>

			<Professionals>
				<p>
					Colaboradores:
					<span>{team.members.length}</span>
				</p>

				<div>
					{team.members.map(itemMember => (
						<img key={itemMember.id} src={itemMember.image} />
					))}
				</div>
			</Professionals>

			<DataItems>
				<li>
					<img src={targetOkrIcon} />

					<p>
						Objetivos cadastrados:
						<span>{team.objetives}</span>
					</p>
				</li>
				<li>
					<img src={checkRoundIcon} />

					<p>
						KR Concluídos:
						<span>{team.krConcludes}</span>
					</p>
				</li>
				<li>
					<img src={pieChartIcon} />

					<p>
						Índice de conclusão:
						{team.completionIndex && <span>{`${team.completionIndex * 100}%`}</span>}
					</p>
				</li>
			</DataItems>
		</Content>
	</Container>
);
