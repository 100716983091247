import { InputHTMLAttributes, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { Input } from 'components/Input';

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Search: React.FC<IInputProps> = ({ onChange }) => {
	const formRef = useRef<FormHandles>(null);

	return (
		<Form ref={formRef} onSubmit={() => console.log('teste')}>
			<Input onChange={onChange} name="filterTeam" label="Buscar time" placeholder="Buscar time" search />
		</Form>
	);
};
