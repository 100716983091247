import { TextContainer } from 'components/CircularProgressBar/style';
import styled from 'styled-components';
import { Divider } from 'styles/globalStyledComponents';

export const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;

	.card {
		max-height: 420px;
		width: calc(330px + 2%);
		position: relative;

		@media (max-width: 375px) {
			max-width: 20rem;
			margin-left: -1.5rem;

			${Divider} {
				min-width: 15rem;
			}
		}

		${TextContainer} {
			top: 3.4rem;
			right: 37px;
		}

		h3 {
			font-family: 'Fira Sans';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 135%;
			color: var(--gray-700);
		}

		.info-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}
`;

export const ValueText = styled.h3`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 125%;

	color: var(--gray-700);
`;

export const TotalText = styled.h4`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 135%;

	color: var(--gray-400);
`;
