import { ToastPrint } from 'components/Toast/ToastPrint';
import { RoutesApp } from 'config/router/routes';
import { AppProvider } from 'hooks';
import { BrowserRouter } from 'react-router-dom';

export const App = () => (
	<BrowserRouter>
		<AppProvider>
			<RoutesApp />
			<ToastPrint />
		</AppProvider>
	</BrowserRouter>
);
