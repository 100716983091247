import React, { createContext, useContext, useState } from 'react';
import { AuthContext } from 'domains/Authentication/hooks/useAuth';
import {
	EntityWithIdAndLabel,
	IArea,
	IMember,
	IObjectivesFormData,
	TeamsOptions,
} from '../interfaces/FormNewObjectivesData';

interface FormNewObjectivesContextData {
	handleFormData(formData: IObjectivesFormData): void;
	formData: IObjectivesFormData;
	selectedOptionsGeralForm: ISelectedOptionsGeralForm;
	handleSelectedOptionsGeralForm(options: ISelectedOptionsGeralForm): void;
}

interface ISelectedOptionsGeralForm {
	responsable: EntityWithIdAndLabel | null;
	company: {
		id: string;
		name: string;
	};
	team: {
		id: string;
		label: string;
		members: {
			id: string;
			name: string;
			image: string;
		}[];
	};
	correspondences: {
		areas: IArea[];
		teams: TeamsOptions[];
	};
	selectedProfessionals: IMember[];
}

const FormNewObjectivesContext = createContext<FormNewObjectivesContextData>({} as FormNewObjectivesContextData);

const FormNewObjectivesProvider: React.FC = ({ children }) => {
	const { user } = useContext(AuthContext);

	const [formData, setFormData] = useState<IObjectivesFormData>({
		name: '',
		levelId: '',
		responsableId: '',
		description: '',
		successVision: '',
		failVision: '',
		strategyAlignment: '',
		strategyAlignmentID: 'none',
		correspondences: {
			areas: ['none'],
			teams: ['none'],
		},
		weight: 1,
		isPrivate: false,
		userId: user.id,
		teams: [
			{
				id: 'none',
				members: [],
			},
		],
		companyId: 'none',
		finalDate: '',
	});

	const [selectedOptionsGeralForm, setSelectedOptionsGeralForm] = useState<ISelectedOptionsGeralForm>({
		responsable: null,
		company: {
			id: user.companyId,
			name: '',
		},
		team: {
			id: '',
			label: '',
			members: [
				{
					id: '',
					name: '',
					image: '',
				},
			],
		},
		correspondences: {
			areas: [],
			teams: [],
		},
		selectedProfessionals: [
			{
				id: '',
				name: '',
				image: '',
			},
		],
	});

	const handleFormData = (formData: IObjectivesFormData) => {
		setFormData({ ...formData });
	};

	const handleSelectedOptionsGeralForm = (options: ISelectedOptionsGeralForm) => {
		setSelectedOptionsGeralForm({ ...options });
	};

	return (
		<FormNewObjectivesContext.Provider
			value={{ formData, handleFormData, selectedOptionsGeralForm, handleSelectedOptionsGeralForm }}
		>
			{children}
		</FormNewObjectivesContext.Provider>
	);
};

const useFormNewObjectives = (): FormNewObjectivesContextData => {
	const context = useContext(FormNewObjectivesContext);

	if (!context) {
		throw new Error('useModal must be used within a FormNewObjectivesProvider');
	}

	return context;
};

export { FormNewObjectivesProvider, useFormNewObjectives };
