import React, { useRef, useCallback, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'domains/Authentication/hooks/useAuth';
import { getCompanieByIdRequest } from 'domains/Companies/services/CompaniesServices';
import sidebarRoutes from 'config/router/sidebar/sidebar';

import { FaAngleDoubleLeft } from 'react-icons/fa';
import { Button } from 'components/Button';
import { MenuItem } from './components/MenuItem';

import { Container, User, Nav, ButtonCollapse } from './style';

export const Menu: React.FC = () => {
	const { user } = useContext(AuthContext);
	const navigate = useNavigate();

	const [collapseState, setCollapseState] = useState(false);
	const [widthState, setWidthState] = useState('16rem');
	const [companyHeaderData, setCompanyHeaderData] = useState({
		socialReason: '',
		image: '',
	});

	const getCompanyData = async () => {
		const data = await getCompanieByIdRequest(user.companyId);

		setCompanyHeaderData({
			socialReason: data.socialReason,
			image: data.image,
		});
	};

	useEffect(() => {
		getCompanyData();
	}, []);

	const content = useRef<HTMLDivElement>(null);

	const collapseMenu = useCallback(() => {
		setCollapseState(!collapseState);
		setWidthState(collapseState ? '16rem' : '4.5rem');
	}, [collapseState]);

	return (
		<Container ref={content} collapseState={collapseState} widthState={widthState}>
			<ButtonCollapse collapseState={collapseState} onClick={collapseMenu}>
				<FaAngleDoubleLeft size="20" color="white" />
			</ButtonCollapse>

			<User collapseState={collapseState}>
				<img src={user.image} alt="Avatar image" />

				<p>Nome</p>
				<strong>{user.person.name}</strong>
				<p>Empresa</p>
				<strong>{companyHeaderData.socialReason}</strong>
				<p>Cargo</p>
				<strong>Desenvolvedor</strong>
				<Button bgColor="secondary" onClick={() => navigate('/settings')}>
					Editar Perfil
				</Button>
			</User>

			<Nav collapseState={collapseState}>
				<ul>
					{sidebarRoutes.map(sidebarRoute => (
						<MenuItem sidebarRoutes={sidebarRoute} key={sidebarRoute.header} />
					))}
				</ul>
			</Nav>
		</Container>
	);
};
