import { HomePage } from 'domains/Home/pages/HomePage';
import { PersonsPage } from 'domains/Persons/pages/PersonsPage';
import { ObjectivesCompanyPage } from 'domains/Objectives/pages/ObjectivesCompanyPage';
import { ObjectivesManagerPage } from 'domains/Objectives/pages/ObjectivesManagerPage';
import { ObjectivesTeamPage } from 'domains/Objectives/pages/ObjectivesTeamPage';
import { ObjectivesUserPage } from 'domains/Objectives/pages/ObjectivesUserPage';
import { CompaniesPage } from 'domains/Companies/pages/CompaniesPage';
import { CompaniesDetailsPage } from 'domains/Companies/pages/CompaniesDetailsPage';
import { TeamsPage } from 'domains/Teams/pages/TeamsPage';
import { SettingsPage } from 'domains/Settings/pages/SettingsPage';
import { ReportsPage } from 'domains/Reports/pages/ReportsPage';
import { MissingRoute } from '../MissingRoute';

export default [
	{
		path: '*',
		element: <MissingRoute />,
	},
	{
		path: '/home',
		element: <HomePage />,
	},
	{
		path: 'persons',
		element: <PersonsPage />,
	},
	{
		path: 'objectives',
		element: <ObjectivesUserPage />,
	},
	{
		path: 'objectives/user',
		element: <ObjectivesUserPage />,
	},
	{
		path: 'objectives/manager',
		element: <ObjectivesManagerPage />,
	},
	{
		path: 'objectives/team',
		element: <ObjectivesTeamPage />,
	},
	{
		path: 'objectives/company',
		element: <ObjectivesCompanyPage />,
	},
	{
		path: 'companies',
		element: <CompaniesPage />,
	},
	{
		path: 'companies/details',
		element: <CompaniesDetailsPage />,
	},
	{
		path: 'teams',
		element: <TeamsPage />,
	},
	{
		path: 'reports',
		element: <ReportsPage />,
	},
	{
		path: 'settings',
		element: <SettingsPage />,
	},
];
