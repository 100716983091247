import { Input } from 'components/Input';
import { useState } from 'react';
import Switch from '@mui/material/Switch';
import { useFormNewObjectives } from 'domains/Objectives/contexts/FormNewObjectives';
import { useFormEditObjectives } from 'domains/Objectives/contexts/FormEditObjectives';

interface DetailsFormProps {
	handleNextStep: () => void;
	handleBackStep: () => void;
	isEditForm: boolean;
}

export const DetailsForm = ({ handleNextStep, handleBackStep, isEditForm }: DetailsFormProps) => {
	const { formData, handleFormData } = isEditForm ? useFormEditObjectives() : useFormNewObjectives();

	const [weight, setWeight] = useState(formData.weight);
	const [isPrivate, setIsPrivate] = useState(formData.isPrivate);
	const [weightDescriptionCount, setWeightDescriptionCount] = useState(10 - weight);

	const handleSubmitValues = () => {
		handleFormData({ ...formData, weight, isPrivate });
		handleNextStep();
	};

	const handleObjectiveWeight = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number(event.target.value);

		if (value < 1) {
			setWeight(1);
			setWeightDescriptionCount(9);
		} else if (value <= 10) {
			setWeight(value);
			setWeightDescriptionCount(10 - value);
		} else {
			setWeight(10);
			setWeightDescriptionCount(0);
		}
	};

	return (
		<>
			<h3 className="form-item-title">Mais detalhes</h3>
			<Input
				placeholder="Objetivo"
				required
				value={weight}
				name="name"
				type="number"
				label="Objetivo"
				onChange={e => handleObjectiveWeight(e)}
				className="input-geral-form"
				min={1}
				max={10}
			/>
			<p className="description-text">
				<b>{weightDescriptionCount} pontos restantes.</b> O peso de um objetivo compara a importância perante
				outros. Quanto maior o peso de um objetivo, maior é o impacto de seu resultado.
			</p>
			<p style={{ marginTop: '1.5rem', marginBottom: '5px', color: 'var(--gray-700)' }}>Modo privado</p>
			<p className="description-text">
				Por padrão todos objetivos são públicos. No modo privado, apenas você, seu líder e o RH poderão
				visuzalizar. Objetivos privados não possuem alinhamento à outros.
			</p>
			<div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginBottom: '1.5rem' }}>
				<Switch checked={isPrivate} onChange={event => setIsPrivate(event.target.checked)} />
				<p style={!isPrivate ? { color: '#ccc' } : { color: 'var(--gray-700)' }}>
					{!isPrivate ? 'Desativado' : 'Ativado'}
				</p>
			</div>

			<div className="buttons-container" style={{ marginTop: '3rem' }}>
				<button type="button" className="back-btn" onClick={() => handleBackStep()}>
					Voltar
				</button>
				<button type="button" className="continue-btn" onClick={() => handleSubmitValues()}>
					Continuar
				</button>
			</div>
		</>
	);
};
