import { Container, TableContent } from './style';

interface TableProps {
	title?: string;
	tableItemsNumber: number;
}

export const TableContainer: React.FC<TableProps> = ({ title, tableItemsNumber, children }) => (
	<Container className="table-container">
		{!!title && <h3>{title}</h3>}

		<div className="table-div">
			<TableContent tableItemsNumber={tableItemsNumber}>{children}</TableContent>
		</div>
	</Container>
);
