import styled, { css } from 'styled-components';

interface ContainerProps {
	noMobile: boolean;
}

interface SwitchButtonProps {
	isActived: boolean;
}

export const Container = styled.div<ContainerProps>`
	background: var(--white);
	width: 208px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 32px;

	box-sizing: border-box;

	box-shadow: var(--elevation-200);
	border-radius: 200px;

	@media (min-width: 1024px) {
		position: absolute;
		right: 3rem;
	}

	@media (max-width: 992px) {
		margin: 0 auto;
	}
`;

export const SwitchButton = styled.button<SwitchButtonProps>`
	padding: 0px 1rem 0px 1rem;

	${props =>
		props.isActived &&
		css`
			& {
				background-color: var(--terciary-400);

				& p {
					font-family: Fira Sans;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 135%;
					color: var(--white);
				}

				border: none;
				border-radius: 200px;
				height: 32px;
				width: 75%;
			}
		`}

	${props =>
		props.isActived === false &&
		css`
			& {
				background-color: transparent;
				border: none;
				height: 32px;
				width: 104px;

				& p {
					font-family: Fira Sans;
					font-style: normal;
					font-weight: normal;
					font-size: 18px;
					line-height: 135%;
					color: var(--gray-400);
				}
			}
		`}
`;
