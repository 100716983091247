import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

interface StepperProps {
	activeStep: number;
	steps: string[];
}

export const StepperComponent = ({ activeStep, steps }: StepperProps) => (
	<Box sx={{ width: '100%' }} className="stepper-component">
		<Stepper activeStep={activeStep} alternativeLabel>
			{steps.map(label => (
				<Step key={label}>
					<StepLabel>{label}</StepLabel>
				</Step>
			))}
		</Stepper>
	</Box>
);
