import { Container, SwitchButton } from './style';

interface SwitchProps {
	leftTitle: string;
	rightTitle: string;
	isActived: boolean;
	noMobile: boolean;
	handleIsActived: () => void;
}

export const Switch = ({ leftTitle, rightTitle, isActived, noMobile, handleIsActived }: SwitchProps) => (
	<Container className="switch" noMobile={noMobile}>
		<SwitchButton onClick={() => handleIsActived()} isActived={!isActived}>
			<p>{leftTitle}</p>
		</SwitchButton>
		<SwitchButton onClick={() => handleIsActived()} isActived={isActived}>
			<p>{rightTitle}</p>
		</SwitchButton>
	</Container>
);
