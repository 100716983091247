import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	max-width: 19.5rem;
	width: 100%;

	margin: 4rem auto;

	text-align: center;

	h3 {
		font: var(--body-xl-500);
		color: var(--secondary-400);
	}

	p {
		margin-bottom: 1rem;

		color: var(--gray-700);
	}

	button {
		max-width: 16.5rem;
	}
`;
