import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	gap: 1rem;

	@media (max-width: 64rem) {
		flex-direction: column;
	}

	.search-subordinates-container {
		margin-top: 1rem;
		box-shadow: none;
	}
`;
