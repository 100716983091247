import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ToastMessage, useToast } from '../../../hooks/useToast';

import { Container } from './style';

interface ToastProps {
	message: ToastMessage;
	style: object;
}

export const ToastContent: React.FC<ToastProps> = ({ style, message }) => {
	const { removeToast } = useToast();

	useEffect(() => {
		const timer = setTimeout(() => {
			removeToast(message.id);
		}, 6000);

		return () => {
			clearTimeout(timer);
		};
	}, [removeToast, message.id]);

	return (
		<Container type={message.type} style={style}>
			<p>{message.description}</p>

			{message.close && (
				<button onClick={() => removeToast(message.id)} type="button">
					Fechar
				</button>
			)}

			{!message.close && <Link to="/">Ir para login</Link>}
		</Container>
	);
};
