import { ICompaniesDataRequest } from 'domains/Companies/Interfaces/ICompaniesDataRequest';
import { useState } from 'react';
import { Card } from 'components/Card';
import { Input } from 'components/Input';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { putSelectedCompanieRequest } from 'domains/Companies/services/CompaniesServices';

import CircularProgress from '@mui/material/CircularProgress';
import { AutocompleteComponent } from 'components/Autocomplete';
import { Container, Header, Button, FormContainer, ContainerDoubleInputs } from './style';
import { ufs } from '../../utils/autocompleteMocks';

export const BasicInfosComponent: React.FC<{ companyData: ICompaniesDataRequest }> = ({ companyData }) => {
	const [newCompanyData] = useState<ICompaniesDataRequest>(companyData);
	const [isEditable, setIsEditable] = useState(false);
	const [isLoadingRequest, setIsLoadingRequest] = useState(false);

	const validationSchema = Yup.object().shape({
		cep: Yup.string().min(8, 'Insira um CEP válido').required(),
		cnpj: Yup.string().min(14, 'Insira um CNPJ válido').required(),
		telephone: Yup.string().min(11, 'Insira um telefone válido').required(),
		email: Yup.string().email('Insira um e-mail válido').required(),
	});

	const handleInputsDisabled = () => {
		setIsEditable(!isEditable);
	};

	const handleSubmit = async (values: ICompaniesDataRequest) => {
		setIsLoadingRequest(true);
		await putSelectedCompanieRequest(values).then(() => {
			setIsEditable(false);
			setIsLoadingRequest(false);
		});
	};

	const handleEditForm = (props: FormikProps<ICompaniesDataRequest>) => {
		handleInputsDisabled();
		props.resetForm();
	};

	return (
		<Container className="basic-infos-container">
			<Card title="Informações básicas" isCollapsed={true} defaultValue={true}>
				<Header>
					<img src={newCompanyData?.image} alt={newCompanyData?.fantasyName} />
				</Header>
				<Formik
					initialValues={newCompanyData}
					validationSchema={validationSchema}
					onSubmit={async (values: ICompaniesDataRequest) => {
						handleSubmit(values);
					}}
				>
					{props => (
						<FormContainer>
							<form
								onSubmit={e => {
									e.preventDefault();
									props.handleSubmit(e);
								}}
							>
								<Input
									disabled={!isEditable}
									value={props.values.fantasyName}
									label="Nome Fantasia"
									name="fantasyName"
									placeholder="Nome Fantasia"
									onChange={props.handleChange}
									required
								/>
								<Input
									disabled={!isEditable}
									value={props.values.socialReason}
									label="Razão Social"
									name="socialReason"
									placeholder="Razão Social"
									onChange={props.handleChange}
									required
								/>
								<Input
									disabled={!isEditable}
									required
									value={props.values.cnpj}
									label="CNPJ"
									name="cnpj"
									placeholder="CNPJ"
									onChange={props.handleChange}
									mask="cnpj"
									error={props.touched.cnpj && Boolean(props.errors.cnpj)}
									helperText={props.touched.cnpj && props.errors.cnpj ? props.errors.cnpj : undefined}
								/>
								<Input
									disabled={!isEditable}
									required
									value={props.values.cep}
									label="CEP"
									name="cep"
									placeholder="CEP"
									onChange={props.handleChange}
									mask="cep"
									error={props.touched.cep && Boolean(props.errors.cep)}
									helperText={props.touched.cep && props.errors.cep ? props.errors.cep : undefined}
								/>
								<Input
									disabled={!isEditable}
									value={props.values.address}
									required
									label="Endereço"
									name="address"
									placeholder="Endereço"
									onChange={props.handleChange}
								/>
								<ContainerDoubleInputs>
									<Input
										disabled={!isEditable}
										value={props.values.number}
										required
										label="Número"
										name="number"
										onChange={props.handleChange}
										placeholder="Número"
									/>
									<Input
										disabled={!isEditable}
										value={props.values.complement}
										label="Complemento"
										name="complement"
										onChange={props.handleChange}
										placeholder="Complemento"
									/>
								</ContainerDoubleInputs>
								<Input
									disabled={!isEditable}
									required
									value={props.values.district}
									label="Bairro"
									name="district"
									onChange={props.handleChange}
									placeholder="Bairro"
								/>
								<ContainerDoubleInputs>
									<Input
										disabled={!isEditable}
										required
										value={props.values.city}
										label="Cidade"
										onChange={props.handleChange}
										name="city"
										placeholder="Cidade"
									/>
									<AutocompleteComponent
										options={ufs}
										defaultValue={props.values.uf}
										label="UF"
										value={props.values.uf}
										name="uf"
										required
										onChange={(_, value) => {
											props.setFieldValue('uf', value, true);
										}}
										disabled={!isEditable}
									/>
								</ContainerDoubleInputs>
								<Input
									disabled={!isEditable}
									required
									value={props.values.telephone}
									label="Telefone"
									name="telephone"
									placeholder="Telefone"
									onChange={props.handleChange}
									mask="telephone"
									error={props.touched.telephone && Boolean(props.errors.telephone)}
									helperText={
										props.touched.telephone && props.errors.telephone
											? props.errors.telephone
											: undefined
									}
								/>
								<Input
									disabled={!isEditable}
									value={props.values.responsible}
									label="Responsável"
									onChange={props.handleChange}
									name="responsible"
									placeholder="Responsável"
								/>
								<Input
									disabled={!isEditable}
									value={props.values.office}
									label="Cargo"
									onChange={props.handleChange}
									name="office"
									placeholder="Cargo"
								/>
								<Input
									disabled={!isEditable}
									value={props.values.email}
									label="Email"
									onChange={props.handleChange}
									name="email"
									placeholder="Email"
									error={props.touched.email && Boolean(props.errors.email)}
									helperText={
										props.touched.email && props.errors.email ? props.errors.email : undefined
									}
								/>
								{!isEditable && <Button onClick={handleInputsDisabled}>Editar dados</Button>}
								{isEditable && (
									<>
										<Button
											type="submit"
											className="submit-btn"
											disabled={!props.dirty || isLoadingRequest}
										>
											{isLoadingRequest ? (
												<CircularProgress color="inherit" size={20} />
											) : (
												'Salvar alterações'
											)}
										</Button>
										<Button className="cancel-btn" onClick={() => handleEditForm(props)}>
											Cancelar
										</Button>
									</>
								)}
							</form>
						</FormContainer>
					)}
				</Formik>
			</Card>
		</Container>
	);
};
