import styled from 'styled-components';

export const Container = styled.div`
	align-self: flex-start;
	font-family: Fira Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: var(--blue-400);

	position: relative;
	background-color: var(--gray-50);
	width: 264px;
	height: 48px;
	border: 1px dashed var(--gray-300);
	box-sizing: border-box;
	border-radius: 4px;

	& input[type='file'] {
		display: none;
	}

	& label {
		position: absolute;
		padding-top: 0.938rem;
		padding-left: 3rem;

		height: 100%;
		width: 100%;

		& img {
			position: absolute;
			top: 0.938rem;
			left: 1.188rem;

			width: 1.125rem;
			height: 1.125rem;
		}
	}

	@media (min-width: 1024px) {
		width: 104px;
		height: 104px;

		& label {
			cursor: pointer;

			padding-top: 1.969rem;
			padding-bottom: 1.969rem;
			padding-left: 5rem;
			top: 0rem;
			text-align: center;
			width: 20rem;

			.preview {
				left: 0;
				top: 0;
				width: 104px;
				height: 104px;

				border: 1px dashed var(--blue-400);
			}

			& img {
				position: absolute;
				left: 2.5rem;
				top: 2.5rem;

				width: 1.5rem;
				height: 1.5rem;
			}
		}
	}
`;
