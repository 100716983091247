import { Modal } from 'components/Modal';
import { Switch } from 'components/Switch';

import { Container } from './style';

import { IBreadcrumbBarProps } from './IBreadcrumbBarProps';

export const BreadcrumbBar: React.FC<IBreadcrumbBarProps> = ({
	title,
	buttonContent,
	hasButton,
	children,
	modalTitle,
	hasSwitch,
	switchProps,
}) => (
	<Container>
		<h1>{title}</h1>
		<div className="container-modal-switch">
			{hasSwitch && switchProps && (
				<Switch
					leftTitle={switchProps.leftTitle}
					rightTitle={switchProps.rightTitle}
					isActived={switchProps.switchState}
					handleIsActived={switchProps.handleSwitchActived}
					noMobile={false}
				/>
			)}
			<Modal title={!modalTitle ? title : modalTitle} buttonContent={buttonContent} hasButton={hasButton}>
				{children}
			</Modal>
		</div>
	</Container>
);
