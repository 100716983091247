import { Card } from 'components/Card';
import { CircularProgressBar } from 'components/CircularProgressBar';
import { IConclusion } from 'domains/Reports/Interfaces/IConclusionsDataRequest';
import { getConclusionsReports } from 'domains/Reports/services/reportsServices';
import { useEffect, useState } from 'react';
import { Divider } from 'styles/globalStyledComponents';
import { ItemTitle } from '../style';
import { Container, TotalText, ValueText } from './style';

export const OkrsConclusion = () => {
	const [conclusionsData, setConclusionsData] = useState<IConclusion[]>([]);

	const handleConclusionsData = async () => {
		await getConclusionsReports().then(response => setConclusionsData(response));
	};

	useEffect(() => {
		handleConclusionsData();
	}, []);

	return (
		<>
			<ItemTitle>Percentual de Coclusão de OKRs</ItemTitle>
			{conclusionsData.length && (
				<Container>
					<Card title="Conclusão de OKRs - Individual" className="card">
						<Divider marginTop="0rem" marginBottom="1rem" />
						<div className="info-container">
							<CircularProgressBar
								progress={conclusionsData[0].percentage}
								size={200}
								strokeWidth={12}
								circleOneStroke="#C4C4C4"
								circleTwoStroke="var(--terciary-400)"
							/>
							<div style={{ display: 'flex', gap: '4px' }}>
								<ValueText>Valor total: {conclusionsData[0].current} </ValueText>
								<TotalText> / {conclusionsData[0].total}</TotalText>
							</div>
						</div>
					</Card>

					<Card title="Conclusão de OKRs - Time" className="card">
						<Divider marginTop="0rem" marginBottom="1rem" />
						<div className="info-container">
							<CircularProgressBar
								progress={conclusionsData[1].percentage}
								size={200}
								strokeWidth={12}
								circleOneStroke="#C4C4C4"
								circleTwoStroke="var(--terciary-400)"
							/>
							<div style={{ display: 'flex', gap: '4px' }}>
								<ValueText>Valor total: {conclusionsData[1].current} </ValueText>
								<TotalText> / {conclusionsData[1].total}</TotalText>
							</div>
						</div>
					</Card>

					<Card title="Conclusão de OKRs - Empresa" className="card">
						<Divider marginTop="0rem" marginBottom="1rem" />
						<div className="info-container">
							<CircularProgressBar
								progress={conclusionsData[2].percentage}
								size={200}
								strokeWidth={12}
								circleOneStroke="#C4C4C4"
								circleTwoStroke="var(--terciary-400)"
							/>
							<div style={{ display: 'flex', gap: '4px' }}>
								<ValueText>Valor total: {conclusionsData[2].current} </ValueText>
								<TotalText> / {conclusionsData[2].total}</TotalText>
							</div>
						</div>
					</Card>
				</Container>
			)}
		</>
	);
};
