import { Link } from 'react-router-dom';

import { Container } from './style';

export const Nav: React.FC = () => (
	<Container>
		<Link to="/persons">Profissionais</Link>

		<Link to="/teams">Times</Link>
	</Container>
);
