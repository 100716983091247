import styled from 'styled-components';

export const Container = styled.div`
	position: fixed;
	bottom: 2.5rem;
	left: 50%;
	transform: translateX(-50%);
	overflow: hidden;

	max-width: 52rem;
	width: 100%;
	z-index: 99999;
`;
