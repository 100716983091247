import { useRef } from 'react';
import Box from '@mui/material/Box';
import { Autocomplete, TextField } from '@mui/material';
import { IAutocompleteProps } from '../../interfaces/IAutocompleteProps';

type IAutocompleteLeaderPropsOmited = Omit<IAutocompleteProps, 'value'>;

interface IAutocompleteLeaderProps extends IAutocompleteLeaderPropsOmited {
	value: string;
}

export const AutocompleteLeaders = ({
	label,
	image,
	disabled,
	required,
	options,
	size,
	onChange,
	value,
}: IAutocompleteLeaderProps) => {
	const inputRef = useRef<HTMLInputElement>(null);

	return (
		<Autocomplete
			fullWidth
			disableClearable
			size={size}
			value={value}
			options={options}
			disabled={disabled}
			ref={inputRef}
			onChange={onChange}
			isOptionEqualToValue={(option, value) => option === value}
			renderOption={(props, option) => (
				<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
					<img loading="lazy" width="20" src={image[0]} alt="" />
					{option}
				</Box>
			)}
			renderInput={params => <TextField {...params} label={label} variant="standard" required={required} />}
		/>
	);
};
