import styled, { css } from 'styled-components';

interface ContainerProps {
	openModalSettings?: boolean;
	openModal: boolean;
}

export const ModalButton = styled.div`
	width: 15.5rem;

	margin-left: auto;
`;

export const ModalButtonSettings = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2px;

	width: 2rem;
	height: 2rem;

	background-color: transparent;
	border: none;

	cursor: pointer;

	span {
		display: block;

		width: 3px;
		height: 3px;

		background-color: var(--gray-700);
		border: none;
		border-radius: 50%;
	}

	@media (max-width: 48rem) {
		position: absolute;
		top: 0;
		right: 0;
	}
`;

export const Overlay = styled.div<ContainerProps>`
	display: flex;
	align-items: center;
	justify-content: center;

	position: fixed;
	top: 50%;
	left: 50%;
	z-index: -10;

	transform: translate(-50%, -50%);

	height: 100vh;
	width: 100%;

	opacity: 0;

	${props =>
		props.openModal &&
		css`
			z-index: 10;

			background-color: var(--black);
			opacity: 0.4;
		`}

	${props =>
		props.openModalSettings &&
		css`
			z-index: 10;

			background-color: var(--black);
			opacity: 0.05;
		`}

	transition: opacity 0.2s ease-out;
`;

export const Container = styled.div<ContainerProps>`
	display: none;

	${props =>
		props.openModal &&
		css`
			position: fixed;
			z-index: 10;
			top: 50%;
			left: 50%;

			overflow-y: scroll;
			transform: translate(-50%, -50%);

			display: block;

			max-height: 90vh;
			width: 41rem;
			background-color: var(--white);

			padding: 2rem;

			box-shadow: var(--elevation-800);
			border-radius: 0.25rem;

			&::-webkit-scrollbar {
				width: 4px;
			}

			&::-webkit-scrollbar-track {
				background: var(--gray-200);
			}

			&::-webkit-scrollbar-thumb {
				background-color: var(--gray-400);
				border-radius: 4px;
			}
			}
		`}

	${props =>
		props.openModalSettings &&
		css`
			position: absolute;
			z-index: 999;
			top: 3rem;
			right: 2.5rem;

			display: flex;
			flex-direction: column;
			gap: 1.5rem;

			width: 15rem;
			background-color: var(--white);

			padding: 1rem;

			box-shadow: var(--elevation-600);
			border-radius: 0.25rem;

			button {
				display: flex;
				align-items: center;
				gap: 0.5rem;

				background-color: transparent;
				border: none;

				font: var(--body-400);
				color: var(--gray-700);

				svg {
					height: 1.5rem;
					width: 1.5rem;

					color: var(--gray-700);
				}
			}
		`}

	.item-FiPlus {
		color: var(--primary-400);

		svg {
			color: var(--primary-400);
		}
	}

	.item-FiTrash2 {
		color: var(--red-400);

		svg {
			color: var(--red-400);
		}
	}

	@media (max-width: 48rem) {
		max-width: 24rem;
		padding: 1rem 1.5rem 1.5rem;
	}

	@media (max-width: 325px) {
		max-width: 19rem;
	}
`;

export const Head = styled.div<ContainerProps>`
	display: flex;
	justify-content: space-between;

	padding-bottom: 1rem;

	border-bottom: 1px solid var(--gray-200);

	h4 {
		font: var(--body-lg-500);
		color: var(--primary-400);
	}

	svg {
		cursor: pointer;

		&:hover {
			transform: rotate(90deg);
			transition: all 0.4s ease-in;
		}
	}
`;

export const InfoContent = styled.div``;
