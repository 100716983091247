import styled, { css } from 'styled-components';
import { Progress } from '../components/CardOKRs/components/styles';

interface IColumnContainer {
	isDoubleColumns?: boolean;
}

interface ISelectableButton {
	isSelected: boolean;
}

export const Container = styled.div`
	width: 100%;

	display: flex;
	flex-direction: column;
`;

export const PageHeader = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	padding: 2rem;

	@media (max-width: 48rem) {
		flex-direction: column;
		gap: 1rem;

		.modal-btn {
			margin-left: 0px;
		}
	}
`;

export const PageTitle = styled.h1`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 125%;
	color: var(--gray-700);
`;

export const Pageview = styled.div`
	padding: 1rem 2rem;
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
	width: 100%;

	.okrs-column {
		width: 100%;
		max-width: 27rem;
	}
`;

export const CardComponent = styled.div`
	padding: 1rem;
	max-width: 280px;
	min-width: 280px;
	gap: 1rem;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 1rem;

	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);

	@media (max-width: 30rem) {
		min-width: 87vw;
	}
`;

export const CardTitle = styled.h2`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 135%;
	align-self: flex-start !important;

	color: var(--secondary-400);
`;

export const ProfileContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	gap: 0.5rem;

	p {
		font-family: 'Fira Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 125%;
		color: var(--gray-700);
	}

	.email {
		font-family: 'Fira Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 135%;

		color: var(--gray-500);
	}

	img {
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		box-shadow: var(--elevation-200);
	}
`;

export const CircleTeamProfile = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 2rem;
	height: 2rem;
	background-color: var(--terciary-400);

	border-radius: 50%;
	box-shadow: var(--elevation-200);

	font: var(--body-sm-500);
	line-height: 0;
	color: var(--white);
`;

export const ColumnContainer = styled.div<IColumnContainer>`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	width: auto;

	${props =>
		props.isDoubleColumns &&
		css`
			min-width: 60%;
		`}

	@media (max-width: 40rem) {
		width: 100%;
	}
`;

export const SelectableButton = styled.button<ISelectableButton>`
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	background: none;
	border: none;
	border-top: ${props => props.isSelected && '4px solid var(--terciary-400)'};

	width: 100%;
	padding: 1rem 1rem 0 1rem;
	height: 6rem;
	box-shadow: var(--elevation-200);
	border-radius: 4px;

	${CircleTeamProfile} {
		height: 2rem;
		width: 2rem;
	}

	${ProfileContainer} {
		.team-description {
			font-size: 16px;
			max-width: 12rem;
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: left;
		}
		.user-description {
			font-size: 15px;
			max-width: 12rem;
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: left;
		}
	}

	${Progress} {
		div {
			height: 7px;
		}
	}
`;
