import styled from 'styled-components';

export const Container = styled.div`
	margin: 1.5rem 0;

	& > h5 {
		padding-top: 0.5rem;
		padding-bottom: 1rem;

		font: var(--body-sm-500);
		color: var(--secondary-400);
	}
`;

export const Items = styled.div`
	& > h6 {
		padding-bottom: 0.5rem;

		font: var(--body-sm-500);
		color: var(--gray-700);
	}

	& p {
		padding-bottom: 1rem;

		font: var(--body-sm-400);
		color: var(--gray-500);
	}

	& div > strong {
		display: block;
		padding: 0.5rem 0;

		font: var(--body-sm-500);
		color: var(--primary-400);
	}
`;
