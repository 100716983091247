import { useContext, useEffect, useState } from 'react';

import { AuthContext } from 'domains/Authentication/hooks/useAuth';
import {
	createComment,
	getIndicatorsComments,
	getIndicatorsUpdates,
	getUserByIdRequest,
} from 'domains/Objectives/services/ObjectivesServices';
import { formatDateComments, formatDateForChart, formatDateNormal } from 'domains/Objectives/utils/formatDates';
import { formatPercentage, getThePercentage } from 'domains/Objectives/utils/percentage';

import { IComment, IIndicator } from 'domains/Objectives/interfaces/IIndicators';
import { ICardKRsProps } from 'domains/Objectives/interfaces/IObjectiveData';
import { IUser } from 'domains/Authentication/interfaces/IUser';

import { Button } from 'components/Button';
import { Chart } from 'react-google-charts';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { MdOutlineMessage } from 'react-icons/md';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import {
	ChartContainer,
	ContentContainer,
	ItemDescription,
	SectionTitle,
	RightContainer,
	NavContainer,
	ButtonNav,
	Title,
	ActivitiesContainer,
	ActivityContainer,
	ActivitiesTitle,
	ActivitiesDescription,
	ResponsableNameAndDate,
	Divider,
	Progress,
	Marking,
	ComponentArea,
	CommentsContainer,
	CommentContainer,
	MessageContainer,
} from './style';

import Profile from '../../assets/profile-01.png';

interface IComponentProps {
	keyResult: ICardKRsProps;
	handleModalContent: () => void;
}

interface IIndicatorsUpdatesProps {
	createdAt: string;
	id: string;
	responsableId: string;
	value: string;
}

export const ContentModalKeyResultsIndicators = ({ keyResult, handleModalContent }: IComponentProps) => {
	const { user } = useContext(AuthContext);

	const [navBarSelected, setNavBarSelected] = useState('Atividades');
	const [newComment, setNewComment] = useState('');

	const [indicatorsUpdates, setIndicatorsUpdates] = useState<IIndicator[]>([]);
	const [comments, setComments] = useState<IComment[]>([]);
	const [loadingChartData, setLoadingChartData] = useState(true);
	const [reloadData, setReloadData] = useState(false);
	const [chartData] = useState<(string | number)[][]>(() => {
		const infoElement = [' ', keyResult.measure.name, 'Meta'];
		const initialValueElement = [
			formatDateForChart(keyResult.date),
			keyResult.initialValue,
			keyResult.initialValue,
		];

		return [infoElement, initialValueElement];
	});

	const [responsablesIdsActivities, setResponsablesIdsActivities] = useState(['']);
	const [responsablesIdsComments, setResponsablesIdsComments] = useState(['']);
	const [responsablesData, setResponsablesData] = useState<IUser[]>([]);

	const [percentualProgress, setPercentualProgress] = useState(0);
	const [percentualProgressLabel, setPercentualProgressLabel] = useState('');

	const getResponsableNameById = (id: string) => {
		const responsableFilteredArray = responsablesData.filter(data => data.id === id);

		if (responsableFilteredArray.length) {
			return responsableFilteredArray[0]?.person.name;
		}
		return 'User name';
	};

	const getResponsableImageById = (id: string) => {
		const responsableFilteredArray = responsablesData.filter(data => data.id === id);

		if (responsableFilteredArray.length) {
			return responsableFilteredArray[0]?.image;
		}
		return Profile;
	};

	const createActivityMessage = (indicator: IIndicator, index: number): string => {
		const firstValue =
			index === indicatorsUpdates.length - 1 ? keyResult.initialValue : indicatorsUpdates[index + 1].value;
		const newValue = indicator.value;

		return `Indicador do dia ${formatDateNormal(indicator.createdAt)} atualizado de
		${firstValue}
		(${formatPercentage(
			getThePercentage(Number(firstValue), keyResult.completedValue),
		)}) para ${newValue} (${formatPercentage(getThePercentage(Number(newValue), keyResult.completedValue))})`;
	};

	const submitComment = () => {
		if (keyResult.id)
			createComment(keyResult.id, newComment).then(() => {
				setNewComment('');
				setReloadData(!reloadData);
			});
	};

	useEffect(() => {
		const handleIndicatorsUpdates = async () => {
			const requestData: IIndicator[] = await getIndicatorsUpdates(keyResult.id);
			setIndicatorsUpdates(requestData.reverse());

			const responsableIdsArray = requestData.map((item: IIndicatorsUpdatesProps) => item.responsableId);
			setResponsablesIdsActivities(
				responsableIdsArray.filter((id: string, index: number) => responsableIdsArray.indexOf(id) === index),
			);
		};

		const getComments = async () => {
			const comments: IComment[] = await getIndicatorsComments(keyResult.id);
			const responsableIdsArray = comments.map(comment => comment.responsableId);

			setResponsablesIdsComments(
				responsableIdsArray.filter((id: string, index: number) => responsableIdsArray.indexOf(id) === index),
			);
			setComments(comments);
		};

		const getAllResponsablesData = () => {
			responsablesIdsActivities.forEach(async (id: string) => {
				const data = await getUserByIdRequest(id);

				setResponsablesData(() => {
					const index = responsablesData.indexOf(data);
					if (index === -1) {
						return [...responsablesData, data];
					}
					return [...responsablesData];
				});
			});

			responsablesIdsComments.forEach(async (id: string) => {
				const data = await getUserByIdRequest(id);

				setResponsablesData(() => {
					const index = responsablesData.indexOf(data);
					if (index === -1) {
						return [...responsablesData, data];
					}
					return [...responsablesData];
				});
			});
		};

		setPercentualProgress(getThePercentage(keyResult.progress, keyResult.completedValue));
		setPercentualProgressLabel(formatPercentage(getThePercentage(keyResult.progress, keyResult.completedValue)));

		Promise.all([getComments(), handleIndicatorsUpdates()]);
		getAllResponsablesData();
		if (loadingChartData) setLoadingChartData(false);
	}, [reloadData]);

	useEffect(() => {
		const handleIndicatorsUpdates = async () => {
			const requestData: IIndicator[] = await getIndicatorsUpdates(keyResult.id);

			requestData.forEach((update, index) => {
				const arrayLenght = requestData.length;
				const { completedValue } = keyResult;
				const numberToMultiply = index + 1;
				const goalValue = (completedValue / arrayLenght) * numberToMultiply;

				chartData.push([formatDateForChart(update.createdAt), Number(update.value), goalValue]);
			});
		};

		handleIndicatorsUpdates();
	}, []);

	const options = {
		colors: ['#145674', '#DCDBDC'],
	};

	return (
		<ComponentArea>
			<SectionTitle>Key Result</SectionTitle>
			<ItemDescription>{keyResult.description}</ItemDescription>
			<ContentContainer>
				<ChartContainer>
					<SectionTitle>Histórico do Indicador</SectionTitle>
					<div className="chart-container-indicators">
						{!loadingChartData && (
							<Chart
								chartType="Line"
								width="44rem"
								height="400px"
								data={chartData}
								options={options}
								className="chart"
							/>
						)}
					</div>
					<Title style={{ marginTop: '-1.5rem', padding: '0' }}>
						<h1>
							{keyResult.progress} {keyResult.measure.name} {percentualProgressLabel}
						</h1>
					</Title>
					<Progress progress={percentualProgress}>
						<div>
							<span />
						</div>
					</Progress>
					<Marking>
						<p>{keyResult.initialValue}</p>
						<p>{keyResult.completedValue}</p>
					</Marking>
				</ChartContainer>
				<RightContainer>
					<NavContainer>
						<ButtonNav
							onClick={() => {
								setNavBarSelected('Atividades');
							}}
							selected={navBarSelected === 'Atividades'}
						>
							Atividades
						</ButtonNav>
						<ButtonNav
							onClick={() => {
								setNavBarSelected('Comentários');
							}}
							selected={navBarSelected === 'Comentários'}
						>
							Comentários
						</ButtonNav>
					</NavContainer>

					{navBarSelected === 'Atividades' && (
						<>
							<Title>
								<HiOutlineClipboardList />
								<h1>Atividades</h1>
							</Title>

							<ActivitiesContainer>
								{indicatorsUpdates.map((indicator, index) => (
									<ActivityContainer>
										<ActivitiesTitle>Indicador Atualizado</ActivitiesTitle>
										<ActivitiesDescription>
											{createActivityMessage(indicator, index)}
										</ActivitiesDescription>
										<ResponsableNameAndDate>
											{getResponsableNameById(indicator.responsableId)} <span />
											{formatDateNormal(indicator.createdAt)}
										</ResponsableNameAndDate>
										<Divider />
									</ActivityContainer>
								))}
							</ActivitiesContainer>
						</>
					)}

					{navBarSelected === 'Comentários' && (
						<>
							<Title>
								<MdOutlineMessage />
								<h1>Comentários</h1>
							</Title>

							<CommentsContainer>
								{comments.map(comment => (
									<CommentContainer selfComment={user.id === comment.responsableId}>
										<img src={getResponsableImageById(comment.responsableId)} alt="Profile" />
										<div>
											<ResponsableNameAndDate>
												{getResponsableNameById(comment.responsableId)} <span />
												{formatDateComments(comment.createdAt)}
											</ResponsableNameAndDate>
											<MessageContainer>
												<p>{comment.description}</p>
											</MessageContainer>
										</div>
									</CommentContainer>
								))}
							</CommentsContainer>

							<TextareaAutosize
								value={newComment}
								minRows={3}
								maxRows={20}
								onChange={event => setNewComment(event.target.value)}
							/>
							<Button
								bgColor="primary"
								className="btn-send-comment"
								disabled={!newComment}
								onClick={submitComment}
							>
								Enviar
							</Button>
						</>
					)}
				</RightContainer>
			</ContentContainer>
			<Button bgColor="primary" className="btn-edit-indicator" onClick={handleModalContent}>
				Atualizar Indicador
			</Button>
		</ComponentArea>
	);
};
