import { Link } from 'react-router-dom';
import { FormRecoveryPassword } from '../components/FormRecoveryPassword';
import { Wrapper } from '../components/Wrapper';

import { ReactComponent as PasswordLock } from '../assets/password-lock.svg';

export const RecoveryPassword: React.FC = () => (
	<Wrapper>
		<PasswordLock />

		<FormRecoveryPassword />

		<Link to="/">Voltar para Login</Link>
	</Wrapper>
);
