import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;

	.backIcon {
		cursor: pointer;
		color: var(--primary-400);
	}

	.link {
		text-decoration: none;
		font-family: Fira Sans;
		font-style: normal;
		font-weight: 500;
		font-size: 1.5em;
		color: var(--gray-700);
	}

	.title {
		color: var(--secondary-400);

		font-family: Fira Sans;
		font-style: normal;
		font-weight: 500;
		font-size: 1.5em;
	}
`;
