import { useEffect, useCallback, useContext } from 'react';

import { AuthContext } from '../../../domains/Authentication/hooks/useAuth';
import { useToast } from '../../../hooks/useToast';

export const ToastPrint: React.FC = () => {
	const { hasError } = useContext(AuthContext);
	const { addToast } = useToast();

	const successToast = useCallback(message => {
		addToast(message);
	}, []);

	const errorToast = useCallback(message => {
		addToast(message);
	}, []);

	useEffect(() => {
		if (hasError === 'off') {
			successToast({
				type: 'success',
				description: localStorage.getItem('@Advy:message'),
				close: true,
			});
		}

		if (hasError === 'on') {
			errorToast({
				type: 'error',
				description: localStorage.getItem('@Advy:message'),
				close: true,
			});
		}
	}, [hasError]);

	return <></>;
};
