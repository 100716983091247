import { useContext, useEffect, useState } from 'react';

import api from 'services/api';

import { ContentContainer } from 'components/ContentContainer';
import { AuthContext } from 'domains/Authentication/hooks/useAuth';

import { IUser } from 'domains/Authentication/interfaces/IUser';

import { Main } from '../components/Main';
import { Profile } from '../components/Profile';
import { Logout } from '../components/Logout';

import { getShowUserData } from '../services/SettingsServices';

export const SettingsPage: React.FC = () => {
	const [companyName, setCompanyName] = useState('');
	const [managerName, setManagerName] = useState('');
	const { user } = useContext(AuthContext);
	const [userProfileData, setUserProfileData] = useState<IUser>(user);

	useEffect(() => {
		const getAllProfileData = async () => {
			const userData = await getShowUserData(user.id);
			setUserProfileData(userData);

			await api.get(`companies/${user?.companyId}`).then(response => {
				setCompanyName(response.data.data.fantasyName);
			});

			const managerData = await getShowUserData(user.managerId);
			setManagerName(managerData.person.name);
		};

		getAllProfileData();
	}, []);

	return (
		<>
			<ContentContainer>
				<Main>
					{user !== undefined && (
						<Profile profileProps={userProfileData} companyName={companyName} managerName={managerName} />
					)}

					<Logout />
				</Main>
			</ContentContainer>
		</>
	);
};
