import styled from 'styled-components';

export const TableRow = styled.tr`
	width: 100%;
`;

export const ContainerButtons = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	right: 2rem;
	top: 1rem;
	gap: 2rem;

	& p {
		display: none;
	}

	@media (min-width: 1200px) {
		& {
			position: absolute;
			right: 2rem;
			top: 1rem;
			gap: 3rem;

			min-width: 21rem;

			p {
				display: block;
			}
		}
	}

	@media (max-width: 425px) {
		right: 1rem;
	}

	button {
		background: transparent;
		border: none;

		display: flex;
		align-items: center;
		width: 100%;

		& p {
			font-family: Fira Sans;
			font-weight: 400;
			font-size: 12px;
			color: var(--secondary-400);
		}
	}
`;

export const InputContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;

	& input[type='file'] {
		display: none;
	}

	& label {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	& p {
		font-family: Fira Sans;
		font-weight: 400;
		font-size: 12px;
		color: var(--secondary-400);
	}
`;
