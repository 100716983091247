import { Button } from 'components/Button';
import { Input } from 'components/Input';

import { ReactComponent as PasswordChange } from '../assets/password-change.svg';
import { FormChangePassword } from '../components/FormChangePassword';
import { Subtitle } from '../components/Subtitle';
import { Title } from '../components/Title';
import { Wrapper } from '../components/Wrapper';

export const ChangePassword: React.FC = () => (
	<Wrapper>
		<PasswordChange />

		<FormChangePassword>
			<Title>Redefina sua senha</Title>

			<Subtitle>Defina uma nova senha para fazer seu login no Advy</Subtitle>

			<Input name="password" type="password" label="Nova Senha" placeholder="Nova Senha" />

			<Button type="submit" bgColor="primary">
				Redefinir
			</Button>
		</FormChangePassword>
	</Wrapper>
);
