import { useState } from 'react';
import { ITask } from 'domains/Objectives/interfaces/ITaskFormData';
import { ModalProvider } from 'hooks/useModal';

import { formatDateNormal } from 'domains/Objectives/utils/formatDates';

import { Divider } from 'styles/globalStyledComponents';
import { BiCalendar } from 'react-icons/bi';
import { FiInfo, FiEdit } from 'react-icons/fi';
import { MdOutlineMessage } from 'react-icons/md';
import { Draggable } from 'react-beautiful-dnd';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Modal } from 'components/Modal';
import { Card, DescriptionText, ProfileContainer, TaskTitle, BottomDescription } from './styles';
import { FormNewTasks } from '../../FormNewTasks';

interface ITaskCardProps {
	task: ITask;
	index: number;
	reloadTasks: () => void;
}

export const TaskCard = ({ task, index, reloadTasks }: ITaskCardProps) => {
	const [openModalDetails, setOpenModalDetails] = useState(false);
	const [openModalEdit, setOpenModalEdit] = useState(false);
	const [contextMenu, setContextMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const handleContextMenu = (event: React.MouseEvent) => {
		event.preventDefault();
		setContextMenu(
			contextMenu === null
				? {
						mouseX: event.clientX - 2,
						mouseY: event.clientY - 4,
				  }
				: null,
		);
	};

	const handleOpenModalDetails = () => {
		handleClose();
		setOpenModalDetails(true);
	};

	const handleOpenModalEdit = () => {
		handleClose();
		setOpenModalEdit(true);
	};

	const handleClose = () => {
		setContextMenu(null);
	};

	return (
		<div onContextMenu={handleContextMenu}>
			<Draggable draggableId={task.id} index={index}>
				{provided => (
					<Card
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						ref={provided.innerRef}
						onClick={handleContextMenu}
					>
						<DescriptionText>Objetivo: {task.keyResult.objective.description}</DescriptionText>
						<DescriptionText>KR: {task.keyResult.description}</DescriptionText>
						<TaskTitle>{task.description}</TaskTitle>
						<ProfileContainer>
							<img src={task.user.image} alt={task.user.person.name} />
							{task.user.person.name}
						</ProfileContainer>
						<Divider marginTop="1rem" marginBottom="1rem" />
						<BottomDescription>
							<DescriptionText>
								<BiCalendar />
								{formatDateNormal(task.predictedDate)}
							</DescriptionText>
							<DescriptionText>
								<MdOutlineMessage />
								{task.comments}
							</DescriptionText>
						</BottomDescription>
					</Card>
				)}
			</Draggable>
			<Menu
				open={contextMenu !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
				}
			>
				<MenuItem onClick={handleOpenModalDetails} style={{ color: 'var(--gray-700)' }}>
					<FiInfo style={{ height: '20px', width: '20px', marginRight: '10px' }} /> Ver Detalhes
				</MenuItem>
				<MenuItem onClick={handleOpenModalEdit} style={{ color: 'var(--gray-700)' }}>
					<FiEdit style={{ height: '20px', width: '20px', marginRight: '10px' }} /> Editar Tarefa
				</MenuItem>
			</Menu>
			<ModalProvider>
				<Modal
					title="Detalhes da Tarefa"
					openModalValue={openModalDetails}
					handleOpenModalValue={() => setOpenModalDetails(!openModalDetails)}
				>
					<FormNewTasks isDetailsForm isEditForm={false} taskData={task} reloadTasks={reloadTasks} />
				</Modal>
			</ModalProvider>
			<ModalProvider>
				<Modal
					title="Editar Tarefa"
					openModalValue={openModalEdit}
					handleOpenModalValue={() => setOpenModalEdit(!openModalEdit)}
				>
					<FormNewTasks isDetailsForm={false} isEditForm taskData={task} reloadTasks={reloadTasks} />
				</Modal>
			</ModalProvider>
		</div>
	);
};
