import { useEffect, useState } from 'react';

import { Input } from 'components/Input';
import { CompanyCard } from '../components/CompanyCard';
import { Modal } from '../../../components/Modal';
import { FormNewCompanies } from '../components/FormNewCompanies';
import { ContentContainer, TopContainer, CardsContainer } from '../styles/styles';

import { getCompaniesRequest } from '../services/CompaniesServices';
import { ICompaniesDataRequest } from '../Interfaces/ICompaniesDataRequest';

export const CompaniesPage: React.FC = () => {
	const [companies, setCompanies] = useState<ICompaniesDataRequest[]>([]);
	const [filteredCompanies, setFilteredCompanies] = useState<ICompaniesDataRequest[]>([]);
	const [companieToSearch, setCompanieToSearch] = useState('');

	useEffect(() => {
		const requestCompaniesData = async () => {
			const companiesData = await getCompaniesRequest();
			setCompanies(companiesData);
			setFilteredCompanies(companiesData);
		};

		requestCompaniesData();
	}, []);

	useEffect(() => {
		const charReg = /^\s*[a-zA-Z0-9-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ,\s]+\s*$/;

		if (companieToSearch !== '' && charReg.test(companieToSearch)) {
			const exp = new RegExp(`${companieToSearch.trim()}`, 'i');

			const filteredCompanies = companies.filter((company: ICompaniesDataRequest) =>
				exp.test(company.fantasyName),
			);

			setFilteredCompanies(filteredCompanies);
		} else {
			setFilteredCompanies(companies);
		}
	}, [companieToSearch]);

	return (
		<>
			<div style={{ display: 'flex' }}>
				<ContentContainer>
					<TopContainer>
						<h1>Empresas Cadastradas</h1>
						<Modal title="Nova Empresa" buttonContent="Nova Empresa" hasButton>
							<FormNewCompanies></FormNewCompanies>
						</Modal>
					</TopContainer>
					<Input
						value={companieToSearch}
						onChange={e => setCompanieToSearch(e.target.value)}
						name="companieInputSearch"
						placeholder="Buscar Empresa"
						search
					/>
					<CardsContainer>
						{filteredCompanies.map(company => (
							<CompanyCard
								id={company.id}
								image={company.image}
								fantasyName={company.fantasyName}
								responseble={company.responsible}
								contributors={company.contributors}
								cycles={company.cycles}
								processes={company.processes}
								key={company.id}
								socialReason={company.socialReason}
							/>
						))}
					</CardsContainer>
				</ContentContainer>
			</div>
		</>
	);
};
