import { useState } from 'react';
import { Container } from './style';
import imageIcon from '../../assets/ImageIcon.svg';
import { IImageInputProps } from '../../Interfaces/IImageInput';

export const ImageInput: React.FC<IImageInputProps> = ({ onChange }) => {
	const [imageUrl, setImageUrl] = useState<string>('');

	const handleChangeImageCompanie = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			const arquivo = event.target.files[0];
			if (arquivo) {
				const url = URL.createObjectURL(arquivo);
				setImageUrl(url);
				onChange(url);
			}
		}
	};

	return (
		<Container>
			<input name="imageUrl" type="file" id="imageUrl" onChange={event => handleChangeImageCompanie(event)} />
			<label htmlFor="imageUrl">
				{(imageUrl && <img src={imageUrl} alt="Preview" className="preview" />) || (
					<img src={imageIcon} alt="Preview" />
				)}
				{imageUrl ? 'Alterar logotipo' : 'Fazer upload do logotipo'}
			</label>
		</Container>
	);
};
