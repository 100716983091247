import styled from 'styled-components';
import { CommentsContainer } from '../ContentModalKeyResultsIndicators/style';

interface ITaskStatusProps {
	status: 'todo' | 'doing' | 'done';
}

export const Container = styled.div`
	padding: 1rem 0 0 0;

	.self-assign-btn {
		border: none;
		background-color: transparent;
		margin-top: 1rem;
		color: var(--primary-400);
		font-size: 14px;
	}

	.flex-container {
		display: flex;
		flex-direction: row;
		gap: 1rem;
	}

	.editable {
		input {
			color: rgba(0, 0, 0, 0.8) !important;
			-webkit-text-fill-color: rgba(0, 0, 0, 0.8) !important;
		}

		label {
			color: rgba(0, 0, 0, 0.6) !important;
			-webkit-text-fill-color: rgba(0, 0, 0, 0.6) !important;
		}
	}

	.submit-btn {
		margin-top: 3rem;
		margin-left: auto;
		width: 10rem;
	}
`;

export const TaskStatusContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 1rem;
`;

export const TaskStatus = styled.div<ITaskStatusProps>`
	display: flex;
	align-items: center;

	height: 2.5rem;
	max-width: ${props =>
		(props.status === 'todo' && '6rem') ||
		(props.status === 'doing' && '9.375rem') ||
		(props.status === 'done' && '6.813rem')};

	padding: 0 1rem;

	box-shadow: var(--elevation-200);

	border-radius: 4px;
	border-color: var(--red-400);
	border-left: 4px solid
		${props =>
			(props.status === 'doing' && 'var(--yellow-400) !important') ||
			(props.status === 'done' && 'var(--green-400) !important')};

	color: var(--red-400);
	color: ${props =>
		(props.status === 'doing' && 'var(--yellow-400) !important') ||
		(props.status === 'done' && 'var(--green-400) !important')};

	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 135%;
`;

export const MenuButton = styled.button`
	background-color: transparent;
	border: none;

	color: var(--primary-400);
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;
`;

export const DeleteButton = styled.button`
	background-color: transparent;
	display: flex;
	align-items: center;
	gap: 0.2rem;

	border: none;

	color: var(--red-400);
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;

	svg {
		height: 1.1rem;
		width: 1.1rem;
	}
`;

export const CommentsArea = styled.div`
	${CommentsContainer} {
		flex-direction: column;
		min-height: 10rem;
		max-height: 13rem;
	}

	textarea {
		padding: 10px;
		width: 100%;
		align-self: center;
		border-color: var(--gray-300);
		margin-top: 1rem;

		color: var(--gray-700);

		font-family: 'Fira Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 135%;

		&:focus {
			border: 1.5px solid var(--secondary-400);
		}
	}

	.btn-send-comment {
		width: 8.125rem;
		height: 3rem;
		align-self: flex-end;
		margin-top: 1rem;
		margin-right: 1.5rem;
	}
`;
