import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;

	& > div {
		display: flex;
		flex-direction: column;

		padding: 0 3rem 2.5rem;
	}

	& > div > form {
		display: flex;
		gap: 1.5rem;

		margin-bottom: 1rem;

		& fieldset {
			height: 3.5rem;
		}
	}

	@media (max-width: 48rem) {
		& > div {
			max-width: 24rem;

			margin: 0 auto;
			padding: 1.25rem;
		}
	}

	.search-subordinates-container,
	.search-teams-container {
		box-shadow: none;
	}

	.columns-container-dnd {
		gap: 2rem;
	}

	.dnd-selectId-container {
		display: flex;
		flex-direction: row;
		gap: 1.5rem;
		margin-left: -2.5rem;

		@media (max-width: 48rem) {
			flex-direction: column;
			margin-left: -0.5rem;
		}
	}
`;
