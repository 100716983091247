import React, { createContext, useContext, useEffect, useState } from 'react';
import {
	IKeyResultData,
	EntityWithIdAndLabel,
	ITeamContributors,
} from 'domains/Objectives/interfaces/IFormNewKeyResultsData';

import { ITeamData } from 'domains/Teams/interfaces/ITeamData';
import { IMember } from '../interfaces/FormNewObjectivesData';
import { getTeamsRequest, getUserByIdRequest } from '../services/ObjectivesServices';

import { measureOptions } from '../utils/measureOptions';

interface IFormEditKeyResultsContext {
	handleFormData(formData: IKeyResultData): void;
	formData: IKeyResultData;
	selectedOptionsForm: ISelectedOptionsForm;
	handleSelectedOptionsForm(options: ISelectedOptionsForm): void;
	keyResultId: string;
	objectiveId: string;
}

interface ISelectedOptionsForm {
	responsable: EntityWithIdAndLabel | null;
	medida: EntityWithIdAndLabel | null;
	team: ITeamContributors;
	selectedProfessionals: IMember[];
}

interface IProviderProps {
	children: React.ReactNode;
	keyResult: IKeyResultData;
	keyResultId: string;
	objectiveId: string;
}

const FormEditKeyResultsContext = createContext<IFormEditKeyResultsContext>({} as IFormEditKeyResultsContext);

export const defaultTeam = {
	id: '',
	label: '',
	members: [
		{
			id: '',
			name: '',
			image: '',
		},
	],
};

const FormEditKeyResultsProvider = ({ children, keyResult, keyResultId, objectiveId }: IProviderProps) => {
	const [formData, setFormData] = useState<IKeyResultData>({ ...keyResult });
	const [selectedOptionsForm, setSelectedOptionsForm] = useState<ISelectedOptionsForm>({
		responsable: null,
		medida: null,
		team: {
			id: '',
			label: '',
			members: [
				{
					id: '',
					name: '',
					image: '',
				},
			],
		},
		selectedProfessionals: [
			{
				id: '',
				name: '',
				image: '',
			},
		],
	});

	const handleFormData = (formData: IKeyResultData) => {
		setFormData({ ...formData });
	};

	const handleSelectedOptionsForm = (options: ISelectedOptionsForm) => {
		setSelectedOptionsForm({ ...options });
	};

	useEffect(() => {
		const handleDatas = async () => {
			const getUserById = async () => {
				const user = await getUserByIdRequest(keyResult.responsableId);

				return user;
			};

			const getFormatedTeams = async () => {
				const data = await getTeamsRequest();
				const teamsArray = data.data;
				const formatedArray = teamsArray.map((team: ITeamData) => ({
					id: `${team.id}`,
					label: team.description,
					members: team.members,
				}));

				return formatedArray;
			};

			const getTeam = async () => {
				const formatedArray = await getFormatedTeams();

				if (keyResult.teams) {
					const teamId = keyResult.teams[0].id;
					const selectedProfessionalsIds = keyResult.teams[0].usersId;

					const selectedTeam = formatedArray.filter((team: ITeamData) => `${team.id}` === teamId) || [];

					const selectedTeamMembers = selectedTeam[0]?.members || [];
					const selectedProfessionals = selectedTeamMembers.filter((member: IMember) =>
						selectedProfessionalsIds.includes(member.id),
					);

					if (selectedTeam.length > 0)
						return {
							id: selectedTeam[0].id,
							label: selectedTeam[0].label,
							members: [...selectedProfessionals],
						};
				}
				return defaultTeam;
			};
			const getMeasure = () => {
				if (keyResult.measure) {
					const measureOfKeyResult = measureOptions.filter(
						measure => keyResult.measure.name === measure.name,
					);

					if (measureOfKeyResult) return measureOfKeyResult[0];
				}

				return { id: '', label: '' };
			};

			const user = await getUserById();
			const selectedTeam = await getTeam();
			const selectedMeasure = getMeasure();

			handleSelectedOptionsForm({
				...selectedOptionsForm,
				responsable: {
					id: keyResult.responsableId,
					label: user.person.name,
				},
				medida: {
					id: selectedMeasure.id,
					label: selectedMeasure.label,
				},
				team: selectedTeam,
			});
		};

		handleDatas();
	}, []);

	return (
		<FormEditKeyResultsContext.Provider
			value={{
				formData,
				handleFormData,
				selectedOptionsForm,
				handleSelectedOptionsForm,
				keyResultId,
				objectiveId,
			}}
		>
			{children}
		</FormEditKeyResultsContext.Provider>
	);
};

const useFormEditKeyResults = (): IFormEditKeyResultsContext => {
	const context = useContext(FormEditKeyResultsContext);

	if (!context) {
		throw new Error('useFormEditKeyResults must be used within a FormEditObjectivesProvider');
	}

	return context;
};

export { FormEditKeyResultsProvider, useFormEditKeyResults };
