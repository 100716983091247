import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';

import { ITask } from 'domains/Objectives/interfaces/ITaskFormData';
import { getVisionsTasks, putEditTask } from 'domains/Objectives/services/TasksServices';

import { Column } from './components/Column';
import { IColumn } from './IColumn';
import { ColumnsContainer, WarningContainer } from './styles';
import AstronautImg from '../../assets/astronaut-01.svg';

interface IDragAndDropProps {
	teamId?: string;
	managerId?: string;
	reloadTasks: () => void;
}

export const DragAndDrop = ({ teamId, managerId, reloadTasks }: IDragAndDropProps) => {
	const { pathname } = useLocation();

	const [todoTasks, setTodoTasks] = useState<ITask[]>([]);
	const [doingTasks, setDoingTasks] = useState<ITask[]>([]);
	const [doneTasks, setDoneTasks] = useState<ITask[]>([]);
	const [hasTasks, setHasTasks] = useState(false);

	const [columns, setColumns] = useState<IColumn>({
		todo: {
			id: 'todo',
			title: 'A fazer',
			tasks: todoTasks,
		},
		doing: {
			id: 'doing',
			title: 'Em Progresso',
			tasks: doingTasks,
		},
		done: {
			id: 'done',
			title: 'Concluído',
			tasks: doneTasks,
		},
	});

	const columnsOrder: Array<'todo' | 'doing' | 'done'> = ['todo', 'doing', 'done'];

	useEffect(() => {
		const handleTasks = async () => {
			const vision = `${pathname.split('/').pop()}`;
			const visionId = teamId || managerId;
			const data: ITask[] = await getVisionsTasks(vision, visionId);

			if (!data) return;

			const todoTasks = data.filter(task => task.status === 1);
			const doingTasks = data.filter(task => task.status === 2);
			const doneTasks = data.filter(task => task.status === 3);

			setTodoTasks(todoTasks);
			setDoingTasks(doingTasks);
			setDoneTasks(doneTasks);

			setColumns({
				todo: {
					id: 'todo',
					title: 'A fazer',
					tasks: todoTasks,
				},
				doing: {
					id: 'doing',
					title: 'Em Progresso',
					tasks: doingTasks,
				},
				done: {
					id: 'done',
					title: 'Concluído',
					tasks: doneTasks,
				},
			});
			setHasTasks(true);
		};

		handleTasks();
	}, [reloadTasks]);

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<DragDropContext
				onDragEnd={async result => {
					const { destination, source, draggableId } = result;

					if (!destination) {
						return;
					}

					if (destination.droppableId === source.droppableId && destination.index === source.index) {
						return;
					}

					const start = columns[source.droppableId];
					const finish = columns[destination.droppableId];
					const draggableItem = start.tasks.filter(task => task.id === draggableId);

					if (start === finish) {
						const newTasksArray = start.tasks;

						newTasksArray.splice(source.index, 1);
						newTasksArray.splice(destination.index, 0, draggableItem[0]);

						const newColumn = {
							...start,
							tasks: newTasksArray,
						};

						setColumns({
							...columns,
							[newColumn.id]: newColumn,
						});
						return;
					}

					const startTasksArray = start.tasks;
					startTasksArray.splice(source.index, 1);
					const newStartTasks = {
						...start,
						tasks: startTasksArray,
					};

					const finishTasksArray = finish.tasks;
					finishTasksArray.splice(destination.index, 0, draggableItem[0]);
					const newFinishTasks = {
						...finish,
						tasks: finishTasksArray,
					};

					setColumns({
						...columns,
						[newStartTasks.id]: newStartTasks,
						[newFinishTasks.id]: newFinishTasks,
					});

					await putEditTask(draggableItem[0], draggableItem[0].keyResult.id, draggableItem[0].id);
				}}
			>
				<ColumnsContainer className="columns-container-dnd">
					{columnsOrder.map(columnId => {
						const column = columns[columnId];

						return <Column columnId={columnId} column={column} key={columnId} reloadTasks={reloadTasks} />;
					})}
				</ColumnsContainer>
			</DragDropContext>
			{!hasTasks && (
				<WarningContainer>
					<img src={AstronautImg} alt="Astronauta" />
					<h2>Ops!</h2>
					<p>Você ainda não tem tarefas. Clique no botão “Nova Tarefa” para adicionar sua primeira tarefa.</p>
				</WarningContainer>
			)}
		</div>
	);
};
