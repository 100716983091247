import { KeyResultMonitoring } from '../KeyResultMonitoring';
import { KeyResultsEvolution } from '../KeyResultsEvolution';
import { OkrsConclusion } from '../OkrsConclusion';
import { OKRsProgress } from '../OKRsProgress';
import { PieCharts } from '../PieCharts';
import { Performance } from '../Performance';
import { Section } from './style';
import { Engagement } from '../Engagement';

export const ManagerPageView = () => (
	<Section>
		<OKRsProgress titles={['OKRs Criadas', 'Progresso das OKRs']} pageView="manager" />
		<PieCharts pageView="manager" />
		<KeyResultsEvolution pageView="manager" />
		<KeyResultMonitoring pageView="manager" />
		<OkrsConclusion />
		<Performance pageView="manager" />
		<Engagement />
	</Section>
);
