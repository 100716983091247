import { useState, useEffect, useContext } from 'react';
import { SelectChangeEvent } from '@mui/material';

import api from 'services/api';
import { useCycle } from 'hooks/useCycles';
import { AuthContext } from 'domains/Authentication/hooks/useAuth';

import { BreadcrumbBar } from 'components/BreadcrumbBar';
import { ContentContainer } from 'components/ContentContainer';
import { SearchSubordinatesComponent } from 'components/SearchSubordinates';

import { Main } from '../components/Main';
import { Nav } from '../components/Nav';
import { Search } from '../components/Search';
import { ManagerContent } from '../components/ManagerContent';
import { ObjectiveList } from '../components/ManagerContent/ObjectivesList';
import { DragAndDrop } from '../components/DragAndDrop';

import { IObjectiveData } from '../interfaces/IObjectiveData';
import { IMembersData } from '../interfaces/IMembersData';
import { FormNewTasks } from '../components/FormNewTasks';
import { FormNewObjectives } from '../components/FormNewObjectives';

export const ObjectivesManagerPage: React.FC = () => {
	const { cycle } = useCycle();
	const { user } = useContext(AuthContext);
	const [teamFilter, setTeamFilter] = useState('');
	const [selectedSubordinateId, setSelectedSubordinateId] = useState(user.subordinates[0]);
	const [professionalList, setProfessionalList] = useState<IMembersData[]>();
	const [objectiveList, setObjectiveList] = useState<IObjectiveData[]>();
	const [isSwitchActived, setIsSwitchActived] = useState(false);
	const [reloadTasks, setReloadTasks] = useState(false);

	const handleChangeFilterPerson = (event: SelectChangeEvent) => {
		setTeamFilter(event.target.value as string);
	};

	const handleSwitchActived = () => {
		setIsSwitchActived(!isSwitchActived);
	};

	const handleChangeSubordinate = (newId: string) => {
		setSelectedSubordinateId(newId);
	};

	useEffect(() => {
		if (cycle.id) {
			api.get(`cycles/${cycle.id}/manager/subordinates`).then(response => {
				setProfessionalList(response.data.data);
			});

			api.get(`cycles/${cycle.id}/objectives/managers/users/${selectedSubordinateId}`).then(response => {
				setObjectiveList(response.data.data);
			});
		}
	}, [cycle, selectedSubordinateId]);

	const switchProps = {
		leftTitle: 'OKRs',
		rightTitle: 'Tarefas',
		handleSwitchActived,
		switchState: isSwitchActived,
	};

	return (
		<>
			<ContentContainer>
				<Main>
					<Nav pageType="manager" />

					<div>
						<BreadcrumbBar
							title={isSwitchActived ? 'Tarefas Cadastradas' : 'Objetivos Cadastrados'}
							buttonContent={isSwitchActived ? 'Nova Tarefa' : 'Novo Objetivo'}
							hasButton
							modalTitle={isSwitchActived ? 'Nova Tarefa' : 'Novo Objetivo'}
							hasSwitch
							switchProps={switchProps}
						>
							{isSwitchActived && (
								<FormNewTasks
									isEditForm={false}
									isDetailsForm={false}
									reloadTasks={() => setReloadTasks(!reloadTasks)}
								/>
							)}
							{!isSwitchActived && <FormNewObjectives isEditForm={false} />}
						</BreadcrumbBar>

						{!isSwitchActived && (
							<>
								<Search onChange={handleChangeFilterPerson} />

								<ManagerContent>
									{professionalList && (
										<SearchSubordinatesComponent
											selectedSubordinateId={selectedSubordinateId}
											handleSelectedSubordinateId={handleChangeSubordinate}
										/>
									)}
									{objectiveList && (
										<ObjectiveList objectiveList={objectiveList} objectiveFilter={teamFilter} />
									)}
								</ManagerContent>
							</>
						)}
					</div>
					{isSwitchActived && (
						<>
							<div className="dnd-selectId-container">
								<SearchSubordinatesComponent
									selectedSubordinateId={selectedSubordinateId}
									handleSelectedSubordinateId={handleChangeSubordinate}
								/>
								<DragAndDrop
									reloadTasks={() => setReloadTasks(!reloadTasks)}
									managerId={selectedSubordinateId}
								/>
							</div>
						</>
					)}
				</Main>
			</ContentContainer>
		</>
	);
};
