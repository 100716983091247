import styled from 'styled-components';

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-flow: wrap;

	gap: 2rem;
	width: 100%;

	padding-top: 2.375rem;
	padding-left: 2rem;
	padding-right: 1rem;

	@media (max-width: 992px) {
		flex-direction: column;
		max-width: 480px;
		margin: 0 auto;
		padding-left: 1rem;
	}

	.header {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		@media (max-width: 425px) {
			.link {
				font-size: 1rem;
			}
		}
	}
`;

export const TopContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	height: 1rem;
	margin-bottom: 0.875rem;

	h1 {
		margin-bottom: 0.75rem;

		font: var(--body-xl-500);
		color: var(--gray-700);
	}

	@media (max-width: 425px) {
		flex-direction: column;
		height: auto;
		justify-content: center;
		align-items: center;
		padding-right: 1rem;

		.modal-btn {
			margin-left: 0px;
		}
	}
`;

export const RightContainer = styled.div`
	grid-column-start: 2;
	grid-row-start: 2;
	grid-row-end: 2;
`;

export const CardsContainer = styled.div`
	width: 100%;

	display: flex;
	flex-direction: row;

	gap: 1.5rem;
	padding-right: 1.6rem;
	padding-bottom: 1rem;
	padding-left: 1.6rem;

	@media (max-width: 425px) {
		padding: 0.5rem;
	}

	.card {
		min-width: 20rem;
		width: auto;
		max-width: 25rem;
		max-height: 100rem;
		position: relative;

		@media (max-width: 426px) {
			max-width: 22rem;
			min-width: 16.3rem;
			width: 100%;
		}
	}

	flex-flow: row wrap;

	.table-container {
		height: 550px;
		width: 100%;
		min-width: 16.5rem;

		table {
			min-width: 35rem;
			overflow-x: auto;
		}

		@media (max-width: 425px) {
			max-width: 300px;
		}
	}

	@media (max-width: 48rem) {
		justify-content: center;
	}
`;

export const SecondaryContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1.5rem;

	@media (max-width: 48rem) {
		flex-wrap: wrap;

		.basic-infos-container {
			width: 100%;
			min-height: 4rem;
			padding: 0rem 0.5rem;
			max-width: 100%;
		}

		max-width: 95%;
	}
`;

export const SecondaryResponsiveContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	@media (min-width: 993px) {
		display: none;
	}
`;

export const Divider = styled.span`
	display: block;
	min-width: 200px;
	width: 100%;
	height: 1px;

	background: var(--gray-200);

	margin-top: 0.959rem;
	margin-bottom: 1.219rem;
`;
