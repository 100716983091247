import { Input } from 'components/Input';
import { IFormNewCycle } from 'domains/Companies/Interfaces/ICompaniesCyclesDataRequest';
import { IFormNewModule } from 'domains/Companies/Interfaces/ICompaniesModulesDataRequest';
import { storeNewCycleRequest, storeNewModuleRequest } from 'domains/Companies/services/CompaniesServices';
import { useEffect, useState } from 'react';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { DateRange } from '@mui/lab/DateRangePicker';
import { Box } from '@mui/material';
import { Button } from 'components/Button';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { isDateBeforeFromAnotherDate, isDateBeforeFromToday } from 'domains/Objectives/utils/formatDates';
import { MobileDateRangePicker } from '@mui/lab';

interface FormNewCyclesOrModulesProps {
	isCycleForm: boolean;
	companyId: number;
}

export const FormNewCyclesOrModules = ({ isCycleForm, companyId }: FormNewCyclesOrModulesProps) => {
	const [formData, setFormData] = useState<IFormNewCycle | IFormNewModule>({
		description: '',
		currentYear: '',
		startTime: null,
		endTime: null,
		companyId,
	});
	const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);
	const [isInvalidInitialDate, setIsInvalidInitialDate] = useState(false);
	const [isInvalidEndDate, setIsInvalidEndDate] = useState(false);
	const [isLoadingRequest, setIsLoadingRequest] = useState(false);
	const [isInvalidCurrentYear, setIsInvalidCurrentYear] = useState(false);

	const handleSubmit = async (values: IFormNewCycle | IFormNewModule) => {
		setIsLoadingRequest(true);
		if (isCycleForm) {
			await storeNewCycleRequest(values);
			setIsLoadingRequest(false);
		} else {
			await storeNewModuleRequest(values);
			setIsLoadingRequest(false);
		}
	};

	useEffect(() => {
		const isFinalDateValid =
			`${dateRange[0]}` !== 'Invalid Date' && dateRange[0] !== null && !isDateBeforeFromToday(dateRange[0]);

		if (!isFinalDateValid && dateRange[0] !== null) {
			setIsInvalidInitialDate(true);
		} else setIsInvalidInitialDate(false);

		if (!isInvalidInitialDate && dateRange[0]) {
			const isFinalDateValid =
				`${dateRange[1]}` !== 'Invalid Date' &&
				dateRange[1] !== null &&
				isDateBeforeFromAnotherDate(dateRange[0], dateRange[1]) === false;

			if (!isFinalDateValid && dateRange[1] !== null) {
				setIsInvalidEndDate(true);
			} else setIsInvalidEndDate(false);
		}
	}, [dateRange]);

	useEffect(() => {
		if (formData.currentYear)
			setIsInvalidCurrentYear(Number(formData.currentYear) < Number(new Date().getFullYear() + 1));
	}, [formData.currentYear]);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit(formData);
			}}
			style={{ marginTop: '1rem' }}
		>
			<Input
				name="description"
				placeholder={`Novo ${isCycleForm ? 'Ciclo' : 'Módulo'}`}
				label={`Novo ${isCycleForm ? 'Ciclo' : 'Módulo'}`}
				value={formData.description}
				onChange={event => {
					setFormData({ ...formData, description: event.target.value });
				}}
				required
			/>
			<LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBrLocale}>
				<DatePicker
					views={['year']}
					label="Ano Vigente"
					value={formData.currentYear}
					minDate={new Date()}
					onChange={value => {
						if (value) {
							const currentYear = new Date(value).getFullYear();
							setFormData({ ...formData, currentYear: `${currentYear + 1}` });
						}
					}}
					renderInput={params => (
						<TextField
							{...params}
							variant="standard"
							placeholder="Ano Vigente"
							style={{ width: '100%', marginTop: '2rem' }}
							error={isInvalidCurrentYear}
							helperText={isInvalidCurrentYear ? 'Ano inválido' : ''}
							required
						/>
					)}
				/>
				<MobileDateRangePicker
					startText="Data de Início"
					value={dateRange}
					minDate={new Date()}
					onChange={newValue => {
						setDateRange(newValue);
						setFormData({ ...formData, startTime: newValue[0], endTime: newValue[1] });
					}}
					orientation="landscape"
					renderInput={(startProps, endProps) => (
						<>
							<div className="dates-inputs">
								<TextField
									{...startProps}
									variant="standard"
									style={{ marginTop: '2rem' }}
									required
									error={isInvalidInitialDate}
									helperText={isInvalidInitialDate ? 'Data Inválida' : ''}
								/>
								<Box sx={{ mx: 2 }}> </Box>
								<TextField
									{...endProps}
									variant="standard"
									label="Data de Término"
									required
									error={isInvalidEndDate}
									helperText={isInvalidEndDate ? 'Data Inválida' : ''}
								/>
							</div>
						</>
					)}
				/>
			</LocalizationProvider>

			<Button
				type="submit"
				bgColor="primary"
				className="submit-btn"
				disabled={isLoadingRequest || isInvalidCurrentYear || isInvalidInitialDate || isInvalidEndDate}
				isLoading={isLoadingRequest}
			>
				Criar
			</Button>
		</form>
	);
};
