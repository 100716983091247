import styled from 'styled-components';

export const Background = styled.header`
	grid-area: header;

	background-color: var(--primary-400);
`;

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	height: 5rem;
	width: 100%;

	margin: 0 auto;
	padding: 0 1.5rem 0 1rem;

	img {
		height: 3rem;
	}

	& .cicle {
		display: flex;
		column-gap: 6rem;
	}

	@media (max-width: 48rem) {
		justify-content: flex-start;
		gap: 1rem;
	}
`;

export const CompanyContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	column-gap: 0.5rem !important;

	@media (max-width: 48rem) {
		display: none !important;
	}
`;

export const CompanyName = styled.p`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 135%;
	color: var(--white);
`;

export const Cicle = styled.div`
	display: flex;
	align-items: center;
	column-gap: 0.5rem;

	p {
		font: var(--body-md-500);
		color: var(--white);

		span {
			font: var(--body-md-400);
		}
	}

	@media (max-width: 48rem) {
		display: none;
	}
`;

export const Development = styled.div`
	p {
		font: var(--body-xs-400);
		text-align: center;
		color: var(--white);
	}

	img {
		height: 2rem;
	}

	@media (max-width: 48rem) {
		& p {
			display: none;
		}
	}
`;
