import { useState } from 'react';
import { InfoModal } from 'domains/Objectives/components/InfoModal';
import { Divider } from 'styles/globalStyledComponents';
import { IObjectiveData } from 'domains/Objectives/interfaces/IObjectiveData';
import { Container, Progress, ShowDetailsButton, TopContainer } from './styles';

interface IOKRCardProps {
	objective: IObjectiveData;
}

export const OKRCard = ({ objective }: IOKRCardProps) => {
	const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);

	const modalItems = [
		{
			hasModal: true,
			title: 'Adicionar Key Result',
			name: 'Adicionar Key Result',
			icon: 'FiPlus',
		},
		{
			hasModal: true,
			title: 'Editar Objetivo',
			name: 'Editar Objetivo',
			icon: 'FiEdit',
		},
		{
			hasModal: false,
			title: 'Duplicar Objetivo',
			name: 'Duplicar Objetivo',
			icon: 'HiDuplicate',
		},
		{
			hasModal: false,
			title: 'Excluir Objetivo',
			name: 'Excluir Objetivo',
			icon: 'FiTrash2',
		},
	];

	return (
		<Container>
			<TopContainer>
				<p>{objective.description}</p>
				<InfoModal
					modalItems={modalItems}
					objective={objective}
					isOpenDetailsModal={isOpenDetailsModal}
					handleIsOpenDetailsModal={() => setIsOpenDetailsModal(false)}
				/>
			</TopContainer>
			<Progress progress={objective.progress}>
				<div>
					<span />
				</div>
				{`${objective.progress}%`}
			</Progress>
			<Divider marginTop={'0'} marginBottom={'0'} className="divider" />
			<ShowDetailsButton onClick={() => setIsOpenDetailsModal(!isOpenDetailsModal)}>
				Ver detalhes do Objetivo
			</ShowDetailsButton>
		</Container>
	);
};
