import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { Input } from 'components/Input';

import { storeUpdatePersonDataRequest } from '../../services/SettingsServices';

import { IProfileProps } from '../../interfaces/IProfileData';
import { IFormUpdatePersonData } from '../../interfaces/IFormUpdatePersonData';

import { Container, ContainerInput, EditableInput, UneditableInput } from './style';

export const Profile: React.FC<IProfileProps> = ({ profileProps, companyName, managerName }) => {
	const [formData] = useState<IFormUpdatePersonData>({
		id: '',
		name: profileProps.person.name,
		cpf: '',
		email: profileProps.email,
		telephone: profileProps.phone,
		profession: '',
		register: '',
		profiles: [
			{
				id: 0,
				description: '',
				startTime: '',
				endTime: '',
			},
		],
		sectorId: 0,
		companyId: 0,
		leaderId: 0,
		areaId: 0,
		ledIds: [0],
	});

	const validationSchema = Yup.object().shape({
		telephone: Yup.string().min(11, 'Insira um telefone válido').required(),
		email: Yup.string().email('Insira um e-mail válido').required(),
	});

	const handleSubmit = async (values: IFormUpdatePersonData) => {
		await storeUpdatePersonDataRequest(values);
	};

	return (
		<Container>
			<h2>Configurações da Conta</h2>

			<Card title="Informações Básicas" className="card">
				<Formik
					initialValues={formData}
					validationSchema={validationSchema}
					onSubmit={async (values: IFormUpdatePersonData) => {
						handleSubmit(values);
					}}
				>
					{props => (
						<form
							onSubmit={e => {
								e.preventDefault();
								props.handleSubmit(e);
							}}
						>
							<img src={profileProps?.image} />

							<ContainerInput>
								<EditableInput>
									<Input
										name="name"
										label="Nome"
										placeholder=""
										required
										value={props.values.name}
										onChange={props.handleChange}
									/>
									<Input
										name="email"
										label="Email"
										type="email"
										placeholder=""
										required
										value={props.values.email}
										onChange={props.handleChange}
									/>
									<Input
										name="telephone"
										label="Telefone"
										mask="telephone"
										placeholder=""
										required
										value={props.values.telephone}
										onChange={props.handleChange}
										error={props.touched.telephone && Boolean(props.errors.telephone)}
										helperText={
											props.touched.telephone && props.errors.telephone
												? props.errors.telephone
												: undefined
										}
									/>
								</EditableInput>

								<UneditableInput>
									<Input name="empresa" label="Empresa" placeholder="" value={companyName} disabled />

									<Input name="manager" label="Líder" placeholder="" value={managerName} disabled />

									<Input name="cargo" label="Cargo" placeholder="" value={'Desenvolvedor'} disabled />
								</UneditableInput>
							</ContainerInput>

							<Button type="submit" bgColor="primary" disabled={!props.dirty}>
								Editar Informações
							</Button>
						</form>
					)}
				</Formik>
			</Card>
		</Container>
	);
};
