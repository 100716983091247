import { useContext, useEffect, useState } from 'react';
import { useCycle } from 'hooks/useCycles';
import { AuthContext } from 'domains/Authentication/hooks/useAuth';

import api from 'services/api';
import { getCompanieByIdRequest, getUsersRequest } from 'domains/Companies/services/CompaniesServices';
import { getVisionsTasks } from 'domains/Objectives/services/TasksServices';

import { ITask } from 'domains/Objectives/interfaces/ITaskFormData';
import { ITeamData } from 'domains/Teams/interfaces/ITeamData';
import { IAreaData } from 'domains/Persons/interfaces/IAreaData';
import { ILeaderData } from 'domains/Persons/interfaces/ILeaderData';
import { IUser } from 'domains/Authentication/interfaces/IUser';

import { Modal } from 'components/Modal';
import { Navbar } from 'components/Navbar';
import { SearchSubordinatesComponent } from 'components/SearchSubordinates';
import { SearchTeamsComponent } from 'components/SearchTeams';
import { FormNewObjectives } from 'domains/Objectives/components/FormNewObjectives';
import { CardOKRs } from '../components/CardOKRs';
import { ResultsInfos } from '../components/ResultsInfos';
import { TasksCard } from '../components/TasksCard';
import { IEntityUserOrManagerInfo, IManager, IResults, ITeamInfos } from '../Interfaces/IRequestsDatas';
import { getCompanyInfos, getManagerInfos, getTeamsInfos, getUserInfos } from '../services/homeServices';
import {
	CardComponent,
	CardTitle,
	CircleTeamProfile,
	Container,
	PageHeader,
	PageTitle,
	Pageview,
	ProfileContainer,
	ColumnContainer,
} from './style';
import { CompanyModaisButtons } from '../components/CompanyModaisButtons';

export const HomePage: React.FC = () => {
	const { cycle } = useCycle();
	const { user } = useContext(AuthContext);

	const [pageView, setPageView] = useState('user');
	const [pageTitle, setPageTitle] = useState('Sua Home Advy');

	const [manager, setManager] = useState<IManager>();
	const [selectedSubordinateId, setSelectedSubordinateId] = useState(user.id);

	const [teamList, setTeamList] = useState<ITeamData[]>();
	const [selectedTeamId, setSelectedTeamId] = useState('');

	const [listAreas, setListAreas] = useState<IAreaData[]>([]);
	const [leadersList, setLeadersList] = useState<ILeaderData[]>([]);
	const [colaboratorsList, setColaboratorsList] = useState<IUser[]>([]);

	const [tasksList, setTasksList] = useState<ITask[]>([]);
	const [okrsResults, setOKRsResults] = useState<IResults>({
		okrs: 0,
		orksCompleted: 0,
		okrsIndividual: 0,
		okrsIndividualCompleted: 0,
	});

	const handlePageView = (newPageView: string) => {
		setPageView(newPageView);
	};

	const handleTeamsList = async () => {
		if (cycle.id)
			await api.get(`cycles/${cycle.id}/teams`).then(response => {
				setTeamList(response.data.data);

				if (teamList) setSelectedTeamId(`${teamList[0].id}`);
			});
	};

	const handleCompanyViewRequestsData = async () => {
		await api.get('areas').then(response => {
			setListAreas(response.data.data);
		});

		api.get('users/managers').then(response => {
			setLeadersList(response.data.data);
		});

		const data = await getUsersRequest();
		setColaboratorsList(data);
	};

	const handleTeamSelection = (teamId: string) => {
		setSelectedTeamId(teamId);
	};

	const handleSubordinateSelection = (id: string) => {
		setSelectedSubordinateId(id);
	};

	const handleRequestsDatas = async () => {
		if (pageView !== 'teams' && pageView !== 'company') {
			const dataTasks: ITask[] = await getVisionsTasks(
				`${pageView === 'user' ? 'user' : 'manager'}`,
				pageView === 'manager' ? selectedSubordinateId : undefined,
			);

			setTasksList(dataTasks);
		}

		if (pageView === 'user') {
			setPageTitle('Sua Home Advy');
			const dataInfos: IEntityUserOrManagerInfo = await getUserInfos();

			setOKRsResults(dataInfos.results);
			setManager(dataInfos.manager);
		}
		if (pageView === 'manager') {
			setPageTitle('Home Advy Colaboradores');
			const dataInfos: IEntityUserOrManagerInfo = await getManagerInfos(selectedSubordinateId);

			setOKRsResults(dataInfos.results);
			setManager(dataInfos.manager);
		}
		if (pageView === 'teams' && selectedTeamId) {
			setPageTitle('Home Advy Times');
			const dataInfos: ITeamInfos = await getTeamsInfos(selectedTeamId);

			setOKRsResults(dataInfos.results);
			setManager(dataInfos.manager);
		}
		if (pageView === 'company') {
			handleCompanyViewRequestsData();
			const dataInfos: IResults = await getCompanyInfos();
			setOKRsResults(dataInfos);

			const companyData = await getCompanieByIdRequest(user.companyId);
			setPageTitle(`Home Advy ${companyData.socialReason || '...'}`);
		}
	};

	useEffect(() => {
		handleTeamsList();
		handleRequestsDatas();
	}, [cycle, pageView]);

	useEffect(() => {
		handleRequestsDatas();
	}, [selectedTeamId, selectedSubordinateId]);

	return (
		<Container>
			<Navbar pageView={pageView} handlePageView={handlePageView}></Navbar>
			<PageHeader>
				<PageTitle>{pageTitle}</PageTitle>
				<Modal title={'Novo Objetivo'} buttonContent={'Novo Objetivo'} hasButton>
					<FormNewObjectives isEditForm={false} pageView={pageView} />
				</Modal>
			</PageHeader>
			<Pageview>
				{pageView === 'teams' && (
					<ColumnContainer>
						<SearchTeamsComponent
							selectedTeamId={selectedTeamId}
							teamsList={teamList || []}
							handleSelectedTeamId={handleTeamSelection}
						/>
					</ColumnContainer>
				)}

				<ColumnContainer style={{ display: `${pageView === 'manager' ? 'flex' : 'none'}` }}>
					<SearchSubordinatesComponent
						selectedSubordinateId={selectedSubordinateId}
						handleSelectedSubordinateId={handleSubordinateSelection}
					/>
				</ColumnContainer>
				<ColumnContainer>
					{pageView !== 'company' && manager && (
						<CardComponent>
							<CardTitle>Líder</CardTitle>
							<ProfileContainer>
								<img src={manager.image} alt="Foto do Líder" />
								<div>
									<p>{manager.person.name}</p>
									<p className="email">{manager.email}</p>
								</div>
							</ProfileContainer>
						</CardComponent>
					)}

					{pageView !== 'teams' && pageView !== 'company' && (
						<CardComponent>
							<CardTitle>Times</CardTitle>
							{teamList?.map(team => (
								<ProfileContainer key={team.id}>
									<CircleTeamProfile>
										{team.description.split(' ').map(index => (
											<span key={index}>{index.slice(0, 1)}</span>
										))}
									</CircleTeamProfile>
									<p>{team.description}</p>
								</ProfileContainer>
							))}
						</CardComponent>
					)}

					{okrsResults && <ResultsInfos {...okrsResults} isPersonalResults={pageView === 'user'} />}
				</ColumnContainer>
				<ColumnContainer
					isDoubleColumns={pageView === 'user'}
					className={pageView === 'company' ? '' : 'okrs-column'}
				>
					{pageView === 'company' && (
						<CompanyModaisButtons
							contributors={116}
							teams={10}
							contributorsWithOkrs={72}
							teamsWithOkrs={75}
							leadersList={leadersList}
							listAreas={listAreas}
							colaboratorsList={colaboratorsList}
						/>
					)}
					<CardOKRs pageView={pageView} subordinateId={selectedSubordinateId} teamId={selectedTeamId} />
					{(pageView === 'user' || pageView === 'manager') && <TasksCard tasks={tasksList} />}
				</ColumnContainer>
			</Pageview>
		</Container>
	);
};
