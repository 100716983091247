import api from 'services/api';
import { ITask, ITaskFormData } from '../interfaces/ITaskFormData';

const cycle = JSON.parse(localStorage.getItem('@Advy:cycle')!);

export const storeNewTask = async (formData: ITaskFormData, id: string) => {
	const data = await api
		.post(`/key-results/${id}/tasks`, formData)
		.then(response => response.data)
		.catch(error => error.message);

	return data;
};

export const putEditTask = async (formData: ITask, keyResultId: string, taskId: string) => {
	const data = await api
		.put(`/key-results/${keyResultId}/tasks/${taskId}`, formData)
		.then(response => response.data)
		.catch(error => error.message);

	return data;
};

export const getObjectivesTasks = async (id: string) => {
	const data = await api
		.get(`/key-results/${id}/tasks`)
		.then(response => response.data)
		.catch(error => error.message);

	return data.data;
};

export const showDetailsTask = async (keyResultId: string, taskId: string) => {
	const data = await api
		.get(`/key-results/${keyResultId}/tasks/${taskId}`)
		.then(response => response.data)
		.catch(error => error.message);

	return data.data;
};

export const deleteTask = async (keyResultId: string, taskId: string) => {
	const data = await api
		.delete(`/key-results/${keyResultId}/tasks/${taskId}`)
		.then(response => response.data)
		.catch(error => error.message);

	return data.data;
};

export const getAllTaskComments = async (taskId: string) => {
	const data = await api
		.get(`/tasks/${taskId}/comments`)
		.then(response => response.data)
		.catch(error => error.message);

	return data.data;
};

export const storeNewTaskComment = async (comment: string, id: string) => {
	const data = await api
		.post(`/tasks/${id}/comments`, comment)
		.then(response => response.data)
		.catch(error => error.message);

	return data;
};

export const getVisionsTasks = async (vision: string, id?: string) => {
	const principalRoute = `cycles/${cycle.id}/tasks/${vision}`;

	const data = await api
		.get(`${principalRoute}${id ? `/${id}` : ''}`)
		.then(response => response.data)
		.catch(error => error.message);

	// Medida utilizada pois todas tasks possuem o mesmo id, o que acaba por quebrar o drangAndDrop
	if (data.data) data.data[0].id = '12345';

	return data.data;
};
