import styled from 'styled-components';

export const Container = styled.fieldset`
	position: relative;

	display: flex;

	width: 100%;

	border: none;

	.MuiFormControl-root {
		width: 100%;
	}

	& svg {
		color: var(--gray-500);
	}
`;

export const EyeButton = styled.button``;
