import styled, { css } from 'styled-components';
import { ItemTitle } from 'styles/globalStyledComponents';

interface TeamButtonProps {
	selected: boolean;
}

export const Container = styled.div`
	.stepper-component {
		margin-top: 2rem;
		margin-bottom: 1.938rem;

		@media (max-width: 375px) {
			display: none;
		}

		.Mui-active circle {
			color: var(--secondary-400);
		}

		.Mui-completed {
			color: var(--secondary-400);
		}

		text {
			fill: rgba(255, 255, 255, 255);
		}
	}

	textarea {
		padding: 10px;

		&:focus {
			border: 1.5px solid var(--primary-400);
		}
	}

	.submit-btn {
		margin-top: 3rem;
		width: 208px;
		margin-left: auto;
	}

	.button-group {
		gap: 1.5rem;
		margin-top: 1rem;
		margin-bottom: 1.5rem;
		flex-wrap: wrap;

		@media (max-width: 768px) {
			justify-content: center;
		}

		.Mui-selected {
			border: 1px solid var(--secondary-400) !important;
		}

		button {
			width: 7.625rem;
			height: 6rem;
			border: none;
			box-shadow: var(--elevation-200);
			border-radius: 4px !important;
		}

		div {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 0.5rem;
			text-transform: lowercase;

			p::first-line {
				text-transform: capitalize;
			}

			svg {
				height: 2rem;
				width: 2rem;
			}
		}
	}

	.self-assign-btn {
		border: none;
		background-color: transparent;
		margin-top: 1rem;
		color: var(--primary-400);
		font-size: 14px;
		margin-bottom: 2rem;
	}

	.container-contributors {
		display: flex;
		width: 100%;
		gap: 2rem;
		margin-top: 0.5rem;

		@media (max-width: 768px) {
			flex-wrap: wrap;
		}

		.option-selector {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			align-items: flex-start;
			padding: 16px;
			width: 20rem;
			height: 211px;
			box-shadow: var(--elevation-200);
			border-radius: 4px;
			margin-bottom: 2rem;

			.option-selector-title {
				color: var(--secondary-400);
				font-family: Fira Sans;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
			}

			.professional-container {
				display: flex;
				align-items: center;
				justify-content: space-around;
				margin-top: -15px;
				color: var(--gray-500);

				img {
					height: 1.5rem;
					width: 1.5rem;
					border-radius: 50%;
					box-shadow: var(--elevation-200);
					margin-right: 8px;
				}

				h2 {
					color: var(--primary-400);
					font-family: Fira Sans;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
				}
			}
		}
	}

	.rotate-270 {
		transform: rotate(270deg);
	}

	.rotate-180 {
		transform: rotate(180deg);
	}

	.rotate-90 {
		transform: rotate(90deg);
	}
`;

export const FormItem = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;

	width: 100%;
	margin-bottom: 1.5rem;

	${ItemTitle} {
		margin-bottom: 5px;
	}

	textarea {
		margin-top: 1rem;
	}

	.weight-input {
		margin-top: 2rem;
		margin-bottom: 1rem;
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;

	button {
		width: 13rem;
	}

	.back-btn {
		background-color: transparent;
		border: 1px solid var(--primary-400);
		box-sizing: border-box;
		color: var(--primary-400);
		margin-right: 3rem;
		border-radius: 4px;
		height: 3rem;
		box-shadow: var(--elevation-200);
	}

	.continue-btn {
		background-color: var(--primary-400);
		color: white;
		border: none;
		border-radius: 4px;
		height: 3rem;
		box-shadow: var(--elevation-200);
	}

	button:disabled,
	button[disabled] {
		background: #ccc;
	}
`;

export const TeamButton = styled.button<TeamButtonProps>`
	border: none;
	background: none;
	padding: 0px 8px;
	width: 100%;
	height: 28px;
	border-radius: 4px;

	color: var(--gray-500);
	font-family: Fira Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;

	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: -5px;

	${props =>
		props.selected &&
		css`
			& {
				background-color: var(--primary-400);
				color: white;
			}
		`}
`;
