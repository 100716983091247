import { Head } from 'components/Card/style';
import styled from 'styled-components';
import { Progress } from '../KeyResultMonitoring/style';

export const Container = styled.div`
	p {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.card {
		width: 100%;
		max-height: 550px;

		h3 {
			font-family: 'Fira Sans';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 135%;

			color: var(--gray-700);
		}
	}
`;

export const TableContainer = styled.div`
	min-height: 200px;
	max-height: 500px;
	width: 100%;

	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: var(--gray-200);
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--gray-400);
		border-radius: 4px;
	}

	.table-container {
		margin-top: 0;
	}

	table {
		width: 100%;

		tbody {
			width: 100%;

			.table-header {
				th:nth-child(1) {
					text-align: left;
					width: 350px;
				}

				th:nth-child(2) {
					text-align: left;

					width: 200px;
					margin-right: 1rem;
				}

				th:nth-child(3) {
					text-align: left;

					width: 170px;
				}

				th:nth-child(4) {
					text-align: left;

					width: 150px;
				}

				th:nth-child(5) {
					text-align: left;

					width: 150px;
				}

				th:nth-child(6) {
					text-align: left;

					width: 150px;
				}

				th {
					padding: 0.5rem;
					text-align: center;

					font-family: 'Fira Sans';
					font-style: normal;
					font-weight: 400;
					font-size: 12px;
					line-height: 140%;
					color: var(--gray-700);
					border-bottom: 1px solid var(--gray-200);
				}
			}
		}
	}

	.table-card {
		max-width: 99%;
		margin-top: 0.5rem;
		padding-top: 0;
		min-width: 1000px;

		${Head} {
			border: 1px solid var(--gray-400);
			border-radius: 4px;
			padding: 0.5rem 1rem;
			width: 100%;
		}
	}
`;

export const CardHeaderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1rem;
	width: 100%;

	img {
		height: 1.5rem;
		width: 1.5rem;
		border-radius: 50%;
		margin-right: 0.5rem;

		filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.24));
	}

	p {
		padding: 0;
		font-family: 'Fira Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 140%;
		color: var(--gray-700);
		border-bottom: none !important;
		text-align: center;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	${Progress} {
		width: 6rem;
		gap: 0.5rem;
		min-width: 8rem;
	}

	.table-header-2 {
		th:nth-child(1) {
			width: 270px;

			p {
				max-width: 190px;
			}
		}

		th:nth-child(2) {
			text-align: center;
			p {
				text-align: center;
				max-width: 170px;
			}
		}

		th:nth-child(3) {
			text-align: left;

			width: 170px;
		}

		th:nth-child(4) {
			width: 200px;
		}

		th {
			min-width: 120px;
		}
	}
`;
