import React, { useState } from 'react';
import { Formik } from 'formik';
import { Input } from 'components/Input';

import { InfoTeamsProps, ITeamData } from '../../../interfaces/ITeamData';

import { Container, MembersList } from './style';

export const InfoTeams: React.FC<InfoTeamsProps> = ({ team }) => {
	const [selectedTeam] = useState<ITeamData>(() => ({
		id: team.id,
		description: team.description,
		leader: {
			name: team.leader.name,
			id: team.leader.id,
			image: team.leader.image,
		},
		members: team.members.map(member => ({
			name: member.name,
			id: member.id,
			image: member.image,
		})),
	}));

	const [formData] = useState<ITeamData>({
		id: selectedTeam.id,
		description: selectedTeam.description,
		leader: {
			id: selectedTeam.leader.id,
			name: selectedTeam.leader.name,
			image: selectedTeam.leader.image,
		},
		members: selectedTeam.members.map(member => ({
			name: member.name,
			id: member.id,
			image: member.image,
		})),
	});

	return (
		<Container>
			<Formik
				initialValues={formData}
				onSubmit={() => {
					console.log('Sucesso');
				}}
			>
				{props => (
					<form
						onSubmit={e => {
							e.preventDefault();
							props.handleSubmit(e);
						}}
					>
						<Input
							name="description"
							placeholder=""
							label="Nome do Time"
							value={props.values.description}
						/>

						<Input name="leader" placeholder="" label="Gestor" value={props.values.leader.name} />

						<MembersList>
							<p>Profissionais</p>

							<ul>
								{props.values.members.map(member => (
									<li key={member.id}>
										<img src={member.image} alt={member.name} />
										<p>{member.name}</p>
									</li>
								))}
							</ul>
						</MembersList>
					</form>
				)}
			</Formik>
		</Container>
	);
};
