import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { IRecoveryPasswordFormData } from 'domains/Authentication/interfaces/IRecoveryPasswordFormData';
import { AuthContext } from 'domains/Authentication/hooks/useAuth';

import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { Subtitle } from '../Subtitle';
import { Title } from '../Title';

export const FormRecoveryPassword: React.FC = () => {
	const [email] = useState('');
	const { recoveryPassword, hasError } = useContext(AuthContext);

	const validationSchema = Yup.object().shape({
		email: Yup.string().email('Insira um e-mail válido').required('Insira um e-mail'),
	});

	const handleSubmit = async (data: IRecoveryPasswordFormData) => {
		await recoveryPassword({ email: data.email });
	};

	return (
		<Formik
			initialValues={{ email }}
			validationSchema={validationSchema}
			onSubmit={async (values: { email: string }) => handleSubmit(values)}
		>
			{({ handleSubmit, handleChange, values, touched, errors }) => (
				<form
					onSubmit={e => {
						e.preventDefault();
						handleSubmit(e);
					}}
				>
					<Title>Esqueci minha senha</Title>

					<Subtitle>Digite seu email cadastrado para receber uma nova senha</Subtitle>

					<Input
						name="email"
						label="E-mail cadastrado"
						placeholder="E-mail cadastrado"
						value={values.email}
						onChange={handleChange}
						error={(touched.email && Boolean(errors.email)) || hasError === 'on'}
						helperText={touched.email && errors.email ? errors.email : undefined}
					/>

					<Button type="submit" bgColor="primary">
						Enviar
					</Button>
				</form>
			)}
		</Formik>
	);
};
