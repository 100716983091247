interface IRemoveItemParams<T> {
	array: Array<T>;
	value: T;
}

export const removeItem = <T>({ array, value }: IRemoveItemParams<T>) => {
	const arr = array.filter(item => item !== value);

	return arr;
};
