import React, { useContext, useEffect, useState } from 'react';

import { useCycle } from 'hooks/useCycles';
import { AuthContext } from 'domains/Authentication/hooks/useAuth';
import { getCompanieByIdRequest } from 'domains/Companies/services/CompaniesServices';

import { FiGrid } from 'react-icons/fi';
import { AiFillCaretDown } from 'react-icons/ai';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { MenuMobile } from './MenuMobile';
import logoAdvy from './assets/advy-small-white.svg';
import { Background, Container, Cicle, Development, CompanyName, CompanyContainer } from './style';

const ITEM_HEIGHT = 48;

export const Header: React.FC = () => {
	const { user } = useContext(AuthContext);
	const { cycle, cyclesOptions, handleCycle } = useCycle();

	const [companyHeaderData, setCompanyHeaderData] = useState({
		socialReason: '',
		image: '',
	});
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClickCyclesMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseCyclesMenu = () => {
		setAnchorEl(null);
	};

	const getCompanyData = async () => {
		const data = await getCompanieByIdRequest(user.companyId);

		setCompanyHeaderData({
			socialReason: data.socialReason,
			image: data.image,
		});
	};

	useEffect(() => {
		getCompanyData();
	}, []);

	return (
		<Background>
			<Container>
				<MenuMobile />

				<CompanyContainer>
					<img src={companyHeaderData.image} alt="Company Logo" />
					<CompanyName>{companyHeaderData.socialReason}</CompanyName>
				</CompanyContainer>

				<div className="cicle">
					<Cicle>
						<FiGrid color="white" size="24" />

						<p>
							<span>Ciclo:</span> {cycle.name}
						</p>

						<IconButton
							aria-label="Ciclos"
							aria-controls={open ? 'cycles-menu' : undefined}
							aria-expanded={open ? 'true' : undefined}
							aria-haspopup="true"
							onClick={handleClickCyclesMenu}
							style={{ width: '30px', height: '30px' }}
						>
							<AiFillCaretDown style={{ color: 'white' }} />
						</IconButton>

						<Menu
							id="cycles-menu"
							MenuListProps={{
								'aria-labelledby': 'long-button',
							}}
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={open}
							onClose={handleCloseCyclesMenu}
							PaperProps={{
								style: {
									maxHeight: ITEM_HEIGHT * 4.5,
									width: '20ch',
								},
							}}
						>
							{cyclesOptions.map(option => (
								<MenuItem
									key={option.id}
									selected={option.id === cycle.id}
									onClick={() => handleCycle(option)}
								>
									{option.name}
								</MenuItem>
							))}
						</Menu>
					</Cicle>

					<Development>
						<p>Uma solução</p>

						<img src={logoAdvy} alt="Advy Logo" />
					</Development>
				</div>
			</Container>
		</Background>
	);
};
