import { useState } from 'react';
import { FormNewObjectivesProvider } from 'domains/Objectives/contexts/FormNewObjectives';

import { StepperComponent } from 'components/Stepper';
import { DetailsForm } from './components/DetailsForm';
import { FundamentationForm } from './components/FundamentationForm';
import { GeralForm } from './components/GeralForm';
import { Revision } from './components/Revision';
import { Container } from './style';

interface IFormProps {
	isEditForm: boolean;
	objectiveId?: string;
	pageView?: string;
}

const steps = ['Geral', 'Fundamentação', 'Mais detalhes', 'Revisão'];

export const FormNewObjectives = ({ isEditForm, objectiveId, pageView }: IFormProps) => {
	const [activeStep, setActiveStep] = useState(0);
	const [skipped, setSkipped] = useState(new Set<number>());

	const isStepSkipped = (step: number) => skipped.has(step);

	const handleNextStep = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep(prevActiveStep => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBackStep = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	};

	return (
		<FormNewObjectivesProvider>
			<Container>
				<StepperComponent activeStep={activeStep} steps={steps} />

				{activeStep === 0 && (
					<GeralForm handleNextStep={handleNextStep} isEditForm={isEditForm} pageView={pageView} />
				)}
				{activeStep === 1 && (
					<FundamentationForm
						handleNextStep={handleNextStep}
						handleBackStep={handleBackStep}
						isEditForm={isEditForm}
					/>
				)}
				{activeStep === 2 && (
					<DetailsForm
						handleNextStep={handleNextStep}
						handleBackStep={handleBackStep}
						isEditForm={isEditForm}
					/>
				)}
				{activeStep === 3 && (
					<Revision handleBackStep={handleBackStep} isEditForm={isEditForm} objectiveId={objectiveId} />
				)}
			</Container>
		</FormNewObjectivesProvider>
	);
};
