import { useEffect, useState } from 'react';
import { useCycle } from 'hooks/useCycles';

import { IObjectiveData } from 'domains/Objectives/interfaces/IObjectiveData';
import {
	getCompanyObjectives,
	getManagerObjectivesList,
	getObjectivesList,
	getTeamsObjectivesById,
} from 'domains/Objectives/services/ObjectivesServices';

import { Card } from 'components/Card';
import { OKRCard } from './components/OKRCard';
import { OKRsContainer } from './style';

interface ICardOKRsProps {
	pageView: string;
	subordinateId: string;
	teamId: string;
}

export const CardOKRs = ({ pageView, subordinateId, teamId }: ICardOKRsProps) => {
	const { cycle } = useCycle();

	const [objectivesList, setObjectivesList] = useState<IObjectiveData[]>([]);

	const handleRequestsDatas = async () => {
		if (pageView === 'user') {
			const data = await getObjectivesList();
			setObjectivesList(data);
		}
		if (pageView === 'manager') {
			const data = await getManagerObjectivesList(subordinateId);
			setObjectivesList(data);
		}
		if (pageView === 'teams') {
			const data = await getTeamsObjectivesById(teamId);

			setObjectivesList(data.length ? data : [data]);
		}
		if (pageView === 'company') {
			const data = await getCompanyObjectives();
			// Endpoint está retornando um objeto e não um array de objetivos
			setObjectivesList([data.data]);
		}
	};

	useEffect(() => {
		handleRequestsDatas();
	}, [cycle, pageView, subordinateId, teamId]);

	return (
		<>
			<Card title="OKRs" isCollapsed defaultValue className="card-okrs">
				<OKRsContainer>
					{objectivesList.map(objective => (
						<OKRCard objective={objective} key={objective.id} />
					))}
				</OKRsContainer>
			</Card>
		</>
	);
};
