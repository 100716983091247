import styled, { css } from 'styled-components';

interface ContainerProps {
	pageType: string;
}

export const Container = styled.nav<ContainerProps>`
	display: flex;
	align-items: center;
	gap: 4rem;

	width: 100%;
	height: 4.5rem;

	padding: 0 3rem;

	box-shadow: var(--elevation-400);

	a {
		padding: 1.5rem 0;

		font: var(--body-lg-400);
		color: var(--gray-400);
	}

	${props =>
		props.pageType === 'user' &&
		css`
			a:first-child {
				font: var(--body-lg-500);
				color: var(--terciary-400);

				border-bottom: 4px solid var(--terciary-400);
			}
		`}

	${props =>
		props.pageType === 'manager' &&
		css`
			a:nth-child(2) {
				font: var(--body-lg-500);
				color: var(--terciary-400);

				border-bottom: 4px solid var(--terciary-400);
			}
		`}

	${props =>
		props.pageType === 'team' &&
		css`
			a:nth-child(3) {
				font: var(--body-lg-500);
				color: var(--terciary-400);

				border-bottom: 4px solid var(--terciary-400);
			}
		`}

	${props =>
		props.pageType === 'company' &&
		css`
			a:last-child {
				font: var(--body-lg-500);
				color: var(--terciary-400);

				border-bottom: 4px solid var(--terciary-400);
			}
		`}

	@media (max-width: 48rem) {
		justify-content: center;
		gap: 2rem;

		padding: 0 1.25rem;

		a {
			padding: 1.5rem 0;

			font: var(--body-ms-400);
			color: var(--gray-400);
			border-bottom: none !important;
		}

		${props =>
			props.pageType === 'user' &&
			css`
				a:first-child {
					font: var(--body-md-400);

					border-bottom: 2px solid var(--terciary-400);
				}
			`}

		${props =>
			props.pageType === 'manager' &&
			css`
				a:nth-child(2) {
					font: var(--body-md-400);

					border-bottom: 2px solid var(--terciary-400);
				}
			`}

	${props =>
			props.pageType === 'team' &&
			css`
				a:nth-child(3) {
					font: var(--body-md-400);

					border-bottom: 2px solid var(--terciary-400);
				}
			`}

	${props =>
			props.pageType === 'company' &&
			css`
				a:last-child {
					font: var(--body-md-400);

					border-bottom: 2px solid var(--terciary-400);
				}
			`}
	}
`;
