import styled from 'styled-components';

export const Container = styled.div`
	position: relative;
	display: flex;
	gap: 1.5rem;
	flex-wrap: wrap;
`;

export const LeaderAvatar = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;

	padding: 1rem 0;

	border-top: 1px solid var(--gray-200);
	border-bottom: 1px solid var(--gray-200);

	img {
		height: 2rem;
		width: 2rem;
	}

	p {
		font: var(--body-sm-500);
		color: var(--gray-700);

		span {
			margin-left: 0.25rem;

			font: var(--body-sm-400);
			color: var(--gray-500);
		}
	}
`;

export const Professionals = styled.div`
	margin-bottom: 1rem;
	padding: 1rem 0;

	border-bottom: 1px solid var(--gray-200);

	p {
		margin-bottom: 0.875rem;

		font: var(--body-sm-500);
		color: var(--gray-700);

		span {
			margin-left: 0.25rem;

			font: var(--body-sm-400);
			color: var(--gray-500);
		}
	}

	div {
		display: flex;

		img {
			height: 2rem;
			width: 2rem;

			&:nth-child(n + 2) {
				margin-left: -0.5rem;
			}
		}
	}
`;

export const DataItems = styled.ul`
	list-style: none;

	li {
		display: flex;
		align-items: center;
		gap: 0.5rem;

		p {
			font: var(--body-sm-500);
			color: var(--gray-700);

			span {
				margin-left: 0.25rem;

				font: var(--body-sm-400);
				color: var(--gray-500);
			}
		}
	}
`;
