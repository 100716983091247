import styled from 'styled-components';

interface TableContentProps {
	tableItemsNumber: number;
}

export const Container = styled.div`
	width: 100%;
	position: relative;
	max-height: 35.5rem;
	overflow-y: auto;
	overflow-x: hidden;

	padding: 1rem 1.5rem;

	box-shadow: var(--elevation-200);
	border-radius: 0.25rem;

	h3 {
		padding-bottom: 1rem;
		margin-bottom: 1.75rem;

		border-bottom: 1px solid var(--gray-200);

		font: var(--body-lg-500);
		color: var(--gray-700);
	}

	&::-webkit-scrollbar {
		width: 0.25rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--secondary-400);
	}

	.table-div {
		overflow-x: auto;
		height: 85%;
	}
`;

export const TableContent = styled.table<TableContentProps>`
	width: 100%;

	border-collapse: collapse;
	overflow-x: auto;

	thead {
		width: 100%;

		tr {
			border-bottom: 1px solid var(--gray-200);
		}

		th {
			width: ${props => `${100 / props.tableItemsNumber}%`};
			padding: 0.5rem 0;

			font: var(--body-md-500);
			color: var(--gray-500);
			text-align: left;
		}

		th:last-child {
			width: 100%;
		}
	}

	tbody {
		tr {
			height: 3rem;
			border-bottom: 1px solid var(--gray-200);
		}

		tr:last-child {
			border: none;
		}

		td {
			width: ${props => `${100 / props.tableItemsNumber}%`};

			color: var(--gray-700);
		}

		td:last-child {
			display: flex;
			align-items: center;

			height: 3rem;
			width: 100%;
		}
	}
`;
