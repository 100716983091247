import styled from 'styled-components';
import { Divider } from 'styles/globalStyledComponents';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;

	h3 {
		color: var(--gray-700);
	}

	.card {
		max-height: 400px;

		@media (max-width: 375px) {
			${Divider} {
				min-width: 10rem;
			}
		}
	}

	.autocomplete-and-description-container {
		@media (max-width: 425px) {
			flex-direction: column;
			width: 100%;
			align-items: flex-start !important;
		}
	}
`;

export const ProgressContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 2rem;
	flex-wrap: wrap;
	padding: 1rem 0;
	height: auto;
	max-height: 200px;

	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: var(--gray-200);
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--gray-400);
		border-radius: 4px;
	}
`;

interface ProgressProps {
	progress: number;
	color: string;
}

export const Progress = styled.div<ProgressProps>`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1rem;

	height: 2rem;
	max-width: 18rem;
	min-width: 9rem;
	width: 100%;

	font: var(--body-md-500);

	div {
		height: 6px;
		width: 100%;

		background-color: #fff;
		box-shadow: inset 6px 0px 6px ${props => props.color};
		opacity: 0.7;

		border-radius: 200px;

		span {
			display: block;

			height: 100%;
			width: ${props => props.progress}%;

			border-radius: 200px;
			background-color: ${props => props.color};
		}
	}
`;

export const OkrText = styled.p`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 135%;
	color: var(--gray-500);
	overflow-wrap: break-word;
	max-width: 90px;
	padding-right: 10px;
`;

export const OkrProgressContainer = styled.div`
	display: flex;
	align-items: center;
	width: 500px;

	gap: 2rem;
`;
