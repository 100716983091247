import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { IButtonProps } from './IButtonProps';

import { Container } from './style';

export const Button: React.FC<IButtonProps> = ({
	bgColor,
	type,
	children,
	disabled,
	className,
	isLoading,
	onClick,
}) => {
	const [backgroundColor, setBackgroundColor] = useState('');
	const [color, setColor] = useState('');

	useEffect(() => {
		if (bgColor === 'primary') {
			setBackgroundColor('var(--primary-400)');
			setColor('var(--white)');
		}
		if (bgColor === 'secondary') {
			setBackgroundColor('var(--secondary-400)');
			setColor('var(--white)');
		}
		if (bgColor === 'terciary') {
			setBackgroundColor('var(--terciary-400)');
			setColor('var(--white)');
		}
		if (bgColor === 'gray-300') {
			setBackgroundColor('var(--gray-300)');
			setColor('var(--gray-400)');
		}
		if (bgColor === 'white') {
			setBackgroundColor('var(--white)');
			setColor('var(--primary-400)');
		}
		if (bgColor === 'red-400') {
			setBackgroundColor('var(--red-400)');
			setColor('var(--white)');
		}
	}, []);

	return (
		<Container
			type={type}
			bgColor={backgroundColor}
			color={color}
			disabled={!!disabled}
			className={className}
			onClick={onClick}
		>
			{isLoading ? <CircularProgress color="inherit" size={20} /> : children}
		</Container>
	);
};
