import { useRef } from 'react';
import { Autocomplete, TextField, Checkbox } from '@mui/material';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';

import { IAutocompleteProps } from '../../interfaces/IAutocompleteProps';

const icon = <MdCheckBoxOutlineBlank />;
const checkedIcon = <MdCheckBox />;

type IAutocompleteLedsPropsOmited = Omit<IAutocompleteProps, 'image' | 'value'>;

interface IAutocompleteLedsProps extends IAutocompleteLedsPropsOmited {
	value: string[];
}

export const AutocompleteLeds = ({ label, disabled, options, size, value, onChange }: IAutocompleteLedsProps) => {
	const inputRef = useRef<HTMLInputElement>(null);
	return (
		<Autocomplete
			multiple
			fullWidth
			value={value}
			disableCloseOnSelect
			isOptionEqualToValue={(option, value) => option === value}
			size={size}
			options={options}
			disabled={disabled}
			ref={inputRef}
			onChange={onChange}
			renderOption={(props, option, { selected }) => (
				<li {...props}>
					<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
					{option}
				</li>
			)}
			renderInput={params => <TextField {...params} label={label} variant="standard" />}
			style={{ minHeight: '100px', paddingBottom: '1rem' }}
		/>
	);
};
