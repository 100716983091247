import styled from 'styled-components';
import { Title } from '../style';

export const ValuesContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin-bottom: 0.8rem;

	h3,
	h4,
	p {
		font-family: 'Fira Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 140%;
	}

	h3 {
		color: var(--primary-400);
	}

	h4 {
		color: var(--gray-700);
	}

	p {
		color: var(--gray-500);
	}

	span {
		display: flex;
		border-radius: 50%;
		border: solid 2px;
		color: var(--gray-500);
	}
`;

export const RadioContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	gap: 1rem;
`;

export const RadioTitle = styled.h2`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 135%;
	color: var(--gray-700);
`;

export const FormContainer = styled.div`
	${Title} {
		padding: 1rem 0;
	}

	.btn-send-comment {
		width: 8.125rem;
		height: 3rem;
		align-self: flex-end;
		margin-top: 1rem;
		margin-right: 1.5rem;
	}

	textarea {
		padding: 10px;
		color: var(--gray-700);

		font-family: 'Fira Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 135%;

		&:focus {
			border: 1.5px solid var(--primary-400);
		}
	}

	.btn-submit {
		width: 13rem;
		justify-self: flex-end;
		margin-top: 1rem;
		margin-left: auto;
	}
`;
