import React, { createContext, useContext, useState } from 'react';
import {
	IKeyResultData,
	EntityWithIdAndLabel,
	ITeamContributors,
} from 'domains/Objectives/interfaces/IFormNewKeyResultsData';
import { IMember } from '../interfaces/FormNewObjectivesData';

interface IFormNewKeyResultsContext {
	handleFormData(formData: IKeyResultData): void;
	formData: IKeyResultData;
	selectedOptionsForm: ISelectedOptionsForm;
	handleSelectedOptionsForm(options: ISelectedOptionsForm): void;
	keyResultId?: string;
	objectiveId: string;
}

interface ISelectedOptionsForm {
	responsable: EntityWithIdAndLabel | null;
	medida: EntityWithIdAndLabel | null;
	team: ITeamContributors;
	selectedProfessionals: IMember[];
}

const FormNewKeyResultsContext = createContext<IFormNewKeyResultsContext>({} as IFormNewKeyResultsContext);

interface IProviderProps {
	children: React.ReactNode;
	keyResultId?: string;
	objectiveId: string;
}

const FormNewKeyResultsProvider = ({ children, keyResultId, objectiveId }: IProviderProps) => {
	const [formData, setFormData] = useState<IKeyResultData>({
		result: '',
		description: '',
		measure: {
			id: '',
			name: '',
		},
		polarity: {
			type: 0,
			initialValue: 0,
			completedValue: 0,
			valueTypeTokeep: 0,
		},
		weight: 1,
		responsableId: '',
		teams: [
			{
				id: '',
				usersId: [''],
			},
		],
		finalDate: '',
		deliveryDate: '',
	});
	const [selectedOptionsForm, setSelectedOptionsForm] = useState<ISelectedOptionsForm>({
		responsable: null,
		medida: null,
		team: {
			id: '',
			label: '',
			members: [
				{
					id: '',
					name: '',
					image: '',
				},
			],
		},
		selectedProfessionals: [
			{
				id: '',
				name: '',
				image: '',
			},
		],
	});

	const handleFormData = (formData: IKeyResultData) => {
		setFormData({ ...formData });
	};

	const handleSelectedOptionsForm = (options: ISelectedOptionsForm) => {
		setSelectedOptionsForm({ ...options });
	};

	return (
		<FormNewKeyResultsContext.Provider
			value={{
				formData,
				handleFormData,
				selectedOptionsForm,
				handleSelectedOptionsForm,
				keyResultId,
				objectiveId,
			}}
		>
			{children}
		</FormNewKeyResultsContext.Provider>
	);
};

const useFormNewKeyResults = (): IFormNewKeyResultsContext => {
	const context = useContext(FormNewKeyResultsContext);

	if (!context) {
		throw new Error('useModal must be used within a FormNewObjectivesProvider');
	}

	return context;
};

export { FormNewKeyResultsProvider, useFormNewKeyResults };
