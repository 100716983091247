import styled, { css } from 'styled-components';

interface TableRowProps {
	selected?: boolean;
}

interface ContainerProps {
	openModal: boolean;
}

export const ContainerDesktop = styled.div`
	width: 100%;

	@media (max-width: 64rem) {
		display: none;
	}
`;

export const TableRow = styled.tr<TableRowProps>`
	${props =>
		props.selected &&
		css`
			position: relative;

			td:first-child::before {
				content: '';
				position: absolute;
				top: 0;
				left: -24px;
				display: inline-block;
				height: 48px;
				width: 4px;
				background-color: var(--terciary-400);
			}

			td {
				color: var(--terciary-400) !important;
			}
		`}
`;

export const Overlay = styled.div<ContainerProps>`
	display: flex;
	align-items: center;
	justify-content: center;

	position: fixed;
	top: 50%;
	left: 50%;
	z-index: -10;

	transform: translate(-50%, -50%);

	height: 100vh;
	width: 100%;

	opacity: 0;

	${props =>
		props.openModal &&
		css`
			z-index: 10;

			background-color: var(--black);
			opacity: 0.4;
		`}

	transition: opacity 0.2s ease-out;
`;

export const ContainerMobile = styled.div`
	width: 100%;

	padding: 1rem 1.5rem;

	box-shadow: var(--elevation-200);
	border-radius: 4px;

	h4 {
		margin-bottom: 1rem;

		font: var(--body-md-500);
		color: var(--secondary-400);
	}

	@media (min-width: 65rem) {
		display: none;
	}
`;

export const Item = styled.button`
	display: flex;
	align-items: center;
	gap: 0.5rem;

	width: 100%;
	background-color: transparent;

	margin-bottom: 1rem;

	border: none;

	& > p {
		font: var(--body-md-400);
		color: var(--gray-700);
	}
`;

export const Avatar = styled.img`
	width: 2rem;
	height: 2rem;

	border-radius: 50%;
	box-shadow: var(--elevation-400);
`;

export const Container = styled.div<ContainerProps>`
	display: none;

	${props =>
		props.openModal &&
		css`
			position: fixed;
			z-index: 10;
			top: 50%;
			left: 50%;

			overflow-y: scroll;
			transform: translate(-50%, -50%);

			display: block;

			max-height: 90vh;
			width: 41rem;
			background-color: var(--white);

			padding: 2rem;

			box-shadow: var(--elevation-800);
			border-radius: 0.25rem;

			&::-webkit-scrollbar {
				width: 4px;
			}

			&::-webkit-scrollbar-track {
				background: var(--gray-200);
			}

			&::-webkit-scrollbar-thumb {
				background-color: var(--gray-400);
				border-radius: 4px;
			}
			}
		`}

	@media (max-width: 48rem) {
		max-width: 20rem;
		width: 100%;
	}
`;

export const Head = styled.div<ContainerProps>`
	display: flex;
	justify-content: space-between;

	padding-bottom: 1rem;

	border-bottom: 1px solid var(--gray-200);

	h4 {
		font: var(--body-lg-500);
		color: var(--primary-400);
	}

	svg {
		cursor: pointer;

		&:hover {
			transform: rotate(90deg);
			transition: all 0.4s ease-in;
		}
	}
`;

export const ModalButton = styled.div`
	width: 15.5rem;

	margin-left: auto;
`;
