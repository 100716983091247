import styled, { css } from 'styled-components';
import { Divider } from 'styles/globalStyledComponents';

interface ProgressProps {
	progress: number;
}

export const Container = styled.div`
	.card {
		max-height: 100%;

		@media (max-width: 375px) {
			${Divider} {
				min-width: 10rem;
			}
		}

		h3 {
			font-family: 'Fira Sans';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 135%;

			color: var(--gray-700);
		}
	}
`;

export const Progress = styled.div<ProgressProps>`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1rem;

	height: 2rem;

	width: 40%;
	min-width: 12rem;

	font: var(--body-md-500);

	div {
		height: 8px;
		width: 100%;

		opacity: 0.7;

		border-radius: 200px;
		background-color: #fff;

		span {
			display: block;

			height: 100%;
			width: ${props => props.progress}%;

			border-radius: 200px;
		}
	}

	${props =>
		props.progress >= 0 &&
		css`
			color: var(--red-400);

			div span {
				background-color: var(--red-400);
			}

			div {
				box-shadow: inset 8px 0px 8px var(--red-400);
			}
		`}

	${props =>
		props.progress >= 50 &&
		css`
			color: var(--yellow-400);

			div span {
				background-color: var(--yellow-400);
			}

			div {
				box-shadow: inset 8px 0px 8px var(--yellow-400);
			}
		`}

	${props =>
		props.progress >= 80 &&
		css`
			color: var(--green-400);

			div span {
				background-color: var(--green-400);
			}

			div {
				box-shadow: inset 8px 0px 8px var(--green-400);
			}
		`}
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 1rem;
	padding-right: 1rem;
	flex-wrap: wrap;
	gap: 1rem;

	@media (min-width: 769px) {
		${Progress} {
			width: 20%;
		}
	}

	.inputs-container {
		width: 76%;
		display: flex;
		gap: 2rem;
		flex-wrap: wrap;

		.autocomplete {
			width: 35%;
			min-width: 12rem;
		}

		@media (min-width: 769px) {
			.autocomplete {
				width: 40%;
			}
		}
	}
`;

export const TableContainer = styled.div`
	min-height: 200px;
	max-height: 400px;
	width: 100%;
	margin-top: 2.5rem;
	overflow-y: auto;

	background: #f9f9f9;

	table {
		padding: 1rem;
		width: 100%;
		min-width: 30rem;

		tbody {
			width: 100%;

			.table-header {
				th:nth-child(1) {
					text-align: left;
				}

				th:nth-child(2) {
					text-align: center;
				}

				th:nth-child(3) {
					text-align: center;
				}

				th {
					text-align: center;
					font-family: 'Fira Sans';
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 135%;

					color: var(--gray-700);
					border-bottom: 1px solid var(--gray-200);
				}
			}

			${Progress} {
				width: 100%;
				font-size: 14px;
				color: var(--gray-700);
				div {
					height: 4px;
					margin-left: 0;
				}
			}
		}

		th:nth-child(1) {
			text-align: left;
		}

		th {
			padding: 0.5rem;
			text-align: center;

			font-family: 'Fira Sans';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 140%;
			color: var(--gray-700);
		}
	}
`;
