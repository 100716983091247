import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: 2.5rem 0;

	h1 {
		font: var(--body-xl-500);
		color: var(--gray-700);
	}

	button {
		max-width: 15.5rem;
	}

	.container-modal-switch {
		display: flex;
		align-items: center;
		flex-direction: row;

		.switch {
			position: relative;
		}
	}

	@media (max-width: 1024px) {
		.container-modal-switch {
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;
			padding-right: 2rem;
			gap: 2rem;
			width: 100%;
		}
	}

	@media (max-width: 48rem) {
		order: 3;

		h1 {
			display: none;
		}

		& > div {
			button {
				max-width: 48rem;
			}
		}
	}
`;
