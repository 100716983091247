import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
	:root {
		/*** COLORS ***/

		--white: #FFFFFF;
		--black: #000000;

		--gray-50:  #F9F9F9;
		--gray-200: #EBEBEB;
		--gray-300: #DCDBDC;
		--gray-400: #B5B5B5;
		--gray-500: #949494;
		--gray-700: #565656;
		--gray-800: #263238;
		--gray-900: #1E1E1E;

		--primary-400:   #21A4D3;

		--secondary-400: #145674;

		--terciary-400:  #0BBFAA;

		--red-400: #EF5350;

		--green-400: #66BB6A;

		--yellow-400: #FFC400;

		--blue-400: #039BE5;

		--purple-500: #9C27B0;
		--purple-800: #4527A0;
		--purple-900: #11409B;


		/*** TYPOGRAPHY ***/

		--heading-xl-400: 400 6rem/100% 'Fira Sans', 'sans-serif';
		--heading-xl-700: 700 6rem/100% 'Fira Sans', 'sans-serif';
		--heading-lg-400: 400 3.75rem/100% 'Fira Sans', 'sans-serif';
		--heading-md-400: 400 3rem/100% 'Fira Sans', 'sans-serif';
		--heading-sm-400: 400 2.125rem/100% 'Fira Sans', 'sans-serif';
		--heading-xs-400: 400 2rem/100% 'Fira Sans', 'sans-serif';
		--heading-xs-500: 500 2rem/100% 'Fira Sans', 'sans-serif';

		--body-xl-500:    500 1.375rem/125% 'Fira Sans', 'sans-serif';
		--body-lg-400:    400 1.125rem/135% 'Fira Sans', 'sans-serif';
		--body-lg-500:    500 1.125rem/135% 'Fira Sans', 'sans-serif';
		--body-md-400:    400 1rem/135% 'Fira Sans', 'sans-serif';
		--body-md-500:    500 1rem/135% 'Fira Sans', 'sans-serif';
		--body-sm-400:    400 0.875rem/135% 'Fira Sans', 'sans-serif';
		--body-sm-500:    500 0.875rem/135% 'Fira Sans', 'sans-serif';
		--body-xs-400:    400 0.75rem/135% 'Fira Sans', 'sans-serif';


		/*** SHADOWS ***/

		--elevation-200:  0px 2px 4px rgba(0, 0, 0, 0.24);
		--elevation-400:  0px 4px 8px rgba(0, 0, 0, 0.16);
		--elevation-600:  0px 8px 16px rgba(0, 0, 0, 0.12);
		--elevation-800:  0px 16px 24px rgba(0, 0, 0, 0.12);
	}

	* {
		margin: 0;
		padding: 0;

		box-sizing: border-box;
		outline: none;
	}

	body, input, textarea, select, button {
		font: var(--body-md-400)
	}

	fieldset {
		border: none
	}

	img, svg {
		display: block;

		margin: 0;
		padding: 0;
	}

	button {
		cursor: pointer;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	.stepper-component {
		.Mui-active circle {
			color: var(--secondary-400);
		}

		text {
			fill: rgba(255, 255, 255, 255);
		}
	}

	.stepper-component .MuiStep-root:first-child .MuiStepConnector-line {
		border-color: tomato !important;
	}

	.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
		font: var(--body-sm-400);
	}

	.display-none {
		display: none !important;
	}

	.css-qivjh0-MuiStepLabel-label.Mui-active {
		color: var(--secondary-400) !important;
	}

	.css-1ns4lbu-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
		color: white !important;
	}

	@media (max-width: 992px) {
		.css-lf7ukq-MuiTypography-root-MuiLink-root {
			font-size: 16px !important;
		}

		.css-ahj2mt-MuiTypography-root {
			font-size: 16px !important;
		}
	}

	@media (max-width: 48rem) {
		.MuiStep-root {
			padding: 0 4px !important;
		}

		.MuiStepLabel-root:nth-child(2) {
			min-width: 80px;
		}

		.MuiStep-root:nth-child(2) .MuiStepConnector-root {
			left: calc(-32% + 20px) !important;
		}

		.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
			font-size: 0.75rem;
		}
	}

	.MuiInput-underline.Mui-disabled:before {
		border-bottom: 1px solid !important;
	}

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: var(--gray-200);
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--gray-400);
		border-radius: 4px;
	}
`;
