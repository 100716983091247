import { Autocomplete, TextField, Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';

import { IAutocompleteMembersData } from '../../interfaces/IAutocompleteMembersData';

export const AutocompleteMembers = ({ options, value, onChange }: IAutocompleteMembersData) => {
	const [selectedMembersIds, setSelectedMembersIds] = useState(value.map(value => value.id));

	useEffect(() => {
		setSelectedMembersIds(value.map(value => value.id));
	}, [value]);

	return (
		<Autocomplete
			multiple
			options={options}
			value={value}
			onChange={(event, value) => onChange(event, value)}
			fullWidth
			disableCloseOnSelect
			isOptionEqualToValue={(option, value) => option.id === value.id}
			getOptionLabel={option => option.name}
			renderOption={(props, option, { selected }) => (
				<li {...props}>
					<Checkbox style={{ marginRight: 8 }} checked={selected || selectedMembersIds.includes(option.id)} />
					{option.name}
				</li>
			)}
			renderInput={params => (
				<TextField {...params} label="Profissionais" variant="standard" required={value.length === 0} />
			)}
		/>
	);
};
