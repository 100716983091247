import { useEffect, useState } from 'react';
import { ICardKRsProps } from 'domains/Objectives/interfaces/IObjectiveData';
import { IIndicator } from 'domains/Objectives/interfaces/IIndicators';
import {
	createComment,
	getIndicatorsStatusOptions,
	getIndicatorsUpdates,
	putUpdateIndicator,
} from 'domains/Objectives/services/ObjectivesServices';
import { formatDateNormal, isDateBeforeFromToday } from 'domains/Objectives/utils/formatDates';

import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ptBrLocale from 'date-fns/locale/pt-BR';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { FormControlLabel, Radio, RadioGroup, TextareaAutosize, TextField } from '@mui/material';

import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { MdOutlineMessage } from 'react-icons/md';
import { ItemDescription, SectionTitle, Title } from '../style';
import { RadioContainer, RadioTitle, ValuesContainer, FormContainer } from './style';

interface IFormProps {
	keyResult: ICardKRsProps;
	handleModalContent: () => void;
}

export const FormUpdateIndicators = ({ keyResult, handleModalContent }: IFormProps) => {
	const [indicatorsUpdates, setIndicatorsUpdates] = useState<IIndicator[]>([]);

	const [newAccumulatedValue, setNewAccumulatedValue] = useState<string | number>('');
	const [newComment, setNewComment] = useState('');
	const [updateDate, setUpdateDate] = useState<Date | null>(null);
	const [status, setStatus] = useState('');
	const [statusOptions, setStatusOptions] = useState([
		{
			id: '',
			name: '',
		},
	]);
	const [isInvalidDate, setIsInvalidDate] = useState(false);

	const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStatus((event.target as HTMLInputElement).value);
	};

	useEffect(() => {
		const handleIndicatorsUpdates = async () => {
			const requestData: IIndicator[] = await getIndicatorsUpdates(keyResult.id);

			setIndicatorsUpdates(requestData);
		};

		const statusOptions = async () => {
			const data: { id: string; name: string }[] = await getIndicatorsStatusOptions();
			setStatusOptions(data);

			setStatus(() => data.filter((option: { id: string; name: string }) => option.name === 'Nenhuma')[0].id);
		};

		handleIndicatorsUpdates();
		statusOptions();
	}, []);

	const submitComment = () => {
		if (keyResult.id)
			createComment(keyResult.id, newComment).then(() => {
				setNewComment('');
			});
	};

	const submitForm = async () => {
		const formData = {
			newAccumulatedValue,
			updateDate: `${updateDate}`,
			statusId: status,
		};

		await putUpdateIndicator(keyResult.id, formData).then(() => {
			handleModalContent();
		});
	};

	useEffect(() => {
		const isFinalDateValid =
			`${updateDate}` !== 'Invalid Date' && updateDate !== null && !isDateBeforeFromToday(updateDate);

		if (!isFinalDateValid && updateDate !== null) {
			setIsInvalidDate(true);
		} else setIsInvalidDate(false);
	}, [updateDate]);

	return (
		<FormContainer>
			<SectionTitle>Key Result</SectionTitle>
			<ItemDescription>{keyResult.description}</ItemDescription>

			<SectionTitle>Valores Realizados</SectionTitle>

			{indicatorsUpdates.map(indicator => (
				<ValuesContainer>
					<h3>Valor realizado:</h3>{' '}
					<h4>
						{indicator.value} {keyResult.measure.name}
					</h4>
					<span />
					<p>{formatDateNormal(indicator.createdAt)}</p>
				</ValuesContainer>
			))}

			<SectionTitle style={{ marginBottom: '1.5rem' }}>Novo Valor</SectionTitle>
			<Input
				placeholder="Novo valor acumulado"
				required
				value={newAccumulatedValue}
				name="newAccumulatedValue"
				label="Novo valor acumulado"
				isMoneyInput
				onChange={e => setNewAccumulatedValue(Number(e.target.value))}
			/>

			<LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBrLocale}>
				<DatePicker
					label="Data de Atualização"
					value={updateDate}
					onChange={value => {
						setUpdateDate(value);
					}}
					minDate={new Date()}
					renderInput={params => (
						<TextField
							{...params}
							variant="standard"
							placeholder="Data de Atualização"
							error={isInvalidDate}
							helperText={isInvalidDate ? 'Data Inválida' : ''}
							style={{ width: '100%', marginBottom: '2rem', marginTop: '1.5rem' }}
							required
						/>
					)}
				/>
			</LocalizationProvider>

			<RadioContainer>
				<RadioTitle>Perspectiva atual do andamento</RadioTitle>
				<RadioGroup
					name="radio-buttons-group"
					defaultValue={'f3eab81d-0532-402a-9438-2a52561daf19'}
					onChange={handleChangeStatus}
				>
					{statusOptions.map(options => (
						<FormControlLabel
							value={options.id}
							control={<Radio />}
							label={options.name}
							style={{ color: 'var(--gray-500)', fontSize: '14px' }}
						/>
					))}
				</RadioGroup>
			</RadioContainer>

			<Title>
				<MdOutlineMessage />
				<h1>Comentários</h1>
			</Title>
			<TextareaAutosize
				value={newComment}
				minRows={3}
				maxRows={20}
				onChange={event => setNewComment(event.target.value)}
				style={{ width: '100%' }}
			/>

			<Button bgColor="primary" className="btn-send-comment" disabled={!newComment} onClick={submitComment}>
				Enviar
			</Button>

			<Button
				bgColor="primary"
				className="btn-submit"
				disabled={!(newAccumulatedValue && status && isInvalidDate === false)}
				onClick={submitForm}
			>
				Concluir
			</Button>
		</FormContainer>
	);
};
