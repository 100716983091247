import styled from 'styled-components';

interface DividerProps {
	marginTop: string;
	marginBottom: string;
}

export const SectionTitle = styled.h2`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 135%;
	color: var(--secondary-400);
	margin-bottom: 1.5rem;

	@media (max-width: 375px) {
		margin-top: 1rem;
	}
`;

export const ItemTitle = styled.h3`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 135%;
	color: var(--gray-700);
`;

export const ItemDescription = styled.p`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 135%;
	color: var(--gray-500);
`;

export const Divider = styled.span<DividerProps>`
	display: block;
	min-width: 264px;
	width: 100%;
	height: 1px;

	background: var(--gray-200);

	margin-top: ${props => props.marginTop};
	margin-bottom: ${props => props.marginBottom};
`;
