import { createTheme, ThemeProvider } from '@mui/material';
import { App } from 'App';
import React from 'react';
import ReactDOM from 'react-dom';
import { GlobalStyle } from 'styles/global';

const theme = createTheme({
	palette: {
		primary: {
			main: '#21A4D3',
		},
		secondary: {
			main: '#145674',
		},
	},
});

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<App />
		</ThemeProvider>
		<GlobalStyle />
	</React.StrictMode>,
	document.getElementById('root'),
);
