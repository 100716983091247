import { ModalButtonSettings } from 'domains/Objectives/components/InfoModal/style';
import styled, { css } from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	gap: 1rem;

	padding: 1rem;

	border: 1px solid var(--gray-200);
	border-radius: 4px;
	width: 100%;

	.divider {
		width: calc(100% + 2rem);
		margin-left: -1rem;
	}

	@media (max-width: 30rem) {
		position: relative;
		width: 17rem;

		.divider {
			min-width: 1rem !important;
		}
	}
`;

export const TopContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	@media (max-width: 30rem) {
		${ModalButtonSettings} {
			top: 1rem;
			right: 1rem;
		}
	}

	p {
		max-width: 430px;
		overflow-wrap: break-word;

		font-family: 'Fira Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 135%;
		color: var(--gray-700);

		@media (max-width: 30rem) {
			max-width: 11rem;
		}
	}
`;

interface ProgressProps {
	progress: number;
}

export const Progress = styled.div<ProgressProps>`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1rem;

	height: 2rem;
	max-width: 18rem;
	width: 100%;

	font: var(--body-md-500);

	div {
		height: 4px;
		width: 100%;

		background-color: var(--gray-200);

		border-radius: 200px;

		span {
			display: block;

			height: 100%;
			width: ${props => props.progress}%;

			border-radius: 200px;
		}
	}
	${props =>
		props.progress >= 0 &&
		css`
			color: var(--red-400);

			div span {
				background-color: var(--red-400);
			}
		`}

	${props =>
		props.progress >= 50 &&
		css`
			color: var(--yellow-400);

			div span {
				background-color: var(--yellow-400);
			}
		`}

	${props =>
		props.progress >= 80 &&
		css`
			color: var(--green-400);

			div span {
				background-color: var(--green-400);
			}
		`}
`;

export const ShowDetailsButton = styled.button`
	display: flex;
	align-items: center;
	gap: 0.75rem;

	background-color: transparent;

	border: none;

	font: var(--body-sm-500);
	color: var(--blue-400);
`;
