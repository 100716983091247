import { ITask } from 'domains/Objectives/interfaces/ITaskFormData';
import { Droppable } from 'react-beautiful-dnd';
import { Divider } from 'styles/globalStyledComponents';
import { ColumnContainer, ColumnTitle } from './styles';
import { TaskCard } from './TaskCard';

interface IColumnProps {
	columnId: 'todo' | 'doing' | 'done';
	column: {
		id: 'todo' | 'doing' | 'done';
		title: string;
		tasks: ITask[];
	};
	reloadTasks: () => void;
}

export const Column = ({ column, columnId, reloadTasks }: IColumnProps) => (
	<Droppable droppableId={columnId}>
		{(provided, snapshot) => (
			<ColumnContainer
				{...provided.droppableProps}
				ref={provided.innerRef}
				type={columnId}
				isDraggingOver={snapshot.isDraggingOver}
			>
				<ColumnTitle>{column.title}</ColumnTitle>
				<Divider marginTop="0rem" marginBottom="0.5rem" />

				{column.tasks.map(
					(task, index) =>
						task && <TaskCard task={task} index={index} key={task.id} reloadTasks={reloadTasks} />,
				)}

				{provided.placeholder}
			</ColumnContainer>
		)}
	</Droppable>
);
