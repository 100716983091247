import { useEffect, useState } from 'react';
import { Card } from 'components/Card';
import { ITask } from 'domains/Objectives/interfaces/ITaskFormData';
import { Divider } from 'styles/globalStyledComponents';
import { BiCalendar } from 'react-icons/bi';
import { formatDateNormal } from 'domains/Objectives/utils/formatDates';
import { MdOutlineMessage } from 'react-icons/md';
import {
	BottomDescription,
	ColumnContainer,
	ColumnTitle,
	Container,
	DescriptionText,
	ProfileContainer,
	TaskContainerCard,
	TaskTitle,
} from './styles';

interface ITasksCardProps {
	tasks: ITask[];
}

export const TasksCard = ({ tasks }: ITasksCardProps) => {
	const [todoTasks, setTodoTasks] = useState<ITask[]>([]);
	const [doingTasks, setDoingTasks] = useState<ITask[]>([]);
	const [doneTasks, setDoneTasks] = useState<ITask[]>([]);

	useEffect(() => {
		setTodoTasks(tasks.filter(task => task.status === 1));
		setDoingTasks(tasks.filter(task => task.status === 2));
		setDoneTasks(tasks.filter(task => task.status === 3));
	}, [tasks]);

	return (
		<Card title="Tarefas" isCollapsed defaultValue>
			<Container>
				<ColumnContainer borderColor="#EF5350">
					<ColumnTitle>A Fazer</ColumnTitle>
					<Divider marginTop="1rem" marginBottom="1rem" />
					{todoTasks.map(task => (
						<TaskCard task={task} key={task.id} />
					))}
				</ColumnContainer>

				<ColumnContainer borderColor="#FFC400">
					<ColumnTitle>Em Progresso</ColumnTitle>
					<Divider marginTop="1rem" marginBottom="1rem" />
					{doingTasks.map(task => (
						<TaskCard task={task} key={task.id} />
					))}
				</ColumnContainer>

				<ColumnContainer borderColor="#66BB6A">
					<ColumnTitle>Concluído</ColumnTitle>
					<Divider marginTop="1rem" marginBottom="1rem" />
					{doneTasks.map(task => (
						<TaskCard task={task} key={task.id} />
					))}
				</ColumnContainer>
			</Container>
		</Card>
	);
};

const TaskCard = ({ task }: { task: ITask }) => (
	<TaskContainerCard>
		<DescriptionText>Objetivo: {task.keyResult.objective.description}</DescriptionText>
		<DescriptionText>KR: {task.keyResult.description}</DescriptionText>
		<TaskTitle>{task.description}</TaskTitle>
		<Divider marginTop="0" marginBottom="0" />
		<BottomDescription>
			<ProfileContainer>
				<DescriptionText>
					<BiCalendar />
					{formatDateNormal(task.predictedDate)}
				</DescriptionText>
				<DescriptionText style={{ marginLeft: '1rem' }}>
					<MdOutlineMessage />
					{task.comments}
				</DescriptionText>
			</ProfileContainer>
			<ProfileContainer>
				<img src={task.user.image} alt={task.user.person.name} />
				{task.user.person.name}
			</ProfileContainer>
		</BottomDescription>
	</TaskContainerCard>
);
