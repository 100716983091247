import { useEffect, useState } from 'react';
import { useCycle } from 'hooks/useCycles';
import { postKeyResultsFromPeriod } from 'domains/Reports/services/reportsServices';
import { IKeyResultPeriodData } from 'domains/Reports/Interfaces/IKeyResultsPeriodDataRequest';

import { isDateBeforeFromAnotherDate } from 'domains/Objectives/utils/formatDates';
import dayjs from 'dayjs';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ptBrLocale from 'date-fns/locale/pt-BR';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { Chart } from 'react-google-charts';
import { Card } from 'components/Card';
import { Divider } from 'styles/globalStyledComponents';
import { TextField } from '@mui/material';
import { Subtitle } from '../style';
import { Container, DatesContainer } from './style';

interface IKeyResultsEvolutionProps {
	pageView: string;
}

export const KeyResultsEvolution = ({ pageView }: IKeyResultsEvolutionProps) => {
	const { cycleDates } = useCycle();

	const [startDate, setStartDate] = useState<Date | null>(null);
	// const [isStartDateInvalid, setIsStartDateInvalid] = useState(false);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const [chartData, setChartData] = useState<(string | number | null)[][]>();

	const handleDateInterval = (startDate: Date | null, endDate: Date | null) => {
		if (startDate && endDate) {
			const date = new Date(startDate.getTime());

			const dates: Date[] = [];

			while (date <= endDate) {
				dates.push(new Date(date));
				date.setDate(date.getDate() + 1);
			}

			return dates.map(date => dayjs(date).format('DD/MM/YYYY'));
		}
		return [];
	};

	const handleChartData = async () => {
		const dates: (string | number)[] = handleDateInterval(startDate, endDate);
		if (startDate && endDate && `${startDate}` !== 'Invalid Date' && `${endDate}` !== 'Invalid Date') {
			await postKeyResultsFromPeriod(pageView, { startDate: `${startDate}`, endDate: `${endDate}` }).then(
				(response: IKeyResultPeriodData[]) => {
					const titlesArray = response.map(period => period.description);

					const dataGraph: Array<Array<number | null | string>> = [];

					for (let i = 0; i < dates.length; i += 1) {
						dataGraph[i] = [];
						for (let j = 0; j < titlesArray.length + 1; j += 1) {
							dataGraph[i][j] = null;
						}
					}

					for (let i = 0; i < dates.length; i += 1) {
						dataGraph[i][0] = dates[i];
					}

					response.forEach((keyResult: IKeyResultPeriodData, indexColumn) => {
						keyResult.history.forEach(history => {
							const indexOfDate = dates.indexOf(dayjs(history.createdAt).format('DD/MM/YYYY'), 0);

							if (dataGraph[indexOfDate] !== undefined)
								dataGraph[indexOfDate][indexColumn + 1] = history.progress;
						});
					});

					const dataGraphFiltered = dataGraph.filter((column: (string | number | null)[]) => {
						let hasValue = false;
						column.forEach(columnItem => {
							if (typeof columnItem === 'number') {
								hasValue = true;
							}
						});
						if (hasValue) return column;
						return null;
					});

					const dataGraphFilled = dataGraphFiltered.map(
						(row: (string | number | null)[], rowIndex: number) => {
							const beforeValueRow = dataGraphFiltered[rowIndex - 1] ?? [];
							const newRow = row;

							if (rowIndex !== 0) {
								newRow.forEach((item, columnIndex) => {
									if (columnIndex !== 0) {
										if (item === null && beforeValueRow[columnIndex] !== null)
											newRow[columnIndex] = beforeValueRow[columnIndex];
									}
								});
							}

							return newRow;
						},
					);

					setChartData([['Datas', ...titlesArray], ...dataGraphFilled]);
				},
			);
		}
	};

	useEffect(() => {
		/* if (isDateBeforeFromAnotherDate(endDate, startDate)
			&& !isDateAfterFromAnotherDate(cycleDates.endDate, endDate))
			handleChartData();
*/
		if (isDateBeforeFromAnotherDate(endDate, startDate)) handleChartData();
	}, [endDate]);

	useEffect(() => {
		// if (
		// 	isDateBeforeFromAnotherDate(cycleDates.startDate, startDate) ||
		// 	isDateAfterFromAnotherDate(cycleDates.endDate, startDate)
		// ) {
		// 	setIsStartDateInvalid(true);
		// } else if (!isDateBeforeFromAnotherDate(startDate, endDate)) {
		// 	setIsStartDateInvalid(false);
		// }
		if (
			startDate &&
			endDate &&
			`${startDate}` !== 'Invalid Date' &&
			`${endDate}` !== 'Invalid Date' &&
			isDateBeforeFromAnotherDate(startDate, endDate) &&
			isDateBeforeFromAnotherDate(endDate, startDate) === false
		)
			handleDateInterval(startDate, endDate);
	}, [startDate, endDate]);

	return (
		<Container>
			<Card title="Evolução dos Key Results por período" className="card">
				<Divider marginTop="0.5rem" marginBottom="1rem" />
				<Subtitle>Período</Subtitle>
				<DatesContainer>
					<LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBrLocale}>
						<DatePicker
							label="Data de Início"
							value={startDate}
							onChange={value => {
								setStartDate(value);
								setEndDate(null);
							}}
							minDate={new Date(cycleDates.startDate)}
							// maxDate={new Date(cycleDates.endDate)}
							maxDate={new Date()}
							renderInput={params => (
								<TextField
									{...params}
									variant="standard"
									placeholder="Data de Início"
									// error={`${startDate}` === 'Invalid Date' || isStartDateInvalid}
									// helperText={
									// 	`${startDate}` === 'Invalid Date' || isStartDateInvalid ? 'Data Inválida' : ''
									// }
									error={`${startDate}` === 'Invalid Date'}
									helperText={`${startDate}` === 'Invalid Date' ? 'Data Inválida' : ''}
								/>
							)}
						/>
					</LocalizationProvider>

					<LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBrLocale}>
						<DatePicker
							label="Data de Término"
							value={endDate}
							onChange={value => {
								setEndDate(value);
							}}
							minDate={startDate}
							// maxDate={new Date(cycleDates.endDate)}
							maxDate={new Date()}
							disabled={startDate === null || `${startDate}` === 'Invalid Date'}
							renderInput={params => (
								<TextField
									{...params}
									variant="standard"
									placeholder="Data de Término"
									// error={
									// 	`${endDate}` === 'Invalid Date' ||
									// 	isDateBeforeFromAnotherDate(startDate, endDate) ||
									// 	isDateAfterFromAnotherDate(cycleDates.endDate, endDate)
									// }
									error={
										`${endDate}` === 'Invalid Date' ||
										isDateBeforeFromAnotherDate(startDate, endDate)
									}
									helperText={`${endDate}` === 'Invalid Date' ? 'Data Inválida' : ''}
								/>
							)}
						/>
					</LocalizationProvider>
				</DatesContainer>

				{chartData && chartData.length > 1 && (
					<Chart chartType="LineChart" width="100%" height="650px" data={chartData} />
				)}
				{chartData && chartData.length === 1 && (
					<Subtitle
						style={{ marginLeft: '45%', fontSize: '17px', paddingTop: '1rem', paddingBottom: '1rem' }}
					>
						Não há registros
					</Subtitle>
				)}
			</Card>
		</Container>
	);
};
