import styled, { css } from 'styled-components';

interface ButtonNavProps {
	selected: boolean;
}

export const Container = styled.div`
	max-width: 27rem;
	width: 100%;

	box-shadow: var(--elevation-200);
	border-radius: 0.25rem;

	nav {
		display: flex;
		gap: 2rem;

		padding: 0 1.5rem;

		box-shadow: var(--elevation-200);
	}

	@media (max-width: 48rem) {
		display: none;
	}
`;

export const ButtonNav = styled.div<ButtonNavProps>`
	background-color: var(--white);

	padding: 1.5rem 0;

	border: none;

	font: var(--body-lg-400);
	color: var(--gray-400);

	cursor: pointer;

	${props =>
		props.selected &&
		css`
			font: var(--body-lg-500);
			color: var(--terciary-400);

			border-bottom: 4px solid var(--terciary-400);

			transition: all 0.2s;
		`}
`;

export const DataContent = styled.div`
	padding: 2rem 1.5rem;

	form fieldset {
		margin-bottom: 2rem;

		input {
			padding-left: 5.75rem;

			border-color: var(--gray-700);
		}

		label {
			opacity: 1;
			color: var(--gray-700);
		}
	}

	form .MuiAutocomplete-root {
		height: 3rem;

		margin-bottom: 2rem;
	}

	form > button {
		max-width: 100%;

		margin-top: 1rem;
	}
`;

export const Avatar = styled.div`
	display: flex;
	gap: 1rem;

	margin-bottom: 2rem;

	img {
		height: 6.5rem;
		width: 6.5rem;

		border-radius: 50%;
	}

	button {
		background-color: var(--white);

		border: none;

		font: var(--body-sm-400);
		color: var(--blue-400);

		transition: all 0.2s;

		&:hover {
			opacity: 0.8;
		}
	}
`;

export const ProfileContent = styled.ul`
	li {
		display: flex;
		align-items: center;

		height: 4rem;

		padding: 0 1rem;

		border-bottom: 1px solid var(--gray-200);

		list-style: none;

		&:first-child {
			gap: 0.5rem;

			font: var(--body-md-500);
			color: var(--blue-400);
		}

		div {
			color: var(--gray-700);
		}

		& div + span {
			height: 6px;
			width: 6px;
			background: var(--gray-700);

			margin: 0.25rem;

			border-radius: 50%;
		}
	}
`;
