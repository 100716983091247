import styled, { css } from 'styled-components';

export const Container = styled.div`
	overflow: hidden;
	transition: all 0.2s ease-in;

	padding: 0rem 1.25rem 1rem 1.25rem;

	max-width: 20.875rem;
	min-height: 26rem;

	@media (min-width: 1024px) {
		.collapse-btn {
			display: none;
		}
	}
`;

export const Header = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	gap: 0.5rem;

	img {
		width: 6rem;
		height: 6rem;
		border-radius: 50%;

		filter: drop-shadow(var(--elevation-400));
	}
`;

export const FormContainer = styled.div`
	padding-top: 2rem;

	form {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		height: 100%;

		.submit-btn {
			background: var(--terciary-400);
		}

		.cancel-btn {
			background: var(--white);
			border: 2px solid var(--terciary-400);
			color: var(--terciary-400);
			display: block;
		}
	}
`;

export const Button = styled.button`
	max-width: 16rem;
	height: 3rem;
	margin-top: 1rem;

	color: var(--white);
	font-size: 1em;
	font-weight: 500;

	background-color: var(--primary-400);

	${props =>
		props.disabled &&
		css`
			& {
				background-color: var(--gray-300) !important;
				color: var(--gray-400);
				cursor: default;
			}
		`}

	box-shadow: var(--elevation-200);

	border: none;
	border-radius: 4px;
	cursor: pointer;
`;

export const ContainerDoubleInputs = styled.div`
	display: flex;
	gap: 3rem;
	width: 100%;
	flex-direction: column;

	@media (min-width: 1024px) {
		& {
			gap: 24px;
			flex-direction: row;
		}
	}
`;
