import { useCallback, useEffect, useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { FiInfo, FiEdit, FiTrash2 } from 'react-icons/fi';

import { useModal } from 'hooks/useModal';

import { deleteTeamRequest } from 'domains/Teams/services/TeamsServices';

import { Button } from '../Button';

import { ModalButton, ModalButtonSettings, Overlay, Container, Head } from './style';

import { IModalProps } from './IModelProps';

export const Modal: React.FC<IModalProps> = ({
	title,
	buttonContent,
	hasButton,
	openModalValue,
	modalItems,
	hasSettings,
	teamId,
	children,
	handleOpenModalValue,
	size,
}) => {
	const [openModalSettings, setOpenModalSettings] = useState(false);
	const [openModalType, setOpenModalType] = useState('');
	const { handleModalState, isOpen } = useModal();

	const handleOpenModal = useCallback(() => {
		handleModalState();
		if (openModalValue && handleOpenModalValue) handleOpenModalValue();
	}, [isOpen, openModalValue]);

	const handleOpenModalSettings = useCallback(
		(type: string) => {
			setOpenModalSettings(true);

			if (openModalSettings) {
				setOpenModalType('');
				setOpenModalSettings(false);
				handleModalState();

				if (type === 'Ver detalhes') {
					setOpenModalType('Detalhes do time');
				}

				if (type === 'Editar time') {
					setOpenModalType('Editar time');
				}

				if (type === 'Excluir time') {
					if (teamId !== undefined) {
						deleteTeamRequest(teamId);
					}
				}
			}
		},
		[openModalSettings],
	);

	useEffect(() => {
		if (openModalValue) {
			handleOpenModal();
		}
	}, [openModalValue]);

	return (
		<>
			<Overlay
				openModal={isOpen}
				openModalSettings={openModalSettings}
				onClick={() => {
					handleModalState();
					setOpenModalSettings(false);
				}}
			/>

			{hasButton && (
				<ModalButton className="modal-btn" onClick={handleOpenModal}>
					<Button type="button" bgColor="terciary">
						{buttonContent}
					</Button>
				</ModalButton>
			)}

			{hasSettings && (
				<ModalButtonSettings onClick={() => handleOpenModalSettings('')}>
					<span />
					<span />
					<span />
				</ModalButtonSettings>
			)}

			<Container openModalSettings={openModalSettings} openModal={isOpen} size={size}>
				{modalItems !== undefined &&
					openModalSettings &&
					modalItems.map(item => (
						<button onClick={() => handleOpenModalSettings(item.name)} key={item.name}>
							{item.icon === 'FiInfo' && <FiInfo />}
							{item.icon === 'FiEdit' && <FiEdit />}
							{item.icon === 'FiTrash2' && <FiTrash2 />}
							{item.name}
						</button>
					))}
			</Container>

			{isOpen && (
				<Container openModal={isOpen} size={size}>
					<Head openModal={isOpen} openModalSettings={openModalSettings}>
						<h4>{title || openModalType}</h4>

						<MdOutlineClose onClick={handleOpenModal} size={24} color="var(--gray-700)" />
					</Head>

					{children}
				</Container>
			)}
		</>
	);
};
