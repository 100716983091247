import React from 'react';
import NumberFormat from 'react-number-format';
import { formats } from './formats';
import { CustomProps } from './ICustomProps';

export const NumberFormatCustom = React.forwardRef<NumberFormat<null>, CustomProps>((props, ref) => {
	const { onChange, mask, isMoneyInput, ...other } = props;
	const format = formats[mask];

	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			onValueChange={values => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator={'.'}
			decimalSeparator={','}
			format={format?.mask}
			prefix={isMoneyInput ? 'R$ ' : format?.prefix}
			inputMode={isMoneyInput ? 'numeric' : undefined}
		/>
	);
});
