import styled from 'styled-components';

export const OKRsContainer = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	height: 100%;
	max-height: 700px;
	gap: 1.5rem;

	overflow-y: scroll;
	overflow-x: hidden;
`;
