import styled from 'styled-components';

export const Container = styled.div`
	& form {
		margin-top: 1.5rem;

		fieldset {
			height: 3.5rem;

			margin-bottom: 1rem;

			border: none;
		}

		fieldset:last-child {
			margin-bottom: 1.5rem;
		}

		.MuiAutocomplete-root {
			height: 3rem;

			margin-bottom: 2rem;
		}

		button {
			margin-left: auto;
		}
	}
`;

export const MembersList = styled.div`
	margin-top: 2rem;

	& > p {
		font: var(--body-md-400);
		color: var(--gray-500);

		border-bottom: 1px solid var(--gray-200);
	}

	ul {
		margin-top: 2rem;
		margin-bottom: 1.5rem;
		padding: 1rem;

		box-shadow: var(--elevation-200);
		border-radius: 4px;

		li {
			display: flex;
			align-items: center;
			gap: 0.5rem;

			margin-bottom: 1rem;

			img {
				height: 1.5rem;
				width: 1.5rem;
			}

			p {
				font: var(--body-md-400);
				color: var(--gray-700);
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
`;
