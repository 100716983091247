import { Toast } from 'components/Toast';
import React, { createContext, useCallback, useContext, useState } from 'react';
import api from 'services/api';
import uuid from 'uuidv4';

// import { Toast } from '../components/Toast';

export interface ToastMessage {
	id: string;
	type?: 'success' | 'error';
	description: string;
	close: boolean;
}

interface ToastContextData {
	addToast(message: Omit<ToastMessage, 'id'>): void;
	removeToast(id: string): void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC = ({ children }) => {
	const [messages, setMessages] = useState<ToastMessage[]>([]);

	const addToast = useCallback(({ type, description, close }: Omit<ToastMessage, 'id'>) => {
		setMessages([]);

		const id = uuid();

		const toast = {
			id,
			type,
			description,
			close,
		};

		setMessages(state => [...state, toast]);
	}, []);

	const removeToast = useCallback((id: string) => {
		setMessages(state => state.filter(message => message.id !== id));
	}, []);

	api.interceptors.response.use(response => {
		if (response) {
			const { method, url } = response.config;
			const { data } = response;
			const urlSplited = url?.split('/');
			const isUpdateTaskRequest =
				urlSplited?.includes('key-results') && urlSplited?.includes('tasks') && method === 'put';

			if (method !== 'get' && method !== 'options' && !isUpdateTaskRequest) {
				addToast({
					type: data.hasError ? 'error' : 'success',
					description: data.message,
					close: true,
				});
				return response;
			}
		}
		return response;
	});

	return (
		<ToastContext.Provider value={{ addToast, removeToast }}>
			{children}
			<Toast messages={messages} />
		</ToastContext.Provider>
	);
};

function useToast(): ToastContextData {
	const context = useContext(ToastContext);

	if (!context) {
		throw new Error('useToast must be used within a ToastProvider');
	}

	return context;
}

export { ToastProvider, useToast };
