import { useEffect, useState } from 'react';
import { useFormNewKeyResults } from 'domains/Objectives/contexts/FormNewKeyResults';
import { useFormEditKeyResults } from 'domains/Objectives/contexts/FormEditKeyResults';
import { keyResultFinalitysOptions } from 'domains/Objectives/utils/keyResultFinalitysOptions';

import { ReactComponent as GrowthIcon } from 'domains/Objectives/assets/growth.svg';
import { ReactComponent as GrowthDisabledIcon } from 'domains/Objectives/assets/growthDisabled.svg';
import { ReactComponent as CheckIcon } from 'domains/Objectives/assets/check.svg';
import { ReactComponent as CheckDisabledIcon } from 'domains/Objectives/assets/checkDisabled.svg';

import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ptBrLocale from 'date-fns/locale/pt-BR';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { Input } from 'components/Input';
import { Autocomplete, TextField } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { measureOptions } from 'domains/Objectives/utils/measureOptions';
import { EntityWithIdAndLabel } from 'domains/Objectives/interfaces/FormNewObjectivesData';

import { ItemDescription, ItemTitle, SectionTitle } from 'styles/globalStyledComponents';
import { ButtonsContainer, FormItem } from '../../style';

interface IMeasurementFormProps {
	handleNextStep: () => void;
	handleBackStep: () => void;
	isEditForm: boolean;
}

export const MeasurementForm = ({ handleNextStep, handleBackStep, isEditForm }: IMeasurementFormProps) => {
	const { formData, handleFormData, selectedOptionsForm, handleSelectedOptionsForm } = isEditForm
		? useFormEditKeyResults()
		: useFormNewKeyResults();

	const [keyResultFinality, setKeyResultFinality] = useState(
		formData.polarity.type || keyResultFinalitysOptions.Aumentar,
	);
	const [weight, setWeight] = useState(formData.weight);
	const [weightDescriptionCount, setWeightDescriptionCount] = useState(10 - weight);
	const [deliveryDate, setDeliveryDate] = useState<Date | null>(new Date(formData.deliveryDate));
	const [isFormDataValid, setIsFormDataValid] = useState(false);
	const [isInvalidDate, setIsInvalidDate] = useState(false);
	const [initialValue, setInitialValue] = useState(formData.polarity.initialValue);
	const [finalValue, setFinalValue] = useState(formData.polarity.completedValue);
	const [valueToKeep, setValueToKeep] = useState(formData.polarity.valueTypeTokeep);
	const [measure, setMesuare] = useState<EntityWithIdAndLabel | null>(selectedOptionsForm.medida);

	const handleChangeKeyResultFinality = (_: React.MouseEvent<HTMLElement>, nextLevel: number) => {
		setKeyResultFinality(nextLevel);
	};

	const handleObjectiveWeight = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number(event.target.value);

		if (value < 1) {
			setWeight(1);
			setWeightDescriptionCount(9);
		} else if (value <= 10) {
			setWeight(value);
			setWeightDescriptionCount(10 - value);
		} else {
			setWeight(10);
			setWeightDescriptionCount(0);
		}
	};

	const handleSubmitValues = () => {
		const deliveryDateValid = keyResultFinality === keyResultFinalitysOptions.Entregar ? `${deliveryDate}` : '';
		const measureData = measure ? { id: measure?.id, name: measure?.label } : { id: '', name: '' };

		handleFormData({
			...formData,
			weight,
			measure: measureData,
			polarity: {
				...formData.polarity,
				type: keyResultFinality,
				initialValue,
				completedValue: finalValue,
				valueTypeTokeep: valueToKeep,
			},
			deliveryDate: deliveryDateValid,
		});
		handleSelectedOptionsForm({
			...selectedOptionsForm,
			medida: measure,
		});
		handleNextStep();
	};

	useEffect(() => {
		if (keyResultFinality === keyResultFinalitysOptions.Entregar) {
			if (`${deliveryDate}` !== 'Invalid Date') {
				setIsFormDataValid(true);
			} else setIsFormDataValid(false);
		} else if (keyResultFinality === keyResultFinalitysOptions.Manter) {
			if (measure !== null) {
				setIsFormDataValid(true);
			} else setIsFormDataValid(false);
		}
		if (
			keyResultFinality === keyResultFinalitysOptions.Aumentar ||
			keyResultFinality === keyResultFinalitysOptions.Reduzir
		) {
			if (initialValue && finalValue && measure !== null) {
				setIsFormDataValid(true);
			} else setIsFormDataValid(false);
		}
	}, [initialValue, finalValue, measure, weight, deliveryDate, keyResultFinality]);

	useEffect(() => {
		const isFinalDateValid =
			`${deliveryDate}` !== 'Invalid Date' && deliveryDate !== null && deliveryDate >= new Date();
		if (!isFinalDateValid && deliveryDate !== null) {
			setIsInvalidDate(true);
		} else setIsInvalidDate(false);
	}, [deliveryDate]);

	return (
		<>
			<SectionTitle>Medição</SectionTitle>

			<ItemTitle>Finalidade</ItemTitle>

			<ToggleButtonGroup
				orientation="horizontal"
				value={keyResultFinality}
				exclusive
				onChange={handleChangeKeyResultFinality}
				color="secondary"
				className="button-group"
				size="large"
			>
				<ToggleButton value={keyResultFinalitysOptions.Aumentar} aria-label="Individuo">
					<div>
						{keyResultFinality === keyResultFinalitysOptions.Aumentar && <GrowthIcon />}
						{keyResultFinality !== keyResultFinalitysOptions.Aumentar && (
							<GrowthDisabledIcon className="rotate-180" />
						)}
						<p>Aumentar</p>
					</div>
				</ToggleButton>
				<ToggleButton value={keyResultFinalitysOptions.Reduzir} aria-label="Time">
					<div>
						{keyResultFinality === keyResultFinalitysOptions.Reduzir && (
							<GrowthIcon className="rotate-180" />
						)}
						{keyResultFinality !== keyResultFinalitysOptions.Reduzir && <GrowthDisabledIcon />}
						<p>Reduzir</p>
					</div>
				</ToggleButton>
				<ToggleButton value={keyResultFinalitysOptions.Manter} aria-label="Empresa">
					<div>
						{keyResultFinality === keyResultFinalitysOptions.Manter && <GrowthIcon className="rotate-90" />}
						{keyResultFinality !== keyResultFinalitysOptions.Manter && (
							<GrowthDisabledIcon className="rotate-270" />
						)}
						<p>Manter</p>
					</div>
				</ToggleButton>
				<ToggleButton value={keyResultFinalitysOptions.Entregar} aria-label="Empresa">
					<div>
						{keyResultFinality === keyResultFinalitysOptions.Entregar && <CheckIcon />}
						{keyResultFinality !== keyResultFinalitysOptions.Entregar && <CheckDisabledIcon />}
						<p>Entregar</p>
					</div>
				</ToggleButton>
			</ToggleButtonGroup>

			{(keyResultFinality === keyResultFinalitysOptions.Aumentar ||
				keyResultFinality === keyResultFinalitysOptions.Reduzir) && (
				<div style={{ display: 'flex', gap: '1.5rem' }}>
					<Input
						placeholder="Valor Inicial"
						required
						value={initialValue}
						name="initialValue"
						label="Valor Inicial"
						isMoneyInput
						onChange={e => setInitialValue(Number(e.target.value))}
					/>

					<Input
						placeholder="Valor Final"
						required
						value={finalValue}
						name="finalValue"
						label="Valor Final"
						isMoneyInput
						onChange={e => setFinalValue(Number(e.target.value))}
					/>
				</div>
			)}

			{keyResultFinality === keyResultFinalitysOptions.Manter && (
				<Input
					placeholder="Valor a ser mantido"
					required
					value={valueToKeep}
					name="valueToKeep"
					label="Valor a ser mantido"
					isMoneyInput
					onChange={e => setValueToKeep(Number(e.target.value))}
				/>
			)}

			{keyResultFinality !== keyResultFinalitysOptions.Entregar && (
				<Autocomplete
					options={measureOptions}
					value={measure}
					onChange={(_, value) => {
						setMesuare(value);
					}}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					renderInput={params => (
						<TextField
							{...params}
							label="Medida"
							variant="standard"
							required
							style={{ marginTop: '1.5rem' }}
						/>
					)}
				/>
			)}

			<FormItem>
				<Input
					placeholder="Objetivo"
					required
					value={weight}
					name="name"
					type="number"
					label="Peso"
					onChange={e => handleObjectiveWeight(e)}
					min={1}
					max={10}
					className="weight-input"
				/>
				<ItemDescription>
					<b>{weightDescriptionCount} pontos restantes.</b> O peso de um Key Result compara a sua importância
					perante outros. Quanto maior o peso do Key Result, maior é seu impacto no objetivo.
				</ItemDescription>
			</FormItem>

			{keyResultFinality === keyResultFinalitysOptions.Entregar && (
				<LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBrLocale}>
					<DatePicker
						label="Data de Entrega"
						value={deliveryDate}
						onChange={value => {
							setDeliveryDate(value);
						}}
						minDate={new Date()}
						renderInput={params => (
							<TextField
								{...params}
								variant="standard"
								placeholder="Data de Entrega"
								error={isInvalidDate}
								helperText={isInvalidDate ? 'Data Inválida' : ''}
								style={{ width: '100%', marginBottom: '2rem' }}
								required
							/>
						)}
					/>
				</LocalizationProvider>
			)}

			<ButtonsContainer>
				<button type="button" className="back-btn" onClick={() => handleBackStep()}>
					Voltar
				</button>
				<button
					disabled={!isFormDataValid}
					type="button"
					className="continue-btn"
					onClick={() => handleSubmitValues()}
				>
					Continuar
				</button>
			</ButtonsContainer>
		</>
	);
};
