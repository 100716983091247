import { Link } from 'react-router-dom';

import { Button } from 'components/Button';
import { Card } from 'components/Card';

import { Container } from './style';

export const Logout: React.FC = () => (
	<Container>
		<Card title="Deslogar do sistema">
			<Link
				to="/"
				onClick={() => {
					localStorage.clear();
				}}
			>
				<Button bgColor="red-400">Sair</Button>
			</Link>
		</Card>
	</Container>
);
