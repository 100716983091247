import styled from 'styled-components';

export const ItemTitle = styled.h2`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 135%;
	color: var(--secondary-400);
`;

export const Text = styled.p`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 135%;

	color: var(--gray-700);
`;

export const Subtitle = styled.h2`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 135%;

	color: var(--secondary-400);
`;
