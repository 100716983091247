import { useCallback, useRef, useState } from 'react';
import { FaEye, FaEyeSlash, FaSearch } from 'react-icons/fa';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { NumberFormatCustom } from 'components/InputMask/NumberMaskCustom';
import { Container } from './style';
import { IInputProps } from './IInputProps';

export const Input = ({
	name,
	label,
	disabled,
	type,
	required,
	placeholder,
	mask,
	value,
	error,
	helperText,
	search,
	className,
	max,
	min,
	onChange,
	InputProps,
	isMoneyInput,
}: IInputProps) => {
	const [visible, setVisible] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const handleButton = useCallback(() => {
		setVisible(!visible);
	}, [visible]);

	return (
		<Container>
			{(mask || isMoneyInput) && (
				<TextField
					disabled={disabled}
					name={name}
					required={required}
					placeholder={placeholder}
					error={error}
					helperText={helperText}
					variant="standard"
					type={type}
					label={label}
					ref={inputRef}
					value={value}
					inputProps={mask ? { mask } : { isMoneyInput }}
					InputProps={{
						// eslint-disable-next-line
						inputComponent: NumberFormatCustom as any,
					}}
					onChange={onChange}
					className={className}
				/>
			)}

			{!mask && !isMoneyInput && name !== 'password' && (
				<TextField
					disabled={disabled}
					name={name}
					required={required}
					placeholder={placeholder}
					error={error}
					variant="standard"
					type={type}
					label={label}
					value={value}
					helperText={helperText}
					InputProps={{
						...InputProps,
						endAdornment: search ? <FaSearch size="14" color="var(--gray-700)" /> : undefined,
					}}
					onChange={onChange}
					className={className}
					inputProps={{ min, max }}
				/>
			)}

			{name === 'password' && (
				<TextField
					disabled={disabled}
					name={name}
					required={required}
					placeholder={placeholder}
					error={error}
					variant="standard"
					type={visible ? 'text' : 'password'}
					label={label}
					value={value}
					helperText={helperText}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleButton}
									edge="end"
									style={{ margin: '0' }}
								>
									{visible ? <FaEyeSlash /> : <FaEye />}
								</IconButton>
							</InputAdornment>
						),
					}}
					onChange={onChange}
					className={className}
				/>
			)}
		</Container>
	);
};
