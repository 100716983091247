import dayjs from 'dayjs';
import { IKeyResult } from 'domains/Reports/Interfaces/IOkrMonitoringData';
import { Progress, TableContainer } from './style';

interface KrTableProps {
	keyResults: IKeyResult[];
}

export const KrTable = ({ keyResults }: KrTableProps) => (
	<TableContainer className="table-container">
		<table>
			<tbody>
				<tr className="table-header">
					<th>Key Results</th>
					<th>Status</th>
					<th>Data</th>
					<th>Progresso</th>
				</tr>

				{keyResults.map(keyResult => (
					<tr key={keyResult.id}>
						<th>{keyResult.description}</th>
						<th>{keyResult.indicator.name}</th>
						<th>{dayjs(keyResult.indicator.createdAt || keyResult.date).format('DD-MM-YYYY')}</th>
						<th>
							<Progress progress={keyResult.progress}>
								<div>
									<span />
								</div>
								{keyResult.progress}%
							</Progress>
						</th>
					</tr>
				))}
			</tbody>
		</table>
	</TableContainer>
);
