import { KeyResultMonitoring } from '../KeyResultMonitoring';
import { OkrsConclusion } from '../OkrsConclusion';
import { OKRsProgress } from '../OKRsProgress';
import { PieCharts } from '../PieCharts';
import { Section } from './style';

export const UserPageView = () => (
	<Section>
		<OKRsProgress titles={['OKRs Criadas', 'Progresso das OKRs']} pageView="user" />
		<PieCharts pageView="user" />
		<KeyResultMonitoring pageView="user" />
		<OkrsConclusion />
	</Section>
);
