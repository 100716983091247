import React, { useState } from 'react';
import { Formik } from 'formik';
import { Input } from 'components/Input';

import { InfoPersonProps, IPersonDataV3 } from '../../interfaces/IPersonData';

import { Container } from './style';

export const InfoPerson: React.FC<InfoPersonProps> = ({ person }) => {
	const [formData] = useState<IPersonDataV3>(() => ({
		id: person.id,
		image: person.image,
		companyRegister: person.companyRegister,
		email: person.email,
		isAdmin: person.isAdmin,
		phone: person.phone,
		person: {
			birthday: person.person.birthday,
			cpf: person.person.cpf,
			gender: person.person.gender,
			id: person.person.id,
			name: person.person.name,
			passport: person.person.passport,
		},
	}));

	return (
		<Container>
			<Formik
				initialValues={formData}
				onSubmit={() => {
					console.log('Sucesso');
				}}
			>
				{props => (
					<form
						onSubmit={e => {
							e.preventDefault();
							props.handleSubmit(e);
						}}
					>
						<Input name="person.name" placeholder="" label="Nome" value={props.values.person.name} />

						<Input name="email" placeholder="" label="Email" value={props.values.email} />

						<Input name="person.cpf" placeholder="" label="CPF" value={props.values.person.cpf} />

						<Input
							name="companyRegister"
							placeholder=""
							label="Matrícula"
							value={props.values.companyRegister}
						/>

						<Input name="phones" placeholder="" label="Telefone" value={props.values.phone} />
					</form>
				)}
			</Formik>
		</Container>
	);
};
