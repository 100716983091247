import { useEffect, useState } from 'react';
import { MdAddCircleOutline } from 'react-icons/md';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { putNewPersonsRequest, deletePersonsRequest } from 'domains/Persons/services/PersonsService';

import { Input } from 'components/Input';
import { Button } from 'components/Button';

// import { AutocompleteComponent } from 'components/Autocomplete';

import { IFormPersonUpdateData, IPersonDataV3 } from '../../interfaces/IPersonData';
import { Container, ButtonNav, DataContent, Avatar, ProfileContent } from './style';

import { IListPersons } from '../../interfaces/IListPersons';

export const Profile: React.FC<IListPersons> = ({ listPerson }) => {
	const [navSelect, setNavSelect] = useState('dataContent');
	const [selected] = useState(true);

	const [formData, setFormData] = useState<IPersonDataV3>({
		id: listPerson[0]?.id,
		isAdmin: listPerson[0]?.isAdmin,
		companyRegister: listPerson[0]?.companyRegister,
		email: listPerson[0]?.email,
		image: listPerson[0]?.image,
		person: {
			name: listPerson[0]?.person?.name,
			birthday: listPerson[0]?.person?.birthday,
			cpf: listPerson[0]?.person?.cpf,
			gender: listPerson[0]?.person?.gender,
			id: listPerson[0]?.person?.id,
			passport: listPerson[0]?.person?.passport,
		},
		phone: listPerson[0]?.phone,
	});

	const validationSchema = Yup.object().shape({
		phone: Yup.string().min(11, 'Insira um telefone válido').required(),
	});

	const handleSubmit = async (values: IFormPersonUpdateData) => {
		await putNewPersonsRequest(values);
	};

	const handleDelete = async (values: IFormPersonUpdateData) => {
		await deletePersonsRequest(values);
	};

	useEffect(() => {
		setFormData(listPerson[0]);
	}, [listPerson]);

	return (
		<Container>
			<nav>
				<ButtonNav
					onClick={() => {
						setNavSelect('dataContent');
					}}
					selected={navSelect === 'dataContent' ? selected : !selected}
				>
					Informações
				</ButtonNav>

				{/* <ButtonNav
					onClick={() => setNavSelect('profileContent')}
					selected={navSelect === 'profileContent' ? selected : !selected}
				>
					Perfil
				</ButtonNav> */}
			</nav>

			{navSelect === 'dataContent' && (
				<DataContent>
					<Avatar>
						{listPerson.length > 0 && <img src={listPerson[0].image} alt="Fake" />}

						<button>Alterar avatar</button>
					</Avatar>

					{listPerson.length > 0 && formData && (
						<Formik
							enableReinitialize
							initialValues={formData}
							validationSchema={validationSchema}
							onSubmit={async (values: IFormPersonUpdateData) => {
								handleSubmit(values);
							}}
						>
							{props => (
								<form
									onSubmit={e => {
										e.preventDefault();
										props.handleSubmit(e);
									}}
								>
									<Input
										name="person.name"
										label="Nome"
										placeholder=""
										required
										value={props.values.person?.name}
										onChange={props.handleChange}
									/>
									{/* <Input
										name="companyId"
										label="Empresa"
										required
										placeholder=""
										value={props.values.companyId}
										onChange={props.handleChange}
									/> */}
									{/* <Input
										name="managerId"
										label="Líder"
										required
										placeholder=""
										value={props.values.managerId}
										onChange={props.handleChange}
									/> */}
									{/* <Input
										name="areaId"
										label="Cargo"
										required
										placeholder=""
										value={props.values.areaId}
										type="text"
										onChange={props.handleChange}
									/> */}
									{/* <AutocompleteComponent
										name="team"
										label="Times"
										required
										defaultValue={props.values.sectorId}
										value={props.values.sectorId}
										options={[props.values.sectorId]}
										onChange={() => {
											console.log('teste');
										}}
									/> */}
									<Input
										name="email"
										label="Email"
										required
										placeholder=""
										value={props.values.email}
										type="email"
										onChange={props.handleChange}
									/>

									<Input
										name="companyRegister"
										placeholder=""
										label="Matrícula"
										value={props.values.companyRegister}
										onChange={props.handleChange}
									/>

									<Input
										name="person.cpf"
										placeholder=""
										label="CPF"
										mask="cpf"
										value={props.values.person.cpf}
										onChange={props.handleChange}
									/>

									<Input
										name="phone"
										label="Telefone"
										mask="telephone"
										placeholder="Telefone"
										error={props.touched.phone && Boolean(props.errors.phone)}
										helperText={
											props.touched.phone && props.errors.phone ? props.errors.phone : undefined
										}
										required
										value={props?.values.phone}
										onChange={props.handleChange}
									/>

									<Button type="submit" bgColor="primary" disabled={!props.dirty}>
										Salvar Alterações
									</Button>
								</form>
							)}
						</Formik>
					)}

					<Formik
						initialValues={formData}
						onSubmit={async (values: IFormPersonUpdateData) => {
							handleDelete(values);
						}}
					>
						{props => (
							<form
								onSubmit={e => {
									e.preventDefault();
									props.handleSubmit(e);
								}}
							>
								<Button type="submit" bgColor="red-400">
									Remover Usuário
								</Button>
							</form>
						)}
					</Formik>
				</DataContent>
			)}

			{navSelect === 'profileContent' && (
				<ProfileContent>
					<li>
						<MdAddCircleOutline color="var(--blue-400)" size="28" />

						<span>Adicionar perfil</span>
					</li>
				</ProfileContent>
			)}
		</Container>
	);
};
