import { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

interface ContainerProps {
	heightState: string;
	heightStateMobile: string;
	collapseState: boolean;
	isCollapsed: boolean;
}

interface HeadProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	collapseState: boolean;
	isCollapsed: boolean;
}

interface ContentProps {
	collapseState: boolean;
	isCollapsed: boolean;
}

interface ButtonCollapseProps {
	collapseState: boolean;
}

export const Container = styled.div<ContainerProps>`
	width: 100%;
	max-height: ${props => props.isCollapsed && props.heightState};

	margin-top: 1rem;
	padding: 1rem;

	border: 1px solid var(--gray-200);
	border-radius: 0.25rem;

	overflow: hidden;
	transition: all 0.2s ease-in;

	& > button {
		display: flex;
		align-items: center;
		gap: 0.75rem;

		padding-top: 1rem;
		background-color: transparent;

		border: none;

		font: var(--body-sm-500);
		color: var(--blue-400);
	}

	@media (max-width: 48rem) {
		max-height: ${props => props.isCollapsed && props.heightStateMobile};
		position: relative;
	}
`;

export const Head = styled.div<HeadProps>`
	display: flex;

	padding-bottom: 1rem;

	border-bottom: 1px solid var(--gray-200);

	@media (max-width: 48rem) {
		flex-direction: column;
	}
`;

export const Title = styled.div`
	h3 {
		margin-bottom: 0.5rem;

		font: var(--body-lg-500);
		color: var(--secondary-400);
	}

	p {
		font: var(--body-sm-400);
		color: var(--gray-700);
	}

	@media (max-width: 48rem) {
		p {
			margin-bottom: 1rem;
		}
	}
`;

interface ProgressProps {
	progress: number;
}

export const Progress = styled.div<ProgressProps>`
	display: flex;
	align-items: center;
	gap: 1rem;

	height: 2rem;
	max-width: 18rem;
	width: 100%;

	margin-left: auto;

	font: var(--body-md-500);

	div {
		height: 4px;
		width: 100%;

		background-color: var(--gray-200);

		border-radius: 200px;

		span {
			display: block;

			height: 100%;
			width: ${props => props.progress}%;

			border-radius: 200px;
		}
	}
	${props =>
		props.progress >= 0 &&
		css`
			color: var(--red-400);

			div span {
				background-color: var(--red-400);
			}
		`}

	${props =>
		props.progress >= 50 &&
		css`
			color: var(--yellow-400);

			div span {
				background-color: var(--yellow-400);
			}
		`}

	${props =>
		props.progress >= 80 &&
		css`
			color: var(--green-400);

			div span {
				background-color: var(--green-400);
			}
		`}

	@media(max-width: 48rem) {
		margin-left: 0;
	}
`;

export const Info = styled.div`
	display: flex;
	align-items: center;
	gap: 3rem;

	padding-top: 1rem;
	padding-bottom: 1rem;

	border-bottom: 1px solid var(--gray-200);

	@media (max-width: 48rem) {
		flex-direction: column;
		align-items: flex-start;
		gap: 0.5rem;
	}
`;

export const Weight = styled.div`
	p {
		font: var(--body-sm-400);
		color: var(--gray-700);

		span {
			color: var(--gray-500);
		}
	}
`;

export const Responsible = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;

	p {
		font: var(--body-sm-400);
		color: var(--gray-700);
	}

	img {
		height: 1.5rem;
		width: 1.5rem;
	}

	span {
		font: var(--body-xs-400);
		color: var(--gray-500);
	}
`;

export const Contributors = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;

	p {
		font: var(--body-sm-400);
		color: var(--gray-700);
	}

	div {
		display: flex;

		img {
			height: 1.5rem;
			width: 1.5rem;

			& + img {
				margin-left: -6px;
			}
		}

		span {
			display: flex;
			align-items: center;
			justify-content: center;

			height: 1.5rem;
			width: 1.5rem;

			margin-left: -6px;

			background-color: var(--secondary-400);

			border-radius: 50%;

			font: var(--body-sm-400);
			color: var(--white);
		}
	}

	@media (max-width: 48rem) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const FinalDate = styled.div`
	margin-left: auto;

	font: var(--body-sm-500);
	color: var(--gray-500);

	span {
		font-weight: 400;
	}

	@media (max-width: 48rem) {
		display: none;
	}
`;

export const Content = styled.div<ContentProps>`
	opacity: 0;

	& > h4 {
		margin: 1rem 0;

		font: var(--body-md-500);
		color: var(--secondary-400);
	}

	${props =>
		props.collapseState &&
		css`
			opacity: 1;
		`}
`;

export const ButtonCollapse = styled.button<ButtonCollapseProps>`
	display: flex;
	align-items: center;
	justify-content: center;

	background-color: transparent;
	border: none;

	transition: all 0.2s ease-in;

	svg {
		transform: ${props => props.collapseState && 'rotate(180deg)'};
		transition: all 0.4s ease-in;
	}
`;
