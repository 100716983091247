import { useState, useEffect, useRef } from 'react';
import { FaChevronDown } from 'react-icons/fa';

import api from 'services/api';

import { formatDateNormal } from 'domains/Objectives/utils/formatDates';
import { IPersonData } from 'domains/Persons/interfaces/IPersonData';

import { InfoModal } from '../InfoModal';
import { CardKRs } from '../CardKRs';

import {
	Container,
	Head,
	Title,
	Progress,
	Info,
	Weight,
	Responsible,
	Contributors,
	FinalDate,
	Content,
	ButtonCollapse,
} from './style';

import { IInfoObjectiveProps, ICardKRsProps } from '../../interfaces/IObjectiveData';

export const CardObjectives: React.FC<IInfoObjectiveProps> = ({ objective }) => {
	const [responsible, setResponsible] = useState<IPersonData>({} as IPersonData);
	const [usersTeam, setUsersTeam] = useState<IPersonData[]>([]);
	const [collapseState, setCollapseState] = useState(false);
	const [heightState, setHeightState] = useState('');
	const [heightStateMobile, setHeightStateMobile] = useState('');
	const [krs, setKrs] = useState<ICardKRsProps[]>([]);

	const content = useRef<HTMLDivElement>(null);

	const heightElement = Number(content.current?.scrollHeight) / 16;

	const modalItems = [
		{
			hasModal: true,
			title: 'Adicionar Key Result',
			name: 'Adicionar Key Result',
			icon: 'FiPlus',
		},
		{
			hasModal: true,
			title: 'Detalhes do time',
			name: 'Ver detalhes',
			icon: 'FiInfo',
		},
		{
			hasModal: true,
			title: 'Editar Objetivo',
			name: 'Editar Objetivo',
			icon: 'FiEdit',
		},
		{
			hasModal: false,
			title: 'Duplicar Objetivo',
			name: 'Duplicar Objetivo',
			icon: 'HiDuplicate',
		},
		{
			hasModal: false,
			title: 'Excluir Objetivo',
			name: 'Excluir Objetivo',
			icon: 'FiTrash2',
		},
	];

	useEffect(() => {
		api.get(`users/${objective.responsableId}`).then(response => {
			setResponsible(response.data.data);
		});

		if (objective.teams !== undefined) {
			objective.teams.map(team =>
				team.members.map(member =>
					api.get(`users/${member.id}`).then(response => {
						setUsersTeam([...usersTeam, response.data.data]);
					}),
				),
			);
		}
	}, [objective.responsableId]);

	useEffect(() => {
		setHeightState(collapseState ? `${heightElement}rem` : '12rem');
		setHeightStateMobile(collapseState ? `${heightElement}rem` : '21rem');
	}, [collapseState]);

	useEffect(() => {
		api.get(`objectives/${objective.id}/key-results`).then(response => {
			setKrs(response.data.data);
		});
	}, []);

	return (
		<Container
			ref={content}
			isCollapsed={!!true}
			collapseState={collapseState}
			heightState={heightState}
			heightStateMobile={heightStateMobile}
		>
			<Head isCollapsed={!!true} collapseState={collapseState}>
				<Title>
					<h3>Objetivo</h3>
					<p>{objective.name}</p>
				</Title>

				<Progress progress={objective.progress}>
					<div>
						<span />
					</div>

					{`${objective.progress}%`}
				</Progress>

				<InfoModal modalItems={modalItems} objective={objective} />
			</Head>

			<Info>
				<Weight>
					<p>
						Peso: <span>{objective.weight}</span>
					</p>
				</Weight>

				{responsible.person !== undefined && (
					<Responsible>
						<p>Gestor: </p>

						<img src={responsible.image} alt="Avatar fake" />

						<span>{responsible.person.name}</span>
					</Responsible>
				)}

				{usersTeam !== undefined && objective.teams !== undefined && (
					<Contributors>
						<p>Contribuintes: </p>

						{objective.teams.map(team => (
							<div key={team.id}>
								{usersTeam.map(user => (
									<img key={user.id} src={user.image} alt="Avatar fake" />
								))}
							</div>
						))}
					</Contributors>
				)}

				<FinalDate>
					<p>
						Data Final: <span>{formatDateNormal(objective.date)}</span>
					</p>
				</FinalDate>
			</Info>

			<ButtonCollapse collapseState={collapseState} onClick={() => setCollapseState(!collapseState)}>
				{collapseState ? 'Recolher Key Results deste objetivo ' : 'Expandir Key Results deste objetivo '}
				<FaChevronDown />
			</ButtonCollapse>

			<Content isCollapsed={!!true} collapseState={collapseState}>
				<h4>Key Results</h4>

				{krs.map(kr => (
					<CardKRs key={kr.id} kr={kr} objectiveId={objective.id} />
				))}
			</Content>
		</Container>
	);
};
