import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Header } from 'components/Header';
import { Menu } from 'components/Menu';

import { Login } from 'domains/Authentication/pages/Login';
import { ChangePassword } from 'domains/Authentication/pages/ChangePassword';
import { RecoveryPassword } from 'domains/Authentication/pages/RecoveryPassword';

import { PrivateRoute } from './PrivateRoute';
import InternalRoutes from './InternalRoutes';

export const RoutesApp: React.FC = () => (
	<Routes>
		<Route path="/" element={<Login />} />
		<Route path="recovery-password" element={<RecoveryPassword />} />
		<Route path="change-password" element={<ChangePassword />} />

		{InternalRoutes.map(({ path, element }) => (
			<Route
				path={path}
				element={
					<PrivateRoute>
						<Header />
						<div style={{ display: 'flex' }}>
							<Menu />
							<div style={{ minWidth: '80vw', width: '100%' }}>{element}</div>
						</div>
					</PrivateRoute>
				}
				key={path}
			/>
		))}
	</Routes>
);
