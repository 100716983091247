import styled, { keyframes } from 'styled-components';

import bgLogin from '../../assets/bg-login.png';

export const Background = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	height: 100vh;
	width: 100vw;
	background: url(${bgLogin}) no-repeat center;

	padding: 0 1rem;
`;

const appearFromLeft = keyframes`
	from {
		opacity: 0;
		transform: translateX(-3rem);
	}

	to {
		opacity: 1;
		transform: translateX(0);
	}
`;

export const Container = styled.div`
	max-width: 34rem;
	width: 100%;
	background: var(--white);

	padding: 2rem 7.5rem 3rem;

	border-radius: 0.5rem;
	box-shadow: var(--elevation-800);

	text-align: center;
	color: var(--gray-700);

	transition: all 0.2s ease-in-out;
	animation: ${appearFromLeft} 1s;

	svg {
		margin: 0 auto 0.5rem;
	}

	fieldset {
		height: 3.5rem;

		margin-bottom: 1rem;

		text-align: left;
	}

	button {
		margin: 0 auto 2rem;
	}

	a {
		display: block;

		text-align: center;
		font: var(--body-sm-400);
		color: var(--blue-400);
	}

	@media (max-width: 512px) {
		padding: 1rem 3rem 2rem;

		button {
			margin-bottom: 1.5rem;
		}
	}
`;
