import styled from 'styled-components';

interface ContainerProps {
	widthState: string;
	collapseState: boolean;
}

interface UserProps {
	collapseState: boolean;
}

interface NavProps {
	collapseState: boolean;
}

interface ButtonCollapseProps {
	collapseState: boolean;
}

export const Container = styled.div<ContainerProps>`
	grid-area: menu;

	position: relative;

	height: auto;
	max-width: ${props => props.widthState};
	padding: ${props => (props.collapseState ? '1.5rem 1rem' : '1.5rem 1rem 1.5rem 1.5rem')};

	background-color: var(--white);
	box-shadow: var(--elevation-800);

	transition: all 0.2s ease-in;

	@media (max-width: 48rem) {
		display: none;
	}

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: var(--gray-200);
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--gray-400);
		border-radius: 4px;
	}
`;

export const User = styled.div<UserProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;

	img {
		max-width: ${props => (props.collapseState ? '2.5rem' : '4.5rem')};

		margin-top: ${props => props.collapseState && '4.5rem'};

		transition: all 0.2s ease-in;
	}

	strong {
		display: ${props => props.collapseState && 'none'};

		font-family: 'Fira Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 135%;
		color: var(--gray-700);
		transition: all 0.2s ease-in;
		overflow-wrap: break-word;
		inline-size: 190px;
		text-align: center;
	}

	p {
		font-family: 'Fira Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 135%;
		color: var(--gray-500);

		margin-bottom: -15px;
		display: ${props => props.collapseState && 'none'};
	}

	button {
		margin-bottom: 10px;
		display: ${props => props.collapseState && 'none'};
	}
`;

export const Nav = styled.nav<NavProps>`
	li {
		list-style: none;
		transition: all 0.2s ease-in;

		a {
			display: flex;
			align-items: center;
			justify-content: ${props => (props.collapseState ? 'center' : 'space-between')};

			padding: 1rem 0;

			svg:first-child {
				margin-right: ${props => (props.collapseState ? '0' : '0.75rem')};
			}

			svg:last-child {
				display: ${props => props.collapseState && 'none'};
			}

			p {
				display: ${props => props.collapseState && 'none'};
				margin-right: auto;

				font: var(--body-md-400);
				color: var(--gray-700);
			}
		}
	}
`;

export const ButtonCollapse = styled.button<ButtonCollapseProps>`
	position: absolute;
	z-index: 10;
	top: ${props => (props.collapseState ? '1.5rem' : '2.5rem')};
	left: ${props => (props.collapseState ? '1rem' : '13.75rem')};

	display: flex;
	align-items: center;
	justify-content: center;

	height: 2.5rem;
	width: 2.5rem;
	background-color: var(--secondary-400);

	border: none;
	border-radius: 50%;

	transition: all 0.2s ease-in;

	svg {
		transform: ${props => props.collapseState && 'rotate(180deg)'};
		transition: all 0.2s ease-in;
	}
`;
