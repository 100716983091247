import { useEffect, useState } from 'react';
import { useFormNewKeyResults } from 'domains/Objectives/contexts/FormNewKeyResults';
import { useFormEditKeyResults } from 'domains/Objectives/contexts/FormEditKeyResults';

import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ptBrLocale from 'date-fns/locale/pt-BR';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { TextField } from '@mui/material';
import { Button } from 'components/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { ItemDescription, ItemTitle, SectionTitle } from 'styles/globalStyledComponents';
import { isDateBeforeFromAnotherDate, isDateBeforeFromToday } from 'domains/Objectives/utils/formatDates';
import { FormItem } from '../../style';

interface IAboutFormProps {
	handleNextStep: () => void;
	isEditForm: boolean;
}

export const AboutForm = ({ handleNextStep, isEditForm }: IAboutFormProps) => {
	const { formData, handleFormData } = isEditForm ? useFormEditKeyResults() : useFormNewKeyResults();

	const [titleKeyResult, setTitleKeyResult] = useState(formData.result);
	const [keyResultDescription, setKeyResultDescription] = useState(formData.description);

	const [finalDate, setFinalDate] = useState<Date | null>(formData.finalDate ? new Date(formData.finalDate) : null);
	const [isInvalidDate, setIsInvalidDate] = useState(false);
	const [isEditInvalidDate, setIsEditInvalidDate] = useState(false);

	const [isFormDataValid, setIsFormDataValid] = useState(false);

	const handleSubmit = () => {
		handleFormData({
			...formData,
			result: titleKeyResult,
			description: keyResultDescription,
			finalDate: `${finalDate}`,
		});
		handleNextStep();
	};

	const handleValidateForm = (): boolean => {
		if (titleKeyResult && keyResultDescription && isInvalidDate === false) return true;
		return false;
	};

	useEffect(() => {
		setIsFormDataValid(handleValidateForm());
	}, [titleKeyResult, keyResultDescription, finalDate]);

	useEffect(() => {
		if (!isEditForm) {
			const isFinalDateValid =
				`${finalDate}` !== 'Invalid Date' && finalDate !== null && !isDateBeforeFromToday(finalDate);

			if (!isFinalDateValid && finalDate !== null) {
				setIsInvalidDate(true);
			} else setIsInvalidDate(false);
		}
		if (isEditForm && formData) {
			const isFinalDateValid =
				`${finalDate}` !== 'Invalid Date' &&
				finalDate !== null &&
				!isDateBeforeFromAnotherDate(formData.finalDate, finalDate);

			if (!isFinalDateValid) {
				setIsEditInvalidDate(true);
			} else setIsEditInvalidDate(false);
		}
	}, [finalDate]);

	return (
		<>
			<SectionTitle>Sobre</SectionTitle>
			<FormItem>
				<ItemTitle>Título do Key Result *</ItemTitle>
				<ItemDescription>Isso vai ajudar a medir se o objetivo foi atingido.</ItemDescription>
				<ItemDescription>Ex.: Receita mensal recorrente de R$ 100.000,00 a partir de jun/22</ItemDescription>

				<TextareaAutosize
					value={titleKeyResult}
					style={{ width: '100%', maxWidth: '100%' }}
					minRows={3}
					maxRows={20}
					onChange={event => setTitleKeyResult(event.target.value)}
				/>
			</FormItem>
			<FormItem>
				<ItemTitle>Descrição *</ItemTitle>
				<ItemDescription>
					Uma boa descrição deixa claro para todos envolvidos o que deve ser atingido
				</ItemDescription>

				<TextareaAutosize
					value={keyResultDescription}
					style={{ width: '100%', maxWidth: '100%' }}
					minRows={3}
					maxRows={20}
					onChange={event => setKeyResultDescription(event.target.value)}
				/>
			</FormItem>
			<LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBrLocale}>
				<DatePicker
					label="Data Final"
					value={finalDate}
					onChange={value => {
						setFinalDate(value);
					}}
					minDate={isEditForm && formData ? new Date(formData.finalDate) : new Date()}
					renderInput={params => (
						<TextField
							{...params}
							variant="standard"
							placeholder="Data Final"
							error={isEditForm ? isEditInvalidDate : isInvalidDate}
							helperText={isInvalidDate || isEditInvalidDate ? 'Data Inválida' : ''}
							style={{ width: '100%', marginTop: '2rem' }}
							required
						/>
					)}
				/>
			</LocalizationProvider>
			<Button
				bgColor="primary"
				className="submit-btn"
				type="submit"
				onClick={() => handleSubmit()}
				disabled={isInvalidDate || isFormDataValid === false || isEditInvalidDate}
			>
				Continuar
			</Button>
		</>
	);
};
