import { CircularProgressBar } from 'components/CircularProgressBar';
import { getThePercentage } from 'domains/Objectives/utils/percentage';
import { Divider } from 'styles/globalStyledComponents';
import { Container, Description, ItemValue, Title } from './style';

import TargetIcon from '../../assets/target-icon.svg';
import ChartIcon from '../../assets/chart-icon.svg';
import CheckedIcon from '../../assets/checked-icon.svg';

interface IResultsInfosProps {
	okrs: number;
	orksCompleted: number;
	okrsIndividual: number;
	okrsIndividualCompleted: number;
	isPersonalResults: boolean;
}

export const ResultsInfos = ({
	okrs,
	orksCompleted,
	okrsIndividual,
	okrsIndividualCompleted,
	isPersonalResults,
}: IResultsInfosProps) => (
	<Container>
		<Title>{isPersonalResults ? 'Seus Resultados' : 'Resultados'} </Title>
		<CircularProgressBar
			progress={getThePercentage(orksCompleted, okrs)}
			size={180}
			strokeWidth={12}
			circleOneStroke="#C4C4C4"
			circleTwoStroke="var(--terciary-400)"
		/>

		<img src={TargetIcon} alt="OKRs" style={{ marginTop: '1.5rem' }} />
		<Divider marginTop={'0.5rem'} marginBottom={'1rem'} />
		<Description>Participação em OKRs</Description>
		<ItemValue>{okrs}</ItemValue>

		<img src={CheckedIcon} alt="OKRs Concluídas" />
		<Divider marginTop={'0.5rem'} marginBottom={'1rem'} />
		<Description>Concluídas</Description>
		<ItemValue>{orksCompleted}</ItemValue>

		<img src={ChartIcon} alt="OKRs Individuais" />
		<Divider marginTop={'0.5rem'} marginBottom={'1rem'} />
		<Description>OKRs Individuais</Description>
		<ItemValue>{okrsIndividual}</ItemValue>

		<img src={CheckedIcon} alt="OKRs Individuais concluídas" />
		<Divider marginTop={'0.5rem'} marginBottom={'1rem'} />
		<Description>Concluídas</Description>
		<ItemValue>{okrsIndividualCompleted}</ItemValue>
	</Container>
);
