import { Input } from 'components/Input';
import { IFormNewCycle } from 'domains/Companies/Interfaces/ICompaniesCyclesDataRequest';
import { IFormNewModule } from 'domains/Companies/Interfaces/ICompaniesModulesDataRequest';
import { putCycleRequest, putModuleRequest } from 'domains/Companies/services/CompaniesServices';
import { useEffect, useState } from 'react';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { DateRange } from '@mui/lab/DateRangePicker';
import { Box } from '@mui/material';
import { Button } from 'components/Button';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { useModal } from 'hooks/useModal';
import { isDateBeforeFromAnotherDate } from 'domains/Objectives/utils/formatDates';
import { MobileDateRangePicker } from '@mui/lab';

interface FormNewCyclesOrModulesProps {
	isCycleForm: boolean;
	companyId: number;
	id: number;
	data: IFormNewCycle | IFormNewModule;
}

export const FormEditCyclesOrModules = ({ isCycleForm, data, id }: FormNewCyclesOrModulesProps) => {
	const { handleModalState } = useModal();
	const [formData, setFormData] = useState<IFormNewCycle | IFormNewModule>(data);
	const [dateRange, setDateRange] = useState<DateRange<Date>>([data.startTime, data.endTime]);
	const [isInvalidInitialDate, setIsInvalidInitialDate] = useState(false);
	const [isInvalidEndDate, setIsInvalidEndDate] = useState(false);
	const [isInvalidCurrentYear, setIsInvalidCurrentYear] = useState(false);
	const [isLoadingRequest, setIsLoadingRequest] = useState(false);

	const handleSubmit = async (values: IFormNewCycle | IFormNewModule) => {
		setIsLoadingRequest(true);
		if (isCycleForm) {
			await putCycleRequest(values, id).then(() => {
				handleModalState();
				setIsLoadingRequest(false);
			});
		} else {
			await putModuleRequest(values, id).then(() => {
				handleModalState();
				setIsLoadingRequest(false);
			});
		}
	};

	useEffect(() => {
		if (formData.startTime) {
			const isFinalDateValid =
				`${dateRange[0]}` !== 'Invalid Date' &&
				dateRange[0] !== null &&
				isDateBeforeFromAnotherDate(formData.startTime, dateRange[0]) === false;

			if (!isFinalDateValid && dateRange[0] !== null) {
				setIsInvalidInitialDate(true);
			} else setIsInvalidInitialDate(false);

			if (!isInvalidInitialDate && dateRange[0]) {
				const isFinalDateValid =
					`${dateRange[1]}` !== 'Invalid Date' &&
					dateRange[1] !== null &&
					isDateBeforeFromAnotherDate(dateRange[0], dateRange[1]) === false;

				if (!isFinalDateValid && dateRange[1] !== null) {
					setIsInvalidEndDate(true);
				} else setIsInvalidEndDate(false);
			}
		}
	}, [dateRange]);

	useEffect(() => {
		setIsInvalidCurrentYear(Number(formData.currentYear) < Number(data.currentYear) - 1);
	}, [formData]);

	return (
		<form
			onSubmit={async e => {
				e.preventDefault();
				if (Number(formData.currentYear) > Number(data.currentYear) - 1) await handleSubmit(formData);
			}}
			style={{ marginTop: '1rem' }}
		>
			<Input
				name="description"
				placeholder={`${isCycleForm ? 'Ciclo' : 'Módulo'}`}
				label={`${isCycleForm ? 'Ciclo' : 'Módulo'}`}
				value={formData.description}
				onChange={event => {
					setFormData({ ...formData, description: event.target.value });
				}}
				required
			/>
			<LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBrLocale}>
				<DatePicker
					views={['year']}
					label="Ano Vigente"
					value={formData.currentYear}
					onChange={value => {
						if (value) {
							const currentYear = new Date(value).getFullYear();
							setFormData({ ...formData, currentYear: `${currentYear + 1}` });
						}
					}}
					renderInput={params => (
						<TextField
							{...params}
							variant="standard"
							placeholder="Ano Vigente"
							style={{ width: '100%', marginTop: '2rem' }}
							error={isInvalidCurrentYear}
							helperText={isInvalidCurrentYear ? 'Ano inválido' : ''}
							required
						/>
					)}
				/>
				<MobileDateRangePicker
					startText="Data de Início"
					value={dateRange}
					minDate={data ? data.startTime : null}
					onChange={newValue => {
						setDateRange(newValue);
						setFormData({ ...formData, startTime: newValue[0], endTime: newValue[1] });
					}}
					renderInput={(startProps, endProps) => (
						<>
							<TextField
								{...startProps}
								variant="standard"
								style={{ marginTop: '2rem' }}
								required
								error={isInvalidInitialDate}
								helperText={isInvalidInitialDate ? 'Data Inválida' : ''}
							/>
							<Box sx={{ mx: 2 }}> </Box>
							<TextField
								{...endProps}
								variant="standard"
								label="Data de Término"
								required
								error={isInvalidEndDate}
								helperText={isInvalidEndDate ? 'Data Inválida' : ''}
							/>
						</>
					)}
				/>
			</LocalizationProvider>

			<Button
				type="submit"
				bgColor="primary"
				className="submit-btn"
				disabled={isLoadingRequest || isInvalidInitialDate || isInvalidEndDate || isInvalidCurrentYear}
				isLoading={isLoadingRequest}
			>
				Salvar
			</Button>
		</form>
	);
};
