import { ReactComponent as HomeIcon } from 'components/Menu/assets/home.svg';
import { ReactComponent as TargetIcon } from 'components/Menu/assets/target.svg';
import { ReactComponent as UserIcon } from 'components/Menu/assets/user.svg';
import { ReactComponent as CompanyIcon } from 'components/Menu/assets/company.svg';
import { ReactComponent as BarChartIcon } from 'components/Menu/assets/bar-chart.svg';
import { ReactComponent as SettingsIcon } from 'components/Menu/assets/settings.svg';

export default [
	{
		header: 'Home',
		route: {
			path: '/home',
		},
		subItems: null,
		icon: <HomeIcon />,
	},
	{
		header: 'OKRs',
		route: {
			path: '/objectives/user',
		},
		subItems: null,
		icon: <TargetIcon />,
	},
	{
		header: 'Pessoas',
		route: {
			path: '/persons',
		},
		subItems: null,
		icon: <UserIcon />,
	},
	{
		header: 'Empresas',
		route: {
			path: '/companies',
		},
		subItems: null,
		icon: <CompanyIcon />,
	},
	{
		header: 'Relatórios',
		route: {
			path: '/reports',
		},
		subItems: null,
		icon: <BarChartIcon />,
	},
	{
		header: 'Configurações',
		route: {
			path: '/settings',
		},
		subItems: null,
		icon: <SettingsIcon />,
	},
];
