import api from '../../../services/api';
import { IPersonFormData, IFormPersonUpdateData } from '../interfaces/IPersonData';

export const storeNewPersonsRequest = async (data: IPersonFormData) => {
	await api
		.post('/users', data)
		.then(response => {
			console.log(response.data.data);
		})
		.catch(error => console.log(error));
};

export const putNewPersonsRequest = async (data: IFormPersonUpdateData) => {
	await api
		.put(`users/${data.id}`, data)
		.then(response => {
			console.log(response.data.data);
		})
		.catch(error => console.log(error));
};

export const deletePersonsRequest = async (data: IFormPersonUpdateData) => {
	await api
		.delete(`users/${data.id}`)
		.then(response => {
			console.log(response.data.data);
		})
		.catch(error => console.log(error));
};

export const getAllProfilesRequest = async (cycleId: string) => {
	const data = await api
		.get(`cycles/${cycleId}/profiles`)
		.then(response => response.data.data)
		.catch(error => error.message);

	return data;
};
