import { Navbar } from 'components/Navbar';
import { useState } from 'react';
import { getDownloadModel } from 'domains/Companies/services/CompaniesServices';
import { UserPageView } from '../components/UserPageView';
import { ManagerPageView } from '../components/ManagerPageView';
import { TeamPageView } from '../components/TeamPageView';
import { CompanyPageView } from '../components/CompanyPageView';

import { Container, Header } from '../styles/ReportsPageStyles';

export const ReportsPage = () => {
	const [pageView, setPageView] = useState('user');

	const handlePageView = (newPageView: string) => {
		setPageView(newPageView);
	};

	const handleDownloadModel = async () => {
		const data = await getDownloadModel();
		const url = window.URL.createObjectURL(new Blob([data]));
		const link = document.createElement('a');

		link.href = url;
		link.setAttribute('download', `relatorio.pdf`);

		document.body.appendChild(link);
		link.click();
		link.parentNode?.removeChild(link);
	};

	return (
		<Container>
			<Navbar pageView={pageView} handlePageView={handlePageView} />

			<Header>
				<h1>Relatórios</h1>
				<button onClick={handleDownloadModel}> Exportar Relatório</button>
			</Header>

			{pageView === 'user' && <UserPageView />}
			{pageView === 'manager' && <ManagerPageView />}
			{pageView === 'teams' && <TeamPageView />}
			{pageView === 'company' && <CompanyPageView />}
		</Container>
	);
};
