import { useState, useCallback } from 'react';
import { MdOutlineClose } from 'react-icons/md';

import { Button } from 'components/Button';
import { ModalProvider } from 'hooks/useModal';
import { CardTeams } from '../CardTeams';
import { InfoTeams } from '../SettingsModal/InfoTeams';

import { ContainerDesktop, ContainerMobile, Item, Circle, Overlay, Container, Head, ModalButton } from './style';

import { ITeamList, ITeamData } from '../../interfaces/ITeamData';
import { FormEditTeams } from '../SettingsModal/FormEditTeams';

export const TeamList: React.FC<ITeamList> = ({ teamList, teamFilter }) => {
	const [openModal, setOpenModal] = useState(false);
	const [openModalType, setOpenModalType] = useState('');
	const [team, setTeam] = useState<ITeamData>({} as ITeamData);

	const handleOpenModal = useCallback(async (t: ITeamData) => {
		setOpenModal(!openModal);
		setOpenModalType('');
		setTeam(t);
	}, []);

	return (
		<>
			<ContainerDesktop>
				{teamList
					.filter(team => team.description.toLowerCase().includes(teamFilter.toLowerCase()))
					.map(item => (
						<CardTeams key={item.id} team={item}></CardTeams>
					))}
			</ContainerDesktop>

			<Overlay
				openModal={openModal}
				onClick={() => {
					setOpenModal(false);
				}}
			/>

			<ContainerMobile>
				<h4>Times</h4>

				{teamList
					.filter(team => team.description.toLowerCase().includes(teamFilter.toLowerCase()))
					.map(item => (
						<Item onClick={() => handleOpenModal(item)} key={item.id}>
							<Circle>
								{item.description.split(' ').map(index => (
									<span>{index.slice(0, 1)}</span>
								))}
							</Circle>

							<p>{item.description}</p>
						</Item>
					))}
			</ContainerMobile>
			<ModalProvider>
				{openModal && (
					<Container openModal={openModal}>
						<Head openModal={openModal}>
							<h4>{openModalType === 'Editar Time' ? 'Editar Time' : 'Detalhes do time'}</h4>

							<MdOutlineClose
								onClick={() => setOpenModal(!openModal)}
								size={24}
								color="var(--gray-700)"
							/>
						</Head>

						{team !== undefined && openModalType !== 'Editar Time' && (
							<>
								<InfoTeams team={team} />

								<ModalButton onClick={() => setOpenModalType('Editar Time')}>
									<Button bgColor="primary">Editar Informações</Button>
								</ModalButton>
							</>
						)}

						{team !== undefined && openModalType === 'Editar Time' && <FormEditTeams team={team} />}
					</Container>
				)}
			</ModalProvider>
		</>
	);
};
