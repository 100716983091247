import styled from 'styled-components';

export const Container = styled.div`
	position: relative;

	.svg {
		transform: rotate(270deg);
		display: block;
		margin: 20px auto;
		max-width: 100%;
		position: relative;

		.svg-circle-bg {
			fill: none;
		}

		.svg-circle {
			fill: none;
		}
	}
`;

export const TextContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 56px;
	right: 35px;

	width: 128px;
	height: 128px;
	background: none;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
	border-radius: 50%;
`;

export const Text = styled.p`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 32px;
	margin-right: 1rem;

	max-height: 32px;
	max-width: 32px;
	color: var(--secondary-400);
`;
