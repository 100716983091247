import dayjs from 'dayjs';

export const formatDateForChart = (date: string) => {
	const dateObject = new Date(date);
	const month = dateObject.toLocaleString('default', { month: 'short' });
	const day = dateObject.getDate();

	return `${day}/${month}`;
};

export const formatDateNormal = (date: string) => {
	const dateObject = new Date(date);
	return dateObject.toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' });
};

export const formatDateComments = (date: string) => {
	const dateObject = new Date(date);

	const currentDate = dateObject.toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' });
	const currentTime = `${dateObject.getHours()}:${dateObject.getMinutes()}`;

	return `${currentDate} às ${currentTime}`;
};

export const isDateBeforeFromToday = (date: Date | dayjs.Dayjs) => {
	const dateToCompare = dayjs(date);
	const today = dayjs();
	const differenceInHours = today.diff(dateToCompare, 'hours');
	const differenceInDays = Math.floor(differenceInHours / 24);

	if (differenceInDays > 0) return true;
	return false;
};

export const isDateBeforeFromAnotherDate = (
	date: Date | string | null | dayjs.Dayjs,
	comparative: Date | string | null | dayjs.Dayjs,
) => {
	const dateToCompare = dayjs(comparative);
	const myDate = dayjs(date);
	const differenceInHours = myDate.diff(dateToCompare, 'hours');
	const differenceInDays = Math.floor(differenceInHours / 24);

	if (differenceInDays > 0) return true;
	return false;
};

export const isDateAfterFromAnotherDate = (
	date: Date | string | null | dayjs.Dayjs,
	comparative: Date | string | null | dayjs.Dayjs,
) => {
	const dateToCompare = dayjs(comparative);
	const myDate = dayjs(date);
	const differenceInHours = myDate.diff(dateToCompare, 'hours');
	const differenceInDays = Math.floor(differenceInHours / 24);

	if (differenceInDays < 0) return true;
	return false;
};
