import styled from 'styled-components';

export const Container = styled.nav`
	display: flex;
	align-items: center;
	gap: 4rem;

	height: 4.5rem;
	width: 100%;

	padding: 0 3rem;

	box-shadow: var(--elevation-400);

	a {
		padding: 1.5rem 0;

		font: var(--body-lg-400);
		color: var(--gray-400);
	}

	a:last-child {
		font: var(--body-lg-500);
		color: var(--terciary-400);

		border-bottom: 4px solid var(--terciary-400);
	}

	@media (max-width: 48rem) {
		margin-bottom: 1rem;
		padding: 0 1.25rem;
	}
`;
