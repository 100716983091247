import { Modal } from 'components/Modal';
import { ModalProvider } from 'hooks/useModal';
import { ICompaniesModulesDataRequest } from '../../Interfaces/ICompaniesModulesDataRequest';
import { ICompaniesCyclesDataRequest } from '../../Interfaces/ICompaniesCyclesDataRequest';
import { TableRow, TableContainer, Container } from './style';
import { Divider } from '../../styles/styles';
import { FormNewCyclesOrModules } from '../FormNewCyclesOrModules';

interface SelectTableProps {
	tableItems: ICompaniesCyclesDataRequest[] | ICompaniesModulesDataRequest[] | undefined;
	selectedItemId: number;
	handleSelectedItem: (id: number) => void;
	isCycleComponent: boolean;
	companyId: number;
}

export const SelectTable = ({
	tableItems,
	selectedItemId,
	handleSelectedItem,
	isCycleComponent,
	companyId,
}: SelectTableProps) => {
	const handleSelectedItemTable = (id: number) => {
		handleSelectedItem(id);
	};

	const formatDate = (date: string): string => {
		const dateObject = new Date(date);
		const day = dateObject.getDate();
		const month = dateObject.getMonth() + 1;
		const year = dateObject.getFullYear();

		return `${day}/${month}/${year}`;
	};

	return (
		<Container>
			<TableContainer>
				{tableItems?.map(item => (
					<>
						<TableRow
							selected={item.id === selectedItemId}
							onClick={() => handleSelectedItemTable(item.id)}
							key={item.id}
						>
							<span />
							<b>{item.description}</b>
							<div>
								<p>
									Início: {formatDate(item.startTime)} | Término: {formatDate(item.endTime)}
								</p>
							</div>
						</TableRow>
						<Divider />
					</>
				))}
			</TableContainer>
			<ModalProvider>
				<Modal
					title={isCycleComponent ? 'Novo Ciclo' : 'Novo Módulo'}
					buttonContent={isCycleComponent ? 'Novo Ciclo' : 'Novo Módulo'}
					hasButton
				>
					<FormNewCyclesOrModules companyId={companyId} isCycleForm={isCycleComponent} />
				</Modal>
			</ModalProvider>
		</Container>
	);
};
