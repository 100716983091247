import styled from 'styled-components';

export const Container = styled.div`
	max-width: 68rem;
	width: 100%;

	& > div {
		padding: 0.5rem;

		& > div:first-child {
			margin: 1rem 1rem 1.5rem;
			padding: 0 0 1rem;
			border-bottom: 2px solid var(--gray-200);
		}
	}

	& button {
		max-width: 24rem;
		width: 100%;
	}
`;
