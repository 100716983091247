import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	gap: 1.5rem;
	flex-wrap: wrap;

	max-width: 68rem;

	margin: 2.5rem 0;

	h2 {
		font: var(--body-xl-500);
		color: var(--gray-700);
	}

	img {
		width: 6.5rem;
		height: 6.5rem;

		margin-bottom: 1.5rem;
	}

	& form {
		max-width: 48rem;
		width: 100%;
	}

	& fieldset {
		height: 3.5rem;

		margin-bottom: 1rem;
	}

	& > div {
		padding: 0.5rem;

		& > div:first-child {
			margin: 1rem;
			padding: 0 0 1rem;
			border-bottom: 2px solid var(--gray-200);
		}
	}

	& button {
		max-width: 23rem;
		width: 100%;

		margin-top: 1.5rem;
	}

	@media (max-width: 48rem) {
		& > div {
			max-width: 100%;
		}
	}

	.card {
		max-height: 100%;
	}
`;

export const ContainerInput = styled.div`
	display: flex;
	gap: 2rem;

	max-width: 50rem;
	width: 100%;

	input {
		max-width: 24rem;
		width: 100%;
	}

	@media (max-width: 48rem) {
		flex-direction: column;
	}
`;

export const EditableInput = styled.div`
	width: 100%;
`;

export const UneditableInput = styled.div`
	width: 100%;

	& div {
		height: 3.5rem;

		margin-bottom: 1rem;

		& span {
			display: block;
			height: 17px;

			font: var(--body-xs-400);
			color: var(--gray-400);
		}

		& p {
			padding-top: 4px;
			padding-bottom: 4px;

			font: var(--body-md-400);
			color: var(--gray-400);

			border-bottom: 1px solid var(--gray-400);
		}
	}
`;
