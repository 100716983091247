import axios from 'axios';

const token = localStorage.getItem('@Advy:token');

const api = axios.create({
	// baseURL: 'https://api.dev.selpe-advy.nscreen.com.br/',
	baseURL: 'https://mock.advy.us/',
	// baseURL: 'http://localhost:3000/',
	headers: {
		Authorization: `${token}`,
	},
});

axios.interceptors.request.use(response => {
	response.headers = {
		Authorization: `${token}`,
	};

	return response;
});

api.interceptors.response.use(
	response => {
		localStorage.setItem('@Advy:hasError', response.data.hasError);
		localStorage.setItem('@Advy:message', response.data.message);

		return response;
	},
	async error => {
		localStorage.setItem('@Advy:hasError', error?.response?.data.hasError);
		localStorage.setItem('@Advy:message', error?.response?.data.message);

		return Promise.reject(error);
	},
);

export default api;
