import { Link } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

import { IMenuItemProps } from '../Interfaces/IMenuItem';

export const MenuItem: React.FC<IMenuItemProps> = ({ sidebarRoutes }) => (
	<li>
		<Link to={`${sidebarRoutes.route.path}`}>
			{sidebarRoutes.icon}

			<p>{sidebarRoutes.header}</p>

			<FaChevronRight size="12" color="#565656" />
		</Link>
	</li>
);
