import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card } from 'components/Card';
import { Switch } from 'components/Switch';

import { BreadcrumbComponent } from '../components/Breadcrumb';
import { BasicInfosComponent } from '../components/BasicInfos';
import { CardsContainer, ContentContainer, SecondaryContainer, Divider } from '../styles/styles';

import { ICompaniesDataRequest } from '../Interfaces/ICompaniesDataRequest';
import { ICompaniesCyclesDataRequest } from '../Interfaces/ICompaniesCyclesDataRequest';
import { ICompaniesModulesDataRequest } from '../Interfaces/ICompaniesModulesDataRequest';
import {
	getFilteredCompaniesRequest,
	getCompanieModulesRequest,
	getCompanieCyclesRequest,
} from '../services/CompaniesServices';
import { Table } from '../components/Table';
import { SelectTable } from '../components/SelectTable';
import { CyclesOrModulesDetails } from '../components/CyclesOrModulesDetails';

export const CompaniesDetailsPage = () => {
	const [company, setCompany] = useState<ICompaniesDataRequest>();
	const [companyName, setCompanyName] = useState('');
	const [companyId, setCompanyId] = useState(0);
	const [isSwitchActived, setIsSwitchActived] = useState(false);
	const [companyCycles, setCompanyCycles] = useState<ICompaniesCyclesDataRequest[]>([]);
	const [companyModules, setCompanyModules] = useState<ICompaniesModulesDataRequest[]>([]);
	const [selectedItemTableId, setSelectedItemTableId] = useState(0);
	const [switchedItem, setSwitchedItem] = useState(false);

	const breadcrumbsUrls = [{ path: '/companies', name: 'Empresas Cadastradas' }];
	const { state } = useLocation();

	const handleSwitchActived = () => {
		setIsSwitchActived(!isSwitchActived);
	};

	const handleSelectedTableItem = (id: number) => {
		setSelectedItemTableId(id);
		setSwitchedItem(true);
	};

	useEffect(() => {
		const locationState = state as { socialReason: string; fantasyName: string; companyId: number };
		setCompanyName(locationState.fantasyName);
		setCompanyId(locationState.companyId);

		const getCompanyFiltered = async () => {
			if (locationState.socialReason) {
				const companyData = await getFilteredCompaniesRequest(locationState.socialReason);

				setCompany(companyData[0]);
			}
		};

		const getCompanieModules = async () => {
			const data = await getCompanieModulesRequest(locationState.companyId);
			setCompanyModules(data);
		};

		const getCompanieCycles = async () => {
			const data = await getCompanieCyclesRequest(locationState.companyId);
			setCompanyCycles(data);
		};

		getCompanyFiltered();
		getCompanieModules();
		getCompanieCycles();
	}, []);

	return (
		<ContentContainer>
			<div style={{ width: '100%' }} className="header">
				<BreadcrumbComponent urls={breadcrumbsUrls} title={companyName} localUrl="/companies" />
				<Switch
					leftTitle="Ciclos"
					rightTitle="Módulos"
					isActived={isSwitchActived}
					handleIsActived={handleSwitchActived}
					noMobile
				/>
			</div>
			<SecondaryContainer>
				{company && <BasicInfosComponent companyData={company} />}
				<CardsContainer>
					<Card title={`${isSwitchActived ? 'Módulos' : 'Ciclos'} Cadastrados`} className="card">
						<Divider />
						<SelectTable
							tableItems={isSwitchActived ? companyModules : companyCycles}
							handleSelectedItem={handleSelectedTableItem}
							selectedItemId={selectedItemTableId}
							isCycleComponent={!isSwitchActived}
							companyId={companyId}
						/>
					</Card>
					{switchedItem && (
						<Card title={`Detalhes do ${isSwitchActived ? 'Módulo' : 'Ciclo'}`} className="card">
							<Divider />
							<CyclesOrModulesDetails
								itemId={selectedItemTableId}
								cycles={companyCycles}
								modules={companyModules}
								isCycleComponent={!isSwitchActived}
								companyId={companyId}
							/>
						</Card>
					)}
					<Table />
				</CardsContainer>
			</SecondaryContainer>
		</ContentContainer>
	);
};
