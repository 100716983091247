import { useState, useRef, useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa';

import { Container, Content, Head, Title, ButtonCollapse } from './style';

import { ICardProps } from './ICardProps';

export const Card: React.FC<ICardProps> = ({ title, isCollapsed, defaultValue, children, className, optionHeader }) => {
	const [collapseState, setCollapseState] = useState(!!defaultValue);
	const [heightState, setHeightState] = useState('');

	const content = useRef<HTMLDivElement>(null);

	const heightElement = Number(content.current?.scrollHeight) / 10;

	useEffect(() => {
		setHeightState(collapseState ? `${heightElement}rem` : '3.5rem');
	}, [collapseState]);

	return (
		<Container
			ref={content}
			isCollapsed={!!isCollapsed}
			collapseState={collapseState}
			heightState={heightState}
			className={className}
		>
			<Head collapseState={collapseState} isCollapsed={!!isCollapsed}>
				{optionHeader}
				{title && !optionHeader && <Title>{title}</Title>}

				{isCollapsed && (
					<ButtonCollapse
						collapseState={collapseState}
						className="collapse-btn"
						onClick={() => isCollapsed && setCollapseState(!collapseState)}
					>
						<FaChevronDown size={14} color="var(--gray-700)" />
					</ButtonCollapse>
				)}
			</Head>

			<Content isCollapsed={!!isCollapsed}>{children}</Content>
		</Container>
	);
};
