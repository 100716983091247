import { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useModal } from 'hooks/useModal';
import { Menu, MenuItem } from '@mui/material';
import { useCycle } from 'hooks/useCycles';

import { getAllProfilesRequest, storeNewPersonsRequest } from 'domains/Persons/services/PersonsService';

import { FiPlusCircle, FiTrash2 } from 'react-icons/fi';
import { AutocompleteComponent } from 'components/Autocomplete';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { Divider } from 'styles/globalStyledComponents';
import { StepperComponent } from 'components/Stepper';
import { AutocompleteLeaders } from '../AutocompleteLeaders';
import { AutocompleteLeds } from '../AutocompleteLeds';

import { Container, ProfileContainer, ProfilesList, ThreeColumns } from './style';

import { IPersonFormData } from '../../interfaces/IPersonData';
import { IListAreas } from '../../interfaces/IAreaData';
import { ILeadersList } from '../../interfaces/ILeaderData';

const steps = ['Informações', 'Perfil'];
const ITEM_HEIGHT = 48;

export const ModalContent: React.FC<IListAreas & ILeadersList> = ({ listAreas, leadersList }) => {
	const { handleModalState } = useModal();
	const { cycle } = useCycle();

	const formRef = useRef(null);

	const [activeStep, setActiveStep] = useState(0);
	const [skipped, setSkipped] = useState(new Set<number>());
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openProfileMenu = Boolean(anchorEl);

	const [formData, setFormData] = useState<IPersonFormData>({
		name: '',
		cpf: '',
		email: '',
		telephone: '',
		profession: '',
		register: '',
		sector: 0,
		image: '',
		sectorId: '',
		companyId: '',
		leaderId: '',
		areaId: '',
		ledIds: [],
		profiles: [],
	});

	const [profilesOptions, setProfilesOptions] = useState<{ id: string; description: string }[]>([]);
	const [profilesSelecteds, setProfilesSelecteds] = useState<{ id: string; description: string }[]>([]);

	const [listAreasName, setListAreasName] = useState<string[]>([]);
	const [leadersListName, setLeadersListName] = useState<string[]>([]);
	const [leadersListImage, setLeadersListImage] = useState<string[]>([]);

	const validationSchema = Yup.object().shape({
		name: Yup.string().required(),
		cpf: Yup.string().min(11, 'Insira um CPF válido').required(),
		telephone: Yup.string().min(11, 'Insira um telefone válido').required(),
		email: Yup.string().email('Insira um e-mail válido').required(),
		register: Yup.string().min(1).required(),
		profession: Yup.string().min(1).required(),
		leaderId: Yup.string().min(1).required(),
		sectorId: Yup.string().min(1).required(),
	});

	const isStepSkipped = (step: number) => skipped.has(step);

	const handleNextStep = async (values: IPersonFormData) => {
		const telephone = values.telephone.replace(/[^\w\s]/gi, '').trim();
		const cpf = values.cpf.replace(/[^\w\s]/gi, '').trim();

		await validationSchema.validate(
			{ ...values, cpf, telephone },
			{
				abortEarly: false,
			},
		);

		setFormData({ ...values });

		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep(prevActiveStep => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBackStep = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	};

	const handleClickProfilesMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseProfilesMenu = () => {
		setAnchorEl(null);
	};

	const handleSelectProfile = (profile: { id: string; description: string }) => {
		if (!profilesSelecteds.includes(profile)) setProfilesSelecteds([...profilesSelecteds, profile]);
	};

	const handleRemoveProfile = (profile: { id: string; description: string }) => {
		const profilesSelecctedsFiltred = profilesSelecteds.filter(item => item !== profile);
		setProfilesSelecteds(profilesSelecctedsFiltred);
	};

	const handleSubmit = async () => {
		await storeNewPersonsRequest({ ...formData, profiles: [...profilesSelecteds] }).then(() => {
			handleModalState();
		});
	};

	useEffect(() => {
		const handleProfilesOptions = async () => {
			const data = await getAllProfilesRequest(cycle.id);

			setProfilesOptions(data);
		};

		Promise.all([handleProfilesOptions()]);
	}, []);

	useEffect(() => {
		setListAreasName(listAreas.map(item => item.description));

		if (leadersList.length > 0) {
			leadersList.forEach(leader => {
				setLeadersListName([...leadersListName, leader.person.name]);
				setLeadersListImage([...leadersListName, leader.image]);
			});
		}
	}, [listAreas, leadersList]);

	return (
		<Container>
			<StepperComponent activeStep={activeStep} steps={steps} />

			{activeStep === 0 && (
				<Formik
					initialValues={formData}
					ref={formRef}
					validationSchema={validationSchema}
					onSubmit={(values: IPersonFormData) => {
						handleNextStep(values);
					}}
				>
					{props => (
						<form
							onSubmit={e => {
								e.preventDefault();
								props.handleSubmit(e);
							}}
						>
							<Input
								name="name"
								label="Nome completo"
								placeholder="Nome completo"
								required
								value={props.values.name}
								onChange={props.handleChange}
								error={props.touched.name && Boolean(props.errors.name)}
								helperText={props.touched.name && props.errors.name ? props.errors.name : undefined}
							/>
							<Input
								name="email"
								label="Email"
								placeholder="Email"
								helperText={props.touched.email && props.errors.email ? props.errors.email : undefined}
								error={props.touched.email && Boolean(props.errors.email)}
								required
								value={props.values.email}
								onChange={props.handleChange}
							/>

							<ThreeColumns>
								<Input
									name="cpf"
									label="CPF"
									mask="cpf"
									placeholder="CPF"
									error={props.touched.cpf && Boolean(props.errors.cpf)}
									helperText={props.touched.cpf && props.errors.cpf ? props.errors.cpf : undefined}
									required
									value={props.values.cpf}
									onChange={props.handleChange}
								/>
								<Input
									name="register"
									label="Matrícula"
									placeholder="Matrícula"
									required
									value={props.values.register}
									onChange={props.handleChange}
								/>
								<Input
									name="telephone"
									label="Telefone"
									mask="telephone"
									placeholder="Telefone"
									className="telephone-input"
									required
									error={props.touched.telephone && Boolean(props.errors.telephone)}
									helperText={
										props.touched.telephone && props.errors.telephone
											? props.errors.telephone
											: undefined
									}
									onChange={props.handleChange}
									value={props.values.telephone}
								/>
							</ThreeColumns>

							<Input
								name="profession"
								label="Cargo"
								placeholder="Cargo"
								required
								value={props.values.profession}
								onChange={props.handleChange}
							/>

							<AutocompleteComponent
								name="areas"
								label="Área"
								options={listAreasName}
								required
								value={props.values.sectorId}
								onChange={(_, value) => {
									props.setFieldValue('sectorId', value, true);
								}}
							/>

							<AutocompleteLeaders
								name="leaders"
								label="Líder"
								options={leadersListName}
								required
								image={leadersListImage}
								value={props.values.leaderId}
								onChange={(_, value) => {
									props.setFieldValue('leaderId', value, true);
								}}
							/>

							<AutocompleteLeds
								name="led"
								label="Liderados"
								value={props.values.ledIds}
								options={leadersListName}
								onChange={(_, value) => {
									props.setFieldValue('ledIds', value, true);
								}}
							/>

							<Button type="submit" bgColor="primary" onClick={() => handleNextStep(props.values)}>
								Continuar
							</Button>
						</form>
					)}
				</Formik>
			)}

			{activeStep === 1 && (
				<ProfileContainer>
					<button className="add-profile-btn" onClick={handleClickProfilesMenu}>
						<FiPlusCircle />
						Adicionar Perfil
					</button>

					<Menu
						id="cycles-menu"
						MenuListProps={{
							'aria-labelledby': 'long-button',
						}}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={openProfileMenu}
						onClose={handleCloseProfilesMenu}
						PaperProps={{
							style: {
								maxHeight: ITEM_HEIGHT * 4.5,
								width: '20ch',
							},
						}}
					>
						{profilesOptions.map(option => (
							<MenuItem
								key={option.id}
								selected={profilesSelecteds.includes(option)}
								onClick={() => handleSelectProfile(option)}
							>
								{option.description}
							</MenuItem>
						))}
					</Menu>

					<Divider marginTop="1rem" marginBottom="1rem" />

					<ProfilesList>
						{profilesSelecteds.map(profile => (
							<>
								<div>
									<p>{profile.description}</p>
									<button onClick={() => handleRemoveProfile(profile)}>
										<FiTrash2 />
									</button>
								</div>
								<Divider marginTop="1rem" marginBottom="1rem" />
							</>
						))}
					</ProfilesList>
					<div className="buttons-container-end">
						<button type="button" className="back-btn" onClick={() => handleBackStep()}>
							Voltar
						</button>
						<Button
							type="submit"
							bgColor="primary"
							disabled={profilesSelecteds.length <= 0}
							onClick={() => handleSubmit()}
						>
							Finalizar
						</Button>
					</div>
				</ProfileContainer>
			)}
		</Container>
	);
};
