import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	max-width: 280px;
	max-height: 950px;
	padding: 1rem;

	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);

	@media (max-width: 30rem) {
		max-width: 87vw;
	}

	span {
		max-width: 100px;
		min-width: 100px;
	}

	.text-container-progress {
		top: 48px;
		right: 26px;
	}
`;

export const Title = styled.h2`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 135%;
	align-self: flex-start !important;
	margin-bottom: 1rem;

	color: var(--secondary-400);
`;

export const Description = styled.p`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 135%;
	color: var(--gray-700);
`;

export const ItemValue = styled.h3`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 48px;
	line-height: 48px;
	color: var(--gray-700);

	margin-bottom: 1.4rem;
`;
