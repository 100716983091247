import { Container, NavOption } from './style';

interface INavbarProps {
	pageView: string;
	handlePageView: (newPageView: string) => void;
}

export const Navbar = ({ pageView, handlePageView }: INavbarProps) => (
	<Container>
		<NavOption selected={pageView === 'user'} onClick={() => handlePageView('user')}>
			Você
		</NavOption>
		<NavOption selected={pageView === 'manager'} onClick={() => handlePageView('manager')}>
			Painel do Gestor
		</NavOption>
		<NavOption selected={pageView === 'teams'} onClick={() => handlePageView('teams')}>
			Times
		</NavOption>
		<NavOption selected={pageView === 'company'} onClick={() => handlePageView('company')}>
			Empresa
		</NavOption>
	</Container>
);
