import styled, { css } from 'styled-components';

export const Container = styled.div`
	.stepper-component {
		margin-top: 2rem;

		@media (max-width: 375px) {
			display: none;
		}

		.Mui-active circle {
			color: var(--secondary-400);
		}

		.Mui-completed {
			color: var(--secondary-400);
		}

		text {
			fill: rgba(255, 255, 255, 255);
		}
	}

	textarea {
		padding: 10px;

		&:focus {
			border: 1.5px solid var(--primary-400);
		}
	}

	.text-area-title {
		font-family: Fira Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: var(--gray-700);
		margin-top: 24px;
	}

	.form-item-title {
		color: var(--secondary-400);
		font-family: Fira Sans;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 135%;

		margin-top: 2rem;
	}

	.selector-title {
		font-family: Fira Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 135%;
		color: #565656;
		margin-top: 2rem;
		margin-bottom: 1rem;
	}

	.option-selector-title {
		color: var(--secondary-400);
		font-family: Fira Sans;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
	}

	.container-contributors {
		display: flex;
		width: 100%;
		gap: 2rem;

		.option-selector {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			align-items: flex-start;
			padding: 16px;
			width: 20rem;
			height: 211px;
			box-shadow: var(--elevation-200);
			border-radius: 4px;

			.professional-container {
				display: flex;
				align-items: center;
				justify-content: space-around;
				margin-top: -15px;
				color: var(--gray-500);

				img {
					height: 1.5rem;
					width: 1.5rem;
					border-radius: 50%;
					box-shadow: var(--elevation-200);
					margin-right: 8px;
				}

				h2 {
					color: var(--primary-400);
					font-family: Fira Sans;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
				}
			}
		}
	}

	.correspondences-selector {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 16px;
		width: 100%;
		height: auto;
		max-height: 250px;
		overflow-y: scroll;
		box-shadow: var(--elevation-200);
		border-radius: 4px;
		margin-top: 10px;
		margin-bottom: 2rem;

		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			background: var(--gray-200);
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--gray-400);
			border-radius: 4px;
		}

		.option-container {
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--gray-700);
			font-size: 14px;
		}
	}

	.input-geral-form {
		margin-top: 0.5rem;
	}

	.button-group {
		gap: 2rem;
		margin-top: 1rem;
		margin-bottom: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		@media (max-width: 768px) {
			margin-left: auto;
			justify-content: center !important;

			button {
				width: 8rem !important;
				height: 8rem !important;
			}
		}

		.Mui-selected {
			border: 1px solid var(--secondary-400) !important;
		}

		button {
			width: 6rem;
			height: 6rem;
			border: none;
			box-shadow: var(--elevation-200);
			border-radius: 4px !important;
		}

		div {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 0.5rem;
			text-transform: lowercase;

			p::first-line {
				text-transform: capitalize;
			}

			svg {
				height: 2rem;
				width: 2rem;
			}
		}
	}

	#self-assign-btn {
		border: none;
		background-color: transparent;
		margin-top: 1rem;
		color: var(--primary-400);
		font-size: 14px;
	}

	.submit-btn {
		margin-top: 2rem;
		margin-left: auto;
		width: 208px;
	}

	.description-text {
		color: var(--gray-500);
		font-family: Fira Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
	}

	.first-letter-capitalize::first-line {
		text-transform: capitalize;
	}

	.buttons-container {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;

		button {
			width: 13rem;
		}

		.back-btn {
			background-color: transparent;
			border: 1px solid var(--primary-400);
			box-sizing: border-box;
			color: var(--primary-400);
			margin-right: 3rem;
			border-radius: 4px;
			height: 3rem;
			box-shadow: var(--elevation-200);
		}

		.continue-btn {
			background-color: var(--primary-400);
			color: white;
			border: none;
			border-radius: 4px;
			height: 3rem;
			box-shadow: var(--elevation-200);
		}

		button:disabled,
		button[disabled] {
			background: #ccc;
		}
	}

	.revision-secondary-title {
		color: var(--gray-700);
		font-family: Fira Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 135%;
		margin-bottom: 5px;
		margin-top: 10px;
	}

	.revision-item-title {
		color: var(--secondary-400);
		font-family: Fira Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 135%;
		font-weight: 500;
		margin-top: 2rem;
		margin-bottom: 0.6rem;
	}

	.contributors-container {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;

		.professional-container {
			display: flex;
			align-items: center;
			justify-content: center;

			font-family: Fira Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			color: var(--gray-500);

			img {
				height: 1.5rem;
				width: 1.5rem;
				border-radius: 50%;
				box-shadow: var(--elevation-200);
				margin-right: 8px;
			}
		}
	}
`;

interface TeamButtonProps {
	selected: boolean;
}

export const TeamButton = styled.button<TeamButtonProps>`
	border: none;
	background: none;
	padding: 0px 8px;
	width: 100%;
	height: 28px;
	border-radius: 4px;

	color: var(--gray-500);
	font-family: Fira Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;

	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: -5px;

	${props =>
		props.selected &&
		css`
			& {
				background-color: var(--primary-400);
				color: white;
			}
		`}
`;
