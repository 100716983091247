import { Card } from 'components/Card';
import {
	IPerformance,
	IPerformanceResponsibleRequestData,
	IPerformanceTeamsRequestData,
} from 'domains/Reports/Interfaces/IPerformances';
import { getReportsTeamsPerformances, getReportsUsersPerformances } from 'domains/Reports/services/reportsServices';
import { useEffect, useState } from 'react';
import { Divider } from 'styles/globalStyledComponents';
import { ItemTitle } from '../style';
import { PerformanceTable } from './PerformanceTable';
import { Container } from './styles';

interface IPerformanceProps {
	pageView: string;
}

export const Performance = ({ pageView }: IPerformanceProps) => {
	const [responsablesPerformance, setResponsablesPerformance] = useState<IPerformance[]>([]);
	const [teamsPerformance, setTeamsPerformance] = useState<IPerformance[]>([]);

	const handleResponsablesPerformance = async () => {
		await getReportsUsersPerformances(pageView).then((response: IPerformanceResponsibleRequestData[]) => {
			const formatedArray = response.map(performance => ({
				responsible: performance.user.person.name,
				created: performance.okrs.create,
				completed: performance.okrs.finished,
				percentual: performance.okrs.percentage,
			}));

			setResponsablesPerformance(formatedArray);
		});
	};

	const handleTeamsPerformance = async () => {
		await getReportsTeamsPerformances(pageView).then((response: IPerformanceTeamsRequestData[]) => {
			const formatedArray = response.map(performance => ({
				responsible: performance.user.description,
				created: performance.okrs.create,
				completed: performance.okrs.finished,
				percentual: performance.okrs.percentage,
			}));

			setTeamsPerformance(formatedArray);
		});
	};

	useEffect(() => {
		Promise.all([handleResponsablesPerformance(), handleTeamsPerformance()]);
	}, []);

	return (
		<>
			<ItemTitle>Performance</ItemTitle>
			<Container>
				<Card title="Performance por Responsável (KRs criadas/concluídas)" className="card">
					<Divider marginTop="0.5rem" marginBottom="0.5rem" />
					<PerformanceTable performances={responsablesPerformance} isTeamComponent={false} />
				</Card>
				<Card title="Performance por Time (KRs criadas/concluídas)" className="card">
					<Divider marginTop="0.5rem" marginBottom="0.5rem" />
					<PerformanceTable performances={teamsPerformance} isTeamComponent={false} />
				</Card>
			</Container>
		</>
	);
};
