import { Autocomplete, TextField } from '@mui/material';
import { Card } from 'components/Card';
import {
	EntityWithIdAndLabel,
	IOkrRepresentativeness,
	IResponsableData,
	ITeamData,
} from 'domains/Reports/Interfaces/IOkrsReportData';
import {
	getAllResponsablesOptions,
	getAllTeamsOptions,
	getKrsRepresentativenessTeamReports,
	getKrsRepresentativenessUserReports,
	getOkrsRepresentativenessTeamReports,
	getOkrsRepresentativenessUserReports,
} from 'domains/Reports/services/reportsServices';
import { useEffect, useState } from 'react';
import { Divider } from 'styles/globalStyledComponents';
import { Chart } from 'react-google-charts';
import { ItemTitle, Text } from '../style';
import { CardHeader, Container } from './style';

interface IPieChartsProps {
	pageView: string;
}

export const PieCharts = ({ pageView }: IPieChartsProps) => {
	const [teamOkrData, setTeamOkrData] = useState<{ total: number; orks: IOkrRepresentativeness[] }>();
	const [teamOkr, setTeamOkr] = useState<EntityWithIdAndLabel | null>(null);
	const [teamsOptions, setTeamsOptions] = useState<EntityWithIdAndLabel[]>([]);
	const [teamOkrChartData, setTeamOkrChartData] = useState<(string | number)[][]>([
		['OKRs', 'Progress'],
		['Selecione Um Time', 100],
	]);

	const [teamKrData, setTeamKrData] = useState<{ total: number; orks: IOkrRepresentativeness[] }>();
	const [teamKr, setTeamKr] = useState<EntityWithIdAndLabel | null>(null);
	const [teamKrChartData, setTeamKrChartData] = useState<(string | number)[][]>([
		['Key Results', 'Progress'],
		['Selecione Um Time', 100],
	]);

	const [responsableOkrData, setReponsableOkrData] = useState<{ total: number; orks: IOkrRepresentativeness[] }>();
	const [responsableOkr, setResponsableOkr] = useState<EntityWithIdAndLabel | null>(null);
	const [responsablesOptions, setResponsableOptions] = useState<EntityWithIdAndLabel[]>([]);
	const [responsableOkrChartData, setResponsableOkrChartData] = useState<(string | number)[][]>([
		['OKRs', 'Progress'],
		['Selecione Um Responsável', 100],
	]);

	const [responsableKrData, setReponsableKrData] = useState<{ total: number; orks: IOkrRepresentativeness[] }>();
	const [responsableKr, setResponsableKr] = useState<EntityWithIdAndLabel | null>(null);
	const [responsableKrChartData, setResponsableKrChartData] = useState<(string | number)[][]>([
		['Key Results', 'Progress'],
		['Selecione Um Time', 100],
	]);

	const handleTeamsOptions = async () => {
		await getAllTeamsOptions().then((response: ITeamData[]) => {
			const formatedArray = response.map(team => ({ id: team.id, label: team.description }));

			setTeamsOptions(formatedArray);
		});
	};

	const handleResponsableOptions = async () => {
		await getAllResponsablesOptions().then((response: IResponsableData[]) => {
			const formatedArray = response.map(responsable => ({
				id: responsable.id,
				label: responsable.person.name,
			}));

			setResponsableOptions(formatedArray);
		});
	};

	const handleTeamOkrChartData = async () => {
		if (teamOkr)
			await getOkrsRepresentativenessTeamReports(teamOkr.id, pageView).then(
				(response: { total: number; orks: IOkrRepresentativeness[] }) => {
					setTeamOkrData(response);
					const { orks } = response;
					const formatedData = orks.map(okr => [okr.description, Number(okr.progress)]);

					setTeamOkrChartData([['OKRs', 'Progresso'], ...formatedData]);
				},
			);
	};

	const handleResponsableOkrChartData = async () => {
		if (responsableOkr)
			await getOkrsRepresentativenessUserReports(responsableOkr.id, pageView).then(
				(response: { total: number; orks: IOkrRepresentativeness[] }) => {
					setReponsableOkrData(response);
					const { orks } = response;
					const formatedData = orks.map(okr => [okr.description, Number(okr.progress)]);

					setResponsableOkrChartData([['OKRs', 'Progresso'], ...formatedData]);
				},
			);
	};

	const handleTeamKrChartData = async () => {
		if (teamKr)
			await getKrsRepresentativenessTeamReports(teamKr.id, pageView).then(
				(response: { total: number; orks: IOkrRepresentativeness[] }) => {
					setTeamKrData(response);
					const { orks } = response;
					const formatedData = orks.map(okr => [okr.description, Number(okr.progress)]);

					setTeamKrChartData([['OKRs', 'Progresso'], ...formatedData]);
				},
			);
	};

	const handleResponsableKrChartData = async () => {
		if (responsableKr)
			await getKrsRepresentativenessUserReports(responsableKr.id, pageView).then(
				(response: { total: number; orks: IOkrRepresentativeness[] }) => {
					setReponsableKrData(response);
					const { orks } = response;
					const formatedData = orks.map(okr => [okr.description, Number(okr.progress)]);

					setResponsableKrChartData([['OKRs', 'Progresso'], ...formatedData]);
				},
			);
	};

	useEffect(() => {
		handleTeamOkrChartData();
	}, [teamOkr]);

	useEffect(() => {
		handleResponsableOkrChartData();
	}, [responsableOkr]);

	useEffect(() => {
		handleTeamKrChartData();
	}, [teamKr]);

	useEffect(() => {
		handleResponsableKrChartData();
	}, [responsableKr]);

	useEffect(() => {
		Promise.all([handleTeamsOptions(), handleResponsableOptions()]);
	}, []);

	return (
		<>
			<ItemTitle>Representatividade de Objetivos</ItemTitle>
			<Container>
				<Card title="Quantidade de Objetivos por Time" className="card">
					<Divider marginTop="0.5rem" marginBottom="1.5rem" />
					<CardHeader>
						<Autocomplete
							options={teamsOptions}
							value={teamOkr}
							onChange={(_, value) => {
								setTeamOkr(value);
							}}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							renderInput={params => (
								<TextField
									{...params}
									label="Selecionar Time"
									placeholder="Selecionar Time"
									variant="standard"
								/>
							)}
							className="autocomplete"
						/>
						{teamOkrData?.total && <Text>Quantidade de Objetivos: {teamOkrData.total}</Text>}
					</CardHeader>
					<Chart chartType="PieChart" data={teamOkrChartData} height={'325px'} />
				</Card>
				<Card title="Quantidade de Objetivos por Responsável" className="card">
					<Divider marginTop="0.5rem" marginBottom="1.5rem" />
					<CardHeader>
						<Autocomplete
							options={responsablesOptions}
							value={responsableOkr}
							onChange={(_, value) => {
								setResponsableOkr(value);
							}}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							renderInput={params => (
								<TextField
									{...params}
									label="Selecionar Responsável"
									placeholder="Selecionar Responsável"
									variant="standard"
								/>
							)}
							className="autocomplete"
						/>
						{responsableOkrData?.total && <Text>Quantidade de Objetivos: {responsableOkrData.total}</Text>}
					</CardHeader>
					<Chart chartType="PieChart" data={responsableOkrChartData} height={'325px'} />
				</Card>
			</Container>

			<ItemTitle>Representatividade de Key Results</ItemTitle>
			<Container>
				<Card title="Quantidade de Key Results por Time" className="card">
					<Divider marginTop="0.5rem" marginBottom="1.5rem" />
					<CardHeader>
						<Autocomplete
							options={teamsOptions}
							value={teamKr}
							onChange={(_, value) => {
								setTeamKr(value);
							}}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							renderInput={params => (
								<TextField
									{...params}
									label="Selecionar Time"
									placeholder="Selecionar Time"
									variant="standard"
								/>
							)}
							className="autocomplete"
						/>
						{teamKrData?.total && <Text>Quantidade de Key Results: {teamKrData.total}</Text>}
					</CardHeader>
					<Chart chartType="PieChart" data={teamKrChartData} height={'325px'} />
				</Card>
				<Card title="Quantidade de Key Results por Responsável" className="card">
					<Divider marginTop="0.5rem" marginBottom="1.5rem" />
					<CardHeader>
						<Autocomplete
							options={responsablesOptions}
							value={responsableKr}
							onChange={(_, value) => {
								setResponsableKr(value);
							}}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							renderInput={params => (
								<TextField
									{...params}
									label="Selecionar Responsável"
									placeholder="Selecionar Responsável"
									variant="standard"
								/>
							)}
							className="autocomplete"
						/>
						{responsableKrData?.total && <Text>Quantidade de Key Results: {responsableKrData.total}</Text>}
					</CardHeader>
					<Chart chartType="PieChart" data={responsableKrChartData} height={'325px'} />
				</Card>
			</Container>
		</>
	);
};
