import { useState } from 'react';
import { StepperComponent } from 'components/Stepper';
import { Container } from './style';
import { AboutForm } from './components/AboutForm';
import { MeasurementForm } from './components/MeasurementForm';
import { ParticipantsForm } from './components/ParticipantsForm';
import { Revision } from './components/Revision';

interface IFormProps {
	handleSubmitKeyResult: () => void;
	isEditForm: boolean;
}

const steps = ['Sobre', 'Medição', 'Participantes', 'Revisão'];

export const FormNewKeyResults = ({ isEditForm, handleSubmitKeyResult }: IFormProps) => {
	const [activeStep, setActiveStep] = useState(0);
	const [skipped, setSkipped] = useState(new Set<number>());

	const isStepSkipped = (step: number) => skipped.has(step);

	const handleNextStep = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep(prevActiveStep => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBackStep = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	};

	return (
		<>
			<Container>
				<StepperComponent activeStep={activeStep} steps={steps} />

				{activeStep === 0 && <AboutForm handleNextStep={handleNextStep} isEditForm={isEditForm} />}
				{activeStep === 1 && (
					<MeasurementForm
						handleNextStep={handleNextStep}
						isEditForm={isEditForm}
						handleBackStep={handleBackStep}
					/>
				)}
				{activeStep === 2 && (
					<ParticipantsForm
						handleNextStep={handleNextStep}
						handleBackStep={handleBackStep}
						isEditForm={isEditForm}
					/>
				)}
				{activeStep === 3 && (
					<Revision
						handleBackStep={handleBackStep}
						isEditForm={isEditForm}
						handleSubmitKeyResult={handleSubmitKeyResult}
					/>
				)}
			</Container>
		</>
	);
};
