import styled from 'styled-components';

export const ContainerForm = styled.div`
	position: relative;

	&::-webkit-scrollbar {
		width: 12px; /* width of the entire scrollbar */
	}

	&::-webkit-scrollbar-track {
		background: orange; /* color of the tracking area */
	}

	&::-webkit-scrollbar-thumb {
		background-color: blue;
		border-radius: 20px;
		border: 3px solid orange;
	}

	#submit-bttn {
		position: absolute;
		bottom: 1rem;
		right: 0;
	}
`;

export const FormSubTitle = styled.h5`
	font-family: Fira Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 1rem;
	color: var(--secondary-400);

	margin-top: 1.5rem;
`;

export const CompanieDataContainer = styled.div`
	margin-top: 2.125rem;
	margin-bottom: 2.5rem;
	display: flex;
	align-items: center;
	gap: 50px;
	flex-direction: column;

	.container-multi-inputs {
		display: flex;
		gap: 3rem;
		width: 100%;
		flex-direction: column;

		@media (min-width: 1024px) {
			& {
				gap: 24px;
				flex-direction: row;

				.small-input-container {
					width: 150px;
				}

				.medium-input-container {
					width: 300px;
				}
			}
		}
	}
`;
