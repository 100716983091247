import React, { useContext, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useModal } from 'hooks/useModal';

import { AuthContext } from 'domains/Authentication/hooks/useAuth';
import { Formik } from 'formik';

import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { IUser } from 'domains/Authentication/interfaces/IUser';
import { getManagersRequest } from 'domains/Objectives/services/ObjectivesServices';
import { IFormNewTeamData } from '../../interfaces/IFormNewTeamData';

import { Container } from './style';
import { AutocompleteMembers } from '../AutocompleteMembers';
import { storeNewTeamRequest } from '../../services/TeamsServices';

interface IFormNewTeamsProps {
	membersList: {
		id: string;
		person: {
			name: string;
		};
	}[];
}

interface IEntityWithIdAndLabel {
	id: string;
	label: string;
}

export const FormNewTeams: React.FC<IFormNewTeamsProps> = ({ membersList }) => {
	const { user } = useContext(AuthContext);
	const { handleModalState } = useModal();

	const [formData] = useState<IFormNewTeamData>({
		id: '',
		description: '',
		leader: {
			id: '',
			name: '',
			image: '',
		},
		members: [],
	});
	const [responsable, setResponsable] = useState<IEntityWithIdAndLabel | null>(null);
	const [responsableOptions, setResponsableOptions] = useState<IEntityWithIdAndLabel[]>([]);

	const handleSubmit = async (values: IFormNewTeamData) => {
		const formatedValues = {
			description: values.description,
			leader: responsable?.id,
			members: values.members.map(member => member.id),
		};

		await storeNewTeamRequest({ ...formatedValues }).then(() => handleModalState());
	};

	useEffect(() => {
		const getManagersOptions = async () => {
			const data = await getManagersRequest();
			const managersArray = data.data;
			const formatedArray = managersArray.map((item: IUser) => ({
				id: item.person.id,
				label: item.person.name,
			}));
			setResponsableOptions(formatedArray);
		};

		Promise.all([getManagersOptions()]);
	}, []);

	return (
		<Container>
			<Formik
				initialValues={formData}
				onSubmit={async (values: IFormNewTeamData) => {
					handleSubmit(values);
				}}
			>
				{props => (
					<form
						onSubmit={e => {
							e.preventDefault();
							props.handleSubmit(e);
						}}
					>
						<Input
							name="description"
							placeholder=""
							label="Nome do Time"
							value={props.values.description}
							onChange={props.handleChange}
							required
						/>
						<div>
							<Autocomplete
								options={responsableOptions}
								value={responsable}
								onChange={(_, value) => {
									props.setFieldValue('leader.id', value?.id, true);
									setResponsable(value);
								}}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								renderInput={params => (
									<TextField
										{...params}
										label="Gestor"
										variant="standard"
										name="responsibleId"
										required
									/>
								)}
								style={{ marginBottom: '1rem' }}
							/>

							<button
								className="self-assign-btn"
								onClick={() => setResponsable({ id: user.person.id, label: user.person.name })}
								type="button"
							>
								Atribuir a mim
							</button>
						</div>
						<div style={{ paddingBottom: '1rem' }}>
							{membersList !== undefined && (
								<AutocompleteMembers
									name="members"
									label="Profissionais"
									value={props.values.members}
									options={membersList.map(member => ({ id: member.id, name: member.person.name }))}
									onChange={(_, value) => props.setFieldValue('members', value)}
								/>
							)}
						</div>
						<Button type="submit" bgColor="primary">
							Criar Time
						</Button>
					</form>
				)}
			</Formik>
		</Container>
	);
};
