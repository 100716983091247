import { Link } from 'react-router-dom';

import { Container } from './style';

interface NavProps {
	pageType: string;
}

export const Nav: React.FC<NavProps> = ({ pageType }) => (
	<Container pageType={pageType}>
		<Link to="/objectives/user">Você</Link>

		<Link to="/objectives/manager">Painel do Gestor</Link>

		<Link to="/objectives/team">Times</Link>

		<Link to="/objectives/company">Empresa</Link>
	</Container>
);
