import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	.modal-btn {
		margin-left: 0;
		margin-right: 0;
		margin-top: 2rem;
		align-self: center;
		width: 100%;

		button {
			max-width: 500px;
		}
	}

	& b {
		font: var(--body-md-500);
		color: var(--gray-700);
	}

	& p {
		font: var(--body-xs-500);
		color: var(--gray-500);
		text-align: left;
		width: 100%;
	}

	.time-content {
		display: flex;
		margin-top: 5px;
		justify-content: space-between;

		.date {
			overflow-wrap: break-word;
			width: 100%;
			max-width: 229px;
		}
	}

	.checkbox-container {
		& span {
			padding-left: 0;
		}
		margin-bottom: -5px;
	}

	.items-container {
		overflow-y: scroll;
		max-height: 200px;
		min-height: 200px;

		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			background: var(--gray-200);
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--gray-400);
			border-radius: 4px;
		}
	}

	.submit-btn {
		margin-top: 2.5rem;
		width: 12rem;
		margin-left: 70%;
	}

	@media (max-width: 992px) {
		gap: 8px;

		.time-content {
			flex-direction: column;
			gap: 8px;

			margin-top: 0;

			.date {
				width: 220%;
			}
		}
	}

	@media (max-width: 425px) {
		.submit-btn {
			margin-left: auto;
		}
	}
`;
