export const measureOptions = [
	{
		label: 'Percentual (%)',
		id: '1',
		name: 'percentage',
	},
	{
		label: 'Reais (R$)',
		id: '2',
		name: 'real',
	},
	{
		label: 'NPS',
		id: '3',
		name: 'nps',
	},
	{
		label: 'Transações',
		id: '4',
		name: 'transactions',
	},
	{
		label: 'Dias',
		id: '5',
		name: 'days',
	},
	{
		label: 'Outros',
		id: '6',
		name: 'others',
	},
];
