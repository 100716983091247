import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;

	& > div {
		display: flex;
		flex-direction: column;

		padding: 0 3rem 2.5rem;
	}

	& > div > form {
		display: flex;
		gap: 1.5rem;

		margin-bottom: 1rem;

		& fieldset {
			height: 3.5rem;
		}
	}

	@media (max-width: 48rem) {
		& > div {
			max-width: 32rem;

			margin: 0 auto;
			padding: 0 1.25rem;
		}
	}
`;
