import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'domains/Authentication/hooks/useAuth';
import { ITeamData } from 'domains/Teams/interfaces/ITeamData';
import {
	EntityWithIdAndLabel,
	IArea,
	IMember,
	IObjectivesFormData,
	TeamsOptions,
} from '../interfaces/FormNewObjectivesData';
import { IObjectiveData } from '../interfaces/IObjectiveData';
import { getAreasRequest, getTeamsRequest, getUserByIdRequest } from '../services/ObjectivesServices';

interface FormEditObjectivesContextData {
	handleFormData(formData: IObjectivesFormData): void;
	formData: IObjectivesFormData;
	selectedOptionsGeralForm: ISelectedOptionsGeralForm;
	handleSelectedOptionsGeralForm(options: ISelectedOptionsGeralForm): void;
	handleIsEditForm: () => void;
}

interface ISelectedOptionsGeralForm {
	responsable: EntityWithIdAndLabel | null;
	company: {
		id: string;
		name: string;
	};
	team: {
		id: string;
		label: string;
		members: {
			id: string;
			name: string;
			image: string;
		}[];
	};
	correspondences: {
		areas: IArea[];
		teams: TeamsOptions[];
	};
	selectedProfessionals: IMember[];
}

interface IProviderProps {
	children: React.ReactNode;
	objective: IObjectiveData;
	handleIsEditForm: () => void;
}

const FormEditObjectivesContext = createContext<FormEditObjectivesContextData>({} as FormEditObjectivesContextData);

export const defaultTeam = {
	id: '',
	label: '',
	members: [
		{
			id: '',
			name: '',
			image: '',
		},
	],
};

const FormEditObjectivesProvider = ({ children, objective, handleIsEditForm }: IProviderProps) => {
	const { user } = useContext(AuthContext);

	const [formData, setFormData] = useState<IObjectivesFormData>({
		name: objective.name,
		levelId: objective.levelId || '',
		responsableId: objective.responsableId,
		description: objective.description || '',
		successVision: objective.successVision || '',
		failVision: objective.failVision || '',
		strategyAlignment: objective.strategyAlignment || '',
		strategyAlignmentID: 'none',
		correspondences: {
			areas: objective.correspondences?.areas.map(areas => areas.id) || [''],
			teams: objective.correspondences?.teams.map(teams => teams.id) || [''],
		},
		weight: objective.weight,
		isPrivate: objective.isPrivate || false,
		userId: user.id,
		teams: objective.teams || [
			{
				id: '',
				members: [
					{
						id: '',
						name: '',
						image: '',
					},
				],
			},
		],
		companyId: user.companyId,
		finalDate: objective.date,
	});

	const [selectedOptionsGeralForm, setSelectedOptionsGeralForm] = useState<ISelectedOptionsGeralForm>({
		responsable: null,
		company: {
			id: user.companyId,
			name: '',
		},
		team: defaultTeam,
		correspondences: {
			areas: [],
			teams: [],
		},
		selectedProfessionals: [
			{
				id: '',
				name: '',
				image: '',
			},
		],
	});

	const handleFormData = (formData: IObjectivesFormData) => {
		setFormData({ ...formData });
	};

	const handleSelectedOptionsGeralForm = (options: ISelectedOptionsGeralForm) => {
		setSelectedOptionsGeralForm({ ...options });
	};

	useEffect(() => {
		const handleDatas = async () => {
			const getUserById = async () => {
				const user = await getUserByIdRequest(objective.responsableId);

				return user;
			};

			const getFormatedTeams = async () => {
				const data = await getTeamsRequest();
				const teamsArray = data.data;
				const formatedArray = teamsArray.map((team: ITeamData) => ({
					id: `${team.id}`,
					label: team.description,
					members: team.members,
				}));

				return formatedArray;
			};

			const getTeam = async () => {
				const formatedArray = await getFormatedTeams();

				if (objective.teams) {
					const teamId = objective.teams[0].id;
					const selectedProfessionalsIds = objective.teams[0].members.map(member => member.id);

					const selectedTeam = formatedArray.filter((team: ITeamData) => `${team.id}` === teamId) || [];

					const selectedTeamMembers = selectedTeam[0]?.members || [];
					const selectedProfessionals = selectedTeamMembers.filter((member: IMember) =>
						selectedProfessionalsIds.includes(member.id),
					);

					if (selectedTeam.length > 0)
						return {
							id: selectedTeam[0].id,
							label: selectedTeam[0].label,
							members: [...selectedProfessionals],
						};
				}
				return defaultTeam;
			};

			const getCorrespondences = async () => {
				const areas = await getAreasRequest();
				const teams = await getFormatedTeams();

				const selectedAreas = areas.filter((area: IArea) =>
					objective.correspondences?.areas.some(compareArea => compareArea.id === area.id),
				);
				const selectedTeams = teams.filter((team: IArea) =>
					objective.correspondences?.teams.some(compareTeam => compareTeam.id === team.id),
				);

				return { selectedAreas, selectedTeams };
			};

			const user = await getUserById();
			const selectedTeam = await getTeam();
			const { selectedAreas, selectedTeams } = await getCorrespondences();

			handleSelectedOptionsGeralForm({
				...selectedOptionsGeralForm,
				responsable: {
					id: objective.responsableId,
					label: user.person.name,
				},
				company: {
					id: user.companyId,
					name: '',
				},
				team: selectedTeam,
				correspondences: {
					areas: selectedAreas,
					teams: selectedTeams,
				},
			});
		};

		handleDatas();
	}, []);

	return (
		<FormEditObjectivesContext.Provider
			value={{
				formData,
				handleFormData,
				selectedOptionsGeralForm,
				handleSelectedOptionsGeralForm,
				handleIsEditForm,
			}}
		>
			{children}
		</FormEditObjectivesContext.Provider>
	);
};

const useFormEditObjectives = (): FormEditObjectivesContextData => {
	const context = useContext(FormEditObjectivesContext);

	if (!context) {
		throw new Error('useModal must be used within a FormNewObjectivesProvider');
	}

	return context;
};

export { FormEditObjectivesProvider, useFormEditObjectives };
