import styled from 'styled-components';

interface NavOptionsProps {
	selected: boolean;
}

export const Container = styled.nav`
	display: flex;
	align-items: center;
	gap: 4rem;

	width: 100%;
	height: 4.5rem;

	padding: 0 3rem;

	box-shadow: var(--elevation-400);

	@media (max-width: 48rem) {
		justify-content: center;
		gap: 2rem;

		padding: 0 1.25rem;
	}
`;

export const NavOption = styled.button<NavOptionsProps>`
	padding: 1.5rem 0;
	background: none;
	width: auto;
	font: ${props => (props.selected ? 'var(--body-lg-500)' : 'var(--body-lg-400)')};
	color: ${props => (props.selected ? 'var(--terciary-400)' : 'var(--gray-400)')};

	border: none;
	border-bottom: ${props => props.selected && '4px solid var(--terciary-400)'};

	@media (max-width: 30rem) {
		border-bottom: none;
	}
`;
