import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Container } from './style';

interface BreadcrumbsProps {
	urls: {
		path: string;
		name: string;
	}[];
	localUrl: string;
	title: string;
}

export const BreadcrumbComponent = (props: BreadcrumbsProps) => {
	const navigate = useNavigate();
	const onlyLinks = props.urls.length > 1 && props.urls ? props.urls.splice(-1, 1) : props.urls;

	return (
		<Container>
			<ArrowBackIcon
				fontSize="medium"
				onClick={() => {
					navigate(props.localUrl);
				}}
				className="backIcon"
			/>
			<Stack spacing={2}>
				<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
					{onlyLinks.map(url => (
						<Link
							underline="hover"
							key="1"
							color="inherit"
							href={url.path}
							className="link"
							style={{ textDecoration: 'none' }}
						>
							{url.name}
						</Link>
					))}
					<Typography key="3" className="title">
						{props.title}
					</Typography>
				</Breadcrumbs>
			</Stack>
		</Container>
	);
};
