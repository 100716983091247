import React, { useState } from 'react';
import { Formik } from 'formik';

import { Button } from 'components/Button';
import { Input } from 'components/Input';

import { InfoPersonProps, IPersonDataV3 } from '../../interfaces/IPersonData';
import { putNewPersonsRequest } from '../../services/PersonsService';

import { Container } from './style';

export const EditPerson: React.FC<InfoPersonProps> = ({ person }) => {
	const [formData] = useState<IPersonDataV3>(() => ({
		id: person.id,
		image: person.image,
		companyRegister: person.companyRegister,
		email: person.email,
		isAdmin: person.isAdmin,
		phone: person.phone,
		person: {
			birthday: person.person.birthday,
			cpf: person.person.cpf,
			gender: person.person.gender,
			id: person.person.id,
			name: person.person.name,
			passport: person.person.passport,
		},
	}));

	const handleSubmit = async (values: IPersonDataV3) => {
		await putNewPersonsRequest({ ...values });
	};

	return (
		<Container>
			<Formik
				initialValues={formData}
				onSubmit={async (values: IPersonDataV3) => {
					handleSubmit(values);
				}}
			>
				{props => (
					<form
						onSubmit={e => {
							e.preventDefault();
							props.handleSubmit(e);
						}}
					>
						<Input
							name="person.name"
							placeholder=""
							label="Nome"
							value={props.values.person.name}
							onChange={props.handleChange}
						/>

						<Input
							name="email"
							placeholder=""
							label="Email"
							value={props.values.email}
							onChange={props.handleChange}
						/>

						<Input
							name="person.cpf"
							placeholder=""
							label="CPF"
							mask="cpf"
							value={props.values.person.cpf}
							onChange={props.handleChange}
						/>

						<Input
							name="companyRegister"
							placeholder=""
							label="Matrícula"
							value={props.values.companyRegister}
							onChange={props.handleChange}
						/>

						<Input
							name="phone"
							placeholder=""
							label="Telefone"
							value={props.values.phone}
							onChange={props.handleChange}
						/>

						<Button type="submit" bgColor="primary">
							Salvar Alterações
						</Button>
					</form>
				)}
			</Formik>
		</Container>
	);
};
