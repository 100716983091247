import styled from 'styled-components';
import { Divider } from 'styles/globalStyledComponents';
import { Text } from '../style';

export const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem;

	h3 {
		color: var(--gray-700);
	}

	@media (min-width: 769px) {
		gap: 2%;
	}

	.card {
		max-height: 600px;

		@media (min-width: 769px) {
			width: 48%;
		}

		@media (max-width: 375px) {
			${Divider} {
				min-width: 10rem;
			}
		}
	}
`;

export const CardHeader = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 1.5rem;
	width: 100%;
	flex-wrap: wrap;
	${Text} {
		margin-bottom: 5px;
	}

	.autocomplete {
		width: 55%;

		min-width: 12rem;

		@media (min-width: 769px) {
			width: 100%;
		}
	}
`;
