import styled from 'styled-components';
import { Divider } from 'styles/globalStyledComponents';

interface ColumnType {
	type: 'todo' | 'doing' | 'done';
	isDraggingOver: boolean;
}

export const ColumnContainer = styled.div<ColumnType>`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5rem;

	width: 18rem;

	min-height: 12rem;
	height: auto;

	border-top: 4px solid
		${props =>
			(props.type === 'todo' && 'var(--red-400) !important') ||
			(props.type === 'doing' && 'var(--yellow-400) !important') ||
			(props.type === 'done' && 'var(--green-400) !important')};

	border-radius: 4px;
	background-color: ${props => (props.isDraggingOver ? 'var(--gray-200)' : 'var(--gray-50)')};
	padding: 1rem;
`;

export const ColumnTitle = styled.h1`
	color: var(--gray-700);

	align-self: flex-start;
	margin-bottom: -0.5rem;

	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 135%;
`;

export const Card = styled.div`
	min-height: 10rem;
	width: 16rem;
	max-width: 16rem;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;

	box-shadow: var(--elevation-200);
	background-color: #ffffff;
	padding: 1rem;

	cursor: context-menu !important;

	${Divider} {
		min-width: 5rem;
		width: 100%;
	}
`;

export const DescriptionText = styled.p`
	display: flex;

	color: var(--gray-500);
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 135%;

	svg {
		height: 1rem;
		width: 1rem;
		margin-right: 5px;
	}
`;

export const TaskTitle = styled.h3`
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;

	color: var(--gray-700);
`;

export const ProfileContainer = styled.div`
	display: flex;
	align-items: center;

	gap: 0.5rem;

	img {
		height: 1.5rem;
		width: 1.5rem;
		border-radius: 50%;

		box-shadow: var(--elevation-200);
	}

	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 135%;
	color: var(--gray-700);
`;

export const BottomDescription = styled.div`
	display: flex;
	gap: 1rem;
`;
