import { ICompanieCardData } from 'domains/Companies/Interfaces/ICompanieCardData';
import { useNavigate } from 'react-router-dom';

import { Container, Header, Divider, DataContainer, DataContentContainer, Button } from './style';

import ContributorsIcon from '../../assets/ContributorsIcon.svg';
import CyclesIcon from '../../assets/CyclesIcon.svg';
import ProcessesIcon from '../../assets/ProcessesIcon.svg';
import ResponsibleIcon from '../../assets/ResponsibleIcon.svg';

export const CompanyCard = (props: ICompanieCardData) => {
	const navigate = useNavigate();

	const goToCompanieDetailsPage = () => {
		navigate('/companies/details', {
			state: {
				socialReason: props.socialReason,
				fantasyName: props.fantasyName,
				companyId: props.id,
			},
		});
	};

	return (
		<Container>
			<Header>
				<img src={props.image} alt="Foto" />
				<h2>{props.fantasyName}</h2>
			</Header>
			<Divider />
			<DataContentContainer>
				<DataContainer>
					<img src={ResponsibleIcon} alt="Responsável" />
					<p>
						<b>Responsável:</b> {props.responseble}
					</p>
				</DataContainer>
				<DataContainer>
					<img src={ContributorsIcon} alt="Colaboradores" />
					<p>
						<b>Colaboradores:</b> {props.contributors}
					</p>
				</DataContainer>
				<DataContainer>
					<img src={ProcessesIcon} alt="Processos" />
					<p>
						<b>Processos:</b> {props.processes}
					</p>
				</DataContainer>
				<DataContainer>
					<img src={CyclesIcon} alt="Ciclos" />
					<p>
						<b>Ciclos:</b> {props.cycles}
					</p>
				</DataContainer>
			</DataContentContainer>
			<Button onClick={goToCompanieDetailsPage}>Ver perfil</Button>
		</Container>
	);
};
