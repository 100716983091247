import { useState } from 'react';
import { useFormNewKeyResults } from 'domains/Objectives/contexts/FormNewKeyResults';
import { useFormEditKeyResults } from 'domains/Objectives/contexts/FormEditKeyResults';
import { putEditKeyResults, storeNewKeyResults } from 'domains/Objectives/services/ObjectivesServices';
import { keyResultFinalitysOptions } from 'domains/Objectives/utils/keyResultFinalitysOptions';

import { Button } from 'components/Button';
import { ButtonsContainer } from '../../style';
import { Container, Items } from './style';

interface IParticipantsFormProps {
	handleSubmitKeyResult: () => void;
	handleBackStep: () => void;
	isEditForm: boolean;
}

export const Revision = ({ handleBackStep, isEditForm, handleSubmitKeyResult }: IParticipantsFormProps) => {
	const { formData, selectedOptionsForm, keyResultId, objectiveId } = isEditForm
		? useFormEditKeyResults()
		: useFormNewKeyResults();

	const [isLoadingRequest, setIsLoadingRequest] = useState(false);

	const handleSubmitStoreKeyResult = async () => {
		setIsLoadingRequest(true);

		if (!isEditForm) {
			await storeNewKeyResults(formData, objectiveId).then(() => {
				setIsLoadingRequest(false);
			});
		} else {
			await putEditKeyResults(formData, objectiveId, keyResultId).then(() => {
				setIsLoadingRequest(false);
			});
		}
		handleSubmitKeyResult();
	};

	const getKeyByValue = (object: { [key: string]: number }, value: number) =>
		Object.keys(object).find(key => object[key] === value);

	const formatDate = (date: string): string => {
		const dateObject = new Date(date);
		const day = dateObject.getDate();
		const month = dateObject.getMonth() + 1;
		const year = dateObject.getFullYear();

		return `${day}/${month}/${year}`;
	};

	return (
		<Container>
			<h5>Sobre</h5>

			<Items>
				<h6>Título do Key Result:</h6>
				<p>{formData.result}</p>
			</Items>

			<Items>
				<h6>Descrição:</h6>
				<p>{formData.description}</p>
			</Items>

			<Items>
				<h6>Data Final:</h6>
				<p>{formatDate(formData.finalDate)}</p>
			</Items>

			<h5>Medição</h5>

			<Items>
				<h6>Finalidade:</h6>
				<p>{getKeyByValue(keyResultFinalitysOptions, formData.polarity.type)}</p>
			</Items>

			<Items>
				<h6>Valor Inicial:</h6>
				<p>
					{formData.polarity.initialValue.toLocaleString('pt-BR', {
						minimumFractionDigits: 2,
						style: 'currency',
						currency: 'BRL',
					})}
				</p>
			</Items>

			<Items>
				<h6>Valor Final:</h6>
				<p>
					{formData.polarity.completedValue.toLocaleString('pt-BR', {
						minimumFractionDigits: 2,
						style: 'currency',
						currency: 'BRL',
					})}
				</p>
			</Items>

			<Items>
				<h6>Medida:</h6>
				<p>{formData.measure.name}</p>
			</Items>

			<Items>
				<h6>Peso:</h6>
				<p>{formData.weight}</p>
			</Items>

			{formData.polarity.type === 4 && (
				<Items>
					<h6>Data de Entrega:</h6>
					<p>{formatDate(formData.deliveryDate)}</p>
				</Items>
			)}

			<h5>Participantes</h5>
			<Items>
				<h6>Gestor:</h6>
				<p>{selectedOptionsForm.responsable?.label}</p>
			</Items>

			<Items>
				<h6>Contribuintes Diretos:</h6>
				<div>
					<strong>{selectedOptionsForm.team.label}</strong>
					<div className="contributors-container">
						{selectedOptionsForm.selectedProfessionals.map(member => (
							<div className="professional-container" key={member.id}>
								<img src={member.image} alt={member.name} />
								<p>{member.name}</p>
							</div>
						))}
					</div>
				</div>
			</Items>

			<ButtonsContainer>
				<button type="button" className="back-btn" onClick={() => handleBackStep()}>
					Voltar
				</button>
				<Button
					type="button"
					bgColor="primary"
					className="continue-btn"
					onClick={() => handleSubmitStoreKeyResult()}
					isLoading={isLoadingRequest}
					disabled={isLoadingRequest}
				>
					{isEditForm ? 'Salvar Alterações' : 'Criar Key Result'}
				</Button>
			</ButtonsContainer>
		</Container>
	);
};
