import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

interface ContainerProps {
	heightState: string;
	collapseState: boolean;
	isCollapsed: boolean;
}

interface HeadProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	collapseState: boolean;
	isCollapsed: boolean;
}

interface ContentProps {
	isCollapsed: boolean;
}

interface ButtonCollapseProps {
	collapseState: boolean;
}

export const Container = styled.div<ContainerProps>`
	position: relative;

	width: 100%;
	max-height: ${props => (props.isCollapsed && props.heightState) || '10rem'};

	box-shadow: var(--elevation-200);
	border-radius: 0.25rem;

	overflow: ${props => props.isCollapsed && 'hidden'};
	transition: all 0.2s ease-in;
`;

export const Content = styled.div<ContentProps>`
	margin: 0 1rem;
	padding: ${props => (props.isCollapsed ? '1rem 0' : '0 0 1rem')};

	border-top: ${props => (props.isCollapsed ? '1px solid var(--gray-200)' : 'none')};
`;

export const Head = styled.div<HeadProps>`
	display: flex;
	justify-content: space-between;

	padding: 1rem;

	cursor: ${props => props.isCollapsed && 'pointer'};
`;

export const Title = styled.h3`
	font: var(--body-lg-500);
	color: var(--secondary-400);
`;

export const ButtonCollapse = styled.button<ButtonCollapseProps>`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 1.5rem;
	height: 1.5rem;

	margin-left: auto;

	background-color: transparent;
	border: none;

	transition: all 0.2s ease-in;

	svg {
		transform: ${props => props.collapseState && 'rotate(180deg)'};
		transition: all 0.4s ease-in;
	}
`;
