import { Link } from 'react-router-dom';
import { ReactComponent as AdvyLogo } from '../assets/advy-logo.svg';
import { Wrapper } from '../components/Wrapper';
import { FormLogin } from '../components/FormLogin';

export const Login: React.FC = () => (
	<Wrapper>
		<AdvyLogo />

		<FormLogin />

		<Link to="/recovery-password">Esqueceu sua senha? Clique aqui!</Link>
	</Wrapper>
);
