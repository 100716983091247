import { useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';

import api from 'services/api';

import { useCycle } from 'hooks/useCycles';
import { ModalProvider } from 'hooks/useModal';
import { ContentContainer } from 'components/ContentContainer';
import { BreadcrumbBar } from 'components/BreadcrumbBar';

import { Main } from '../components/Main';
import { Nav } from '../components/Nav';
import { FormNewTeams } from '../components/FormNewTeams';
import { Search } from '../components/Search';
import { TeamList } from '../components/TeamList';

import { ITeamData } from '../interfaces/ITeamData';
import { IMembersData } from '../interfaces/IMembersData';

export const TeamsPage: React.FC = () => {
	const { cycle } = useCycle();
	const [teamFilter, setTeamFilter] = useState('');
	const [teamList, setTeamList] = useState<ITeamData[]>();
	const [membersList, setMembersList] = useState<IMembersData[]>();

	const handleChangeFilterPerson = (event: SelectChangeEvent) => {
		setTeamFilter(event.target.value as string);
	};

	useEffect(() => {
		api.get(`cycles/${cycle.id}/teams`).then(response => {
			setTeamList(response.data.data);
		});

		api.get('users').then(response => {
			setMembersList(response.data.data);
		});
	}, [cycle]);

	return (
		<>
			<ContentContainer>
				<Main>
					<Nav />

					<div>
						<ModalProvider>
							<BreadcrumbBar
								title="Times Cadastrados"
								buttonContent="Novo Time"
								modalTitle="Novo Time"
								hasButton
							>
								{teamList !== undefined && membersList !== undefined && (
									<FormNewTeams membersList={membersList} />
								)}
							</BreadcrumbBar>
						</ModalProvider>

						<Search onChange={handleChangeFilterPerson} />

						{teamList && <TeamList teamList={teamList} teamFilter={teamFilter} />}
					</div>
				</Main>
			</ContentContainer>
		</>
	);
};
