import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

interface ContainerProps {
	type?: 'error' | 'success';
}

export const Container = styled(animated.div)<ContainerProps>`
	position: relative;

	display: flex;
	justify-content: space-between;

	max-width: 46rem;
	width: 100%;

	margin: 0 auto;
	padding: 1.5rem;
	background-color: var(--white);

	border-radius: 0.5rem;
	box-shadow: var(--elevation-800);

	& + div {
		margin-top: 0.5rem;
	}

	${props =>
		props.type === 'error' &&
		css`
			border-top: 0.5rem solid var(--red-400);
		`}

	${props =>
		props.type === 'success' &&
		css`
			border-top: 0.5rem solid var(--green-400);
		`}

	p {
		font: var(--body-md-400);
		color: var(--gray-700);
	}

	a,
	button {
		background-color: transparent;

		border: none;

		font: var(--body-md-500);
		color: var(--terciary-400);

		transition: all 0.4s ease-in-out;

		&:hover {
			filter: brightness(88%);
		}
	}
`;
