import { useCallback, useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { FiInfo, FiEdit, FiTrash2 } from 'react-icons/fi';
import { ModalProvider } from 'hooks/useModal';

import { Button } from 'components/Button';
import { deleteTeamRequest } from '../../services/TeamsServices';

import { InfoTeams } from './InfoTeams';
import { FormEditTeams } from './FormEditTeams';

import { ModalButton, ModalButtonSettings, Overlay, Container, Head } from './style';

import { IModalProps } from './IModelProps';

export const SettingsModal: React.FC<IModalProps> = ({ team }) => {
	const [openModal, setOpenModal] = useState(false);
	const [openModalSettings, setOpenModalSettings] = useState(false);
	const [openModalType, setOpenModalType] = useState('');

	const modalItems = [
		{
			hasModal: true,
			title: 'Detalhes do time',
			name: 'Ver detalhes',
			icon: 'FiInfo',
		},
		{
			hasModal: true,
			title: 'Editar time',
			name: 'Editar time',
			icon: 'FiEdit',
		},
		{
			hasModal: false,
			title: '',
			name: 'Excluir time',
			icon: 'FiTrash2',
		},
	];

	const handleOpenModalSettings = useCallback(
		async (type: string) => {
			setOpenModalSettings(true);

			if (openModalSettings) {
				setOpenModalSettings(false);
				setOpenModal(!openModal);
				setOpenModalType('');

				if (type === 'Ver detalhes') {
					setOpenModalType('Detalhes do time');
				}

				if (type === 'Editar time') {
					setOpenModalType('Editar time');
				}

				if (team !== undefined && type === 'Excluir time') {
					setOpenModal(false);
					setOpenModalType('');

					await deleteTeamRequest(team.id);
				}
			}
		},
		[openModalSettings],
	);

	return (
		<>
			<Overlay
				openModal={openModal}
				openModalSettings={openModalSettings}
				onClick={() => {
					setOpenModal(false);
					setOpenModalSettings(false);
				}}
			/>

			<ModalButtonSettings onClick={() => handleOpenModalSettings('')}>
				<span />
				<span />
				<span />
			</ModalButtonSettings>

			<Container openModalSettings={openModalSettings} openModal={openModal} modalType={openModalType}>
				{openModalSettings &&
					modalItems.map(item => (
						<button onClick={() => handleOpenModalSettings(item.name)} key={item.name}>
							{item.icon === 'FiInfo' && <FiInfo />}
							{item.icon === 'FiEdit' && <FiEdit />}
							{item.icon === 'FiTrash2' && <FiTrash2 />}
							{item.name}
						</button>
					))}
			</Container>

			<ModalProvider>
				{openModal && (
					<Container openModal={openModal}>
						<Head openModal={openModal} openModalSettings={openModalSettings}>
							<h4>
								{openModalType === 'Detalhes do time' && 'Detalhes do time'}
								{openModalType === 'Editar time' && 'Editar time'}
							</h4>

							<MdOutlineClose
								onClick={() => setOpenModal(!openModal)}
								size={24}
								color="var(--gray-700)"
							/>
						</Head>

						{team !== undefined && openModalType === 'Detalhes do time' && (
							<>
								<InfoTeams team={team} />

								<ModalButton onClick={() => setOpenModalType('Editar time')}>
									<Button bgColor="primary">Editar Informações</Button>
								</ModalButton>
							</>
						)}

						{team !== undefined && openModalType === 'Editar time' && (
							<FormEditTeams team={team} handleModalState={() => setOpenModal(!openModal)} />
						)}

						{openModalType === 'Excluir time' && <span />}
					</Container>
				)}
			</ModalProvider>
		</>
	);
};
