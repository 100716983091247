import { useState, useEffect } from 'react';
import { SelectChangeEvent } from '@mui/material';

import api from 'services/api';
import { useCycle } from 'hooks/useCycles';

import { BreadcrumbBar } from 'components/BreadcrumbBar';
import { ContentContainer } from 'components/ContentContainer';

import { Main } from '../components/Main';
import { Nav } from '../components/Nav';
import { Search } from '../components/Search';
import { ObjectiveList } from '../components/ObjectivesList';
import { DragAndDrop } from '../components/DragAndDrop';

import { IObjectiveData } from '../interfaces/IObjectiveData';
import { FormNewObjectives } from '../components/FormNewObjectives';
import { FormNewTasks } from '../components/FormNewTasks';

export const ObjectivesCompanyPage: React.FC = () => {
	const { cycle } = useCycle();

	const [teamFilter, setTeamFilter] = useState('');
	const [objectiveList, setObjectiveList] = useState<IObjectiveData[]>();
	const [isSwitchActived, setIsSwitchActived] = useState(false);
	const [reloadTasks, setReloadTasks] = useState(false);

	const handleChangeFilterPerson = (event: SelectChangeEvent) => {
		setTeamFilter(event.target.value as string);
	};

	const handleSwitchActived = () => {
		setIsSwitchActived(!isSwitchActived);
	};

	useEffect(() => {
		if (cycle.id)
			api.get(`cycles/${cycle.id}/objectives`).then(response => {
				setObjectiveList(response.data.data);
			});
	}, [cycle]);

	const switchProps = {
		leftTitle: 'OKRs',
		rightTitle: 'Tarefas',
		handleSwitchActived,
		switchState: isSwitchActived,
	};

	return (
		<>
			<ContentContainer>
				<Main>
					<Nav pageType="company" />

					<div>
						<BreadcrumbBar
							title={isSwitchActived ? 'Tarefas Cadastradas' : 'Objetivos Cadastrados'}
							buttonContent={isSwitchActived ? 'Nova Tarefa' : 'Novo Objetivo'}
							hasButton
							modalTitle={isSwitchActived ? 'Nova Tarefa' : 'Novo Objetivo'}
							hasSwitch
							switchProps={switchProps}
						>
							{isSwitchActived && (
								<FormNewTasks
									isEditForm={false}
									isDetailsForm={false}
									reloadTasks={() => setReloadTasks(!reloadTasks)}
								/>
							)}
							{!isSwitchActived && <FormNewObjectives isEditForm={false} />}
						</BreadcrumbBar>

						{!isSwitchActived && (
							<>
								<Search onChange={handleChangeFilterPerson} />

								{objectiveList && (
									<ObjectiveList objectiveList={objectiveList} objectiveFilter={teamFilter} />
								)}
							</>
						)}
					</div>
					{isSwitchActived && <DragAndDrop reloadTasks={() => setReloadTasks(!reloadTasks)} />}
				</Main>
			</ContentContainer>
		</>
	);
};
